import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import TableListTemplate from '../components/template/settings/TableListTemplate';
import { useLobbyTableListQuery } from '../store/api/holdem';
import TablePreview from '../components/template/settings/TablePreview';
import { closeModal, ModalType, showModal } from '../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useDevice from '../hooks/useDevice';
import usePageVisibility from '../hooks/usePageVisibility';
import { LobbyTableList } from '../store/api/responseTypes';
import _ from 'lodash';
import LobbyBanners from '../components/template/settings/LobbyBanners';
import BaseButton from '../components/common/button/BaseButton';
import Footer from '../components/template/footer/Footer';
import { useAuthContext } from '../hooks/AuthContext';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import useAssets from '../hooks/useAssets';

const LobbyScreen: React.FC = () => {
  const { isAuthorized } = useAuthContext();
  const [tableList, setTableList] = useState<LobbyTableList | undefined>(undefined);
  const [selectedTable, setSelectedTable] = React.useState<number | undefined>(undefined);
  const { getNetworks } = useAssets();
  const [selectedAssetFilter, setSelectedAssetFilter] = useState<string>('USDT'); // ['USDT', 'USDC', 'BRETT', 'TOSHI', 'Play Money'
  const [skip, setSkip] = useState(false);
  const isVisible = usePageVisibility();
  const { isTablet, isMobile } = useDevice();
  const { data: listData, refetch } = useLobbyTableListQuery(undefined, { pollingInterval: 10000, skip });
  const firstTableId = React.useMemo(() => {
    const myList = tableList?.my.filter(info => (selectedAssetFilter === 'ALL' ? true : info.asset === selectedAssetFilter)) || [];
    const joinedList = tableList?.joined.filter(info => (selectedAssetFilter === 'ALL' ? true : info.asset === selectedAssetFilter)) || [];
    const generalList = tableList?.general.filter(info => (selectedAssetFilter === 'ALL' ? true : info.asset === selectedAssetFilter)) || [];
    if (!tableList || (myList.length === 0 && joinedList.length === 0 && generalList.length === 0)) {
      return undefined;
    }
    if (myList.length > 0) {
      return myList[0]?.tableId;
    } else if (joinedList.length > 0) {
      return joinedList[0]?.tableId;
    } else if (generalList.length > 0) {
      return generalList[0]?.tableId;
    }
  }, [tableList, selectedAssetFilter]);

  useEffect(() => {
    setSelectedTable(undefined);
  }, [selectedAssetFilter]);

  useEffect(() => {
    if (!isTablet && !isMobile) {
      dispatch(closeModal(ModalType.TablePreviewModal));
    }
  }, [isTablet, isMobile]);

  useEffect(() => {
    if (!listData?.data) return;
    setTableList(listData?.data);
  }, [listData]);

  useEffect(() => {
    if (isVisible) {
      setSkip(false);
      _.delay(() => {
        refetch?.();
      }, 100);
    } else {
      setSkip(true);
    }
  }, [isVisible, refetch]);

  const dispatch = useDispatch();

  const showAuthModal = () => {
    dispatch(showModal(ModalType.AuthModal));
  };

  const showCreateTableModal = () => {
    dispatch(showModal(ModalType.CreateTableModal));
  };

  const onCreateTableClick = () => {
    isAuthorized ? showCreateTableModal() : showAuthModal();
  };

  const onSlide = (splide: any, prev: HTMLButtonElement, next: HTMLButtonElement, prevIndex: number, nextIndex: number) => {
    if (prevIndex < 0) {
      if (!prev.classList.contains('none')) {
        prev.className += ' none';
      }
    } else {
      prev.classList.remove('none');
    }
    if (nextIndex < 0) {
      if (!next.classList.contains('none')) {
        next.className += ' none';
      }
    } else {
      next.classList.remove('none');
    }
  };

  return (
    <Wrap>
      <StyledInnerWrap>
        <LobbyBanners />
        <StyledButtons>
          <StyledFilters>
            <StyledCarousel align={'flex-start'}>
              <Splide onArrowsUpdated={onSlide} hasTrack={false} options={{ isNavigation: true, autoWidth: true, width: '100%', gap: `${8}px`, pagination: false, type: 'slide', arrows: false, drag: isMobile }}>
                <SplideTrack>
                  {/*<SplideSlide onClick={() => setSelectedAssetFilter('ALL')}>*/}
                  {/*  <BaseButton textStyle="TextSmall_Semibold" color={selectedAssetFilter === 'ALL' ? 'actionGreen' : undefined} bgColor={'gray800'} size={isMobile ? 'btn36' : 'btn36'} borderRadius={100} textColor={selectedAssetFilter === 'ALL' ? 'gray900' : 'white'} padding={'8px 12px'}>*/}
                  {/*    ALL*/}
                  {/*  </BaseButton>*/}
                  {/*</SplideSlide>*/}
                  {getNetworks?.map(network => (
                    <SplideSlide key={network.name} onClick={() => setSelectedAssetFilter(network.name)}>
                      <BaseButton
                        textStyle="TextSmall_Semibold"
                        color={selectedAssetFilter === network.name ? 'actionGreen' : undefined}
                        bgColor={'gray800'}
                        size={isMobile ? 'btn36' : 'btn36'}
                        borderRadius={100}
                        textColor={selectedAssetFilter === network.name ? 'gray900' : 'white'}
                        padding={'8px 12px'}
                      >
                        <img src={network.symbolImage} alt={network.name} style={{ marginRight: '4px' }} />
                        {network.name === 'BPP' ? network.displayName : network.name}
                      </BaseButton>
                    </SplideSlide>
                  ))}
                </SplideTrack>
              </Splide>
            </StyledCarousel>
          </StyledFilters>
          <StyledCreateButton>
            <BaseButton textStyle={'TextSmall_Semibold'} size={isMobile ? 'btn36' : 'btn40'} color="primary" textColor="white" minWidth={isMobile ? 110 : 114} onClick={onCreateTableClick}>
              Create Table
            </BaseButton>
          </StyledCreateButton>
        </StyledButtons>

        <StyledContentWrap>
          <Background>
            <Content>
              <TableListTemplate assetFilter={selectedAssetFilter} tableList={tableList} setSelectedTable={setSelectedTable} selectedTable={isTablet || isMobile ? -1 : selectedTable ?? firstTableId ?? -1} />
            </Content>
          </Background>
          {!(isTablet || isMobile) && (
            <StyledPreviewWrap>
              <TablePreview tableId={selectedTable ?? firstTableId ?? -1} />
            </StyledPreviewWrap>
          )}
        </StyledContentWrap>
        <Footer />
      </StyledInnerWrap>
    </Wrap>
  );
};

const StyledCarousel = styled.div<{ align?: 'flex-start' | 'center' | 'flex-end' }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  width: 100%;

  .splide__track {
    padding-left: 0px;
    padding-right: 36px !important;
  }

  .splide__list {
    display: flex;

    li,
    .splide__slide.is-active {
      display: flex;
      align-items: ${p => p.align};
      border: none !important;
      list-style: none;
    }
  }
`;

const StyledFilters = styled.div`
  @media (max-width: 768px) {
    margin-left: 16px;
    box-sizing: border-box;
    width: 100vw;
  }
`;
const StyledCreateButton = styled.div`
  @media (max-width: 768px) {
    margin: 0 16px;
  }
`;

const StyledInnerWrap = styled.div`
  max-height: 100%;
  width: 1089px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding-top: 116px;
  padding-bottom: 364px;
  @media (max-width: 992px) {
    padding-bottom: 458px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 80px;
    height: 100%;
    padding-bottom: 426px;
  }
`;

const StyledContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: max-content;
    //margin-bottom: 400px;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 992px) {
    width: 720px;
  }
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    gap: 16px;
  }
`;

const StyledPreviewWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 361px;
  height: 100%;
  gap: 12px;
`;

const Wrap = styled.div`
  position: relative;
  height: auto;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.gray900};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    padding-bottom: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const Background = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding-bottom: 60px;
  height: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    display: flex;
    padding-bottom: 0;
    height: max-content;
    overflow: inherit;
  }
`;

const Content = styled.div`
  width: 720px;
  height: 100%;
  max-width: 728px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100vw;
    min-width: 100%;
    max-width: 100%;
    height: max-content;
  }
`;

export default LobbyScreen;
