import React, { useEffect, useState } from 'react';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { ArrowLeft, MetamaskIcon } from '../../common/icon';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import LinearLayout from '../../layout/LinearLayout';
import Text from '../../common/text/Text';
import styled from '@emotion/styled';
import { dismissModal, ModalType, showModal } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useDevice from '../../../hooks/useDevice';
import BaseButton from '../../common/button/BaseButton';
import ChainNetworkService from '../../../services/ChainNetworkService';
import { showSuccessToast } from 'src/components/common/toast/BpToast';
import { useLazyWalletConnectChallengeQuery, useWalletConnectVerifyMutation } from '../../../store/api/auth';
import { logDebug, logError } from '../../../utils/ConsoleUtil';

const ConnectingWalletModal = () => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [step, setStep] = useState<'connect' | 'sign'>('connect');
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const backToAuthModal = () => dispatch(showModal(ModalType.AuthModal));
  const closeModal = () => dispatch(dismissModal());
  const { isMobile } = useDevice();

  const [walletConnectTrigger] = useLazyWalletConnectChallengeQuery();
  const [walletConnectVerifyMutation] = useWalletConnectVerifyMutation();

  const processSignMessage = () => {
    setIsLoading(true);
    setSnackbarMessage('');
    walletConnectTrigger()
      .unwrap()
      .then(response => {
        logDebug(response);
        ChainNetworkService.getPublicKey()
          .then(async publicKey => {
            setStep('sign');
            const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
            await delay(500);
            ChainNetworkService.signMessage(response.data, publicKey)
              .then(response => {
                walletConnectVerifyMutation({ chainNetwork: 'ethereum', publicKey: response.publicKey, signature: response.signature, timestamp: response.timestamp })
                  .then(response => {
                    showSuccessToast('MetaMask successfully connected');
                    setIsLoading(false);
                    closeModal();
                  })
                  .catch(error => {
                    setSnackbarMessage(error.error.message);
                    setIsLoading(false);
                  });
              })
              .catch(error => {
                setSnackbarMessage(error.error.message);
                setIsLoading(false);
              });
          })
          .catch(error => {
            setSnackbarMessage(error.error.message);
            setIsLoading(false);
          });
      })
      .catch(error => {
        setSnackbarMessage(error.error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    processSignMessage();
  }, []);

  return (
    <Background>
      <HorizontalLayout margin={'12px 0'}>
        <LeftIconDiv>
          <ArrowLeft onClick={backToAuthModal} />
        </LeftIconDiv>
        <Title>{step === 'connect' ? 'Connect your wallet' : 'Sign'}</Title>
        <RightIconDiv>
          <XCloseIcon onClick={closeModal} />
        </RightIconDiv>
      </HorizontalLayout>
      {snackbarMessage && (
        <Snackbar>
          <ErrorText>{snackbarMessage}</ErrorText>
        </Snackbar>
      )}
      <LinearLayout>
        <WalletIconWrap>
          <MetamaskIcon w={isMobile ? 60 : 72} h={isMobile ? 60 : 72} />
        </WalletIconWrap>
        <TextWrap>
          <Text textColor={'gray300'} marginBottom={isMobile ? 16 : 24} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextLg_Semibold'} text={step === 'connect' ? 'Waiting for MetaMask' : 'Click sign-in in your wallet to confirm you own this wallet'} />
        </TextWrap>
        <ButtonWrap>
          <BaseButton isLoading={isLoading} loadingText={'Connecting'} size={isMobile ? 'btn40' : 'btn48'} color={'gray'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} leftIcon={'MetamaskIcon'} iconSize={20} gap={8} text={'Connect with MetaMask'} onClick={processSignMessage} />
        </ButtonWrap>
      </LinearLayout>
    </Background>
  );
};

const TextWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const ButtonWrap = styled.div`
  padding: 0 40px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const WalletIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  @media (max-width: 768px) {
    padding-right: 16px;
  }
`;

const Title = styled.h1`
  ${({ theme }) => theme.textStyles.TextXl_Semibold};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
  flex: 1;
  margin-left: 24px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
    margin-left: 8px;
  }
`;

const LeftIconDiv = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  @media (max-width: 768px) {
    padding-left: 16px;
  }
`;

const Background = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 0 0 32px 0;
  border-radius: 12px;
  min-width: 600px;
  @media (max-width: 768px) {
    min-width: 0;
    padding: 0 0 24px 0;
  }
`;

const Snackbar = styled.div`
  background-color: ${({ theme }) => `rgba(${hexToRgb(theme.colors.error600)}, 0.3)`};
  color: ${({ theme }) => theme.colors.error400};
  flex-direction: column;
  width: 100%;
  display: flex;
  padding: 0;
  margin-bottom: 16px;
`;

const ErrorText = styled.span`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.error400};
  align-self: center;
  padding: 10px 16px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

function hexToRgb(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
}

export default ConnectingWalletModal;
