import { Web3 } from 'web3';
import { EthereumProvider } from '@walletconnect/ethereum-provider';

class ChainNetworkService {
  private provider: any = null;

  constructor() {
    this.setProvider().then();
  }

  private async setProvider() {
    const provider = await EthereumProvider.init({
      projectId: '2df999ce14da1611b1aebc4489fd24b5',
      metadata: {
        name: 'basepoker',
        description: 'basepoker',
        url: 'https://develop.testbase.xyz/',
        icons: []
      },
      showQrModal: false,
      optionalChains: [1, 137],
      rpcMap: {
        80002: 'https://rpc-amoy.polygon.technology/'
      }
    });
    this.provider = provider;
  }

  public async getPublicKey() {
    const handleURI = (uri: string) => {
      window.location.href = uri;
    };
    try {
      if (/Mobi|Android|iPhone/i.test(window.navigator.userAgent)) {
        await this.provider.disconnect();
        this.provider.on('display_uri', handleURI);
        await this.provider.connect();
        const web3 = new Web3(this.provider);
        const accounts = await web3.eth.requestAccounts();
        return accounts[0];
      } else {
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.requestAccounts();
        return accounts[0];
      }
    } catch (error: any) {
      throw { error: { message: 'Please install wallet' } };
    }
  }

  public async signMessage(challenge: string, publicKey: string) {
    try {
      let web3;
      if (/Mobi|Android/i.test(window.navigator.userAgent)) {
        web3 = new Web3(this.provider);
        const isConfirm = window.confirm('Almost done! Now go back to MetaMask and sign in.');
        if (!isConfirm) throw new Error();
        window.location.href = 'metamask://wc';
      } else {
        web3 = new Web3(window.ethereum);
      }
      const timestamp = new Date().toISOString();
      const message = challenge.replace('{{CURRENT_TIME}}', timestamp);
      const signature = await web3.eth.personal.sign(message, publicKey, publicKey);
      return { publicKey, signature, timestamp };
    } catch (error: any) {
      throw { error: { message: 'Signature denied' } };
    }
  }
}

export default new ChainNetworkService();
