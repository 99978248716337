import React from 'react';
import styled from '@emotion/styled';
import BuyInNotification from './BuyInNotification';
import { bpSocketService } from '../../../services/BpWebSocketService';
import { useTableContext } from '../../../hooks/TableProvider';

interface Notification {
  userId: number;
  name: string;
  profileImage: string;
  amount: number;
  requestAt: string;
}

const StackContainer = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
`;

const StackWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const NotificationItem = styled.div`
  position: absolute;
  width: fit-content;
  top: 60px;
  right: 16px;
  @media (max-width: 768px) {
    top: 8px;
    right: 8px;
  }
  transition:
    transform 0.3s,
    opacity 0.3s;
`;

const StackedNotifications: React.FC = () => {
  const { chipRequests, tableUsers } = useTableContext();

  const onApprove = (userId: number, amount: number) => {
    bpSocketService.approveOrDenyChips(userId, amount, 'APPROVE');
  };

  const onDeny = (userId: number, amount: number) => {
    bpSocketService.approveOrDenyChips(userId, amount, 'DENY');
  };

  const notifications: Notification[] = chipRequests.map(request => {
    const user = tableUsers?.find(user => user.userId === request.userId);
    return {
      userId: request.userId,
      name: user?.name || '',
      profileImage: user?.profileImage || '',
      amount: request.amount,
      requestAt: request.requestAt
    };
  });

  notifications.sort((a, b) => new Date(a.requestAt).getTime() - new Date(b.requestAt).getTime());

  return (
    <StackContainer>
      <StackWrapper>
        {notifications.map((notification, index) => (
          <NotificationItem
            key={notification.requestAt}
            style={{
              transform: `translateY(${index * 6}px)`,
              opacity: 1 - index * 0.05,
              zIndex: notifications.length - index - 5
            }}
          >
            <BuyInNotification user={{ name: notification.name, image: notification.profileImage }} chips={notification.amount} onApprove={() => onApprove(notification.userId, notification.amount)} onDeny={() => onDeny(notification.userId, notification.amount)} />
          </NotificationItem>
        ))}
      </StackWrapper>
    </StackContainer>
  );
};

export default StackedNotifications;
