import React from 'react';
import Text from '../../common/text/Text';
import LinearLayout from '../../layout/LinearLayout';
import useDevice from '../../../hooks/useDevice';
import { StyledPolicyLink, StyledPolicyText } from './styles';

const ResponsibleGaming = () => {
  const { isMobile } = useDevice();
  return (
    <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40} gap={24}>
      <Text lineHeight={20} fontStyle={isMobile ? 'TextMd_Bold' : 'TextLg_Bold'} textColor={'white'} text={'Responsible Gaming'} textAlign={'start'} />
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'BASEPOKER.COM'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Last updated: June 17th, 2024'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Version RG20240617.1'} />
      </LinearLayout>
      <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
            text={'At Basepoker, we aspire to offer you a platform that is safe, protected, and regulated to enhance your experience and ensure you have a peaceful and fun time. We urge all our users to use our website and services in a responsible and rational manner. You can find adequate information regarding how to play responsibly and several firms also offer expert support to those who are dealing with the bad habit of compulsive gambling, which is moving towards the construction of addiction.'} />
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Play responsible'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Typically, online gambling is meant for entertainment and recreation. However, several individuals seem to lose complete control over their actions while engaging in this practice. It is a good practice to monitor the time spent and the amount of money spent at an online casino, on a daily basis.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Assistance for problem gaming'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'In case you are struck with the realization that you are spending more than what your financial situation enables you to afford, or if your gaming renders you dysfunctional in terms of your normal routine, we urge you to take up important measures and adopt safe practices which can facilitate choosing the option of self-exclusion, and assists you in seeking the required help from relevant entities.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Gambling Therapy'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Gambling Therapy offers support and counseling in various languages to any person who feels they are facing negative consequences through gambling.'} />
          <StyledPolicyText><StyledPolicyLink
            onClick={() => window.open('https://gamblingtherapy.org', '_blank')}>www.gamblingtherapy.org</StyledPolicyLink></StyledPolicyText>
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Gamblers Anonymous'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'A fellowship of several people who have united to help each other get through their addiction to gambling and also assist others who are dealing with similar problems due to online gaming.'} />
          <StyledPolicyText><StyledPolicyLink
            onClick={() => window.open('https://gamblersanonymous.org.uk', '_blank')}>www.gamblersanonymous.org.uk</StyledPolicyLink></StyledPolicyText>
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'The Responsible Gaming Foundation'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'This foundation supports people who are unable to play responsibly and they also offer support and guidance to the family members of such individuals. You can connect with them for free at 1777, or chat directly with their staff through their website.'} />
          <StyledPolicyText><StyledPolicyLink
            onClick={() => window.open('https://rgf.org.mt', '_blank')}>www.rgf.org.mt</StyledPolicyLink></StyledPolicyText>
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Be Gamble Aware'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'BeGambleAware® is managed and sponsored by an independent charity GambleAware®, which aims to protect people from gambling-related risks.'} />
          <StyledPolicyText><StyledPolicyLink
            onClick={() => window.open('https://begambleaware.org', '_blank')}>www.begambleaware.org</StyledPolicyLink></StyledPolicyText>
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Protecting Minors'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Minors are prohibited from playing casino online and the parents are responsible for ensuring their children do not partake in any such activities. We recommend you monitor your children’s online activities and prevent their access to such websites using filtering software'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'In case you have a shared computer which is accessed by those who are legally minors or those who have requested self-exclusion from other gambling sites, please consider utilizing filtering solutions like:'} />
          <LinearLayout>
            <StyledPolicyText><StyledPolicyLink
              onClick={() => window.open('https://netnanny.com', '_blank')}>www.netnanny.com</StyledPolicyLink></StyledPolicyText>
            <StyledPolicyText><StyledPolicyLink
              onClick={() => window.open('https://gamban.com', '_blank')}>www.gamban.com</StyledPolicyLink></StyledPolicyText>
          </LinearLayout>
        </LinearLayout>
      </LinearLayout>
    </LinearLayout>
  );
};


export default ResponsibleGaming;