import React from 'react';
import { IconProps } from '../types';

export const Login = React.memo<IconProps>(({ w = 16, h = 16, color = 'gray200' }) => (
  <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7784_46396)">
      <path
        d="M0.893908 9.81601C0.809122 9.48324 0.766729 9.31686 0.821294 9.13832C0.864838 8.99584 0.985302 8.84084 1.11262 8.76346C1.27216 8.6665 1.46953 8.6665 1.86428 8.6665L9.05779 8.6665L7.5292 10.1951C7.26885 10.4554 7.26885 10.8776 7.5292 11.1379C7.78955 11.3983 8.21166 11.3983 8.47201 11.1379L11.1387 8.47124C11.399 8.21089 11.399 7.78878 11.1387 7.52843L8.47201 4.86177C8.21166 4.60142 7.78955 4.60142 7.5292 4.86177C7.26885 5.12212 7.26885 5.54423 7.5292 5.80457L9.05779 7.33317L1.86428 7.33317C1.46953 7.33317 1.27216 7.33317 1.11262 7.23621C0.985302 7.15884 0.864838 7.00383 0.821294 6.86135C0.766729 6.68281 0.809122 6.51643 0.893908 6.18366C1.702 3.01204 4.57739 0.666504 8.0006 0.666504C12.0507 0.666504 15.3339 3.94975 15.3339 7.99984C15.3339 12.0499 12.0507 15.3332 8.0006 15.3332C4.57739 15.3332 1.702 12.9876 0.893908 9.81601Z"
        fill="#EAECF0"
      />
    </g>
    <defs>
      <clipPath id="clip0_7784_46396">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

Login.displayName = 'Login';
