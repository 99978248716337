import { useEffect, useState } from 'react';

const usePageMessage = () => {
  const [tableJoinDetails, setTableJoinDetails] = useState<{ needJoin: boolean; seatId: number | undefined } | undefined>(undefined);
  const getMessageEvent = (event: MessageEvent<any>) => {
    if (event.origin === window.origin && event.data.type === 'joinTable') {
      setTableJoinDetails({ needJoin: true, seatId: event.data.seatId ?? undefined });
    }
  };
  useEffect(() => {
    window.addEventListener('message', getMessageEvent);
    return () => {
      window.removeEventListener('message', getMessageEvent);
    };
  }, []);

  return {
    tableJoinDetails,
    resetPageMassage: () => {
      setTableJoinDetails(undefined);
    }
  };
};

export default usePageMessage;
