import React from 'react';
import { ClickableIconProps, IconProps } from '../types';

export const SelectedAvatar = React.memo<ClickableIconProps>(({ w = 20, h = 20 }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#D1FADF" />
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2483 6.15838L8.28164 11.9167L6.69831 10.2251C6.40664 9.95005 5.94831 9.93338 5.61497 10.1667C5.28997 10.4084 5.19831 10.8334 5.39831 11.1751L7.27331 14.2251C7.45664 14.5084 7.77331 14.6834 8.13164 14.6834C8.47331 14.6834 8.79831 14.5084 8.98164 14.2251C9.28164 13.8334 15.0066 7.00838 15.0066 7.00838C15.7566 6.24172 14.8483 5.56672 14.2483 6.15005V6.15838Z"
      fill="#018F69"
    />
  </svg>
));

SelectedAvatar.displayName = 'SelectedAvatar';
