import React from 'react';
import useDevice from 'src/hooks/useDevice';
import ShowCard from 'src/components/common/card/ShowCard';
import { AuctionButtonsContainer, ButtonContainer, CardWrap, ShowCardWrap, StyledActionButton } from './styles';
import useTableActions from '../../../../hooks/useTableActions';
import Presets from './Presets';
import PreActions from './PreActions';
import BaseButton from '../../../common/button/BaseButton';
import { useTableHandActions } from '../../../../hooks/useTableHandActions';
import { SeatStatus } from '../../../../store/api/responseTypes';
import { useTableContext } from '../../../../hooks/TableProvider';

const ActionButtons = () => {
  const { isMobile } = useDevice();
  const { isMyTurn, myUserTotalStack, mySeatStatus } = useTableContext();
  const { betForRaise, handleBetChange, minRaise, maxRaise, isPresetOpen, reservedAction, getAllowedActions, getActionTitle, onReservedActionClick, onActionClick } = useTableHandActions();
  const { canBuyIn, availableSeats, myCards, canSeatBackIn, seatBackIn, canReserve, canShowCards, showCards, showVerifyPasswordModal, showBuyIn } = useTableActions();

  const renderShowCardButtons = () => {
    if (!myCards) {
      return <></>;
    }
    return (
      <>
        <BaseButton size={isMobile ? 'btn48' : 'btn60'} w={160} color="gray" shadow="button" textColor="white" textStyle="TextMd_Bold" block={isMobile} onClick={() => showCards([0])}>
          <ShowCardWrap>
            SHOW
            <CardWrap>
              <ShowCard card={myCards[0]} />
            </CardWrap>
          </ShowCardWrap>
        </BaseButton>
        <BaseButton size={isMobile ? 'btn48' : 'btn60'} w={160} color="gray" shadow="button" textColor="white" textStyle="TextMd_Bold" block={isMobile} onClick={() => showCards([1])}>
          <ShowCardWrap>
            SHOW
            <CardWrap>
              <ShowCard card={myCards[1]} />
            </CardWrap>
          </ShowCardWrap>
        </BaseButton>
        <BaseButton size={isMobile ? 'btn48' : 'btn60'} w={160} minWidth={130} color="gray" shadow="button" textColor="white" textStyle="TextMd_Bold" block={isMobile} onClick={() => showCards([0, 1])}>
          <ShowCardWrap>
            SHOW
            <CardWrap>
              <ShowCard card={myCards[0]} />
              <ShowCard card={myCards[1]} />
            </CardWrap>
          </ShowCardWrap>
        </BaseButton>
      </>
    );
  };

  return (
    <AuctionButtonsContainer>
      {isMyTurn && !reservedAction && isPresetOpen && <Presets betAmount={betForRaise} setBetAmount={handleBetChange} minRaise={minRaise} maxRaise={maxRaise} onCall={() => onActionClick('CALL')} onBetting={() => onActionClick('BET')} />}
      <ButtonContainer>
        {mySeatStatus === SeatStatus.SEAT_IN &&
          isMyTurn &&
          !reservedAction &&
          getAllowedActions().map((action, i) => (
            <StyledActionButton key={i} shadow="button" color="actionRed" textStyle={isMobile ? 'TextMd_Bold' : 'TextXl_Bold'} textColor="white" size={isMobile ? 'btn48' : 'btn70'} text={getActionTitle(action, false)} onClick={() => onActionClick(action)} w={200} block />
          ))}
        {mySeatStatus === SeatStatus.SEAT_IN && !isMyTurn && getAllowedActions().length > 0 && <PreActions getActions={getAllowedActions} getActionText={action => getActionTitle(action, true)} handleSelect={onReservedActionClick} selectedAction={reservedAction} isMobile={isMobile} />}
        {canSeatBackIn && <BaseButton shadow="button" color="actionRed" size={isMobile ? 'btn48' : 'btn70'} onClick={seatBackIn} w={240} textStyle={isMobile ? 'TextMd_Bold' : 'TextXl_Bold'} block={isMobile} textColor="white" text="SIT BACK IN" />}
        {canBuyIn && (
          <BaseButton
            shadow="button"
            color="actionRed"
            size={isMobile ? 'btn48' : 'btn70'}
            onClick={() => {
              showBuyIn();
            }}
            w={240}
            textStyle={isMobile ? 'TextMd_Bold' : 'TextXl_Bold'}
            block={isMobile}
            textColor="white"
            text={myUserTotalStack ? 'ADD CHIPS' : 'BUY IN'}
          />
        )}
        {canReserve && (
          <BaseButton
            shadow="button"
            color="actionRed"
            size={isMobile ? 'btn48' : 'btn70'}
            onClick={() => {
              showVerifyPasswordModal();
            }}
            w={240}
            textStyle={isMobile ? 'TextMd_Bold' : 'TextXl_Bold'}
            block={isMobile}
            textColor="white"
            text={availableSeats === 1 ? 'TAKE A SEAT' : `SEAT ME${isMobile ? '' : '\\n'} ANYWHERE`}
          />
        )}
        {canShowCards && myCards && renderShowCardButtons()}
      </ButtonContainer>
    </AuctionButtonsContainer>
  );
};

export default ActionButtons;
