import React from 'react';
import { IconProps } from '../types';

export const HeaderLogo = React.memo<IconProps>(({ w = 170, h = 20 }) => (
  <svg width={w} height={h} viewBox="0 0 170 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.918 18.9999V1.5H40.5112C43.5445 1.5 45.9762 3.62499 45.9762 6.14998C45.9762 7.72498 44.9484 9.27497 43.5445 9.74997C45.3996 10.125 46.7784 11.85 46.7784 14C46.7784 16.8249 44.2715 18.9999 41.2632 18.9999H33.918ZM37.6282 15.45H40.9875C42.1407 15.45 42.993 14.55 42.993 13.45C42.993 12.35 42.1156 11.45 41.0376 11.45H37.6282V15.45ZM37.6282 8.47497H40.3858C41.4136 8.47497 42.2159 7.69998 42.2159 6.72498C42.2159 5.77498 41.3886 5.04999 40.3858 5.04999H37.6282V8.47497Z"
      fill="white"
    />
    <path d="M57.0145 1.5L64.0088 18.9999H59.9727L58.8696 16.0749H52.1009L50.9728 18.9999H47.037L54.0062 1.5H57.0145ZM53.3544 12.85H57.6162L55.5103 7.29998L53.3544 12.85Z" fill="white" />
    <path
      d="M71.204 19.2499C67.3434 19.2499 64.5607 16.7249 64.5607 13.2H68.2208C68.2208 14.75 69.4492 15.7499 71.204 15.7499C72.8335 15.7499 73.9616 15 73.9616 13.85C73.9616 10.725 64.7362 12.8 64.7362 6.42498C64.7362 3.42499 67.3183 1.25 70.7779 1.25C75.1148 1.25 77.0953 3.99999 77.0953 7.07498H73.2597C73.2346 5.74998 72.2569 4.74999 70.6525 4.74999C69.2486 4.74999 68.3712 5.37499 68.3712 6.39998C68.3712 9.34997 77.6468 7.22498 77.6468 13.85C77.6468 16.5999 75.2652 19.2499 71.204 19.2499Z"
      fill="white"
    />
    <path d="M79.821 1.5H90.5756V5.04999H83.5312V8.19998H89.7985V11.75H83.5312V15.45H90.5756V18.9999H79.821V1.5Z" fill="white" />
    <path
      d="M99.5386 9.22497C100.817 9.22497 101.745 8.32498 101.745 7.09998C101.745 5.92498 100.817 5.04999 99.5386 5.04999H96.5554V9.22497H99.5386ZM92.8452 18.9999V1.5H99.5386C102.973 1.5 105.48 3.84999 105.48 7.09998C105.48 10.375 102.973 12.775 99.5386 12.775H96.5554V18.9999H92.8452Z"
      fill="white"
    />
    <path
      d="M115.665 1.25C120.779 1.25 124.64 5.12499 124.64 10.25C124.64 15.375 120.779 19.2499 115.665 19.2499C110.551 19.2499 106.715 15.375 106.715 10.25C106.715 5.12499 110.551 1.25 115.665 1.25ZM110.526 10.25C110.526 13.325 112.732 15.6499 115.665 15.6499C118.598 15.6499 120.804 13.325 120.804 10.25C120.804 7.17498 118.598 4.84999 115.665 4.84999C112.732 4.84999 110.526 7.17498 110.526 10.25Z"
      fill="white"
    />
    <path d="M137.205 18.9999L130.511 10.65V18.9999H126.801V1.5H130.511V8.89997L136.754 1.5H141.442L134.397 9.74997L142.068 18.9999H137.205Z" fill="white" />
    <path d="M142.886 1.5H153.64V5.04999H146.596V8.19998H152.863V11.75H146.596V15.45H153.64V18.9999H142.886V1.5Z" fill="white" />
    <path
      d="M155.91 18.9999V1.5H162.678C165.637 1.5 168.219 3.89999 168.219 6.89998C168.219 9.14997 167.141 10.9 165.411 11.75L169.999 18.9999H165.486L161.525 12.325H159.62V18.9999H155.91ZM159.62 8.77497H162.553C163.631 8.77497 164.458 7.94998 164.458 6.89998C164.458 5.84998 163.631 5.07499 162.553 5.07499H159.62V8.77497Z"
      fill="white"
    />
    <path d="M20.0553 9.99996C20.0553 15.5228 15.5658 19.9999 10.0277 19.9999C4.48954 19.9999 0 15.5228 0 9.99996C0 4.47714 4.48954 0 10.0277 0C15.5658 0 20.0553 4.47714 20.0553 9.99996Z" fill="white" />
    <path d="M20.0553 9.99996C20.0553 15.5228 15.5658 19.9999 10.0277 19.9999C4.48954 19.9999 0 15.5228 0 9.99996C0 4.47714 4.48954 0 10.0277 0C15.5658 0 20.0553 4.47714 20.0553 9.99996Z" fill="#12B76A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9362 19.5859C19.1599 17.6056 21.309 14.0532 21.309 10.0003C21.309 5.9471 19.1596 2.39451 15.9353 0.414323C16.8434 0.144758 17.8053 0 18.8011 0C24.3392 0 28.8288 4.47714 28.8288 9.99996C28.8288 15.5228 24.3392 19.9999 18.8011 19.9999C17.8056 19.9999 16.8439 19.8553 15.9362 19.5859ZM14.4149 18.9951C17.7545 17.3722 20.0556 13.9543 20.0556 10.0003C20.0556 6.04611 17.7541 2.62792 14.4141 1.00523C11.0745 2.62807 8.77344 6.04604 8.77344 9.99996C8.77344 13.9542 11.0749 17.3724 14.4149 18.9951Z"
      fill="#12B76A"
    />
  </svg>
));

HeaderLogo.displayName = 'HeaderLogo';
