import styled from '@emotion/styled';
import React, { useCallback, useEffect } from 'react';
import Text from 'src/components/common/text/Text';
import Input from 'src/components/common/input';
import { bpSocketService } from '../../../../services/BpWebSocketService';
import { useChats } from '../../../../hooks/useChats';
import useDevice from '../../../../hooks/useDevice';
import { logWarn } from '../../../../utils/ConsoleUtil';
import BaseButton from '../../../common/button/BaseButton';
import { useTableContext } from '../../../../hooks/TableProvider';

export type messageType = 'System' | 'Dealer' | 'Player' | 'Observer';

const Chat = () => {
  const { tableUsers, isChatEnable } = useTableContext();
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const [isChatLoading, setIsChatLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const { chats: chatMessages } = useChats();
  const { isMobile } = useDevice();

  const sendMessage = () => {
    if (!message || isChatLoading) return;
    setIsChatLoading(true);
    bpSocketService.chatMessageSend(message);
    setMessage('');
    setIsChatLoading(false);
  };

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    if (inputValue.length > 200) {
      logWarn('Input exceeded 200 characters');
      inputValue = inputValue.slice(0, 200); // 200자까지 잘라내기
    }
    setMessage(inputValue);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  }, [chatMessages, messagesEndRef]);

  return (
    <Wrap>
      <MessageContainer>
        {chatMessages.map((chat, i) => (
          <React.Fragment key={chat.header.messageId + '-' + i}>
            {/*<UserProfile>*/}
            {/*  <AvatarWrap>{userList?.find(user => user.userId === chat.payload.userId) ? <img alt={'chat-profile-image'} src={userList?.find(user => user.userId === chat.payload.userId)?.profileImage ?? require('src/assets/img/dealer-button.png')} /> : <DealerAvatar w={16} h={11} />}</AvatarWrap>*/}
            {/*  <StyledText text={userList?.find(user => user.userId === chat.payload.userId)?.name || 'Dealer'} fontStyle="TextMd" />*/}
            {/*<Message type={userList?.find(user => user.userId === chat.payload.userId) ? 'Player' : 'Dealer'}>{chat.payload.message}</Message>*/}
            {/*</UserProfile>*/}
            <NewMessage isDealer={tableUsers?.find(user => user.userId === chat.payload.userId)?.name === undefined}>
              <BoldText>{(tableUsers?.find(user => user.userId === chat.payload.userId)?.name || 'Dealer') + ':'}&nbsp;&nbsp;</BoldText>
              {chat.payload.message}
            </NewMessage>
          </React.Fragment>
        ))}
        <div ref={messagesEndRef} />
      </MessageContainer>
      {isChatEnable && (
        <InputContainer>
          <StyledInput
            disabled={!isChatEnable}
            bgColor="gray600_30"
            color="white"
            radius={8}
            value={message}
            onChange={e => handleChange(e)}
            onKeyDown={e => {
              if (e.nativeEvent.isComposing) return;
              if (e.key === 'Enter') {
                sendMessage();
              }
            }}
            suffix={
              <BaseButton color={'gray'} disabled={!message} textStyle={isMobile ? 'TextMd_Semibold' : 'TextMd_Semibold'} textColor={'white'} onClick={sendMessage} w={64} size={'btn32'}>
                Send
              </BaseButton>
            }
            placeholder={isChatEnable ? 'Type your message!' : 'Please sit to send message'}
            block
          />
        </InputContainer>
      )}
    </Wrap>
  );
};

const NewMessage = styled.span<{ isDealer: boolean }>`
  width: fit-content;
  max-width: 80%;
  word-break: break-all;
  align-items: center;
  ${({ theme, isDealer }) => (isDealer ? theme.textStyles.TextMd : theme.textStyles.TextMd)}
  color: ${({ theme, isDealer }) => (isDealer ? theme.colors.gray300 : theme.colors.white)};
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const BoldText = styled.span`
  ${({ theme }) => theme.textStyles.TextMd_Semibold}
  display: inline-block; // inline-block 설정
`;

const StyledInput = styled(Input)`
  padding: 4px 4px 4px 32px;
  @media (max-width: 768px) {
    padding: 16px 8px 16px 8px;
    background: rgba(71, 84, 103, 0.3);
    height: 48px;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 240px;
  min-width: 388px;
  z-index: 1;
  @media (max-width: 768px) {
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 12px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto; // Changed from 'scroll' to 'auto' for better appearance
  flex: 1; // This ensures it takes up all available space
  gap: 8px;
  @media (max-width: 768px) {
    padding: 56px 12px 0 12px;
  }

  &::-webkit-scrollbar {
    display: none; // Hides the scrollbar
  }

  scrollbar-width: none; // For Firefox, hide scrollbar
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  @media (max-width: 768px) {
    padding: 0 12px;
    box-sizing: border-box;
  }
`;

export default Chat;
