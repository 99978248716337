import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import { ReactComponent as CommingsoonIcon } from 'src/assets/img/lobby-commingsoon.svg';

const TournamentCommingSoon = () => {
  return (
    <StyledWrap>
      <StyledHead>
        <StyledHeadWrap>
          <div>Start</div>
          <div>Tournament</div>
          <div>Buy-in</div>
          <div>Players</div>
          <div>Prize</div>
        </StyledHeadWrap>
      </StyledHead>
      <StyledListWrap>
        <StyledCommingSoon>
          <CommingsoonIcon />
          <Text fontStyle={'TextSmall_Medium'} textColor={'gray300'} text={'Coming Soon'} />
        </StyledCommingSoon>
      </StyledListWrap>
    </StyledWrap>
  );
};

const StyledBaseItem = styled.div<{ isSelected?: boolean; isMyTable?: boolean }>`
  display: flex;
  gap: 16px;
  cursor: pointer;

  div {
    display: flex;
    align-items: start;
  }

  div:nth-of-type(1) {
    flex: 1;
  }

  div:nth-of-type(2) {
    flex: 3.5;
  }

  div:nth-of-type(3) {
    flex: 2;
  }

  div:nth-of-type(4) {
    flex: 2;
  }

  div:last-of-type {
    flex: 0.5;
  }

  @media (max-width: 768px) {
    gap: 12px;
    div:nth-of-type(1) {
      width: 16px;
    }

    div:nth-of-type(2) {
      flex: 3;
    }

    div:nth-of-type(3) {
      flex: 2.3;
    }

    div:nth-of-type(4) {
      flex: 1.4;
    }

    div:last-of-type {
      flex: 1 !important;
    }
  }
`;

const StyledHeadWrap = styled(StyledBaseItem)`
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0 8px !important;
  }
`;

const StyledCommingSoon = styled.div`
  width: 164px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  @media (max-width: 768px) {
    min-height: 100%;
  }
`;

const StyledListWrap = styled.div`
  position: relative;
  height: 100%;
  padding-top: 48px;
  padding-bottom: 16px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-top: 34px;
    padding-bottom: 8px;
    overflow: inherit;
    height: max-content;
    min-height: 482px;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledWrap = styled.div`
  position: relative;
  width: 100%;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray800};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    border-radius: 0;
    max-height: max-content;
    height: max-content;
    min-height: 100%;
    margin-bottom: 64px;
  }
`;

const StyledHead = styled.div`
  display: flex;
  z-index: 5;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 16px;
  ${({ theme }) => theme.textStyles.TextMd_Medium}
  height: 48px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray750};
  justify-content: flex-start;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

  div {
    color: ${({ theme }) => theme.colors.gray400};
  }

  div:last-of-type {
    flex: 1;
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0 8px;
    height: 34px;
    ${({ theme }) => theme.textStyles.TextXm_Medium}
  }
`;

export default TournamentCommingSoon;
