import React from 'react';

interface CalculateElementsProps {
  ref: React.RefObject<HTMLElement>;
  baseFontSize?: number;
  dest?: 'card' | 'chip';
  isMobile?: boolean;
  seatId?: number;
}

const useCalculateElements = ({ ref, baseFontSize = 16, dest, isMobile }: CalculateElementsProps) => {
  const table = document.getElementById('table');

  const calculate = () => {
    if (ref.current) {
      if (table) {

        const rect = table.getBoundingClientRect();
        const elementRect = ref.current.getBoundingClientRect();
        let centerX = 0;
        let centerY = 0;

        // 요소를 테이블의 가로 중앙과 세로 40% 위치에 맞춤
        if (isMobile) {
          centerX = (rect.x + (rect.width / 2) - (elementRect.left + (elementRect.width / 2))) / 8.5;
          centerY = (rect.y + (rect.height * (dest === 'chip' ? 0.495 : 0.3)) - (elementRect.top + elementRect.height)) / 8.5;
        } else {
          centerX = (rect.left + (rect.width / 2) - (elementRect.left + (elementRect.width / 2))) / baseFontSize;
          centerY = (rect.top + (rect.height * (dest === 'chip' ? 0.585 : 0.2)) - (elementRect.top + elementRect.height)) / baseFontSize;
        }


        return { left: centerX + 'em', top: centerY + 'em' };
      }
    }
    return { left: '0em', top: '0em' };
  };
  return {
    calculate
  };
};

export default useCalculateElements;