import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Copy = React.memo<IconProps>(({ w = 20, h = 20, color = 'white' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6975 5.83398H9.29992C8.86064 5.83397 8.48165 5.83396 8.16951 5.85946C7.84006 5.88638 7.51238 5.94581 7.19706 6.10647C6.72665 6.34615 6.3442 6.7286 6.10452 7.19901C5.94386 7.51433 5.88443 7.84201 5.85751 8.17146C5.83201 8.4836 5.83202 8.86255 5.83203 9.30183V15.6994C5.83202 16.1387 5.83201 16.5177 5.85751 16.8298C5.88443 17.1593 5.94386 17.487 6.10452 17.8023C6.3442 18.2727 6.72665 18.6552 7.19706 18.8948C7.51238 19.0555 7.84006 19.1149 8.16951 19.1418C8.48164 19.1673 8.86058 19.1673 9.29983 19.1673H15.6975C16.1367 19.1673 16.5158 19.1673 16.8279 19.1418C17.1573 19.1149 17.485 19.0555 17.8003 18.8948C18.2708 18.6552 18.6532 18.2727 18.8929 17.8023C19.0535 17.487 19.113 17.1593 19.1399 16.8298C19.1654 16.5177 19.1654 16.1387 19.1654 15.6994V9.30188C19.1654 8.86259 19.1654 8.48361 19.1399 8.17146C19.113 7.84201 19.0535 7.51433 18.8929 7.19901C18.6532 6.7286 18.2708 6.34615 17.8003 6.10647C17.485 5.94581 17.1573 5.88638 16.8279 5.85946C16.5158 5.83396 16.1368 5.83397 15.6975 5.83398Z"
      fill={Colors[color]}
    />
    <path
      d="M4.29991 0.833985H10.6975C11.1368 0.833971 11.5158 0.833959 11.8279 0.859461C12.1573 0.886379 12.485 0.945809 12.8003 1.10647C13.2707 1.34615 13.6532 1.72861 13.8929 2.19901C14.0535 2.51433 14.113 2.84201 14.1399 3.17146C14.1628 3.45128 14.1651 3.7848 14.1653 4.16734L9.26465 4.16733C8.85745 4.16723 8.41594 4.16713 8.03379 4.19835C7.60127 4.23369 7.02984 4.32115 6.44041 4.62148C5.6564 5.02095 5.01898 5.65837 4.61951 6.44238C4.31918 7.03181 4.23172 7.60324 4.19638 8.03576C4.16516 8.41792 4.16526 8.85942 4.16536 9.26663L4.16537 14.1673C3.78284 14.1671 3.44932 14.1647 3.16951 14.1418C2.84005 14.1149 2.51237 14.0555 2.19705 13.8948C1.72665 13.6552 1.3442 13.2727 1.10451 12.8023C0.943853 12.487 0.884423 12.1593 0.857505 11.8298C0.832004 11.5177 0.832018 11.1388 0.832032 10.6995V4.30188C0.832018 3.86263 0.832004 3.48359 0.857505 3.17146C0.884423 2.84201 0.943853 2.51433 1.10451 2.19901C1.3442 1.72861 1.72665 1.34615 2.19705 1.10647C2.51237 0.945809 2.84005 0.886379 3.16951 0.859461C3.48164 0.833959 3.86064 0.833971 4.29991 0.833985Z"
      fill={Colors[color]}
    />
  </svg>
));

Copy.displayName = 'Copy';
