import React from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import useDevice from '../../../hooks/useDevice';

export interface TournamentPrizeData {
  id: number;
  rank: string;
  award: string;
}

interface TournamentTableProps {
  tournamentPrize?: TournamentPrizeData[];
}

const TournamentInfoTabPrize: React.FC<TournamentTableProps> = ({ tournamentPrize }) => {
  const { isMobile } = useDevice();
  const renderRow = (tournamentTable: TournamentPrizeData) => (
    <TournamentTablesRow key={tournamentTable.id} textStyles={'TextSmall_Medium'} selected={false}>
      <TournamentTablesCell color={'white'}>{tournamentTable.rank}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{tournamentTable.award}</TournamentTablesCell>
    </TournamentTablesRow>
  );

  return (
    <TournamentTablesContainer>
      <TableSection>
        <StyledTableHeader>
          <TournamentTablesHeader textStyles={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}>
            <TournamentTablesCell color={'gray400'}>Rank</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Award</TournamentTablesCell>
          </TournamentTablesHeader>
        </StyledTableHeader>
        <PrizeBody>{tournamentPrize?.map(renderRow)}</PrizeBody>
      </TableSection>
    </TournamentTablesContainer>
  );
};

const TournamentTablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
`;

const TournamentTablesHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr;
  padding: 8px 0;
`;

const TournamentTablesRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr;
  padding: 8px 0;
`;

const TournamentTablesCell = styled(StyledCell)`
  padding: 0;
`;

const PrizeBody = styled(StyledTableBody)`
  padding-top: 36px;
`;

export default TournamentInfoTabPrize;
