import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import LinearLayout from '../../layout/LinearLayout';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useUpdateProfileImageMutation, useUploadProfileImageMutation } from '../../../store/api/user';
import Text from '../../common/text/Text';
import { showFailToast, showSuccessToast } from '../../common/toast/BpToast';
import useAvatarUpload from '../../../hooks/useAvatarUpload';
import { avatarBaseUrl } from '../../../utils/Util';

const extractIndexFromUrl = (url: string) => {
  const match = url.match(/\/(\d+)\.png$/);
  return match ? parseInt(match[1], 10) : null;
};

interface MobileSettingTemplateProps {
  setProfileImageView: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileImageSelectionTemplate: React.FC<MobileSettingTemplateProps> = ({ setProfileImageView }) => {
  const { user } = useUserProfile();
  const presetAvatars = Array.from({ length: 35 }, (_, index) => `${avatarBaseUrl}/${index}.png`);

  const [currentAvatar, setCurrentAvatar] = useState<string | undefined>(user?.profile);
  const [selectedAvatar, setSelectedAvatar] = useState<string | undefined>(user?.profile);

  const [updateProfileImage] = useUpdateProfileImageMutation();
  const [uploadProfileImage] = useUploadProfileImageMutation();

  const isUpdatable = currentAvatar !== user?.profile;

  const { file, fileUrl, fileInputRef, triggerFileSelect, handleFileSelected, clearFile } = useAvatarUpload({
    onFileChange: file => {
      if (file) setSelectedAvatar(undefined);
    },
    onFileSelectError: error => {
      showFailToast(error);
    }
  });

  useEffect(() => {
    if (fileUrl) {
      setCurrentAvatar(fileUrl);
    }
  }, [fileUrl]);

  const update = async () => {
    if (file) {
      try {
        await uploadProfileImage(file).unwrap();
        showSuccessToast('Avatar uploaded');
        setProfileImageView(false);
      } catch (error) {
        showFailToast('Upload failed');
      }
    } else if (selectedAvatar) {
      const index = extractIndexFromUrl(selectedAvatar);
      if (index !== null) {
        try {
          await updateProfileImage({ index: index });
          showSuccessToast('Avatar updated');
          setProfileImageView(false);
        } catch (error) {
          showSuccessToast('Update failed');
        }
      }
    }
  };

  return (
    <Wrap>
      <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40}>
        <Text fontStyle={'TextXl_Bold'} text={'Change Avatar'} textAlign={'start'} />
        <AvatarSection>
          <AvatarLabel>Your Avatar</AvatarLabel>
          <Avatar src={currentAvatar} />
        </AvatarSection>
        <input type="file" ref={fileInputRef} onChange={handleFileSelected} style={{ display: 'none' }} accept="image/png, image/jpeg, image/jpg" />
        <UploadImageSection onClick={triggerFileSelect}>
          <Text fontStyle={'TextMd_Semibold'} text={'Upload new avatar'} textAlign={'center'} textColor={'primary600'} hasPointer={true} />
          <Text fontStyle={'TextXs_Medium'} text={'JPG or PNG (max 5mb)'} textAlign={'center'} textColor={'gray400'} hasPointer={true} />
        </UploadImageSection>
        <AvatarUploadSection>
          <AvatarListView>
            {presetAvatars.map((avatar, index) => (
              <AvatarItemView
                key={index}
                onClick={() => {
                  clearFile();
                  setSelectedAvatar(avatar);
                  setCurrentAvatar(avatar);
                }}
              >
                <AvatarImageView isSelected={avatar === selectedAvatar} src={avatar} alt={`Avatar-${index}`} />
              </AvatarItemView>
            ))}
          </AvatarListView>
        </AvatarUploadSection>
        <ButtonWrap>
          <Cancel onClick={() => setProfileImageView(false)}>Cancel</Cancel>
          <SaveButton onClick={update} isChanged={isUpdatable}>
            Save
          </SaveButton>
        </ButtonWrap>
      </LinearLayout>
    </Wrap>
  );
};

export default ProfileImageSelectionTemplate;

const Wrap = styled.div`
  width: 100%;
`;

const AvatarSection = styled.div`
  flex-direction: column;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 12px;
`;

const Avatar = styled.img`
  margin-top: 4px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 65%;
    height: auto;
  }

  svg {
    width: 65%;
    height: auto;
    fill: currentColor;
  }
`;

const AvatarLabel = styled.label`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.gray300};
`;

const UploadImageSection = styled.section`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 12px;
  cursor: pointer;
`;

const AvatarUploadSection = styled.section`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
`;

const AvatarListView = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  justify-content: start;
  align-items: center;
  height: 254px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray900};
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: auto; // 세로 스크롤 추가
  // 스크롤바 숨기기

  &::-webkit-scrollbar {
    display: none; // 크롬, 사파리, 최신 엣지에서 스크롤바 숨김
  }

  -ms-overflow-style: none; // 인터넷 익스플로러 및 구 엣지에서 스크롤바 숨김
  scrollbar-width: none; // 파이어폭스에서 스크롤바 숨김

  & > :nth-of-type(-n + 5) {
    padding-top: 20px;
  }

  & > :nth-last-of-type(-n + 5) {
    padding-bottom: 20px;
  }

  @media (max-width: 768px) {
    gap: 10px;
    height: 224px;
    padding-left: 16px;
    padding-right: 16px;
    & > :nth-of-type(-n + 5) {
      padding-top: 16px;
    }

    & > :nth-last-of-type(-n + 5) {
      padding-bottom: 16px;
    }
  }
`;

const AvatarItemView = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
  }
`;

const AvatarImageView = styled.img<{ isSelected: boolean }>`
  border: ${({ isSelected }) => (isSelected ? '1px solid #fff' : 'none')}; // isSelected가 true일 때 흰색 테두리 추가
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  flex-direction: row;
  gap: 12px;
  margin-top: 40px;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const Cancel = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  min-width: 124px;
  background-color: transparent;
  border-style: none;
  color: ${({ theme }) => theme.colors.gray200};
  cursor: pointer;
  padding: 12px 20px;
  user-select: none;
  @media (max-width: 768px) {
    flex: 1;
  }
  transition: background-color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.gray600};
  }
`;

const SaveButton = styled.button<{ isChanged: boolean }>`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  min-width: 124px;
  background-color: ${({ isChanged, theme }) => (isChanged ? theme.colors.primary600 : theme.colors.gray700)};
  border-radius: 8px;
  color: ${({ isChanged, theme }) => (isChanged ? theme.colors.white : theme.colors.gray400)};
  border: none;
  cursor: pointer;
  padding: 12px 20px;
  user-select: none;
  @media (max-width: 768px) {
    flex: 1;
  }
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ isChanged, theme }) => (isChanged ? theme.colors.primary500 : theme.colors.gray700)};
  }
`;
