import { ApiResponse, basePokerApi, handleTransformErrorResponse, handleTransformResponse } from './api';
import { EmailSignInInRequestDto, EmailSignInVerifyCodeRequestDto, WalletConnectSignInRequestDto } from './responseTypes';

export const authApi = basePokerApi.injectEndpoints({
  endpoints: build => ({
    emailAuth: build.mutation<ApiResponse<boolean>, EmailSignInInRequestDto>({
      query: (emailSignInInRequestDto: EmailSignInInRequestDto) => ({
        url: 'auth/email',
        method: 'POST',
        body: emailSignInInRequestDto
      }),
      transformResponse: handleTransformResponse('emailAuth'),
      transformErrorResponse: handleTransformErrorResponse('emailAuth')
    }),
    emailAuthVerify: build.mutation<ApiResponse<boolean>, EmailSignInVerifyCodeRequestDto>({
      query: (emailForm: EmailSignInVerifyCodeRequestDto) => ({
        url: 'auth/email/verify',
        method: 'POST',
        body: emailForm
      }),
      invalidatesTags: ['Util', 'Auth', 'User', 'Table', 'UserNote', 'Country', 'Emoji'],
      transformResponse: handleTransformResponse('emailAuthVerify'),
      transformErrorResponse: handleTransformErrorResponse('emailAuthVerify')
    }),
    walletConnectChallenge: build.query<ApiResponse<string>, void>({
      query: () => `auth/walletconnect/challenge`,
      transformResponse: handleTransformResponse('walletConnectChallenge'),
      transformErrorResponse: handleTransformErrorResponse('walletConnectChallenge')
    }),
    walletConnectVerify: build.mutation<ApiResponse<any>, WalletConnectSignInRequestDto>({
      query: (walletConnectSignInRequestDto: WalletConnectSignInRequestDto) => ({
        url: 'auth/walletconnect/verify',
        method: 'POST',
        body: walletConnectSignInRequestDto
      }),
      invalidatesTags: ['Util', 'Auth', 'User', 'Table', 'UserNote', 'Country', 'Emoji'],
      transformResponse: handleTransformResponse('walletConnectVerify'),
      transformErrorResponse: handleTransformErrorResponse('walletConnectVerify')
    }),
    logout: build.mutation<ApiResponse<any>, void>({
      query: () => ({
        url: 'auth/logout',
        method: 'POST'
      }),
      invalidatesTags: ['Util', 'Auth', 'User', 'Table', 'UserNote', 'Country', 'Emoji', 'Assets'],
      transformResponse: handleTransformResponse('logout'),
      transformErrorResponse: handleTransformErrorResponse('logout')
    }),
    forceLogout: build.mutation<ApiResponse<any>, void>({
      query: () => ({
        url: 'auth/forceLogout',
        method: 'POST'
      }),
      invalidatesTags: ['Util', 'Auth', 'User', 'Table', 'UserNote', 'Country', 'Emoji'],
      transformResponse: handleTransformResponse('forceLogout'),
      transformErrorResponse: handleTransformErrorResponse('forceLogout')
    }),
    refreshToken: build.mutation<ApiResponse<boolean>, void>({
      query: () => ({
        url: 'auth/refresh',
        method: 'POST'
      }),
      invalidatesTags: ['Util', 'Country', 'Emoji'],
      transformResponse: handleTransformResponse('refreshToken'),
      transformErrorResponse: handleTransformErrorResponse('refreshToken')
    })
  })
});

export const { useEmailAuthMutation, useEmailAuthVerifyMutation, useLazyWalletConnectChallengeQuery, useWalletConnectVerifyMutation, useLogoutMutation, useForceLogoutMutation, useRefreshTokenMutation } = authApi;
