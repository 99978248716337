import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const CashierSend = React.memo<IconProps>(({ w = 18, h = 18, color = 'white' }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12ZM12.5 4C8.08172 4 4.5 7.58172 4.5 12C4.5 16.4183 8.08172 20 12.5 20C16.9183 20 20.5 16.4183 20.5 12C20.5 7.58172 16.9183 4 12.5 4ZM10.7929 8.29289C11.1834 7.90237 11.8166 7.90237 12.2071 8.29289L15.2071 11.2929C15.5976 11.6834 15.5976 12.3166 15.2071 12.7071L12.2071 15.7071C11.8166 16.0976 11.1834 16.0976 10.7929 15.7071C10.4024 15.3166 10.4024 14.6834 10.7929 14.2929L13.0858 12L10.7929 9.70711C10.4024 9.31658 10.4024 8.68342 10.7929 8.29289Z"
      fill="#D0D5DD"
    />
  </svg>
));

CashierSend.displayName = 'CashierSend';
