import styled from '@emotion/styled';
import Text from 'src/components/common/text/Text';
import React, { useEffect, useState } from 'react';
import { numberToDisplayString } from '../../../../utils/StringUtil';
import useDevice from '../../../../hooks/useDevice';
import { useTableContext } from '../../../../hooks/TableProvider';
import { TableAssetInfo } from '../../../../store/slices/streamingTypes';
import { useCreateConfig } from '../../../../hooks/useCreateConfig';
import TournamentInfoBox from '../../tournament/TournamentInfoBox';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { dismissModal, ModalType, showModal } from '../../../../store/slices/modalSlice';

const TableInfo = () => {
  const [isTournamentInfoOpen, setIsTournamentInfoOpen] = useState(false);
  const path = window.location.pathname.split('/')[1];
  const { handId, tableSettings, tableAssetInfo } = useTableContext();
  const { getAssetInfo } = useCreateConfig();
  const dispatch = useDispatch();
  const closeModal = () => dispatch(dismissModal());
  const { isMobile } = useDevice();

  const getChipTitle = (assetInfo: TableAssetInfo) => {
    switch (assetInfo.name) {
      case 'BPP':
        return 'Play Money';
      default:
        return assetInfo.name;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      closeModal();
    }
  }, [isMobile]);

  return (
    <TableInfoWrap isInfoOpen={isTournamentInfoOpen}>
      {(path !== 'tournament' || isMobile) && (
        <TextWrap
          onClick={() => {
            path === 'tournament' && dispatch(showModal(ModalType.TournamentInfoModal));
          }}
        >
          {tableSettings?.asset && <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`Chip: ${getChipTitle(tableSettings?.asset)}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />}
          <Text
            fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
            textAlign={isMobile ? 'center' : 'start'}
            text={`Blinds: ${numberToDisplayString(tableSettings?.blindAmount?.small)} / ${numberToDisplayString(tableSettings?.blindAmount?.big)}`}
            textColor={isMobile ? 'white_40' : 'gray300'}
            marginTop={0}
          />
          {handId && handId > 0 && <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`Hand: #${handId}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />}
          {tableSettings?.tablePassword && <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`Password: ${tableSettings?.tablePassword}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />}
        </TextWrap>
      )}
    </TableInfoWrap>
  );
};

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 10;
  user-select: none;
`;

const TableInfoWrap = styled.div<{ isInfoOpen?: boolean }>`
  position: absolute;
  top: 76px;
  gap: 8px;
  left: 24px;
  display: flex;
  flex-direction: column;
  z-index: auto;
  user-select: none;

  @media (max-width: 768px) {
    position: absolute;
    top: 55%;
    left: 50%;
    display: flex;
    gap: 0;
    transform: translateX(-50%);
    align-self: center;
    align-items: center;
    z-index: 100;
    ${({ isInfoOpen }) =>
      isInfoOpen &&
      css`
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        align-self: initial;
        transform: translateX(0);
      `}
  }
`;

export default TableInfo;
