import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import { useDispatch } from 'react-redux';
import CreateTableTemplate from '../settings/CreateTableTemplate';
import LinearLayout from '../../layout/LinearLayout';
import { TableCreateResponse } from '../../../store/api/responseTypes';
import { dismissModal } from '../../../store/slices/modalSlice';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import { isSafari } from '../../../utils/BrowserUtil';
import useDevice from '../../../hooks/useDevice';

const TableModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();

  const onHandleCreateTable = (response: TableCreateResponse) => {
    if (isSafari() && isMobile) {
      window.location.href = `cashgame/table/${response.shareCode}`;
      dispatch(dismissModal());
      return;
    }
    const openTable = () => {
      const width = 1600;
      const height = 900;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
      const url = `cashgame/table/${response.shareCode}`;
      const windowName = `${response.shareCode}`;
      const windowFeatures = `width=${width},height=${height},top=${top},left=${left}`;
      return window.open(url, windowName, windowFeatures);
    };

    const newTab = openTable();

    if (newTab) {
      newTab.onload = () => {
        newTab.postMessage({}, window.origin);
        dispatch(dismissModal());
      };
    }
  };

  const onRequestClose = () => {
    dispatch(dismissModal());
  };

  return (
    <Background>
      <LinearLayout borderRadius={12} gap={isMobile ? 8 : 16}>
        <HorizontalLayout margin={'0 0 16px 0'}>
          <Text fontStyle={isMobile ? 'TextMd_Semibold' : 'TextXl_Semibold'} text={'Create Cash Game'} textAlign={'start'} />
          <XCloseIcon onClick={onRequestClose} />
        </HorizontalLayout>
        <CreateTableTemplate onCreateSuccess={onHandleCreateTable} />
      </LinearLayout>
    </Background>
  );
};

const Background = styled.div`
  width: 600px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px;
  border-radius: 12px;
  gap: 16px;
  @media (max-width: 768px) {
    padding: 20px;
    width: 80vw;
  }
`;

export default TableModal;
