import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const DealerAvatar = React.memo<IconProps>(({ w = 24, h = 24, color = 'white' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="url(#pattern0_13333_194382)" />
    <circle cx="12" cy="12" r="12" fill="#018F69" />
    <path d="M15.13 12C15.13 15.0375 12.6385 17.5 9.56502 17.5C6.49154 17.5 4 15.0375 4 12C4 8.96242 6.49154 6.5 9.56502 6.5C12.6385 6.5 15.13 8.96242 15.13 12Z" fill="white" />
    <path d="M15.13 12C15.13 15.0375 12.6385 17.5 9.56502 17.5C6.49154 17.5 4 15.0375 4 12C4 8.96242 6.49154 6.5 9.56502 6.5C12.6385 6.5 15.13 8.96242 15.13 12Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8452 17.2722C14.6343 16.1831 15.8269 14.2293 15.8269 12.0002C15.8269 9.77091 14.6341 7.81698 12.8447 6.72788C13.3487 6.57962 13.8825 6.5 14.4351 6.5C17.5086 6.5 20.0002 8.96242 20.0002 12C20.0002 15.0375 17.5086 17.5 14.4351 17.5C13.8827 17.5 13.349 17.4204 12.8452 17.2722ZM12.0009 16.9473C13.8543 16.0547 15.1313 14.1748 15.1313 12.0002C15.1313 9.82536 13.8541 7.94536 12.0005 7.05288C10.1472 7.94544 8.87012 9.82532 8.87012 12C8.87012 14.1748 10.1474 16.0548 12.0009 16.9473Z"
      fill="white"
    />
  </svg>
));

DealerAvatar.displayName = 'DealerAvatar';
