export const DELAY_BEFORE_BET = 100;
export const DURATION_BET_MOVE = 0.2;
export const DELAY_GATHER_CHIP = DELAY_BEFORE_BET + DURATION_BET_MOVE * 1000 + 200;

export const DELAY_ROUND = DELAY_GATHER_CHIP + DURATION_BET_MOVE * 1000;
export const DELAY_WINNING_DISPLAY = 2300;
export const DELAY_WINNING_CHIP = 3000;
export const DELAY_WINNING_CHIP_INVISIBLE = DELAY_WINNING_CHIP - DELAY_WINNING_DISPLAY - 100;

// 카드가 나눠지는 시간
export const DURATION_CARD_DEAL = 0.3;
// 카드가 뒤집히는 시간
export const DURATION_CARD_FLIP = 0.2;

export const DELAY_SETTLEMENT_CLEAR = 3750;
export const DELAY_ACTION_TEXT_CLEAR = 1000;
