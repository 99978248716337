import { css } from '@emotion/react';

export const TextStyles = {
  Text6xl: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 60px;
      font-style: normal;
      //font-weight: 400;
      line-height: 72px; /* 120% */
      letter-spacing: -1.2px;
  `,
  Text6xl_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 60px;
      font-style: normal;
      //font-weight: 500;
      line-height: 72px; /* 120% */
      letter-spacing: -1.2px;
  `,
  Text6xl_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 60px;
      font-style: normal;
      ////font-weight: 600;
      line-height: 72px; /* 120% */
      letter-spacing: -1.2px;
  `,
  Text6xl_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 60px;
      font-style: normal;
      ////font-weight: 700;
      line-height: 72px; /* 120% */
      letter-spacing: -1.2px;
  `,
  Display2xl: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 72px;
      font-style: normal;
      //font-weight: 400;
      line-height: 90px; /* 125% */
      letter-spacing: -1.44px;
  `,
  Display2xl_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 72px;
      font-style: normal;
      //font-weight: 500;
      line-height: 90px; /* 125% */
      letter-spacing: -1.44px;
  `,
  Display2xl_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 72px;
      font-style: normal;
      ////font-weight: 600;
      line-height: 90px; /* 125% */
      letter-spacing: -1.44px;
  `,
  Display2xl_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 72px;
      font-style: normal;
      ////font-weight: 700;
      line-height: 90px; /* 125% */
      letter-spacing: -1.44px;
  `,
  DisplayXl: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 60px;
      font-style: normal;
      //font-weight: 400;
      line-height: 72px; /* 120% */
      letter-spacing: -1.2px;
  `,
  DisplayXl_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 60px;
      font-style: normal;
      //font-weight: 500;
      line-height: 72px; /* 120% */
      letter-spacing: -1.2px;
  `,
  DisplayXl_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 60px;
      font-style: normal;
      ////font-weight: 600;
      line-height: 72px; /* 120% */
      letter-spacing: -1.2px;
  `,
  DisplayXl_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 60px;
      font-style: normal;
      ////font-weight: 700;
      line-height: 72px; /* 120% */
      letter-spacing: -1.2px;
  `,
  DisplayLg: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 48px;
      font-style: normal;
      //font-weight: 400;
      line-height: 60px; /* 125% */
      letter-spacing: -0.96px;
  `,
  DisplayLg_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 48px;
      font-style: normal;
      //font-weight: 500;
      line-height: 60px; /* 125% */
      letter-spacing: -0.96px;
  `,
  DisplayLg_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 48px;
      font-style: normal;
      ////font-weight: 600;
      line-height: 60px; /* 125% */
      letter-spacing: -0.96px;
  `,
  DisplayLg_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 48px;
      font-style: normal;
      ////font-weight: 700;
      line-height: 60px; /* 125% */
      letter-spacing: -0.96px;
  `,
  DisplayMd: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 36px;
      font-style: normal;
      //font-weight: 400;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
  `,
  DisplayMd_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 36px;
      font-style: normal;
      //font-weight: 500;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
  `,
  DisplayMd_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 36px;
      font-style: normal;
      ////font-weight: 600;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
  `,
  DisplayMd_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 36px;
      font-style: normal;
      ////font-weight: 700;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
  `,
  DisplaySm: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 30px;
      font-style: normal;
      //font-weight: 400;
      line-height: 38px; /* 126.667% */
  `,
  DisplaySm_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 30px;
      font-style: normal;
      //font-weight: 500;
      line-height: 38px; /* 126.667% */
  `,
  DisplaySm_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 30px;
      font-style: normal;
      ////font-weight: 600;
      line-height: 38px; /* 126.667% */
  `,
  DisplaySm_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 30px;
      font-style: normal;
      ////font-weight: 700;
      line-height: 38px; /* 126.667% */
  `,
  DisplayXs: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 24px;
      font-style: normal;
      //font-weight: 400;
      line-height: 32px; /* 133.333% */
  `,
  DisplayXs_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 24px;
      font-style: normal;
      //font-weight: 500;
      line-height: 32px; /* 133.333% */
  `,
  DisplayXs_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 24px;
      font-style: normal;
      //font-weight: 600;
      line-height: 32px; /* 133.333% */
  `,
  DisplayXs_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 24px;
      font-style: normal;
      //font-weight: 700;
      line-height: 32px; /* 133.333% */
  `,
  TextXl: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 20px;
      font-style: normal;
      //font-weight: 400;
      line-height: 30px; /* 150% */
  `,
  TextXl_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 20px;
      font-style: normal;
      //font-weight: 500;
      line-height: 30px; /* 150% */
  `,
  TextXl_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 20px;
      font-style: normal;
      //font-weight: 600;
      line-height: 30px; /* 150% */
  `,
  TextXl_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 20px;
      font-style: normal;
      //font-weight: 700;
      line-height: 30px; /* 150% */
  `,
  TextLg: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 18px;
      font-style: normal;
      //font-weight: 400;
      line-height: 28px; /* 155.556% */
  `,
  TextLg_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 18px;
      font-style: normal;
      //font-weight: 500;
      line-height: 28px; /* 155.556% */
  `,
  TextLg_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 18px;
      font-style: normal;
      //font-weight: 600;
      line-height: 28px; /* 155.556% */
  `,
  TextLg_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 18px;
      font-style: normal;
      //font-weight: 700;
      line-height: 28px; /* 155.556% */
  `,
  TextMd: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 16px;
      font-style: normal;
      //font-weight: 400;
      line-height: 24px; /* 150% */
  `,
  TextMd_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 16px;
      font-style: normal;
      //font-weight: 500;
      line-height: 24px; /* 150% */
  `,
  TextMd_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 16px;
      font-style: normal;
      //font-weight: 600;
      line-height: 24px; /* 150% */
  `,
  TextMd_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 16px;
      font-style: normal;
      //font-weight: 700;
      line-height: 24px; /* 150% */
  `,
  TextSmall: css`
      font-family: Yellix, Lunasima-Bold;
      font-size: 14px;
      font-style: normal;
      //font-weight: 400;
      line-height: 20px; /* 142.857% */
  `,
  TextSmall_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 14px;
      font-style: normal;
      //font-weight: 500;
      line-height: 20px; /* 142.857% */
  `,
  TextSmall_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 14px;
      font-style: normal;
      //font-weight: 600;
      line-height: 20px; /* 142.857% */
  `,
  TextSmall_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 14px;
      font-style: normal;
      //font-weight: 700;
      line-height: 20px; /* 142.857% */
  `,
  TextXm: css`
      font-family: Yellix-Regular, Lunasima-Bold;
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 126.667% */
  `,
  TextXm_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 126.667% */
  `,
  TextXm_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 126.667% */
  `,
  TextXm_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 13px;
      font-style: normal;
      line-height: 18px; /* 126.667% */
  `,
  TextXs: css`
      font-family: Yellix, Lunasima-Bold;
      font-size: 12px;
      font-style: normal;
      //font-weight: 400;
      line-height: 16px; /* 150% */
  `,
  TextXs_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 12px;
      font-style: normal;
      //font-weight: 500;
      line-height: 16px; /* 150% */
  `,
  TextXs_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 12px;
      font-style: normal;
      //font-weight: 600;
      line-height: 16px; /* 150% */
  `,
  TextXs_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 12px;
      font-style: normal;
      //font-weight: 700;
      line-height: 16px; /* 150% */
  `,
  Text2xs: css`
      font-family: Yellix, Lunasima-Bold;
      font-size: 11px;
      font-style: normal;
      //font-weight: 400;
      line-height: 16px; /* 150% */
  `,
  Text2xs_Medium: css`
      font-family: Yellix-Medium, Lunasima-Bold;
      font-size: 11px;
      font-style: normal;
      //font-weight: 500;
      line-height: 16px; /* 150% */
  `,
  Text2xs_Semibold: css`
      font-family: Yellix-SemiBold, Lunasima-Bold;
      font-size: 11px;
      font-style: normal;
      //font-weight: 600;
      line-height: 16px; /* 150% */
  `,
  Text2xs_Bold: css`
      font-family: Yellix-Bold, Lunasima-Bold;
      font-size: 11px;
      font-style: normal;
      //font-weight: 700;
      line-height: 16px; /* 150% */
  `
};
