import React from 'react';
import { PresetButtons, PresetWrap, SliderGroup, StyledInput } from './styles';
import Slider from 'src/components/common/slider';
import { BetProps } from './types';
import BaseButton from '../../../common/button/BaseButton';
import { usePresets } from './usePreset';
import useDevice from '../../../../hooks/useDevice';
import { useTableContext } from '../../../../hooks/TableProvider';
import { addBigNumbers } from '../../../../utils/BigNumberUtil';

const Presets = (betProps: BetProps) => {
  const { isMobile } = useDevice();
  const { tableSettings, myPlayerData } = useTableContext();
  const { presetButtons, onSliderChanged, onPresetButtonClick, onInputChanged, displayBet, handleBlurInput, handleEnter } = usePresets(betProps);

  return (
    <PresetWrap>
      <SliderGroup>
        <PresetButtons>
          {presetButtons.map((preset, i) => (
            <BaseButton disabled={preset.disabled} key={i} textStyle="TextMd_Bold" textColor="white" w={64} size={'btn36'} onClick={() => onPresetButtonClick(preset.value)} color="actionGray">
              {preset.text}
            </BaseButton>
          ))}
        </PresetButtons>
        <Slider value={betProps.betAmount} step={tableSettings?.betUnit ?? 1} min={addBigNumbers(betProps.minRaise, myPlayerData?.callAmount ?? 0).toNumber()} max={addBigNumbers(betProps.maxRaise, myPlayerData?.callAmount ?? 0).toNumber()} onChange={value => onSliderChanged(value)} />
        {!isMobile && (
          <StyledInput
            value={displayBet}
            type={'text'}
            textAlign="center"
            placeholder="0"
            onChange={e => {
              onInputChanged(e.target.value);
            }}
            onBlur={() => {
              handleBlurInput();
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleEnter();
              }
            }}
            color="gray700"
            bgColor="gray100"
          />
        )}
      </SliderGroup>
    </PresetWrap>
  );
};

export default Presets;
