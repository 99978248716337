import { Emoji } from '../store/api/responseTypes';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectEmojiList } from '../store/slices/utilSlice';
import { useEmojiListQuery } from '../store/api/util';

export const useEmojiList = (): Emoji[] => {
  const emojiListFromRedux = useSelector(selectEmojiList);
  const { data: emojiListFromApi, refetch } = useEmojiListQuery(undefined, { skip: emojiListFromRedux.length > 0 });

  return useMemo(() => {
    if (emojiListFromRedux.length > 0) {
      return emojiListFromRedux;
    } else {
      return emojiListFromApi?.data || [];
    }
  }, [emojiListFromRedux, emojiListFromApi]);
};
