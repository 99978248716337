import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TextStyles } from '../../../styles/textStyles';

export const Menus = styled.div<{ isVisible: boolean }>`
  background-color: ${({ theme }) => theme.colors.gray900};
  visibility: hidden; // 기본적으로는 보이지 않음
  display: flex;
  position: absolute;
  margin-top: 8px;
  top: 100%; // Avatar 바로 아래
  right: 0; // MenuContainer의 오른쪽 끝과 맞춤
  flex-direction: column;
  width: 170px;
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray700};
  padding: 12px;
  gap: 4px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);

  &:hover {
    cursor: pointer;
  }

  transition:
    opacity 0.3s ease,
    visibility 0.3s ease; // 애니메이션 효과 적용
  ${props =>
    props.isVisible &&
    css`
      visibility: visible; // 보여질 때는 보이게 설정
      opacity: 1; // 투명도를 1로 설정
    `}
`;

export const MenuItem = styled.div`
    ${({ theme }) => theme.textStyles.TextMd_Medium};
    padding: 10px;
    color: ${({ theme }) => theme.colors.white};
    border-radius: 6px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray800};
    }
}
`;

// 게임 스타일 컨테이너 스타일 정의
export const Settings = styled.div<{ isVisible: boolean }>`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  background-color: ${({ theme }) => theme.colors.gray900};
  visibility: hidden;
  opacity: 0;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray700};
  margin-top: 8px;

  &:hover {
    cursor: pointer;
  }

  align-items: start;
  gap: 24px;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  ${props =>
    props.isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const HoverContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  display: flex;
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    margin-right: 14px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

export const MobileIconButtons = styled.div`
  position: absolute;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 6px;
`;

export const IconButtons = styled.div`
  display: flex;
  gap: 12px;
`;

export const HeaderProfile = styled.div`
  display: flex;
  gap: 24px;
  justify-content: end;
  align-items: center;

  @media (max-width: 768px) {
    position: absolute;
    right: 0;
    padding: 6px;
  }
`;

export const SettingButtons = styled.div`
  display: flex;
  gap: 16px;
  flex: 20;
`;

export const StyledLogo = styled.div`
  display: flex;
  justify-content: start;

  &:hover {
    cursor: pointer;
  }
`;

export const HeaderWrap = styled.header<{ isInTable?: boolean }>`
  ${TextStyles.TextMd}
  background-color: ${({ theme, isInTable }) => (isInTable ? 'transparent' : theme.colors.gray800)};
  box-shadow: ${({ isInTable }) => (isInTable ? 'none' : '0px 2px 4px 0px rgba(0, 0, 0, 0.15)')};
  position: fixed;
  top: 0;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  z-index: 101;
  justify-content: space-between;
  @media (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.gray900};
  }
`;

export const StyledLeftHeaderContents = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
  margin-left: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
    flex: 1;
  }
`;

export const StyledRightHeaderContents = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
    flex: 1;
  }
`;
