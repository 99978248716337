import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Ethereum = React.memo<IconProps>(({ w = 32, h = 32, color = 'white' }) => (
  <svg width={w} height={h} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_8130_192898)'>
      <circle cx='16' cy='16' r='16' fill='white' />
      <path d='M15.9862 29.333V22.6426L8.08984 17.8027L15.9862 29.333Z' fill='#F0CDC2' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M8.07228 17.787C8.07944 17.7788 8.09112 17.7769 8.1003 17.7825L15.9967 22.6224C16.0036 22.6266 16.0078 22.6343 16.0078 22.6427V29.3331C16.0078 29.3433 16.0015 29.3524 15.9922 29.3555C15.9829 29.3587 15.9727 29.3551 15.967 29.3468L8.07062 17.8166C8.06443 17.8076 8.06512 17.7952 8.07228 17.787ZM8.1689 17.8792L15.9628 29.2598V22.6562L8.1689 17.8792Z'
            fill='#1616B4' />
      <path d='M16.0117 29.333V22.6426L23.908 17.8027L16.0117 29.333Z' fill='#C9B3F5' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M23.9237 17.787C23.9309 17.7952 23.9316 17.8076 23.9254 17.8166L16.0291 29.3468C16.0234 29.3551 16.0132 29.3587 16.0039 29.3555C15.9946 29.3524 15.9883 29.3433 15.9883 29.3331V22.6427C15.9883 22.6343 15.9925 22.6266 15.9994 22.6224L23.8957 17.7825C23.9049 17.7769 23.9166 17.7788 23.9237 17.787ZM16.0333 22.6562V29.2598L23.8271 17.8792L16.0333 22.6562Z'
            fill='#1616B4' />
      <path d='M15.9869 20.9801V12.4414L8 16.1899L15.9869 20.9801Z' fill='#88AAF1' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M15.9982 12.4218C16.0046 12.4261 16.0085 12.4335 16.0085 12.4415V20.9803C16.0085 20.9887 16.0042 20.9964 15.9973 21.0007C15.9903 21.0049 15.9818 21.0049 15.9748 21.0007L7.98788 16.2105C7.98054 16.2061 7.97617 16.1977 7.97659 16.1889C7.97701 16.18 7.98214 16.1722 7.98987 16.1685L15.9768 12.42C15.9838 12.4168 15.9918 12.4174 15.9982 12.4218ZM8.04864 16.1926L15.9635 20.9396V12.4779L8.04864 16.1926Z'
            fill='#1616B4' />
      <path d='M16.0117 20.9801V12.4414L23.9987 16.1899L16.0117 20.9801Z' fill='#C9B3F5' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M15.9986 12.4218C16.005 12.4174 16.013 12.4168 16.02 12.42L24.007 16.1685C24.0147 16.1722 24.0198 16.18 24.0202 16.1889C24.0207 16.1977 24.0163 16.2061 24.0089 16.2105L16.022 21.0007C16.015 21.0049 16.0065 21.0049 15.9995 21.0007C15.9926 20.9964 15.9883 20.9887 15.9883 20.9803V12.4415C15.9883 12.4335 15.9921 12.4261 15.9986 12.4218ZM16.0333 12.4779V20.9396L23.9482 16.1926L16.0333 12.4779Z'
            fill='#1616B4' />
      <path d='M8 16.1891L15.9869 2.66602V12.4406L8 16.1891Z' fill='#F0CDC2' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M15.9921 2.64348C16.0018 2.64636 16.0085 2.65561 16.0085 2.66615V12.4408C16.0085 12.45 16.0033 12.4585 15.9952 12.4623L8.00829 16.2108C7.99926 16.215 7.98865 16.2126 7.98214 16.2048C7.97562 16.197 7.97473 16.1857 7.97993 16.1769L15.9668 2.65375C15.9721 2.64479 15.9825 2.6406 15.9921 2.64348ZM8.0568 16.1364L15.9635 12.4255V2.74909L8.0568 16.1364Z'
            fill='#1616B4' />
      <path d='M23.9986 16.1891L16.0117 2.66602V12.4406L23.9986 16.1891Z' fill='#B8FAF6' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M16.0046 2.64348C16.0143 2.6406 16.0247 2.64479 16.0299 2.65375L24.0169 16.1769C24.0221 16.1857 24.0212 16.197 24.0146 16.2048C24.0081 16.2126 23.9975 16.215 23.9885 16.2108L16.0016 12.4623C15.9935 12.4585 15.9883 12.45 15.9883 12.4408V2.66615C15.9883 2.65561 15.995 2.64636 16.0046 2.64348ZM16.0333 2.74909V12.4255L23.94 16.1364L16.0333 2.74909Z'
            fill='#1616B4' />
    </g>
    <defs>
      <clipPath id='clip0_8130_192898'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>

));

Ethereum.displayName = 'Ethereum';
