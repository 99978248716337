import styled from '@emotion/styled';

export const StyledPolicyLink = styled.span`
    
    cursor: pointer;
    text-decoration: underline;
`;

export const StyledPolicyText = styled.div`
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
    color: ${({ theme }) => theme.colors.white};
    line-height: 20px;
    text-align: start;

    @media (max-width: 768px) {
        ${({ theme }) => theme.textStyles.TextXs_Medium};
    }
`;