import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const MessageText = React.memo<IconProps>(({ w = 24, h = 24, color = 'white' }) => (
  <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4986 2C7.25188 2 2.99858 6.25329 2.99858 11.5C2.99858 12.56 3.17257 13.5814 3.49433 14.536C3.55093 14.7039 3.58424 14.8033 3.60589 14.8766L3.61298 14.9012L3.60923 14.909C3.58352 14.962 3.54532 15.0332 3.47386 15.1655L1.82199 18.223C1.71961 18.4124 1.61278 18.6101 1.53932 18.7819C1.46629 18.9527 1.34801 19.2661 1.40621 19.6376C1.47443 20.073 1.73072 20.4564 2.107 20.6859C2.42801 20.8818 2.76279 20.8923 2.94854 20.8901C3.13541 20.8879 3.35883 20.8648 3.573 20.8426L8.72797 20.3097C8.81053 20.3012 8.85369 20.2968 8.88531 20.2944L8.88938 20.2941L8.90175 20.2984C8.9406 20.3124 8.99329 20.3326 9.08424 20.3677C10.1452 20.7764 11.2969 21 12.4986 21C17.7453 21 21.9986 16.7467 21.9986 11.5C21.9986 6.25329 17.7453 2 12.4986 2ZM7.99854 8.5C7.44625 8.5 6.99854 8.94772 6.99854 9.5C6.99854 10.0523 7.44625 10.5 7.99854 10.5H11.9985C12.5508 10.5 12.9985 10.0523 12.9985 9.5C12.9985 8.94772 12.5508 8.5 11.9985 8.5H7.99854ZM7.99854 12C7.44625 12 6.99854 12.4477 6.99854 13C6.99854 13.5523 7.44625 14 7.99854 14H14.9985C15.5508 14 15.9985 13.5523 15.9985 13C15.9985 12.4477 15.5508 12 14.9985 12H7.99854Z"
      fill={Colors[color]}
    />
  </svg>
));

MessageText.displayName = 'MessageText';
