import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PolicyTab from '../components/template/policy/PolicyTab';
import { PolicyPages, selectLastPolicyPage, setPolicyTab } from '../store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import TermsOfService from '../components/template/policy/TermsOfService';
import PolicyLayout from '../components/template/policy/PolicyLayout';
import Footer from '../components/template/footer/Footer';
import PrivacyPolicy from '../components/template/policy/PrivacyPolicy';
import CookiePolicy from '../components/template/policy/CookiePolicy';
import HouseRules from '../components/template/policy/HouseRules';
import ResponsibleGaming from '../components/template/policy/ResponsibleGaming';

const PolicyScreen = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(selectLastPolicyPage);
  const [activeNode, setActiveNode] = useState<React.ReactNode>(<TermsOfService />);

  const tabClick = (tabName: PolicyPages) => {
    dispatch(setPolicyTab(tabName));
    selectTab(tabName);
  };

  useEffect(() => {
    dispatch(setPolicyTab(activeTab));
    selectTab(activeTab);
  }, [activeTab]);

  const selectTab = (activeTab: PolicyPages) => {
    switch (activeTab) {
      case 'Terms of Service':
        setActiveNode(<TermsOfService />);
        break;
      case 'Privacy Policy':
        setActiveNode(<PrivacyPolicy />);
        break;
      case 'Cookie Policy':
        setActiveNode(<CookiePolicy />);
        break;
      case 'House Rules':
        setActiveNode(<HouseRules />);
        break;
      case 'Responsible Gaming':
        setActiveNode(<ResponsibleGaming />);
        break;
    }
  };
  return (
    <Wrap>
      <PolicyTab onTabClick={tabClick} activeTab={activeTab} />
      <PolicyLayout>{activeNode}</PolicyLayout>
      <Footer />
    </Wrap>
  );
};

const Wrap = styled.div`
  overflow: scroll;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray900};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 456px;

  @media (max-width: 992px) {
    padding-bottom: 580px;
  }
  @media (max-width: 768px) {
    padding: 20px 20px 548px 20px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

export default PolicyScreen;
