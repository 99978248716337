import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import { TextStyles } from '../../../styles/textStyles';
import { ReactComponent as HelpCircle } from '../../../assets/img/help-circle.svg';
import { autoUpdate, useFloating, useFocus, useHover, useInteractions } from '@floating-ui/react';
import { logDebug } from '../../../utils/ConsoleUtil';

interface HorizontalTitleDescriptionProps {
  children?: React.ReactNode;
  label: string | undefined;
  value?: string | undefined;
  borderColor?: keyof typeof Colors;
  labelFontStyle?: keyof typeof TextStyles;
  valueFontStyle?: keyof typeof TextStyles;
  labelColor?: keyof typeof Colors;
  valueColor?: keyof typeof Colors;
  showHelpIcon?: boolean;
  imageUrl?: string;
  iconSize?: number;
  helpMessage?: React.ReactNode;
}

const TitleValueHorizontal: React.FC<HorizontalTitleDescriptionProps> = ({ children, label, value, borderColor, imageUrl, iconSize, labelFontStyle = 'TextMd_Medium', valueFontStyle = 'TextMd_Medium', labelColor = 'gray300', valueColor = 'white', showHelpIcon = false, helpMessage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    placement: 'top',
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange(isOpen, event, reason) {
      setIsOpen(isOpen);
    }
  });
  const hover = useHover(context);
  const focus = useFocus(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus]);

  return (
    <Section>
      <TitleWrap>
        <Label labelFontStyle={labelFontStyle} labelColor={isOpen ? 'white' : labelColor}>
          {label ?? ''}
        </Label>
        {showHelpIcon && <HelpIcon ref={refs.setReference} {...getReferenceProps()} />}
        {helpMessage !== undefined && isOpen && (
          <Tooltip ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            {helpMessage}
          </Tooltip>
        )}
      </TitleWrap>
      <Value borderColor={borderColor} valueFontStyle={valueFontStyle} valueColor={valueColor}>
        {imageUrl && <StyledImage src={imageUrl} alt="icon" />}
        {value ?? ''}
        {children}
      </Value>
    </Section>
  );
};

const StyledImage = styled.img<{ size?: number }>`
  width: ${({ size }) => size ?? 16}px;
  height: ${({ size }) => size ?? 16}px;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Label = styled.span<{ labelFontStyle: keyof typeof TextStyles; labelColor: keyof typeof Colors }>`
  ${({ theme, labelFontStyle }) => theme.textStyles[labelFontStyle]};
  color: ${({ theme, labelColor }) => theme.colors[labelColor]};
  user-select: none;
`;

const Value = styled.span<{ borderColor?: keyof typeof Colors; valueFontStyle: keyof typeof TextStyles; valueColor: keyof typeof Colors }>`
  ${({ theme, valueFontStyle }) => theme.textStyles[valueFontStyle]};
  color: ${({ theme, valueColor }) => theme.colors[valueColor]};
  ${({ theme, borderColor }) => (borderColor ? `background-color: ${theme.colors[borderColor]}; border-radius: 100px; padding: 4px 12px;` : 'padding: 0;')};
  user-select: none;
  display: flex;
  gap: 4px;
  align-items: center;
`;

const HelpIcon = styled(HelpCircle)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray400};

  &:hover {
    color: ${({ theme }) => theme.colors.gray200};
  }
`;

const Tooltip = styled.div`
  padding: 12px;
  margin: -8px;
  max-width: 280px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray50};
  color: ${({ theme }) => theme.colors.gray800};
`;

export default TitleValueHorizontal;
