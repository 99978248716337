import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ToggleSwitch from '../../common/switch/ToggleSwitch';
import TableStyleSelector from './TableStyleSelector';
import DeckStyleSelector from './DeckStyleSelector';
import { DeckStyleOptions, Setting, TableStyleOptions } from '../../../store/api/responseTypes';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useUpdateProfileMutation } from '../../../store/api/user';
import LinearLayout from '../../layout/LinearLayout';
import { showSuccessToast } from '../../common/toast/BpToast';
import { defaultSetting } from '../../../hooks/useUserTableSetting';

const GameSettingTemplate = () => {
  const { setting } = useUserProfile();
  const [requestUpdate] = useUpdateProfileMutation();
  const [settings, setSettings] = useState<Setting>(setting ?? defaultSetting);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const toggleSetting = (key: keyof Setting) => {
    setSettings(prevSettings => {
      // 먼저 기본 토글 동작을 수행
      const newSettings = {
        ...prevSettings,
        [key]: !prevSettings[key]
      };

      // isChatEnabled가 비활성화되는 경우, isEmojiEnabled도 비활성화
      if (key === 'isChatEnabled' && !newSettings.isChatEnabled) {
        newSettings.isEmojiEnabled = false;
      }

      // isChatEnabled가 false일 경우, isEmojiEnabled를 true로 설정하는 시도를 방지
      if (key === 'isEmojiEnabled' && !prevSettings.isChatEnabled) {
        return prevSettings; // 변경 없이 이전 상태를 유지
      }

      return newSettings;
    });
  };

  const handleDeckStyleChange = (newStyle: DeckStyleOptions) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      deckStyle: newStyle
    }));
  };

  const handleTableStyleChange = (newStyle: TableStyleOptions) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      tableStyle: newStyle
    }));
  };

  const saveSetting = () => {
    requestUpdate({ ...settings }).then(() => {
      showSuccessToast('Game setting updated');
    });
  };

  useEffect(() => {
    const hasProfileChanged = JSON.stringify(setting) !== JSON.stringify(settings);
    setHasChanges(hasProfileChanged);
  }, [setting, settings]);

  return (
    <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40} gap={24}>
      <DeckStyleSelector tableId={undefined} onChange={handleDeckStyleChange} />
      <TableStyleSelector tableId={undefined} onChange={handleTableStyleChange} />
      <SettingItem>
        <ToggleSwitch isActive={settings.isSoundEnabled} onClick={() => toggleSetting('isSoundEnabled')} />
        <SettingLabel>Enable sounds</SettingLabel>
      </SettingItem>
      <SettingItem>
        <ToggleSwitch isActive={settings.isChatEnabled} onClick={() => toggleSetting('isChatEnabled')} />
        <SettingLabel>Enable chat</SettingLabel>
      </SettingItem>
      <SettingItem>
        <ToggleSwitch isActive={settings.isEmojiEnabled} onClick={() => toggleSetting('isEmojiEnabled')} />
        <SettingLabel>Enable emojis</SettingLabel>
      </SettingItem>
      <SaveButton
        hasChanges={hasChanges}
        onClick={() => {
          if (hasChanges) {
            saveSetting();
          }
        }}
      >
        Update
      </SaveButton>
    </LinearLayout>
  );
};

export default GameSettingTemplate;

const SettingItem = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const SettingLabel = styled.label`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: white;
`;

const SaveButton = styled.button<{ hasChanges: boolean }>`
  width: 124px;
  height: 48px;
  margin-top: 40px;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  color: ${({ hasChanges, theme }) => (hasChanges ? theme.colors.white : theme.colors.gray500)};
  cursor: pointer;
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  background-color: ${({ hasChanges, theme }) => (hasChanges ? theme.colors.primary600 : theme.colors.gray700)};
  align-self: end;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme, hasChanges }) => (hasChanges ? theme.colors.primary500 : theme.colors.gray700)};
  }
`;
