import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import TournamentStateStatus from '../components/template/tournament/TournamentStateStatus';
import TournamentStateTimer from '../components/template/tournament/TournamentStateTimer';
import TournamentDetailTab, { TournamentTab } from '../components/template/tournament/TournamentDetailTab';
import Footer from '../components/template/footer/Footer';
import { useTournamentLobby } from '../hooks/useTournamentLobby';
import BaseButton from '../components/common/button/BaseButton';
import { useTestTournamentBlindStatusChangeMutation, useTestTournamentStatusChangeMutation } from '../store/api/tournament';
import LottieAnimation from '../components/common/loading/LottieAnimation';
import useDevice from '../hooks/useDevice';

const infoTab: TournamentTab[] = ['Information', 'Structure', 'Prize Pool'];

const TournamentDetail: React.FC = () => {
  const [communityTab, setCommunityTab] = React.useState<TournamentTab[]>([]);
  const { tournamentId, tournamentData } = useTournamentLobby();
  const { isTablet, isMobile } = useDevice();

  const [reqTournamentStatusChange] = useTestTournamentStatusChangeMutation();
  const [reqTournamentBlindStatusChange] = useTestTournamentBlindStatusChangeMutation();

  useEffect(() => {
    if (tournamentData?.payload.snapshot) {
      const tab: TournamentTab[] = ['Lobby Chat'];
      if (tournamentData.payload.snapshot?.players) {
        tab.push('Players');
      }
      if (tournamentData.payload.snapshot?.tables) {
        tab.push('Tables');
      }
      if (tournamentData.payload.snapshot?.inTheMoney) {
        tab.push('In The Money');
      }
      setCommunityTab(tab);
    }
  }, [tournamentData?.payload.snapshot]);

  const changeTournamentStatus = async () => {
    if (tournamentId) {
      await reqTournamentStatusChange({ tournamentId: tournamentId });
    }
  };

  const changeTournamentBlindStatus = async () => {
    if (tournamentId) {
      await reqTournamentBlindStatusChange({ tournamentId: tournamentId });
    }
  };

  return (
    <DetailContainer>
      {tournamentId && tournamentData ? (
        <StyledInnerWrap>
          <EqualHeightWrapper>
            <BaseButton bgColor={'warning700'} size={'btn40'} textColor={'white'} onClick={changeTournamentStatus}>
              Tournament Status Change
            </BaseButton>
            <BaseButton bgColor={'warning700'} size={'btn40'} textColor={'white'} onClick={changeTournamentBlindStatus}>
              Blind Status Change
            </BaseButton>
            <BaseButton bgColor={'warning600'} size={'btn40'} textColor={'white'}>
              Test Button (Unimplemented)
            </BaseButton>
          </EqualHeightWrapper>
          <TopWrapper>
            <TournamentStateTimer />
            <TournamentStateStatus />
          </TopWrapper>
          <EqualHeightWrapper>
            <TournamentDetailTab initialTab="Information" tabs={infoTab} />
            <TournamentDetailTab initialTab="Lobby Chat" tabs={communityTab} />
          </EqualHeightWrapper>
        </StyledInnerWrap>
      ) : (
        <StyledInnerWrap></StyledInnerWrap>
      )}
      <Footer />
    </DetailContainer>
  );
};

export default TournamentDetail;

const DetailContainer = styled.div`
  position: relative;
  height: auto;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.gray900};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.gray50};
  gap: 8px;

  @media (max-width: 768px) {
    width: 100%;
    padding-bottom: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const StyledInnerWrap = styled.div`
  padding-top: 116px;
  width: 1080px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 364px;
  @media (max-width: 992px) {
    width: 100%;
    padding-bottom: 458px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 80px;
    height: 100%;
    padding-bottom: 426px;
  }
`;

const EqualHeightWrapper = styled.div`
  min-width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  gap: 8px;

  @media (max-width: 1024px) {
    flex-direction: column;
    max-height: 100%;
  }
`;

const TopWrapper = styled(EqualHeightWrapper)``;

export const TournamentDetailBox = styled.div`
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 164px;
`;

export const TournamentTabBox = styled(TournamentDetailBox)`
  height: 650px;
`;

export const TournamentInfoBox = styled.div`
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  overflow: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
