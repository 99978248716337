import React from 'react';
import { IconProps } from '../types';

export const Metamask = React.memo<IconProps>(({ w = 21, h = 20 }) => (
  <svg width={w} height={h} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.22266 17.5397L8.84306 18.2296V17.3275L9.05697 17.1152H10.5543V18.1765V18.9194H8.95002L6.97133 18.0704L6.22266 17.5397Z" fill="#CDBDB2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.7773 17.5397L12.2105 18.2296V17.3275L11.9966 17.1152H10.4992V18.1765V18.9194H12.1035L14.0821 18.0704L14.7773 17.5397Z" fill="#CDBDB2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.05766 15.5779L8.84375 17.3289L9.11109 17.1166H11.8919L12.2127 17.3289L11.9988 15.5779L11.571 15.3125L9.43195 15.3656L9.05766 15.5779Z" fill="#393939" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.61328 3.74023L8.89672 6.71177L9.48501 15.3611H11.5706L12.2123 6.71177L13.3888 3.74023H7.61328Z" fill="#F89C35" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.99735 10.2696L0.5 14.5677L4.24337 14.3554H6.64977V12.4983L6.54282 8.67773L6.00805 9.10224L1.99735 10.2696Z" fill="#F89D35" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.83203 10.748L9.21712 10.8542L8.73588 13.0828L6.65024 12.5522L4.83203 10.748Z" fill="#D87C30" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.83203 10.8008L6.65024 12.4988V14.1968L4.83203 10.8008Z" fill="#EA8D3A" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.64844 12.5527L8.78751 13.0834L9.48275 15.3651L9.00141 15.6304L6.64844 14.2508V12.5527Z" fill="#F89D35" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.65047 14.248L6.22266 17.538L9.05697 15.5747L6.65047 14.248Z" fill="#EB8F35" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.21445 10.8555L9.48189 15.3659L8.67969 13.0576L9.21445 10.8555Z" fill="#EA8E3A" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.19141 14.2992L6.65134 14.2461L6.22352 17.536L4.19141 14.2992Z" fill="#D87C30" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.67649 18.9742L6.22196 17.5415L4.18985 14.3047L0.5 14.57L1.67649 18.9742Z" fill="#EB8F35" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.89626 6.7168L6.59681 8.62707L4.83203 10.7496L9.21712 10.9088L8.89626 6.7168Z" fill="#E8821E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.22266 17.5395L9.05697 15.5762L8.84306 17.2742V18.2293L6.9179 17.8578L6.22266 17.5395Z" fill="#DFCEC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.7773 17.5395L11.9966 15.5762L12.2105 17.2742V18.2293L14.1356 17.8578L14.7773 17.5395Z" fill="#DFCEC3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.04061 11.8105L8.62891 13.031L6.54327 12.5004L8.04061 11.8105Z" fill="#393939" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.625 1.25L8.89783 6.71546L7.66792 3.74392L1.625 1.25Z" fill="#E88F35" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.62274 1.25L0.660156 4.16848L1.19492 7.35227L0.820586 7.56452L1.35535 8.04209L0.92754 8.41353L1.51578 8.94416L1.14145 9.26254L1.99707 10.3238L6.00783 9.10335C7.96864 7.54683 8.93122 6.75088 8.89557 6.71551C8.85992 6.68013 6.43564 4.8583 1.62274 1.25Z"
      fill="#8E5A30"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.0027 10.2696L20.5 14.5677L16.7566 14.3554H14.3502V12.4983L14.4572 8.67773L14.9919 9.10224L19.0027 10.2696Z" fill="#F89D35" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.168 10.748L11.7829 10.8542L12.2641 13.0828L14.3498 12.5522L16.168 10.748Z" fill="#D87C30" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.168 10.8008L14.3498 12.4988V14.1968L16.168 10.8008Z" fill="#EA8D3A" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3516 12.5527L12.2125 13.0834L11.5172 15.3651L11.9986 15.6304L14.3516 14.2508V12.5527Z" fill="#F89D35" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3495 14.248L14.7773 17.538L11.9966 15.6277L14.3495 14.248Z" fill="#EB8F35" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.7855 10.8555L11.5181 15.3659L12.3203 13.0576L11.7855 10.8555Z" fill="#EA8E3A" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.8086 14.3012L14.3487 14.248L14.7765 17.538L16.8086 14.3012Z" fill="#D87C30" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.3235 18.9742L14.778 17.5415L16.8102 14.3047L20.5 14.57L19.3235 18.9742Z" fill="#EB8F35" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.1037 6.7168L14.4032 8.62707L16.168 10.7496L11.7829 10.9088L12.1037 6.7168Z" fill="#E8821E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9594 11.8105L12.3711 13.031L14.4567 12.5004L12.9594 11.8105Z" fill="#393939" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.3789 1.25L12.1061 6.71546L13.336 3.74392L19.3789 1.25Z" fill="#E88F35" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3773 1.25L20.3398 4.16848L19.8051 7.35227L20.1794 7.56452L19.6446 8.04209L20.0725 8.41353L19.4842 8.94416L19.8586 9.26254L19.0029 10.3238L14.9922 9.10335C13.0314 7.54683 12.0688 6.75088 12.1044 6.71551C12.1401 6.68013 14.5644 4.8583 19.3773 1.25Z"
      fill="#8E5A30"
    />
  </svg>
));

Metamask.displayName = 'Metamask';
