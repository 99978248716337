import styled from '@emotion/styled';
import { Colors } from 'src/styles/Colors';
import Text from 'src/components/common/text/Text';
import { TextStyles } from 'src/styles/textStyles';

export const StyledText = styled(Text)<{ color: keyof typeof Colors }>`
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const Wrap = styled.div<{ block?: boolean }>`
  gap: 4px;
  display: flex;
  flex: ${({ block }) => (block ? 1 : 'none')};
`;

export const InputWrap = styled.div<{ w?: number; h?: number; block?: boolean; radius?: number; bgColor?: keyof typeof Colors }>`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 4px;
  height: ${({ h }) => `${h}px`};
  width: ${({ w }) => (w ? `${w}px` : '100%')};
  flex: ${({ block }) => (block ? 1 : 'none')};
  background-color: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] : theme.colors.gray900)};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '8px')};
  align-items: center;
`;

export const StyledInput = styled.input<{ placeholderColor?: keyof typeof Colors; textStyles?: keyof typeof TextStyles; color?: keyof typeof Colors; textAlign?: 'left' | 'center' | 'right' }>`
  width: 100%;
  border: none;
  outline: none;
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  background-color: transparent;
  color: ${({ theme, color }) => color && theme.colors[color]};

  ${({ theme, textStyles }) => (textStyles ? theme.textStyles[textStyles] : theme.textStyles.TextMd)}
  ::placeholder {
    ${({ theme, textStyles }) => (textStyles ? theme.textStyles[textStyles] : theme.textStyles.TextMd)}
    color: ${({ theme, placeholderColor }) => (placeholderColor ? theme.colors[placeholderColor] : theme.colors.gray500)};
  }
`;
