import React from 'react';
import { IconProps } from '../types';

interface BlindButtonProps extends IconProps {
  type?: 'sb' | 'bb';
}

export const BlindButton = React.memo<BlindButtonProps>(({ w = 24, h = 24, color = 'white', type = 'bb' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 24 24" fill="none">
    <g filter="url(#filter0_ii_15039_330885)">
      <circle cx="12" cy="12" r="12" fill="#175CD3" />
    </g>
    {type === 'sb' ? (
      <>
        <path
          d="M12.7305 16.8499V6.34985H16.6755C18.4905 6.34985 19.9455 7.62485 19.9455 9.13985C19.9455 10.0849 19.3305 11.0149 18.4905 11.2999C19.6005 11.5249 20.4255 12.5599 20.4255 13.8499C20.4255 15.5449 18.9255 16.8499 17.1255 16.8499H12.7305ZM14.9505 14.7199H16.9605C17.6505 14.7199 18.1605 14.1799 18.1605 13.5199C18.1605 12.8599 17.6355 12.3199 16.9905 12.3199H14.9505V14.7199ZM14.9505 10.5349H16.6005C17.2155 10.5349 17.6955 10.0699 17.6955 9.48485C17.6955 8.91485 17.2005 8.47985 16.6005 8.47985H14.9505V10.5349Z"
          fill="#F2F4F7"
        />
        <path
          d="M7.57461 17C5.26461 17 3.59961 15.485 3.59961 13.37H5.78961C5.78961 14.3 6.52461 14.9 7.57461 14.9C8.54961 14.9 9.22461 14.45 9.22461 13.76C9.22461 11.885 3.70461 13.13 3.70461 9.30495C3.70461 7.50495 5.24961 6.19995 7.31961 6.19995C9.91461 6.19995 11.0996 7.84995 11.0996 9.69495H8.80461C8.78961 8.89995 8.20461 8.29995 7.24461 8.29995C6.40461 8.29995 5.87961 8.67495 5.87961 9.28995C5.87961 11.06 11.4296 9.78495 11.4296 13.76C11.4296 15.41 10.0046 17 7.57461 17Z"
          fill="#F2F4F7"
        />
      </>
    ) : (
      <>
        <path
          d="M12.832 16.5V6H16.777C18.592 6 20.047 7.275 20.047 8.79C20.047 9.735 19.432 10.665 18.592 10.95C19.702 11.175 20.527 12.21 20.527 13.5C20.527 15.195 19.027 16.5 17.227 16.5H12.832ZM15.052 14.37H17.062C17.752 14.37 18.262 13.83 18.262 13.17C18.262 12.51 17.737 11.97 17.092 11.97H15.052V14.37ZM15.052 10.185H16.702C17.317 10.185 17.797 9.72 17.797 9.135C17.797 8.565 17.302 8.13 16.702 8.13H15.052V10.185Z"
          fill="#F2F4F7"
        />
        <path
          d="M4 16.5V6H7.945C9.76 6 11.215 7.275 11.215 8.79C11.215 9.735 10.6 10.665 9.76 10.95C10.87 11.175 11.695 12.21 11.695 13.5C11.695 15.195 10.195 16.5 8.395 16.5H4ZM6.22 14.37H8.23C8.92 14.37 9.43 13.83 9.43 13.17C9.43 12.51 8.905 11.97 8.26 11.97H6.22V14.37ZM6.22 10.185H7.87C8.485 10.185 8.965 9.72 8.965 9.135C8.965 8.565 8.47 8.13 7.87 8.13H6.22V10.185Z"
          fill="#F2F4F7"
        />
      </>
    )}
    <defs>
      <filter id="filter0_ii_15039_330885" x="0" y="-1" width="24" height="25" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.44 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_15039_330885" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect1_innerShadow_15039_330885" result="effect2_innerShadow_15039_330885" />
      </filter>
    </defs>
  </svg>
));

BlindButton.displayName = 'BlindButton';
