import React from 'react';
import styled from '@emotion/styled';
import { XCloseIcon } from '../icon/icons/XCloseIcon';
import { ClearIcon } from '../icon';
import * as Icons from '../icon';
import useDevice from '../../../hooks/useDevice';
import { Colors } from '../../../styles/Colors';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: () => void;
  prefixIcon?: keyof typeof Icons;
  bgColor?: keyof typeof Colors;
}

const TextInput = ({ onClear, prefixIcon, bgColor = 'gray900', ...props }: InputProps) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const { isMobile } = useDevice();
  const Icon = prefixIcon ? Icons[prefixIcon] : null;
  const clearInput = () => {
    onClear && onClear();
  };
  return (
    <StyledInputWrap bgColor={bgColor}>
      {Icon && (
        <StyledClearIconWrap onClick={clearInput}>
          <Icon w={isMobile ? 20 : 24} h={isMobile ? 20 : 24} />
        </StyledClearIconWrap>
      )}
      <Input ref={ref} {...props} />
      {onClear && props.value && (
        <StyledClearIconWrap onClick={clearInput}>
          <ClearIcon w={isMobile ? 20 : 24} h={isMobile ? 20 : 24} />
        </StyledClearIconWrap>
      )}
    </StyledInputWrap>
  );
};

const StyledClearIconWrap = styled.div`
  width: 24px;
  height: 24px;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const StyledInputWrap = styled.div<{ bgColor?: keyof typeof Colors }>`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  ${({ bgColor, theme }) => bgColor && `background-color: ${theme.colors[bgColor]};`}
  min-height: 52px;

  @media (max-width: 768px) {
    min-height: 40px;
  }
  border-radius: 8px;

  cursor: pointer;

  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray600};
    outline: none;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray500};
    box-shadow: none;
    outline: none;
  }
`;

const Input = styled.input`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100%;
  min-height: 52px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 0;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray500};
    box-shadow: none;
    outline: none;
  }

  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
    min-height: 40px;
  }
`;

export default TextInput;
