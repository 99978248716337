import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import { useDispatch } from 'react-redux';
import { dismissModal } from '../../../store/slices/modalSlice';
import BreakTimeTab from './BreakTimeTab';

const BreakTimeModal = () => {
  const dispatch = useDispatch();
  const [breakEndTime, setBreakEndTime] = useState<Date | null>(new Date(new Date().getTime() + 30 * 60 * 1000 + 21 * 1000));
  const [diffTime, setDiffTime] = useState(0);
  const closeModal = () => {
    dispatch(dismissModal());
  };

  const interval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (breakEndTime) {
      const end = breakEndTime;
      interval.current = setInterval(() => {
        breakEndTime.getTime();
        const now = new Date().getTime();
        const diff = end.getTime() - now;

        setDiffTime(diff);
        if (diff < 0) {
          clearInterval(interval.current!);
          setDiffTime(0);
        }
      }, 500);
    } else {
      clearInterval(interval.current!);
      setDiffTime(0);
    }
    return () => {
      clearInterval(interval.current!);
    };
  }, [breakEndTime]);

  const timeFormat = (time: number) => {
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <ModalWrap>
      <HorizontalLayout justifyContent={'end'} margin={'0'}>
        <RightIconDiv>
          <XCloseIcon onClick={closeModal} />
        </RightIconDiv>
      </HorizontalLayout>
      <Text fontStyle={'TextMd_Semibold'} text={'Players are now on break'} lineHeight={30} />
      <Text fontStyle={'DisplayMd_Semibold'} textColor={'warning300'} text={timeFormat(diffTime)} lineHeight={44} />
      <BreakTimeTab initialTab={'Current Table'} />
    </ModalWrap>
  );
};

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-right: 16px;
  }
`;

const ModalWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px 40px;
  border-radius: 12px;
  width: 600px;
  height: 556px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 0;
  }
`;

export default BreakTimeModal;
