import React from 'react';
import { SliderProps } from './types';
import useSlider from './useSlider';
import styled from '@emotion/styled';

const BuyInSlider = (props: SliderProps) => {
  const { value, progress, handleSlider } = useSlider(props);

  const handleTouchStart = (e: React.TouchEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  return (
    <BuyInWrap>
      <BuyInProgress value={value} type="range" step={props.step} min={props.min} max={props.max} onTouchStart={handleTouchStart} onTouchMove={handleTouchStart} onChange={handleSlider} progress={progress} />
    </BuyInWrap>
  );
};

export const BuyInWrap = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0 8px;
  box-sizing: border-box;
`;

const BuyInProgress = styled.input<{ progress: number }>`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: 25px;
  background: transparent;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    touch-action: none;
    cursor: pointer;
    ${({ theme }) => theme.shadow.sliderTrack}
    background: ${({ progress, theme }) => `linear-gradient(to right, ${theme.colors.primary600} ${progress}%, ${theme.colors.gray500} ${progress}%)`};
    border-radius: 25px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 12px;
    touch-action: none;
    cursor: pointer;
    ${({ theme }) => theme.shadow.sliderTrack}
    background: ${({ progress, theme }) => `linear-gradient(to right, ${theme.colors.primary600} ${progress}%, ${theme.colors.gray500} ${progress}%)`};
    border-radius: 25px;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    ${({ theme }) => theme.shadow.button}
    transform: rotateZ(90deg);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.gray600};
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    margin-top: -10px;
  }

  &::-moz-range-thumb {
    box-shadow: none;
    ${({ theme }) => theme.shadow.button}
    transform: rotateZ(90deg);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.gray600};
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    margin-top: -10px;
  }
`;

export default BuyInSlider;
