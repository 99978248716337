import React from 'react';
import { StyledWinningRate } from './styles';
import { animate, useMotionValue, useTransform } from 'framer-motion';

interface WinningRateProps {
  winRate: number;
  seatPosition: number;
  isTopWinRate?: boolean;

}

const WinningRate = ({ isTopWinRate, winRate, seatPosition }: WinningRateProps) => {
  const count = useMotionValue(winRate);
  const rounded = useTransform(count, latest => {
    return Number(latest.toFixed(1)) + '%';
  });
  React.useEffect(() => {
    if (winRate === count.get()) return;
    animate(count, winRate, { duration: 0.5 });
  }, [winRate, count]);
  return (
    <StyledWinningRate position={seatPosition} color={isTopWinRate ? 'primary600' : 'error500'}>{rounded}</StyledWinningRate>
  );
};

export default WinningRate;