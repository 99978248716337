import { CheckIcon } from '../icon';
import { StyledCheckbox } from './style';
import { CheckboxProps } from './types';

const Checkbox = ({ size, bgColor, borderColor, type = 'checkbox', allowClick = true, ...props }: CheckboxProps) => {
  return (
    <StyledCheckbox size={size} borderColor={borderColor} bgColor={bgColor} type={type} checked={props.checked}>
      <input {...props} disabled={!allowClick} type="checkbox" hidden />
      {type === 'checkbox' && props.checked && <CheckIcon />}
    </StyledCheckbox>
  );
};

export default Checkbox;
