import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import BaseButton from '../../common/button/BaseButton';
import Input from '../../common/input';
import useDevice from '../../../hooks/useDevice';
import { logWarn } from '../../../utils/ConsoleUtil';
import Text from '../../common/text/Text';
import { messageType } from '../room/chatBox/Chat';
import { useUserProfile } from '../../../hooks/useUserProfile';

const TournamentDetailInfoTabChat: React.FC = () => {
  const { user } = useUserProfile();
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const [message, setMessage] = React.useState('');
  const [messages, setMessages] = React.useState<string[]>([]);
  const { isMobile } = useDevice();

  const sendMessage = () => {
    setMessages([...messages, message]);
    setMessage('');
  };

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    if (inputValue.length > 200) {
      logWarn('Input exceeded 200 characters');
      inputValue = inputValue.slice(0, 200); // 200자까지 잘라내기
    }
    setMessage(inputValue);
  }, []);

  return (
    <Wrap>
      <MessageContainer>
        {messages.map((chat, i) => (
          <React.Fragment key={i}>
            <UserProfile>
              <AvatarWrap>
                <img alt={'chat-profile-image'} src={user?.profile} />
              </AvatarWrap>
              <StyledText text={user?.name} fontStyle="TextMd" />
              <Message type={'Player'}>{chat}</Message>
            </UserProfile>
          </React.Fragment>
        ))}
        <div ref={messagesEndRef} />
      </MessageContainer>
      <InputContainer>
        <StyledInput
          bgColor="gray600_30"
          color="white"
          radius={8}
          value={message}
          onChange={e => handleChange(e)}
          onKeyDown={e => {
            if (e.nativeEvent.isComposing) return;
            if (e.key === 'Enter') {
              sendMessage();
            }
          }}
          suffix={
            <BaseButton color={'gray'} disabled={!message} textStyle={isMobile ? 'TextMd_Semibold' : 'TextMd_Semibold'} textColor={'white'} onClick={sendMessage} w={64} size={'btn32'}>
              Send
            </BaseButton>
          }
          placeholder={'Type your message!'}
          block
        />
      </InputContainer>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  gap: 8px;
  padding: 8px 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  height: 64px;
  @media (max-width: 768px) {
    padding: 0 12px;
    box-sizing: border-box;
  }
`;

const StyledInput = styled(Input)`
  padding: 4px 16px 4px 32px;
  height: 100%;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
`;

const AvatarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Message = styled.div<{ type: messageType }>`
  display: inline-flex;
  width: fit-content;
  max-width: 80%;
  word-break: break-all;
  align-items: center;
  ${({ theme }) => theme.textStyles.TextMd}
  color: ${({ theme, type }) => (type === 'Dealer' ? theme.colors.white : theme.colors.black)};
  border-radius: 0 8px 8px 8px;
  background: ${({ theme, type }) => (type === 'Dealer' ? theme.colors.gray600_20 : theme.colors.gray100)};
  padding: 4px 12px;
  margin-left: 32px;
  box-shadow:
    0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
`;

export default TournamentDetailInfoTabChat;
