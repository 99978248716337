import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TableCreateConfig, TableCreateAssetValueConfig, TableCreateAssetConfig } from '../store/api/responseTypes';
import { selectTableCreateConfig } from '../store/slices/tableSlice';
import { useHoldemTableConfigQuery } from '../store/api/holdem';

export const useCreateConfig = (): {
  tableCreateConfig: TableCreateConfig | undefined;
  getAssetConfig: (asset: string | undefined) => TableCreateAssetValueConfig[] | undefined;
  getAssetInfo: (asset: string | undefined) => TableCreateAssetConfig | undefined;
  getStakesBySeq: (seq: number) => TableCreateAssetValueConfig | undefined;
  getAssets: () => TableCreateAssetConfig[] | undefined;
} => {
  const tableCreateConfigFromRedux = useSelector(selectTableCreateConfig);
  const { data: tableCreateConfig } = useHoldemTableConfigQuery(undefined);

  const finalConfig = useMemo(() => {
    return tableCreateConfig?.data ?? tableCreateConfigFromRedux;
  }, [tableCreateConfig?.data, tableCreateConfigFromRedux]);

  const getAssets = (): TableCreateAssetConfig[] | undefined => {
    const assets = finalConfig?.stakes;
    if (assets && assets.every(asset => asset.sortWeight !== undefined)) {
      return assets.sort((a, b) => (a.sortWeight ?? 0) - (b.sortWeight ?? 0));
    }
    return assets;
  };

  const getAssetInfo = (asset: string | undefined): TableCreateAssetConfig | undefined => {
    return finalConfig?.stakes.find(stake => stake.asset === asset);
  };

  const getAssetConfig = (asset: string | undefined): TableCreateAssetValueConfig[] | undefined => {
    return finalConfig?.stakes.find(stake => stake.asset === asset)?.values;
  };

  const getStakesBySeq = (seq: number): TableCreateAssetValueConfig | undefined => {
    for (const stake of finalConfig?.stakes || []) {
      const found = stake.values.find(value => value.seq === seq);
      if (found) return found;
    }
    return undefined;
  };

  return { tableCreateConfig: finalConfig, getAssets, getAssetInfo, getAssetConfig, getStakesBySeq };
};
