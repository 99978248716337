import { Colors } from 'src/styles/Colors';

import { ClubIcon, DiamondsIcon, HeartIcon, SpadeIcon } from '../icon';
import useDevice from 'src/hooks/useDevice';
import { useUserTableSetting } from 'src/hooks/useUserTableSetting';
import { Card } from 'src/store/slices/streamingTypes';
import { BigNumber } from './style';

const useCard = ({ card }: { card?: Card }) => {
  const deckStyle = useUserTableSetting()?.deckStyle;
  const { isMobile } = useDevice();
  const cardSizeMap = {
    web: {
      sm: 20,
      md: 40
    },
    mobile: {
      sm: 14,
      md: 24
    }
  };

  const getTextColor = (): keyof typeof Colors => {
    if (deckStyle === 'FOUR_COLOR_COLORED') return 'white';
    switch (card?.[1]) {
      case 'D':
        return deckStyle === 'CLASSIC' ? 'error600' : 'blue600';
      case 'H':
        return 'error600';
      case 'C':
        return deckStyle === 'CLASSIC' ? 'gray900' : 'primary600';
      case 'S':
      default:
        return 'gray900';
    }
  };
  const getBgColor = (): keyof typeof Colors => {
    if (deckStyle !== 'FOUR_COLOR_COLORED') return 'white';
    switch (card?.[1]) {
      case 'D':
        return 'gradient-card_blue';
      case 'H':
        return 'gradient-card_red';
      case 'C':
        return 'gradient-card_green';
      case 'S':
      default:
        return 'gradient-card_gray';
    }
  };
  const getBorderColor = (): keyof typeof Colors => {
    if (deckStyle !== 'FOUR_COLOR_COLORED') return 'gray200';
    switch (card?.[1]) {
      case 'D':
        return 'blue700';
      case 'H':
        return 'error500';
      case 'C':
        return 'primary500';
      case 'S':
      default:
        return 'gray600';
    }
  };

  const textColor = getTextColor();
  const bgColor = getBgColor();
  const borderColor = getBorderColor();

  const getSuit = (isSmall: boolean) => {
    const size = cardSizeMap[isMobile ? 'mobile' : 'web'][isSmall ? 'sm' : 'md'];
    switch (card?.[1]) {
      case 'S':
        return <SpadeIcon color={textColor} w={size} h={size} />;
      case 'H':
        return <HeartIcon color={textColor} w={size} h={size} />;
      case 'D':
        return <DiamondsIcon color={textColor} w={size} h={size} />;
      case 'C':
        return <ClubIcon color={textColor} w={size} h={size} />;
      default:
        return <DiamondsIcon color={textColor} w={size} h={size} />;
    }
  };

  const getBigNumber = () => {
    return <BigNumber>{card?.[0] === 'T' ? '10' : card?.[0]}</BigNumber>;
  };

  const suit = deckStyle === 'FOUR_COLOR_COLORED' ? getBigNumber() : getSuit(false);
  const smallSuit = getSuit(true);

  return { suit, smallSuit, textColor, bgColor, borderColor };
};

export default useCard;
