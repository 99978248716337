import React from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import useDevice from '../../../hooks/useDevice';
import { useUserProfile } from '../../../hooks/useUserProfile';

export interface PlayerData {
  id: number;
  rank: number;
  flagUrl: string;
  username: string;
  chips: number;
}

interface PlayersProps {
  players?: PlayerData[];
}

const TournamentInfoTabPlayers: React.FC<PlayersProps> = ({ players }) => {
  const { user } = useUserProfile();
  const { isMobile } = useDevice();
  const renderPrizePool = (prizePlayer: PlayerData) => (
    <InTheMoneyRow key={prizePlayer.id} textStyles={'TextSmall_Medium'} selected={false}>
      <PlayerCell color={user?.id === prizePlayer.id ? 'warning300' : 'white'}>{prizePlayer.rank}</PlayerCell>
      <PlayerCell color={user?.id === prizePlayer.id ? 'warning300' : 'white'}>{prizePlayer.flagUrl}</PlayerCell>
      <PlayerCell align={'flex-start'} color={user?.id === prizePlayer.id ? 'warning300' : 'white'}>
        {prizePlayer.username}
      </PlayerCell>
      <PlayerCell align={'flex-end'} color={user?.id === prizePlayer.id ? 'warning300' : 'white'}>
        {prizePlayer.chips + ' (100BB)'}
      </PlayerCell>
    </InTheMoneyRow>
  );

  return (
    <InTheMoneyContainer>
      <TableSection>
        <StyledTableHeader>
          <InTheMoneyHeader textStyles={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}>
            <PlayerCell color={'gray400'}>Rank</PlayerCell>
            <PlayerCell color={'gray400'}>Flag</PlayerCell>
            <PlayerCell align={'flex-start'} color={'gray400'}>
              Player
            </PlayerCell>
            <PlayerCell align={'flex-end'} color={'gray400'}>
              Chips(BB)
            </PlayerCell>
          </InTheMoneyHeader>
        </StyledTableHeader>
        <PlayerBody>{players?.map(renderPrizePool)}</PlayerBody>
      </TableSection>
    </InTheMoneyContainer>
  );
};

const InTheMoneyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InTheMoneyHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr 3fr 2.7fr;
  padding: 8px 16px;
`;

const InTheMoneyRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr 3fr 2.7fr;
  padding: 8px 16px;
`;

const PlayerCell = styled(StyledCell)<{ align?: 'flex-end' | 'center' | 'flex-start' }>`
  justify-content: ${({ align }) => align || 'center'};
  padding: 0;
`;

const PlayerBody = styled(StyledTableBody)`
  padding-top: 36px;
`;

export default TournamentInfoTabPlayers;
