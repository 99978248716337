import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../store/slices/authSlice';
import { useMemo } from 'react';

export const useUserProfile = () => {
  const user = useSelector(selectCurrentUser);
  return useMemo(
    () => ({
      user: user?.profile ?? undefined,
      kyc: user?.kycInfo ?? undefined,
      stats: user?.stats ?? undefined,
      setting: user?.setting ?? undefined,
      seed: user?.gameSeed ?? undefined,
      isGameSeedAutoGenerated: user?.isGameSeedAutoGenerated ?? undefined
    }),
    [user]
  );
};
