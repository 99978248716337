import BigNumber from 'bignumber.js';

export const getBigNumber = (value: string | number | BigNumber): BigNumber => {
  return new BigNumber(value);
};

export const multiplyBigNumbers = (value1?: string | number | BigNumber, value2?: string | number | BigNumber): BigNumber => {
  return getBigNumber(value1 || 0).multipliedBy(getBigNumber(value2 || 0));
};

export const divideBigNumbers = (value1?: string | number | BigNumber, value2?: string | number | BigNumber, decimals?: number): BigNumber => {
  if (decimals === undefined) {
    return getBigNumber(value1 || 0).dividedBy(getBigNumber(value2 || 0));
  }
  return getBigNumber(value1 || 0)
    .dividedBy(getBigNumber(value2 || 0))
    .decimalPlaces(decimals);
};

export const addBigNumbers = (value1?: string | number | BigNumber, value2?: string | number | BigNumber): BigNumber => {
  return getBigNumber(value1 || 0).plus(getBigNumber(value2 || 0));
};

export const subtractBigNumbers = (value1?: string | number | BigNumber, value2?: string | number | BigNumber): BigNumber => {
  return getBigNumber(value1 || 0).minus(getBigNumber(value2 || 0));
};

export const modBigNumbers = (value1?: string | number | BigNumber, value2?: string | number | BigNumber): BigNumber => {
  return getBigNumber(value1 || 0).mod(getBigNumber(value2 || 0));
};
