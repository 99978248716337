import React from 'react';
import styled from '@emotion/styled';
import { TournamentDetailBox } from '../../../pages/TournamentDetailScreen';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import { useNavigate } from 'react-router-dom';
import { TournamentPreviewData } from '../../../pages/TournamentLobbyScreen';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import tournamentSlice from '../../../store/slices/tournamentSlice';
import { getTournamentDuration, getTournamentDate, getTournamentPayout, getTournamentValue } from '../../../utils/DateUtil';
import { toFixedFloor } from '../../../utils/StringUtil';

const TournamentDetailTabInformation: React.FC = () => {
  const { tournamentInfo, tournamentStructure, tournamentAsset } = useTournamentLobby();

  const currentBlindInfo = tournamentStructure?.blindSchedule.find(blindSchedule => {
    return blindSchedule.blindId === tournamentInfo?.dynamic?.currentBlindId?.toString();
  });

  const getDisplayValue = (value: string | number | undefined): string => {
    return value !== undefined && value !== null ? String(value) : '-';
  };

  const handleLinkClick = () => {
    alert('Link Clicked!');
  };

  const renderHelpMessage = (defaultText: string, boldText?: string, clickableText?: string): React.ReactNode => {
    return (
      <>
        {boldText && <BoldText>{boldText}</BoldText>} <DefaultText>{defaultText}</DefaultText> {clickableText && <ClickableText onClick={handleLinkClick}>{clickableText}</ClickableText>}
      </>
    );
  };

  return (
    <Wrap>
      <PreviewHeader>{tournamentInfo?.static.title ?? 'Tournament Info'}</PreviewHeader>
      <PreviewDetails>
        <TitleValueHorizontal label={'Game Type'} labelColor={'gray400'} value={tournamentInfo?.static.gameType} showHelpIcon={false} />
        <TitleValueHorizontal label={'Starting Time'} labelColor={'gray400'} value={getTournamentDate(tournamentInfo?.static.startingTime)} showHelpIcon={false} />
        <TitleValueHorizontal
          label={'Late Registration'}
          labelColor={'gray400'}
          value={getTournamentDuration(tournamentInfo?.static.lateRegistration)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage('is a period during which players can join a tournament even after it has officially started.', 'Late Registration')}
        />
        <TitleValueHorizontal label={'Chips'} labelColor={'gray400'} value={`${tournamentAsset?.displayName} - ${tournamentAsset?.name}`} showHelpIcon={false} />
        <TitleValueHorizontal
          label={'Buy-in'}
          labelColor={'gray400'}
          value={toFixedFloor(tournamentInfo?.static.buyIn, 0, false, true) + ' ' + tournamentAsset?.name}
          showHelpIcon={true}
          helpMessage={renderHelpMessage("is the entry fee required for participating in a tournament. The buy-in amount contributes to the prize pool of the tournament, which is then distributed among the winners according to the tournament's payout distribution structure.", 'Buy-in')}
        />
        <TitleValueHorizontal
          label={'Guarantee'}
          labelColor={'gray400'}
          value={toFixedFloor(tournamentInfo?.static.guarantee, 0, false, true) + ' ' + tournamentAsset?.name}
          showHelpIcon={true}
          helpMessage={renderHelpMessage("is the minimum prize pool amount guaranteed by the tournament organizer, regardless of the number of participants. If the total buy-ins don't meet the guarantee, the organizer typically covers the shortfall.", 'Guarantee (GTD)')}
        />
        <TitleValueHorizontal
          label={'Overlay'}
          labelColor={'gray400'}
          value={toFixedFloor(tournamentInfo?.dynamic.overlay, 0, false, true) + ' ' + tournamentAsset?.name}
          showHelpIcon={true}
          helpMessage={renderHelpMessage('occurs when the guaranteed prize pool exceeds the total buy-ins collected from players. This shortfall is covered by the tournament organizer, providing extra value to the participants.', 'Overlay')}
        />
        <TitleValueHorizontal
          label={'Payout Distribution'}
          labelColor={'gray400'}
          value={getTournamentPayout(tournamentInfo?.static.payout)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage("is the method by which the tournament's prize pool is divided among the top-performing players.", 'Payout Distribution')}
        />
        <TitleValueHorizontal label={'Starting Stack'} labelColor={'gray400'} value={`${toFixedFloor(tournamentInfo?.static.startingStack, 0, false, true)}`} showHelpIcon={true} helpMessage={renderHelpMessage('is the amount of chips each player starts with in the tournament.', 'Starting stack')} />
        <TitleValueHorizontal
          label={'Blind Interval'}
          labelColor={'gray400'}
          value={getTournamentDuration(currentBlindInfo?.interval)}
          showHelpIcon={true}
          helpMessage={renderHelpMessage('are the duration between increases in the mandatory bets (blinds) during the tournament. Shorter intervals result in shorter tournaments. Blind structure can be viewed', 'Blind Intervals', 'here.')}
        />
        <TitleValueHorizontal label={'Registered Players'} labelColor={'gray400'} value={toFixedFloor(tournamentInfo?.dynamic.entrantCount, 0, false, true)} showHelpIcon={false} />
        <TitleValueHorizontal label={'Break Time'} labelColor={'gray400'} value={getTournamentDuration(tournamentInfo?.static.breakTimeDuration)} showHelpIcon={false} />
        <TitleValueHorizontal label={'Re-entries'} labelColor={'gray400'} value={getTournamentValue(tournamentInfo?.static.reEntries.code)} showHelpIcon={true} helpMessage={renderHelpMessage('are the number of times a player can re-enter a tournament after being eliminated.', 'Re-entries')} />
        <TitleValueHorizontal label={'Estimated Duration'} labelColor={'gray400'} value={getTournamentDuration(tournamentInfo?.static.estimatedDuration)} showHelpIcon={false} />
      </PreviewDetails>
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: 24px 32px;
`;

const PreviewHeader = styled.div`
  ${({ theme }) => theme.textStyles.TextLg_Semibold}
`;

const PreviewDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
`;

const BoldText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Bold}
  color: ${({ theme }) => theme.colors.gray800};
`;

const DefaultText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Medium}
  color: ${({ theme }) => theme.colors.gray800};
`;

const ClickableText = styled.span`
  ${({ theme }) => theme.textStyles.TextSmall_Bold}
  color: ${({ theme }) => theme.colors.primary600};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.blue300};
  }
`;

export default TournamentDetailTabInformation;
