import styled from '@emotion/styled';
import Button from 'src/components/common/button';
import Input from 'src/components/common/input';
import BaseButton from '../../../common/button/BaseButton';

export const StyledInput = styled(Input)`
  width: 64px;
  height: 34px;
  border-radius: 6px;
  ${({ theme }) => theme.textStyles.TextSmall_Medium};

  border: none;
  background-color: ${({ theme }) => theme.colors.gray100};
  color: ${({ theme }) => theme.colors.white};

  &:hover,
  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.error600};
    outline: none;
  }
`;
export const SliderGroup = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    align-items: center;
    height: 240px;
  }
`;

export const PresetWrap = styled.div`
  margin-bottom: 8px;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 45%;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.3);
  }
  bottom: 100%;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.gray600_30};
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 8px;
  border-radius: 8px;
  @media (max-width: 768px) {
    width: 136px;
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.gray900};
  }
`;

export const PresetButtons = styled.div`
  gap: 8px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    & > button {
      height: 54px;
    }
  }
`;

export const CardWrap = styled.div`
  display: flex;
  gap: 4px;
  height: 100%;
  box-sizing: border-box;
`;

export const ShowCardWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 16px;
  @media (max-width: 768px) {
    padding: 3px 3px 3px 8px;
  }
`;
export const StyledShowCardText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Bold};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  box-sizing: border-box;
`;
export const AuctionButtonsContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-end;
  width: 616px;
  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    min-height: 64px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`;

export const StyledCheckButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledCheckButton = styled(BaseButton)<{ isChecked?: boolean; type: 'checkbox' | 'bar' }>`
  ${({ type }) => (type === 'checkbox' ? 'padding-left: 24px;' : 'padding-left: 7px;')}
  .checkButton {
    cursor: pointer;
    color: ${({ theme, isChecked }) => theme.colors[isChecked ? 'white' : 'gray400']};
    line-height: 24px;
    @media (max-width: 768px) {
      line-height: 14px;
    }
  }

  &:hover {
    .checkButton {
      color: ${({ theme, isChecked }) => !isChecked && theme.colors.gray300};
      line-height: 24px;
      @media (max-width: 768px) {
        line-height: 14px;
      }
    }
  }
`;

export const StyledActionButton = styled(BaseButton)`
  line-height: 24px;
  @media (max-width: 768px) {
    line-height: 14px;
  }
`;
