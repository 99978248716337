import { ApiResponse, basePokerApi, handleTransformErrorResponse, handleTransformResponse } from './api';
import { TableCreateRequestDto, TableCreateResponse, TableInfo, TableCloseRequestDto, TableCreateConfig, LobbyTableList } from './responseTypes';

export const holdemApi = basePokerApi.injectEndpoints({
  endpoints: build => ({
    holdemTableList: build.query<ApiResponse<TableInfo[]>, void>({
      query: () => `holdem/table/list`,
      transformResponse: handleTransformResponse('holdemTableList'),
      transformErrorResponse: handleTransformErrorResponse('holdemTableList'),
      providesTags: result =>
        result
          ? [
              ...result.data.map(({ tableId }) => ({
                type: 'Table' as const,
                tableId
              })),
              { type: 'Table', id: 'LIST' }
            ]
          : [{ type: 'Table', id: 'LIST' }]
    }),
    lobbyTableList: build.query<ApiResponse<LobbyTableList>, void>({
      query: () => 'holdem/table/lobby-list',
      transformResponse: handleTransformResponse('holdemLobbyTableList'),
      transformErrorResponse: handleTransformErrorResponse('holdemLobbyTableList')
    }),
    holdemTableCreate: build.mutation<ApiResponse<TableCreateResponse>, TableCreateRequestDto>({
      query: (tableCreateRequestDto: TableCreateRequestDto) => ({
        url: 'holdem/table/create',
        method: 'POST',
        body: tableCreateRequestDto
      }),
      transformResponse: handleTransformResponse('holdemTableCreate'),
      transformErrorResponse: handleTransformErrorResponse('holdemTableCreate'),
      invalidatesTags: ['Table']
    }),
    holdemTableClose: build.mutation<ApiResponse<{}>, TableCloseRequestDto>({
      query: (tableCloseRequestDto: TableCloseRequestDto) => ({
        url: 'holdem/table/close',
        method: 'POST',
        body: tableCloseRequestDto
      }),
      transformResponse: handleTransformResponse('holdemTableClose'),
      transformErrorResponse: handleTransformErrorResponse('holdemTableClose'),
      invalidatesTags: (result, error, arg) => ['Table']
    }),
    holdemTableConfig: build.query<ApiResponse<TableCreateConfig>, void>({
      query: () => `holdem/table/config`,
      transformResponse: handleTransformResponse('holdemTableConfig'),
      transformErrorResponse: handleTransformErrorResponse('holdemTableConfig')
    })
  })
});

export const { useHoldemTableListQuery, useHoldemTableCreateMutation, useHoldemTableCloseMutation, useHoldemTableConfigQuery, useLobbyTableListQuery, useLazyLobbyTableListQuery } = holdemApi;
