import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Cards = React.memo<IconProps>(({ w = 20, h = 20, color = 'white' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_15614_129927)">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M6.38671 17.6267L2.55896 4.27779L11.4583 1.72595L15.286 15.0749L6.38671 17.6267ZM0.863093 3.96148C0.745648 3.55191 0.98247 3.12467 1.39205 3.00722L11.7746 0.0300854C12.1841 -0.0873595 12.6114 0.149462 12.7288 0.559041L12.9585 1.36017H17.6245C18.4766 1.36017 19.1675 2.05099 19.1675 2.90316V16.3937C19.1675 17.2203 18.5161 17.9 17.6903 17.9353L9.72075 18.2759L6.07041 19.3226C5.66083 19.44 5.23359 19.2032 5.11615 18.7936L0.863093 3.96148ZM16.2754 16.3964L17.6245 16.3937V2.90316H13.401L16.9819 15.3912C17.0993 15.8008 16.8625 16.228 16.4529 16.3455L16.2754 16.3964ZM10.9139 8.22446L7.65995 4.01902L6.99521 4.20963L6.46425 9.50038L7.30534 9.2592L7.40611 8.2026L9.41388 7.62689L10.0525 8.47148L10.9139 8.22446ZM8.93349 7.0012L7.47515 7.41937L7.6709 5.33721L8.93349 7.0012ZM11.6627 14.2691C12.102 14.1431 12.4727 13.8621 12.7004 13.4811C12.9267 13.0949 12.9885 12.66 12.8705 12.2485C12.751 11.8318 12.4681 11.4958 12.0731 11.2934L8.92002 9.67772L7.10776 12.7172C6.88002 13.0981 6.81818 13.533 6.93767 13.9497C7.05566 14.3612 7.33856 14.6973 7.73507 14.9049C8.13009 15.1073 8.58786 15.1508 9.03269 15.0232C9.41635 14.9132 9.73785 14.6914 9.97389 14.3813L10.0856 14.2252L10.141 14.5025L10.144 14.5129C10.2382 15.0722 9.87093 15.6172 9.2871 15.7846L9.21482 15.8053L9.27008 15.998L11.9724 15.2231L11.9171 15.0304L11.8448 15.0511C11.2666 15.217 10.6663 14.9494 10.4443 14.4268L10.4413 14.4164L10.3473 14.1727L10.3414 14.1519L10.5229 14.2182C10.8833 14.3629 11.2791 14.3791 11.6627 14.2691Z"
            fill={Colors[color]} />
    </g>
    <defs>
      <clipPath id="clip0_15614_129927">
        <rect width="20" height="20" fill={Colors[color]} />
      </clipPath>
    </defs>
  </svg>
));

Cards.displayName = 'Cards';




