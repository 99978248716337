import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
import { logDebug } from '../utils/ConsoleUtil';

const breakpoint = {
  mobile: 0,
  tablet: 768,
  desktop: 1920,
  desktopMedium: 1280,
  desktopSmall: 1024
};

export const device = {
  mobileSmall: `(max-width: 320px)`,
  mobile: `(min-width: ${breakpoint.mobile}px) and (max-width: ${breakpoint.tablet}px)`,
  tablet: `(min-width: ${breakpoint.tablet + 1}px) and (max-width: ${breakpoint.desktopSmall}px)`,
  desktopSmall: `(min-width: ${breakpoint.desktopSmall + 1}px) and (max-width: ${breakpoint.desktopMedium}px)`,
  desktop: `(min-width: ${breakpoint.desktopMedium + 1}px)`
};

// 인앱 웹뷰 감지 함수
const detectInAppWebView = () => {
  const userAgent = navigator.userAgent;
  // Android WebView: 'wv'라는 문자열을 User-Agent에 포함합니다.
  const isAndroidWebView = /wv/.test(userAgent);
  // iOS WebView: UIWebView나 WKWebView가 있는지 체크합니다.
  const isIOSWebView = /iPhone|iPod|iPad/.test(userAgent) && /AppleWebKit(?!.*Safari)/.test(userAgent);
  return isAndroidWebView || isIOSWebView;
};

export function useDevice() {
  const isDesktop = useMediaQuery({ query: `${device.desktop}` });
  const isDesktopSmall = useMediaQuery({ query: `${device.desktopSmall}` });
  const isTablet = useMediaQuery({ query: `${device.tablet}` });
  const isMobile = useMediaQuery({ query: `${device.mobile}` });
  const isMobileSmall = useMediaQuery({ query: `${device.mobileSmall}` });

  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [isInAppWebView, setIsInAppWebView] = useState(false);

  useEffect(() => {
    // 초기 로드 시 인앱 웹뷰 감지
    setIsInAppWebView(detectInAppWebView());

    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isDesktop, isDesktopSmall, isTablet, isMobile, isMobileSmall, screenSize, isInAppWebView };
}

export default useDevice;
