import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Shield = React.memo<IconProps>(({ w = 20, h = 20, color = 'primary500', subColor = 'white' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2386 0.907758C10.0805 0.884914 9.91995 0.884914 9.76186 0.907758C9.57967 0.934085 9.41014 0.99813 9.27552 1.04898L9.23945 1.06258L4.66242 2.77885C4.1476 2.97106 3.69548 3.13985 3.34729 3.4467C3.0427 3.71514 2.80806 4.05372 2.66367 4.43317C2.49861 4.86693 2.49931 5.34953 2.50011 5.89907L2.50022 10.0006C2.50022 12.3561 3.7813 14.32 5.1661 15.7626C6.56019 17.215 8.15522 18.2408 8.99864 18.7328L9.0328 18.7528C9.18671 18.8432 9.38649 18.9605 9.65075 19.0172C9.86697 19.0636 10.1335 19.0636 10.3497 19.0172C10.614 18.9605 10.8137 18.8432 10.9677 18.7528L11.0018 18.7328C11.8452 18.2408 13.4403 17.215 14.8343 15.7626C16.2191 14.32 17.5002 12.3561 17.5002 10.0006L17.5003 5.89906C17.5011 5.34953 17.5018 4.86693 17.3368 4.43317C17.1924 4.05372 16.9577 3.71514 16.6531 3.4467C16.305 3.13985 15.8528 2.97106 15.338 2.77885L10.761 1.06258L10.7249 1.04898C10.5903 0.99813 10.4208 0.934085 10.2386 0.907758Z"
      fill={Colors[subColor]} />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.76186 0.907758C9.91995 0.884914 10.0805 0.884914 10.2386 0.907758C10.4208 0.934085 10.5903 0.99813 10.7249 1.04898L10.761 1.06258L15.338 2.77885C15.8528 2.97106 16.305 3.13985 16.6531 3.4467C16.9577 3.71514 17.1924 4.05372 17.3368 4.43317C17.5018 4.86693 17.5011 5.34953 17.5003 5.89906L17.5002 10.0006C17.5002 12.3561 16.2191 14.32 14.8343 15.7626C13.4403 17.215 11.8452 18.2408 11.0018 18.7328L10.9677 18.7528C10.8137 18.8432 10.614 18.9605 10.3497 19.0172C10.1335 19.0636 9.86697 19.0636 9.65075 19.0172C9.38649 18.9605 9.18671 18.8432 9.0328 18.7528L8.99864 18.7328C8.15522 18.2408 6.56019 17.215 5.1661 15.7626C3.7813 14.32 2.50022 12.3561 2.50022 10.0006L2.50011 5.89907C2.49931 5.34953 2.49861 4.86693 2.66367 4.43317C2.80806 4.05372 3.0427 3.71514 3.34729 3.4467C3.69548 3.13985 4.1476 2.97106 4.66242 2.77885L9.23945 1.06258L9.27552 1.04898C9.41014 0.99813 9.57967 0.934085 9.76186 0.907758ZM13.5061 8.08999C13.8316 7.76455 13.8316 7.23691 13.5061 6.91148C13.1807 6.58604 12.6531 6.58604 12.3276 6.91148L9.16687 10.0722L8.08946 8.99481C7.76402 8.66937 7.23638 8.66937 6.91095 8.99481C6.58551 9.32025 6.58551 9.84789 6.91095 10.1733L8.57761 11.84C8.90305 12.1654 9.43069 12.1654 9.75613 11.84L13.5061 8.08999Z"
          fill={Colors[color]} />
  </svg>
));


Shield.displayName = 'Shield';
