import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Success = React.memo<IconProps>(({ w = 92, h = 92, color = 'white' }) => (
  <svg width={w} height={h} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M91.8327 46.0013C91.8327 71.3144 71.3124 91.8346 45.9994 91.8346C20.6863 91.8346 0.166016 71.3144 0.166016 46.0013C0.166016 20.6883 20.6863 0.167969 45.9994 0.167969C71.3124 0.167969 91.8327 20.6883 91.8327 46.0013Z" fill="#018F69" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.7796 28.7243C71.4068 30.3515 71.4068 32.9897 69.7796 34.6169L41.1338 63.2627C39.5066 64.8899 36.8684 64.8899 35.2412 63.2627L22.2204 50.2418C20.5932 48.6147 20.5932 45.9765 22.2204 44.3493C23.8476 42.7221 26.4858 42.7221 28.1129 44.3493L38.1875 54.4238L63.8871 28.7243C65.5142 27.0971 68.1524 27.0971 69.7796 28.7243Z"
      fill="white"
    />
  </svg>
));

Success.displayName = 'Success';
