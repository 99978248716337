import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import LinearLayout from '../../layout/LinearLayout';
import useDevice from '../../../hooks/useDevice';

const HouseRules = () => {
  const { isMobile } = useDevice();
  return (
    <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40} gap={24}>
      <Text lineHeight={20} fontStyle={isMobile ? 'TextMd_Bold' : 'TextLg_Bold'} textColor={'white'} text={'House Rules'} textAlign={'start'} />
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'BASEPOKER.COM'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Last updated: June 17th, 2024'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Version HR20240617.1'} />
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Acknowledgment of House Rules'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'The House Rules & Policy, managed and enforced by the Basepoker, must be fully acknowledged by you, the End User (‘User’), prior to engaging in play on Basepoker.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Acceptance of Terms'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'By agreeing to or accepting Basepoker\'s general Terms and Conditions, the User simultaneously consents to the terms. The User’s decision to play on Basepoker signifies their acceptance of these terms.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Right to Amend House Rules'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'We reserve the right to modify the House Rules. Users will be notified of any material changes via email. Continued use of the Services following notification will be considered acceptance of the changes.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Penalties for House Rules Breaches'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'In the event of an House Rule breach, the Security Team reserves the right to impose one or more penalties following a thorough investigation of the case, based on accumulated information, data, and evidence.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Types of Penalties for House Rules Breaches'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Penalties for confirmed HRP breaches may include one or more of the following:'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={' • WARNING: A written notice via email;\\n • REWARD REMOVAL: Removal or reduction of promotional rewards;\\n •  SUSPENSION: Temporary suspension of the account;\\n •  ACCOUNT CLOSURE: Permanent closure of the account;\\n •  PERMANENT BAN: Permanent ban from Basepoker;\\n •  CONFISCATION OF FUNDS: Confiscation of funds won through prohibited activities or behavior to compensate victims of unfair gameplay or, where no specific victim can be identified, deposited into the Network Ecology Improvement Fund. Confiscation may include funds in the poker wallet, main wallet, as well as any pending withdrawals.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'House Rules'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Familiarize yourself with the fundamental rules and guidelines that govern gameplay on our platform. Learn about fair play, player conduct, and specific regulations to ensure a safe and enjoyable poker experience for everyone.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Betting and Chip Rules'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={' • Players are not allowed to place bets exceeding the value of the chips they had on the table at the start of a hand.\\n' +
                  ' • Players may only acquire additional chips between hands.\\n' +
                  ' • If a player runs out of chips during a hand, they are deemed "all-in."\\n' +
                  ' • Players are prohibited from removing money from the table during a playing session. Continuing to play after withdrawing money from the game is not permitted under the table bets format.\\n' +
                  ' • Players must not interfere, interrupt, attempt to interrupt, or try to manipulate the operations of the website or the normal play of any games on Basepoker. Non-compliance may result in exclusion from the website and possible criminal and civil investigations.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Time Bank'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={' • When your normal time to act is almost over, the TIME bank button will appear, allowing you to activate your time bank.\\n' +
                  ' • If you have money invested in the pot (including antes and blinds) and do not act within the normal time, the time bank will start automatically.\\n' +
                  ' • If you don\'t have any money invested in the pot and fail to act within the normal time, your hand will automatically fold, and you will be forced to sit out.\\n' +
                  ' • The time bank provides additional thinking time and is not a safeguard against disconnections.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Missed Blind Rules'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'If a player misses posting the big blind by sitting out, the following rules apply when rejoining the game:'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={' • Upon rejoining, the player must post the big blind that was missed during their absence.\\n •  This post is considered a "live bet" and will be added directly to the pot.\\n •  Players cannot avoid posting the missed big blind by immediately sitting out again; they must post the missed big blind upon rejoining the table to resume play.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Tie Hands Rules'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={' • In the event that two or more active players reach a showdown with equal ranking hands, the winner is determined by card value (e.g., a pair of aces beats a pair of kings).\\n' +
                  ' • If poker hands remain tied (e.g., a pair of kings vs. a pair of kings), the highest-ranked card not held in common (the "kicker") determines the winner.\\n' +
                  ' • In cases of exact ties in poker hand ranking, the pot (the accumulated chips in the center of the table) will be evenly split between players holding tied poker hands.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Button Rule'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Basepoker employs the Simplified Forward Moving Button rule. This means that the dealer button always moves forward, followed by the small blind (SB) and big blind (BB), regardless of the situation. This policy may result in players occasionally skipping the posting of the SB or BB.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Live Chat Guideline'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Our aim is to create a fun and comfortable environment for everyone. Therefore, we do not tolerate vulgarity at the tables. All chat must be clean and respectful. The following behaviors are prohibited in our chat and may result in warnings or chat suspensions:'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={' • Profanity/Abuse: Using profane, vulgar, racist, or abusive/insulting language. Retaliatory chat will also likely result in sanctions for all involved. Attempting to bypass the profanity filter is against chat rules.\\n •  Begging/Solicitation: Repeatedly asking for chips, whether play money or real money.\\n •  Flooding: Sending multiple frequent messages to drown out legitimate chat.\\n •  Commercial Use: Using chat for any commercial gain, including promoting third-party services or products.\\n •  Malicious Behavior: Engaging in collusion, fraud, or spam. Making untrue or derogatory statements about our site is prohibited. Users should act respectfully towards other customers and staff.\\n •  Stalking/Harassment: Following another player from table to table to harass, abuse, intimidate, or intentionally disrupt the game. Reports should include the user\'s ID and approximate dates and times.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Use of Real-Time Assistance (RTA)'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'RTA is defined as any external help that provides users with an unfair advantage over their opponents, affects their decision-making in real-time, or enables them to play in a manner they otherwise could not. All decisions at the poker table must be made without any external assistance. External assistance includes, but is not limited to, software, apps, websites, and both physical and digital reference materials. Consulting any charts during play is strictly forbidden. Potential penalties for breaches: PERMANENT BAN, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Collusion'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Collusion is defined as any behavior where two or more users conspire to gain an unfair advantage over their direct opponents in cash games or over the entire field in tournaments. Users must play their own hands independently and in their own interests. Users must not share information about their hole cards with other users while the same hand is still in play under any circumstances. Users must avoid \'soft playing,\' which means intentionally playing hands in a non-aggressive manner against certain opponents (e.g., friends, backers, or other persons of interest). The only exceptions are in-the-money (ITM) bubble situations or multi-handed situations after the money bubble where one or more users are all-in. 4.5. Users must not conspire to cause financial harm to any other user. Potential penalties for breaches: WARNING, SUSPENSION, PERMANENT BAN, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Multi-Accounting'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Multi-Accounting is defined as intentionally creating and/or using multiple accounts on the same Basepoker skin. Users are strictly prohibited from participating in any game using multiple accounts simultaneously. Users are strictly prohibited from participating in any freerolls or promotions using multiple accounts simultaneously. Users with Verified Name accounts are allowed to maintain only one account across the entire network without exception. Verified Name accounts are subject to closer scrutiny and higher standards. Potential penalties for breaches: SUSPENSION, ACCOUNT CLOSURE, PERMANENT BAN, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Use of Bots'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'A bot is defined as any software, program, website, or app that allows play without human intervention or reduces the need for a human to make decisions and/or perform actions. All decisions at the poker table must be made by the person to whom the account belongs. Bots include, but are not limited to, artificial intelligence, fully or partially automated bots, or any other tools that automate decisions or actions. Potential penalties for breaches: PERMANENT BAN, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Chip Dumping'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Chip Dumping is defined as intentionally losing chips to another user. When Chip Dumping occurs, both the user transferring the chips and the user receiving the chips are at fault. Chip Dumping as a method to transfer money is strictly prohibited and may be considered money laundering. Potential penalties for breaches: WARNING, SUSPENSION, PERMANENT BAN, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Bum Hunting'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Bum Hunting is defined as the intentional practice of seeking out less experienced users while deliberately avoiding engagement with more skilled users. Users are strictly prohibited from selectively participating in games based on the presence of certain users. Users are strictly prohibited from targeting specific users, such as by leaving a game shortly after these users leave and/or resuming the game when they return. Potential penalties for breaches: WARNING, SUSPENSION, REWARD REMOVAL, PERMANENT BAN'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Data Mining'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Data Mining is defined as the accumulation of hand histories other than one’s own.The sole permitted use for importing hand histories is to track one’s own gameplay and results. Users are strictly prohibited from using any third-party tools to import and/or convert hand histories from the poker tables. Potential penalties for breaches: GAME REMOVAL, PERMANENT BAN, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Trespassing'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Trespassing is defined as returning to any Basepoker site after receiving a network-wide ban. Users are strictly prohibited from returning to Basepoker to play on any new or pre-existing accounts, regardless of who owns the account, after a network-wide ban has been issued. Potential penalties for breaches: PERMANENT BAN, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Account Sharing'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Account Sharing is defined as sharing an account between two or more people. Users are only allowed to possess and play on an account registered under their own credentials. Potential penalties for breaches: SUSPENSION, REWARD REMOVAL, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Hacking'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Hacking is defined as gaining unauthorized access to an account—its username and password—by compromising digital devices or networks. If the user is a victim of hacking, they should immediately report the incident to the Security Team. Potential penalties for breaches: PERMANENT BAN, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Offensive Conduct'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Offensive Conduct is defined as any act that is defamatory, racist, vulgar, hateful, obscene, profane, threatening, insulting, offensive, discriminatory, illegal, associated with criminal activities, intended to impersonate another person or entity, intended to advertise, intended to solicit personal information, intended to deceive or mislead others, harmful to the computer, data, or financial security of any party, or considered spamming other users. Offensive Conduct includes activities related to profile pictures, chats, SnapCam, audio chats, or voice messages. Potential penalties for breaches: CHAT SUSPENSION / CHAT BAN, WARNING, SUSPENSION, PERMANENT BAN'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Predatory Behavior'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Predatory Behavior is defined as exploiting unethical advantages or engaging in exploitative tactics to gain undeserved benefits over other users. Predatory behavior includes, but is not limited to, grimming/buttoning, ratholing, and other actions that defy the spirit of fair competition and are deemed unethical by the poker community. Potential penalties for breaches: WARNING, SUSPENSION, PERMANENT BAN'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Circumvention'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Circumvention is defined as any manipulation with malicious intent of the Basepoker network and its software to bypass security measures. Users are strictly prohibited from exploiting or attempting to exploit software bugs within the client for financial gain. Potential penalties for breaches: PERMANENT BAN, CONFISCATION OF FUNDS'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Prohibited Use of Tools or Services'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Basepoker strictly prohibits the use of any auxiliary tools or services that may facilitate Prohibited Activities and Behaviors or provide an unfair advantage over opponents. This includes tools or services that violate Basepoker’s Terms and Conditions, Privacy Policy, House Rules. Examples listed are not exhaustive. Prohibited tools or services include, but are not limited to, RTA, bots (fully or partially automated), solvers, charts, or HUDs. Tools or services that do not support or interact with Basepoker during gameplay will not be flagged.'} />
        </LinearLayout>
      </LinearLayout>
    </LinearLayout>
  );
};


export default HouseRules;