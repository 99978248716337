import React from 'react';
import { IconProps } from '../types';

interface DealerButtonProps extends IconProps {
  className?: string;
}

export const DealerButton = React.memo<DealerButtonProps>(({ w = 24, h = 24, color = 'white', className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ii_13925_326990)">
      <circle cx="10" cy="10" r="10" fill="#FEB273" />
    </g>
    <path
      d="M9.85 4.5835C12.88 4.5835 15.175 6.8035 15.175 9.7885C15.175 12.8335 12.88 15.0835 9.85 15.0835H6.25V4.5835H9.85ZM8.47 12.9535H10C11.665 12.9535 12.88 11.6035 12.88 9.7885C12.88 8.0035 11.68 6.7135 10 6.7135H8.47V12.9535Z"
      fill="#1D2839" />
    <defs>
      <filter id="filter0_ii_13925_326990" x="0" y="-1" width="20" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.44 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_13925_326990" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="effect1_innerShadow_13925_326990" result="effect2_innerShadow_13925_326990" />
      </filter>
    </defs>
  </svg>


));

DealerButton.displayName = 'DealerButton';


