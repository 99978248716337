import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TournamentInfoBox } from '../../../pages/TournamentDetailScreen';
import TournamentInfoTabPrize, { TournamentPrizeData } from './TournamentInfoTabPrize';
import TournamentInfoTabPlayers, { PlayerData } from './TournamentInfoTabPlayers';

const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};

  flex-shrink: 0;
`;

const Tab = styled.button<{ isActive: boolean }>`
  background: none;
  height: 44px;
  padding: 8px 16px;
  width: 100%;
  ${({ theme }) => theme.textStyles.TextMd_Semibold}
  cursor: pointer;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray400)};
  border-bottom: ${({ theme, isActive }) => (isActive ? `2px solid ${theme.colors.white}` : `2px solid transparent`)};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const TabContent = styled.div`
  box-sizing: border-box;
  //flex: 1;
  overflow: scroll;
`;

export type TournamentTab = 'Prize' | 'Players';

const TournamentInfoTab: React.FC<{ initialTab: TournamentTab }> = ({ initialTab }) => {
  const tabs: TournamentTab[] = ['Prize', 'Players'];
  const [activeTab, setActiveTab] = useState<TournamentTab>(initialTab);

  const handleTabClick = (tab: TournamentTab) => {
    setActiveTab(tab);
  };

  const renderTabContent = (tab: TournamentTab) => {
    switch (tab) {
      case 'Prize':
        return <TournamentInfoTabPrize tournamentPrize={tournamentPrizeSample} />;
      case 'Players':
        return <TournamentInfoTabPlayers players={playersSample} />;
      default:
        return <div>{tab} Empty Content</div>;
    }
  };

  return (
    <TournamentInfoBox>
      <Tabs>
        {tabs.map(tab => (
          <Tab key={tab} isActive={activeTab === tab} onClick={() => handleTabClick(tab)}>
            {tab}
          </Tab>
        ))}
      </Tabs>
      <TabContent>{renderTabContent(activeTab)}</TabContent>
    </TournamentInfoBox>
  );
};

const tournamentPrizeSample: TournamentPrizeData[] = [
  { id: 1, rank: '1', award: '1000 USDT' },
  { id: 2, rank: '2', award: '800 USDT' },
  { id: 3, rank: '3', award: '600 USDT' },
  { id: 4, rank: '4', award: '400 USDT' },
  { id: 5, rank: '5', award: '200 USDT' },
  { id: 6, rank: '6-10', award: '100 USDT' }
];

const playersSample: PlayerData[] = [
  { id: 1, rank: 1, flagUrl: '🇺🇸', username: 'PlayerOne', chips: 10000 },
  { id: 2, rank: 2, flagUrl: '🇬🇧', username: 'PlayerTwo', chips: 10000 },
  { id: 3, rank: 3, flagUrl: '🇨🇦', username: 'PlayerThree', chips: 10000 },
  { id: 4, rank: 4, flagUrl: '🇦🇺', username: 'PlayerFour', chips: 10000 },
  { id: 5, rank: 5, flagUrl: '🇩🇪', username: 'PlayerFive', chips: 10000 },
  { id: 6, rank: 6, flagUrl: '🇫🇷', username: 'PlayerSix', chips: 10000 },
  { id: 7, rank: 7, flagUrl: '🇮🇹', username: 'PlayerSeven', chips: 10000 },
  { id: 8, rank: 8, flagUrl: '🇯🇵', username: 'PlayerEight', chips: 10000 },
  { id: 9, rank: 9, flagUrl: '🇧🇷', username: 'PlayerNine', chips: 10000 },
  { id: 10, rank: 10, flagUrl: '🇪🇸', username: 'PlayerTen', chips: 10000 },
  { id: 11, rank: 11, flagUrl: '🇷🇺', username: 'PlayerEleven', chips: 10000 },
  { id: 12, rank: 12, flagUrl: '🇮🇳', username: 'PlayerTwelve', chips: 10000 },
  { id: 13, rank: 13, flagUrl: '🇲🇽', username: 'PlayerThirteen', chips: 10000 },
  { id: 14, rank: 14, flagUrl: '🇨🇭', username: 'PlayerFourteen', chips: 10000 },
  { id: 15, rank: 15, flagUrl: '🇳🇱', username: 'PlayerFifteen', chips: 10000 },
  { id: 16, rank: 16, flagUrl: '🇧🇪', username: 'PlayerSixteen', chips: 10000 },
  { id: 17, rank: 17, flagUrl: '🇸🇪', username: 'PlayerSeventeen', chips: 10000 },
  { id: 18, rank: 18, flagUrl: '🇳🇴', username: 'PlayerEighteen', chips: 10000 },
  { id: 19, rank: 19, flagUrl: '🇰🇷', username: 'PlayerNineteen', chips: 10000 },
  { id: 20, rank: 20, flagUrl: '🇨🇳', username: 'PlayerTwenty', chips: 10000 }
];

export default TournamentInfoTab;
