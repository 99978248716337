import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { TableCreateConfig } from '../api/responseTypes';
import { holdemApi } from '../api/holdem';
import { PURGE } from 'redux-persist';
import { logDebug } from '../../utils/ConsoleUtil';

type UserTableState = {
  tableSelectedSeatId: Record<number, number | undefined>;
  tableRotateNumber: Record<number, number>;
  tableCreateConfig: TableCreateConfig | undefined;
};

const tableSlice = createSlice({
  name: 'userTable',
  initialState: {
    tableSelectedSeatId: {},
    tableRotateNumber: {},
    tableCreateConfig: undefined
  } as UserTableState,
  reducers: {
    setTableSelectedSeatId: (state, payloadAction: PayloadAction<{ tableId: number; seatId: number | undefined }>) => {
      const { tableId, seatId } = payloadAction.payload;
      if (tableId !== undefined) {
        if (!state.tableSelectedSeatId) {
          state.tableSelectedSeatId = {};
        }
        state.tableSelectedSeatId[tableId] = seatId;
      }
    },
    setTableRotateNumber: (state, payloadAction: PayloadAction<{ tableId: number; rotateNumber: number }>) => {
      state.tableRotateNumber[payloadAction.payload.tableId] = payloadAction.payload.rotateNumber;
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, (state, action) => {
      state.tableSelectedSeatId = {};
      state.tableRotateNumber = {};
      state.tableCreateConfig = undefined;
    });
    builder.addMatcher(holdemApi.endpoints.holdemTableConfig.matchFulfilled, (state, { payload }) => {
      state.tableCreateConfig = payload.data;
    });
  }
});

export const selectTableSelectedSeatId = (state: RootState, tableId?: number) => (tableId !== undefined && !Number.isNaN(tableId) ? state.tableSlice.tableSelectedSeatId?.[tableId] : undefined);
export const selectTableRotateNumber = (state: RootState, tableId?: number) => (tableId !== undefined ? state.tableSlice.tableRotateNumber[tableId] : 0);

export const selectTableCreateConfig = (state: RootState) => state.tableSlice.tableCreateConfig;

export const { setTableSelectedSeatId, setTableRotateNumber } = tableSlice.actions;

export default tableSlice;
