import React from 'react';
import { SliderProps } from './types';
import _ from 'lodash';
import { divideBigNumbers, multiplyBigNumbers, subtractBigNumbers } from '../../../utils/BigNumberUtil';

const useSlider = ({ max, onChange, value, min }: SliderProps) => {
  const progress = multiplyBigNumbers(divideBigNumbers(subtractBigNumbers(value, min), subtractBigNumbers(max, min)), 100).toNumber();
  const [showTooltip, setShowTooltip] = React.useState(false);
  const tooltipTimer = React.useRef<number | null>(null);

  const handleSlider = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value));
    !showTooltip && setShowTooltip(true);

    tooltipTimer.current && clearTimeout(tooltipTimer.current);
    tooltipTimer.current = window.setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  return { progress, handleSlider, value, showTooltip };
};

export default useSlider;
