import React from 'react';
import { ClickableIconProps, ExtendedIconProps, IconProps } from '../types';

export const AddChips = React.memo<ExtendedIconProps>(({ w = 24, h = 24, color = 'white', onClick, opacity }) => (
  <svg width={w} height={h} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} opacity={opacity}>
    <path d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="#018F69" />
    <g filter="url(#filter0_d_15731_113742)">
      <path d="M13.0673 10.8024C13.0673 12.8636 11.3733 14.5346 9.28367 14.5346C7.19401 14.5346 5.5 12.8636 5.5 10.8024C5.5 8.74124 7.19401 7.07031 9.28367 7.07031C11.3733 7.07031 13.0673 8.74124 13.0673 10.8024Z" fill="white" />
      <path d="M13.0673 10.8024C13.0673 12.8636 11.3733 14.5346 9.28367 14.5346C7.19401 14.5346 5.5 12.8636 5.5 10.8024C5.5 8.74124 7.19401 7.07031 9.28367 7.07031C11.3733 7.07031 13.0673 8.74124 13.0673 10.8024Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5152 14.38C12.7316 13.641 13.5425 12.3152 13.5425 10.8026C13.5425 9.28986 12.7314 7.96398 11.5149 7.22494C11.8575 7.12434 12.2204 7.07031 12.5962 7.07031C14.6858 7.07031 16.3798 8.74124 16.3798 10.8024C16.3798 12.8636 14.6858 14.5346 12.5962 14.5346C12.2205 14.5346 11.8577 14.4806 11.5152 14.38ZM10.9411 14.1595C12.2012 13.5539 13.0695 12.2782 13.0695 10.8026C13.0695 9.3268 12.2011 8.05109 10.9409 7.44548C9.68076 8.05115 8.8125 9.32678 8.8125 10.8024C8.8125 12.2782 9.68089 13.5539 10.9411 14.1595Z"
        fill="white"
      />
    </g>
    {/*<mask id="mask0_15731_113742" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">*/}
    {/*  <path d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="#018F69" />*/}
    {/*</mask>*/}
    <g mask="url(#mask0_15731_113742)">
      <path
        d="M10.9997 0C10.36 0 9.73437 0.0592122 9.125 0.166655V1.91955C9.125 2.20816 9.37691 2.43364 9.65808 2.38847C10.0953 2.31819 10.5432 2.2809 10.9997 2.2809C11.4562 2.2809 11.904 2.31819 12.3413 2.38847C12.6225 2.43364 12.8744 2.2082 12.8744 1.91955V0.166655C12.265 0.0592122 11.6393 0 10.9997 0Z"
        fill="white"
      />
      <path
        d="M9.125 20.0802V21.8331C9.73437 21.9405 10.36 21.9997 10.9997 21.9997C11.6393 21.9997 12.265 21.9405 12.8744 21.8331V20.0802C12.8744 19.7916 12.6225 19.5661 12.3413 19.6113C11.904 19.6815 11.4562 19.7188 10.9997 19.7188C10.5432 19.7188 10.0953 19.6815 9.65808 19.6113C9.37691 19.5661 9.125 19.7916 9.125 20.0802Z"
        fill="white"
      />
      <path
        d="M21.9997 10.9997C21.9997 10.3601 21.9404 9.73438 21.833 9.125H20.0802C19.7916 9.125 19.5661 9.37692 19.6113 9.6581C19.6815 10.0954 19.7188 10.5433 19.7188 10.9997C19.7188 11.4562 19.6815 11.9041 19.6113 12.3414C19.5661 12.6226 19.7915 12.8745 20.0802 12.8745H21.833C21.9404 12.2651 21.9997 11.6394 21.9997 10.9997Z"
        fill="white"
      />
      <path
        d="M1.9195 9.125H0.166651C0.0592106 9.73438 0 10.3601 0 10.9997C0 11.6394 0.0592106 12.2651 0.166651 12.8745H1.9195C2.20811 12.8745 2.43358 12.6226 2.38841 12.3414C2.31813 11.9041 2.28084 11.4562 2.28084 10.9997C2.28084 10.5433 2.31813 10.0954 2.38841 9.6581C2.43358 9.37692 2.20811 9.125 1.9195 9.125Z"
        fill="white"
      />
      <path
        d="M18.7853 3.21396C18.3269 2.75557 17.8367 2.34905 17.3241 1.98828L16.0851 3.22726C15.8812 3.43125 15.9023 3.77118 16.1357 3.94069C16.4988 4.20435 16.846 4.49899 17.1732 4.8261C17.5003 5.1532 17.7949 5.50053 18.0586 5.86358C18.2281 6.09697 18.5679 6.11815 18.772 5.91416L20.0109 4.67518C19.6502 4.16259 19.2437 3.67239 18.7853 3.21396Z"
        fill="white"
      />
      <path
        d="M3.22723 16.0852L1.98828 17.3241C2.34904 17.8367 2.75555 18.327 3.21393 18.7853C3.67231 19.2437 4.16254 19.6503 4.67511 20.011L5.91405 18.772C6.11804 18.5681 6.09686 18.2281 5.86348 18.0586C5.50044 17.795 5.15316 17.5003 4.82602 17.1732C4.49893 16.8461 4.20429 16.4988 3.94064 16.1357C3.77113 15.9023 3.43126 15.8811 3.22723 16.0852Z"
        fill="white"
      />
      <path
        d="M18.7853 18.7854C19.2437 18.327 19.6502 17.8368 20.0109 17.3242L18.7719 16.0851C18.568 15.8812 18.228 15.9023 18.0585 16.1357C17.7949 16.4988 17.5003 16.8461 17.1732 17.1732C16.8461 17.5003 16.4987 17.795 16.1357 18.0586C15.9023 18.2281 15.8812 18.568 16.0851 18.772L17.3241 20.011C17.8367 19.6503 18.3269 19.2438 18.7853 18.7854Z"
        fill="white"
      />
      <path
        d="M5.91403 3.22723L4.67509 1.98828C4.16248 2.34905 3.67229 2.75553 3.21387 3.21393C2.75546 3.67232 2.34899 4.16257 1.98828 4.67516L3.22717 5.91416C3.43116 6.11815 3.77108 6.09697 3.94059 5.86358C4.20423 5.50053 4.49887 5.15323 4.82596 4.82608C5.15305 4.49898 5.50037 4.20433 5.86341 3.94067C6.09688 3.77116 6.11806 3.43127 5.91403 3.22723Z"
        fill="white"
      />
    </g>
    <defs>
      <filter id="filter0_d_15731_113742" x="3.5" y="6.07031" width="14.8789" height="11.4648" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15731_113742" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15731_113742" result="shape" />
      </filter>
    </defs>
  </svg>
));

AddChips.displayName = 'AddChips';
