import { useLazyGetBalanceQuery } from '../store/api/assets';
import { useSelector } from 'react-redux';
import { selectUserBalance } from '../store/slices/assetSlice';
import { useMemo } from 'react';
import { AssetBalance } from '../store/api/responseTypes';

export const useMyBalance = () => {
  const userBalanceFromRedux = useSelector(selectUserBalance);
  const [refetchBalance] = useLazyGetBalanceQuery();

  // assetName으로 AssetBalance를 찾는 함수
  const findAssetBalance = (assetName: string | undefined): AssetBalance | undefined => {
    return userBalanceFromRedux?.balances.find((balance: AssetBalance) => balance.assetName === assetName);
  };

  return useMemo(() => {
    return {
      myBalance: userBalanceFromRedux,
      refetchBalance,
      findAssetBalance
    };
  }, [userBalanceFromRedux, refetchBalance]);
};
