import styled from '@emotion/styled';
import React from 'react';
import { Colors } from 'src/styles/Colors';
import { TChipColor } from '../../chip/types';

interface ChipProps {
  w?: number;
  h?: number;
  chipColor?: TChipColor;
  className?: string;
}

export const Chip = React.memo<ChipProps>(({ w = 57, h = 60, chipColor = 'white', className }) => {
  const { color, shadowColor, subShadowColor } = React.useMemo(() => {
    switch (chipColor) {
      case 'red':
        return { color: Colors.error500, shadowColor: Colors.error600, subShadowColor: Colors.gray300 };
      case 'blue':
        return { color: Colors.blueLight400, shadowColor: Colors.blueLight500, subShadowColor: '#ABABAB' };
      case 'green':
        return { color: Colors.success400, shadowColor: Colors.success700, subShadowColor: Colors.gray300 };
      case 'black':
        return { color: Colors.gray500, shadowColor: Colors.gray700, subShadowColor: Colors.gray300 };
      case 'purple':
        return { color: Colors.purple500, shadowColor: Colors.purple700, subShadowColor: '#ABABAB' };
      case 'yellow':
        return { color: Colors.warning400, shadowColor: '#AC750F', subShadowColor: Colors.gray300 };
      case 'orange':
        return { color: Colors.orange500, shadowColor: Colors.orange700, subShadowColor: Colors.gray300 };
      case 'brown':
        return { color: '#D49E5C', shadowColor: '#8B5F2A', subShadowColor: Colors.gray300 };
      case 'white':
      default:
        return { color: Colors.white, shadowColor: Colors.gray400, subShadowColor: Colors.gray500 };
    }
  }, [chipColor]);
  return (
    <svg className={className} width={w} height={h} viewBox="0 0 57 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.2319 59.5946C43.8239 59.5946 56.4638 46.9548 56.4638 31.3627C56.4638 15.7707 43.8239 3.13086 28.2319 3.13086C12.6398 3.13086 0 15.7707 0 31.3627C0 46.9548 12.6398 59.5946 28.2319 59.5946Z" fill={shadowColor} />
      <path d="M28.2319 56.4638C43.8239 56.4638 56.4638 43.8239 56.4638 28.2319C56.4638 12.6398 43.8239 0 28.2319 0C12.6398 0 0 12.6398 0 28.2319C0 43.8239 12.6398 56.4638 28.2319 56.4638Z" fill={color} />
      <path
        d="M28.2335 0C26.5917 0 24.9859 0.15197 23.4219 0.427727V4.92659C23.4219 5.66732 24.0684 6.24603 24.7901 6.13009C25.9124 5.94971 27.0619 5.854 28.2335 5.854C29.405 5.854 30.5546 5.94971 31.6768 6.13009C32.3985 6.24603 33.0451 5.66743 33.0451 4.92659V0.427727C31.4811 0.15197 29.8752 0 28.2335 0Z"
        fill={chipColor === 'white' ? '#BABFC7' : 'white'}
      />
      <path
        d="M23.4219 51.5367V56.0356C24.9859 56.3113 26.5917 56.4633 28.2335 56.4633C29.8752 56.4633 31.4811 56.3113 33.0451 56.0356V51.5367C33.0451 50.796 32.3985 50.2173 31.6768 50.3332C30.5546 50.5136 29.405 50.6093 28.2335 50.6093C27.0619 50.6093 25.9124 50.5136 24.7901 50.3332C24.0684 50.2173 23.4219 50.796 23.4219 51.5367Z"
        fill={chipColor === 'white' ? '#BABFC7' : 'white'}
      />
      <path
        d="M56.4652 28.2315C56.4652 26.5898 56.3133 24.9839 56.0375 23.4199H51.5386C50.7979 23.4199 50.2192 24.0665 50.3352 24.7881C50.5155 25.9104 50.6112 27.06 50.6112 28.2315C50.6112 29.4031 50.5155 30.5526 50.3352 31.6749C50.2192 32.3966 50.7978 33.0431 51.5386 33.0431H56.0375C56.3133 31.4791 56.4652 29.8732 56.4652 28.2315Z"
        fill={chipColor === 'white' ? '#BABFC7' : 'white'}
      />
      <path
        d="M4.9266 23.4199H0.427727C0.15197 24.9839 0 26.5898 0 28.2315C0 29.8732 0.15197 31.4791 0.427727 33.0431H4.9266C5.66733 33.0431 6.24603 32.3966 6.13009 31.6749C5.94971 30.5526 5.854 29.4031 5.854 28.2315C5.854 27.06 5.94971 25.9104 6.13009 24.7881C6.24603 24.0665 5.66733 23.4199 4.9266 23.4199Z"
        fill={chipColor === 'white' ? '#BABFC7' : 'white'}
      />
      <path
        d="M48.2149 8.2473C47.0385 7.07083 45.7802 6.02748 44.4647 5.10156L41.2847 8.28144C40.7611 8.805 40.8155 9.67744 41.4145 10.1125C42.3462 10.7892 43.2376 11.5454 44.0772 12.3849C44.9167 13.2244 45.6729 14.1159 46.3496 15.0476C46.7847 15.6467 47.657 15.701 48.1807 15.1775L51.3606 11.9976C50.4347 10.682 49.3914 9.42389 48.2149 8.2473Z"
        fill={chipColor === 'white' ? '#BABFC7' : 'white'}
      />
      <path
        d="M8.28144 41.2867L5.10156 44.4665C6.02748 45.7821 7.07083 47.0403 8.2473 48.2168C9.42378 49.3933 10.682 50.4366 11.9976 51.3625L15.1775 48.1826C15.701 47.6591 15.6467 46.7867 15.0476 46.3516C14.1159 45.6749 13.2245 44.9187 12.3849 44.0792C11.5454 43.2397 10.7892 42.3482 10.1125 41.4165C9.67744 40.8175 8.80511 40.763 8.28144 41.2867Z"
        fill={chipColor === 'white' ? '#BABFC7' : 'white'}
      />
      <path
        d="M48.2149 48.2169C49.3913 47.0404 50.4347 45.7822 51.3606 44.4666L48.1806 41.2866C47.6571 40.7631 46.7846 40.8174 46.3496 41.4164C45.6729 42.3482 44.9167 43.2395 44.0772 44.0792C43.2377 44.9187 42.3462 45.6749 41.4145 46.3516C40.8155 46.7866 40.7611 47.659 41.2847 48.1826L44.4645 51.3625C45.7802 50.4366 47.0383 49.3934 48.2149 48.2169Z"
        fill={chipColor === 'white' ? '#BABFC7' : 'white'}
      />
      <path
        d="M15.1774 8.28137L11.9975 5.10156C10.6819 6.02749 9.42375 7.07074 8.24717 8.24723C7.07059 9.42372 6.02736 10.682 5.10156 11.9975L8.28131 15.1775C8.80486 15.701 9.6773 15.6467 10.1124 15.0476C10.789 14.1159 11.5452 13.2245 12.3848 12.3849C13.2243 11.5454 14.1157 10.7891 15.0475 10.1124C15.6467 9.67738 15.7011 8.80504 15.1774 8.28137Z"
        fill={chipColor === 'white' ? '#BABFC7' : 'white'}
      />
      <path d="M44.4648 51.3634L51.3634 44.4648V47.5373L51.2431 47.7081C49.4046 50.3188 47.1315 52.5945 44.5228 54.4359L44.4648 51.3634Z" fill={subShadowColor} />
      <path d="M12.0586 51.3634L5.10205 44.4648V47.5953L5.21548 47.755C7.07466 50.373 9.36954 52.6524 12.0001 54.4938L12.0586 51.3634Z" fill={subShadowColor} />
      <path d="M23.4219 56C26.562 56.6127 29.7903 56.6199 32.9332 56.0213L33.0451 56V59.1884L32.9323 59.2072C29.7833 59.732 26.5687 59.7257 23.4219 59.1884V56Z" fill={subShadowColor} />
    </svg>
  );
});

Chip.displayName = 'Chip';
