import React from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import { CopyIcon } from '../icon';
import { logError } from '../../../utils/ConsoleUtil';
import { css } from '@emotion/react';
import _ from 'lodash';
import Text from './Text';

interface CopyableBoxProps {
  copyableValue: string;
  backgroundColor: keyof typeof Colors;
  className?: string;
  mode?: 'copyIcon' | 'copySuffix';
  onCopy?: (success: boolean) => void;
}

const CopyableBox: React.FC<CopyableBoxProps> = ({ copyableValue, backgroundColor, mode = 'copyIcon', onCopy, ...props }) => {
  const [suffixText, setSuffixText] = React.useState<string>('Copy');
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(copyableValue);
      setSuffixText('Copied');
      _.debounce(() => setSuffixText('Copy'), 2000)();
      if (onCopy) onCopy(true);
    } catch (err) {
      logError('Failed to copy:', err);
      if (onCopy) onCopy(false);
    }
  };

  return (
    <Section {...props} backgroundColor={backgroundColor} mode={mode} onClick={handleCopy}>
      <Value mode={mode}>{copyableValue}</Value>
      {mode === 'copyIcon' && (
        <StyledCopyIcon>
          <CopyIcon />
        </StyledCopyIcon>
      )}
      {mode === 'copySuffix' && (
        <SuffixWrap>
          <Text hasPointer={true} text={suffixText} fontStyle={'TextSmall_Semibold'} textColor={'gray300'} />
        </SuffixWrap>
      )}
    </Section>
  );
};

const StyledCopyIcon = styled.div`
  width: 20px;
  height: 20px;
`;

const SuffixWrap = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  width: 79px;
  margin-left: auto;
  ${({ theme }) => theme.ButtonColors.gray};
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  @media (max-width: 768px) {
    width: 72px;
  }
`;

const Section = styled.div<{ backgroundColor: keyof typeof Colors; mode: 'copyIcon' | 'copySuffix' }>`
  width: 100%;
  min-height: ${({ mode }) => (mode === 'copyIcon' ? '44px' : '52px')};
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: ${({ mode }) => (mode === 'copyIcon' ? 'center' : 'flex-start')};
  align-items: center;
  flex-direction: row;
  padding: ${({ mode }) => (mode === 'copyIcon' ? '16px 0' : '0 0 0 16px')};
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor!]};
  gap: 8px;
  border-radius: ${({ mode }) => (mode === 'copyIcon' ? '100px' : '8px')};
  cursor: pointer;
`;

const Value = styled.div<{ mode: 'copyIcon' | 'copySuffix' }>`
  ${({ mode }) =>
    mode === 'copyIcon'
      ? css`
          max-width: 95%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: 768px) {
            max-width: 90%;
          }
        `
      : css`
          padding: 8px 0;
          margin-right: 88px;
          word-break: break-all;
        `}
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.white};
`;

export default CopyableBox;
