import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const VolumeOn = React.memo<IconProps>(({ w = 24, h = 24, color = 'gray400' }) => (
  <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1639 4.18816C19.6123 3.86564 20.2372 3.96763 20.5597 4.41596C22.0953 6.55066 23 9.17113 23 11.9999C23 14.8287 22.0953 17.4492 20.5597 19.5839C20.2372 20.0322 19.6123 20.1342 19.1639 19.8117C18.7156 19.4892 18.6136 18.8643 18.9361 18.416C20.2352 16.6102 21 14.3959 21 11.9999C21 9.60401 20.2352 7.38968 18.9361 5.5839C18.6136 5.13557 18.7156 4.51067 19.1639 4.18816Z"
      fill={Colors[color]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1732 7.17975C15.6262 6.86379 16.2495 6.97486 16.5655 7.42783C17.4696 8.72404 18 10.3015 18 11.9999C18 13.6983 17.4696 15.2758 16.5655 16.572C16.2495 17.025 15.6262 17.1361 15.1732 16.8201C14.7202 16.5042 14.6092 15.8808 14.9251 15.4278C15.6027 14.4564 16 13.276 16 11.9999C16 10.7238 15.6027 9.54349 14.9251 8.57203C14.6092 8.11905 14.7202 7.49571 15.1732 7.17975Z"
      fill={Colors[color]}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3823 2.71166C10.861 2.67399 11.3288 2.86775 11.6406 3.23287C11.9199 3.55981 11.9642 3.95307 11.9811 4.14396C12.0001 4.35793 12 4.62369 12 4.89407C12 4.90647 12 4.91888 12 4.9313L12 19.1058C12 19.3762 12.0001 19.6419 11.9811 19.8559C11.9642 20.0468 11.9199 20.4401 11.6406 20.767C11.3288 21.1321 10.861 21.3259 10.3823 21.2882C9.95368 21.2545 9.64424 21.0078 9.4973 20.8847C9.33259 20.7468 9.14469 20.5589 8.95353 20.3677L5.76153 17.1757C5.6689 17.083 5.6225 17.037 5.58738 17.0049L5.58472 17.0025L5.58114 17.0023C5.53365 17.0002 5.46826 16.9999 5.33726 16.9999L3.56812 16.9999C3.31574 17 3.06994 17 2.86178 16.983C2.63318 16.9643 2.36345 16.9202 2.09202 16.7819C1.7157 16.5902 1.40974 16.2842 1.21799 15.9079C1.07969 15.6365 1.03563 15.3668 1.01695 15.1382C0.999943 14.93 0.999973 14.6842 1 14.4318L1.00001 9.59993C1.00001 9.58929 1 9.57866 1 9.56804C0.999973 9.31565 0.999943 9.06986 1.01695 8.8617C1.03563 8.63311 1.07969 8.36338 1.21799 8.09195C1.40974 7.71563 1.7157 7.40967 2.09202 7.21792C2.36345 7.07962 2.63318 7.03555 2.86178 7.01688C3.06993 6.99987 3.31572 6.9999 3.56811 6.99993C3.57873 6.99993 3.58936 6.99993 3.60001 6.99993H5.33726C5.46826 6.99993 5.53365 6.99969 5.58114 6.99752L5.58472 6.99735L5.58738 6.99494C5.6225 6.96289 5.6689 6.91683 5.76153 6.8242L8.92721 3.65851C8.936 3.64973 8.94477 3.64095 8.95354 3.63218C9.1447 3.44096 9.33259 3.25301 9.4973 3.11512C9.64424 2.9921 9.95368 2.7454 10.3823 2.71166Z"
      fill={Colors[color]}
    />
  </svg>
));

VolumeOn.displayName = 'Volume';
