import React from 'react';
import { Global, css } from '@emotion/react';

const globalStyle = css`
  html {
    height: 100%;
  }

  body {
    margin: 0;
    height: 100%;
    overscroll-behavior: none;
    -ms-overflow-style: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  #root {
    height: 100%;
    --toastify-toast-min-height: 40px;
  }

  select,
  input,
  button,
  textarea {
    border: 0;
    outline: 0 !important;

    &:disabled {
      opacity: 1;
    }
  }

  .no-scroll {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;

const GlobalStyle = () => {
  return <Global styles={globalStyle} />;
};

export default GlobalStyle;
