import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { TournamentPlayer } from '../../../store/slices/tournamentTypes';
import { toFixedFloor } from '../../../utils/StringUtil';

const TournamentDetailTabInTheMoney: React.FC = () => {
  const { tournamentInTheMoney } = useTournamentLobby();

  const renderPrizePool = (index: number, tournamentPlayer: TournamentPlayer) => (
    <InTheMoneyRow key={index} selected={false}>
      <InTheMoneyCell color={'white'}>{tournamentPlayer.rank === 0 ? '-' : tournamentPlayer.rank}</InTheMoneyCell>
      <InTheMoneyCell color={'white'}>{<StyledFlag src={`https://assets.testbase.xyz/national_flag/${tournamentPlayer.flag}.png`} alt={`user-flag`} />}</InTheMoneyCell>
      <InTheMoneyCell color={'white'}>{tournamentPlayer.nickname}</InTheMoneyCell>
      <InTheMoneyCell color={'white'}>{toFixedFloor(tournamentPlayer.chips, 0, false, true)}</InTheMoneyCell>
    </InTheMoneyRow>
  );

  return (
    <InTheMoneyContainer>
      <TableSection>
        <StyledTableHeader>
          <InTheMoneyHeader>
            <InTheMoneyCell color={'gray400'}>Rank</InTheMoneyCell>
            <InTheMoneyCell color={'gray400'}>Flag</InTheMoneyCell>
            <InTheMoneyCell color={'gray400'}>Player</InTheMoneyCell>
            <InTheMoneyCell color={'gray400'}>Award</InTheMoneyCell>
          </InTheMoneyHeader>
        </StyledTableHeader>
        <StyledTableBody>
          {tournamentInTheMoney?.map((value, index, array) => {
            return renderPrizePool(index, value);
          })}
        </StyledTableBody>
      </TableSection>
    </InTheMoneyContainer>
  );
};

const InTheMoneyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InTheMoneyHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr 2fr 1fr;
`;

const InTheMoneyRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr 2fr 1fr;
`;

const InTheMoneyCell = styled(StyledCell)`
  align-items: center;
  padding: 8px;
`;

const StyledFlag = styled.img`
  width: 20px;
  height: 15px;
  border-radius: 2px;
`;

export default TournamentDetailTabInTheMoney;
