import React from 'react';
import { ClickableIconProps, IconProps } from '../types';

export const CalendarLeft = React.memo<ClickableIconProps>(({ w = 24, h = 24, color = 'white', onClick }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5899 3.57745C10.9153 3.90289 10.9153 4.43053 10.5899 4.75596L6.17916 9.16671H15.834C16.2942 9.16671 16.6673 9.5398 16.6673 10C16.6673 10.4603 16.2942 10.8334 15.834 10.8334H6.17916L10.5899 15.2441C10.9153 15.5696 10.9153 16.0972 10.5899 16.4226C10.2645 16.7481 9.73683 16.7481 9.4114 16.4226L3.57806 10.5893C3.25263 10.2639 3.25263 9.73622 3.57806 9.41079L9.4114 3.57745C9.73683 3.25201 10.2645 3.25201 10.5899 3.57745Z"
      fill="#101828"
    />
  </svg>
));

CalendarLeft.displayName = 'CalendarLeft';
