/**
 *   @name setViewportHeight
 *   Usage
 *   useEffect(() => {
 *     setViewportHeight();
 *     window.addEventListener('resize', setViewportHeight);
 *     return () => window.removeEventListener('resize', setViewportHeight);
 *   }, []);
 *   const Wrap = styled.div`
 *        height: calc(var(--vh, 1vh) * 100);
 *        width: 100vw;
 * `;
 */
export function setViewportHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
