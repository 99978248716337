import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Club = React.memo<IconProps>(({ w = 40, h = 40, color = 'gray900' }) => (
  <svg width={w} height={h} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.1887 14.4182C29.9012 14.4182 29.6017 14.4299 29.2902 14.4532L28.3558 14.5582L28.7871 13.7183C29.434 12.4234 29.7933 10.9886 29.7933 9.55381C29.7933 4.26947 25.4088 0 19.982 0C14.5912 0 10.2066 4.26947 10.2066 9.55381C10.2066 10.9536 10.5301 12.4234 11.2129 13.7183L11.6083 14.5582L10.6738 14.4532C10.3624 14.4299 10.0629 14.4182 9.77538 14.4182C4.38454 14.4182 0 18.6877 0 23.937C0 29.2213 4.38454 33.4908 9.77538 33.4908C12.9739 33.4908 15.9928 31.986 17.646 29.5363L17.7179 29.5013L17.7538 29.4663C17.8976 29.3263 18.0653 29.2097 18.257 29.1164L18.9757 28.6964V29.5013V31.0411C18.9757 35.2056 15.5256 38.6002 11.2489 38.6002H10.7098V40H29.2543V38.6002H28.7152C24.4744 38.6002 20.9883 35.2056 20.9883 31.0411V29.5013V28.6964L21.7071 29.0464C21.8269 29.1164 21.9227 29.1747 21.9946 29.2213L22.1024 29.2913L22.1384 29.3613C23.9712 31.951 26.9901 33.4908 30.1887 33.4908C35.6155 33.4908 40 29.2213 40 23.937C40 18.6877 35.6155 14.4182 30.1887 14.4182Z"
      fill={Colors[color]}
    />
  </svg>
));

Club.displayName = 'Club';
