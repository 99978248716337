import React from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../styles/Colors';

type Props = {
  children: React.ReactNode;
  width?: string; // 변경: width 속성 추가
  gap?: number;
  marginTop?: number;
  marginBottom?: number;
  backgroundColor?: keyof typeof Colors;
  borderRadius?: number;
  padding?: number;
  flex?: number;
  alignItem?: 'center' | 'start';
};

const LinearLayout: React.FC<Props & { className?: string; onClick?: () => void }> = ({ children, width, gap = 0, marginTop = 0, marginBottom = 0, backgroundColor, borderRadius, padding = 0, flex, alignItem }) => {
  return (
    <LinearLayoutForm width={width} gap={gap} marginTop={marginTop} marginBottom={marginBottom} backgroundColor={backgroundColor ? Colors[backgroundColor] : undefined} borderRadius={borderRadius} padding={padding} flex={flex} alignItem={alignItem}>
      {children}
    </LinearLayoutForm>
  );
};

const LinearLayoutForm = styled.div<{
  gap: number;
  marginTop: number;
  marginBottom: number;
  backgroundColor?: string;
  borderRadius?: number;
  padding?: number;
  width?: string;
  flex?: number;
  alignItem?: string;
}>`
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
  box-sizing: border-box;
  display: flex;
  gap: ${({ gap }) => gap}px;
  padding: ${({ padding }) => padding}px;
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: ${({ borderRadius }) => (borderRadius !== undefined ? `${borderRadius}px` : '0')};
  flex: ${({ flex }) => (flex !== undefined ? flex : 'unset')};
  align-items: ${({ alignItem }) => alignItem || 'start'};
  @media (max-width: 768px) {
    min-width: 0;
    padding: ${({ padding }) => (padding ? padding / 2 : 0)}px;
  }
`;

export default LinearLayout;
