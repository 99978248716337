import React from 'react';
import { PasswordIcon } from '../../common/icon';
import styled from '@emotion/styled';
import useDevice from '../../../hooks/useDevice';
import { LobbyTableInfo } from '../../../store/api/responseTypes';
import { ModalType, showModalWithRequest } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useAsset from '../../../hooks/useAsset';

interface TableItemProps {
  tableInfo: LobbyTableInfo;
  selectedTable?: number;
  isMyTable?: boolean;
  setSelectedTable?: (tableId: number) => void;
}

const TableItem = ({ tableInfo, setSelectedTable, selectedTable, isMyTable }: TableItemProps) => {
  const { isMobile, isTablet } = useDevice();
  const dispatch = useDispatch();
  const { network, displayDecimal } = useAsset(tableInfo.asset);

  const openTable = (shareCode: string) => {
    if (isMobile || isTablet) return;
    const width = 1600;
    const height = 900;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const url = `/cashgame/table/${shareCode}`;
    const windowName = `${shareCode}`;
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left}`;
    window.open(url, windowName, windowFeatures);
  };
  const handleItemClick = (tableId: number) => {
    if (isMobile || isTablet) {
      dispatch(showModalWithRequest({ type: ModalType.TablePreviewModal, data: { tableId } }));
      return;
    }
    setSelectedTable?.(tableId);
  };

  return (
    <StyledTableItem isMyTable={isMyTable} onDoubleClick={() => openTable(tableInfo.shareCode)} onClick={() => handleItemClick(tableInfo.tableId)} isSelected={selectedTable === tableInfo.tableId} key={tableInfo.tableId}>
      <StyledItemWrap>
        <div>{tableInfo.hasPassword && <PasswordIcon w={isMobile ? 16 : 20} h={isMobile ? 16 : 20} color={selectedTable === tableInfo.tableId ? 'white' : 'gray300'} tooltip={'Password required'} />}</div>
        <div>{tableInfo?.tableTitle}</div>
        <div>
          {network?.type === 'CRYPTO' && <StyledSymbol src={network?.symbolImage} alt={network?.displayName} />}
          {`${network?.type === 'CRYPTO' ? '' : 'P'}${tableInfo?.smallBlindAmount.toLocaleString(undefined, { maximumFractionDigits: displayDecimal })} / ${network?.type === 'CRYPTO' ? '' : 'P'}${tableInfo?.bigBlindAmount.toLocaleString(undefined, { maximumFractionDigits: displayDecimal })}`}
        </div>
        <div>
          {network?.type === 'CRYPTO' && <StyledSymbol src={network?.symbolImage} alt={network?.displayName} />}
          {`${network?.type === 'CRYPTO' ? '' : 'P'}${tableInfo?.minBuyInAmount.toLocaleString(undefined, { maximumFractionDigits: displayDecimal })}`}
        </div>
        <div>
          {!isMobile ? (
            [...Array(tableInfo?.maxSeatCnt)].map((_, i) => <StyledSeatCount isJoined={tableInfo.isSeated && i === 0} isActive={i < tableInfo.activeSeatCnt} key={i} />)
          ) : (
            <StyledCountText isSeated={tableInfo.isSeated} isFull={tableInfo.maxSeatCnt === tableInfo.activeSeatCnt} isEmpty={tableInfo.activeSeatCnt === 0}>{`${tableInfo.activeSeatCnt} / ${tableInfo.maxSeatCnt}`}</StyledCountText>
          )}
        </div>
      </StyledItemWrap>
    </StyledTableItem>
  );
};

const StyledCountText = styled.div<{ isEmpty?: boolean; isFull?: boolean; isSeated?: boolean }>`
  ${({ theme }) => theme.textStyles.TextXs_Medium}
  color: ${({ theme, isEmpty, isFull, isSeated }) => (isEmpty ? theme.colors.gray400 : isFull ? theme.colors.error500 : isSeated ? theme.colors.warning500 : theme.colors.success500)};
`;

const StyledSeatCount = styled.div<{ isActive?: boolean; isJoined?: boolean }>`
  display: flex;
  flex: none !important;
  width: 14px !important;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ theme, isJoined, isActive }) => (isJoined ? theme.colors.warning400 : isActive ? theme.colors.primary500 : theme.colors.gray500)};
`;

const StyledSymbol = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const StyledTableItem = styled.div<{ isSelected?: boolean; isMyTable?: boolean }>`
  display: flex;
  gap: 16px;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
  }

  div:nth-of-type(1) {
    width: 20px;
  }

  div:nth-of-type(2) {
    flex: 4;
    display: -webkit-box;
    -webkit-line-clamp: 2; // 두 줄로 제한
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; // 줄임표 표시
  }

  div:nth-of-type(3) {
    flex: 3;
  }

  div:nth-of-type(4) {
    flex: 1.5;
  }

  div:last-of-type {
    flex: 2.5;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    gap: 12px;
    div:nth-of-type(1) {
      width: 16px;
    }

    div:nth-of-type(2) {
      flex: 3;
      display: -webkit-box;
      -webkit-line-clamp: 2; // 두 줄로 제한
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; // 줄임표 표시
    }

    div:nth-of-type(3) {
      flex: 3;
    }

    div:nth-of-type(4) {
      flex: 1.4;
    }

    div:last-of-type {
      flex: 1 !important;
    }
  }
  padding: 0 16px;
  ${({ theme }) => theme.textStyles.TextMd_Medium}
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.gray300)};

  ${({ isSelected }) => isSelected && 'background-color: #68DDC126;'}
  div:nth-of-type(2) {
    color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.warning200)};
    display: block;
  }

  ${({ theme, isSelected, isMyTable }) => isMyTable && !isSelected && `background-color: ${theme.colors.gray900};`}
  &:hover {
    @media (min-width: 768px) {
      background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.gray750} !important;
    }
  }

  div:nth-of-type(4) {
    color: ${({ theme }) => theme.colors.white};
  }

  div:last-of-type {
    gap: 1px;
  }

  @media (max-width: 768px) {
    padding: 0 8px;
    overflow: inherit;
    ${({ theme }) => theme.textStyles.TextXm_Medium}
  }
`;

const StyledItemWrap = styled.div`
  padding: 8px 16px;
  display: flex;
  gap: 16px !important;
  border-radius: 4px;

  transition: background-color 0.2s;

  @media (max-width: 768px) {
    gap: 12px !important;
    padding: 8px;
  }
`;

export default TableItem;
