import React, { useEffect } from 'react';
import ChatNav from './ChatNav';
import styled from '@emotion/styled';
import Chat from './Chat';
import Note from './Note';
import SeedPhrase from './SeedPhrase';
import useDevice from 'src/hooks/useDevice';
import { useUserTableSetting } from '../../../../hooks/useUserTableSetting';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { selectedUser } from '../../../../store/slices/streaming';
import { useTableId } from '../../../../hooks/useTableId';

export type SupportChatTab = 'Chat' | 'Note' | 'Seed' | 'Hide';

const ChatBox = () => {
  const { isMobile } = useDevice();
  const tableId = useTableId();
  const selectedUserData = useSelector((state: RootState) => selectedUser(state, tableId));
  const setting = useUserTableSetting();
  const [tab, setChatTab] = React.useState<SupportChatTab>('Chat');

  useEffect(() => {
    setChatTab(setting?.isChatEnabled ? 'Chat' : 'Note');
  }, [setting?.isChatEnabled]);

  useEffect(() => {
    if (selectedUserData?.userId === undefined) return;
    setChatTab('Note');
  }, [selectedUserData?.userId, selectedUserData?.timestamp]);

  return (
    <ChatContainer>
      <ChatNav useChat={setting?.isChatEnabled ?? true} selectedTab={tab} setSelectedTab={setChatTab} />
      {!isMobile && (
        <ContentWrap>
          {tab === 'Chat' && <Chat />}
          {tab === 'Note' && <Note />}
          {tab === 'Seed' && <SeedPhrase />}
        </ContentWrap>
      )}
    </ChatContainer>
  );
};

const ChatContainer = styled.div`
  min-width: 388px;
  width: 25%;
  gap: 16px;
  display: flex;
  padding: 16px;
  @media (max-width: 768px) {
    min-width: unset;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
`;

const ContentWrap = styled.div`
  flex: 1;
  align-self: end;
`;

export default ChatBox;
