import { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useUserProfile } from './useUserProfile';
import Intercom from '@intercom/messenger-js-sdk';

const useAnalytics = () => {
  const path = location.pathname;
  const { user } = useUserProfile();
  const isInTable = useMemo(() => path.split('/').some(p => p === 'table'), [path]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: path, title: path });
  }, [path]);

  useEffect(() => {
    if (user) {
      ReactGA.gtag('set', { user_id: user.id });
      ReactGA.gtag('config', user.id, {
        name: user.name,
        country: user.country,
        createdAt: user.createdAt
      });
      if (!isInTable) {
        Intercom({
          app_id: 'jlnk6voo',
          user_id: user?.id.toString(),
          name: user?.name,
          email: user?.email
        });
      }
    } else {
      ReactGA.gtag('set', { user_id: null });
      if (!isInTable) {
        Intercom({
          app_id: 'jlnk6voo'
        });
      }
    }
  }, [user]);
};

export default useAnalytics;
