import React from 'react';
import styled from '@emotion/styled';
import { TextStyles } from '../../../styles/textStyles';
import { HeaderLogoIcon } from '../../common/icon';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BeGambleAwareLogo } from '../../../assets/img/BeGambleAwareLogo.svg';
import { ReactComponent as AgeRestrictedIcon } from '../../../assets/img/ageRestrictedIcon.svg';
import { ReactComponent as GaLogo } from '../../../assets/img/gaLogo.svg';
import { PolicyPages, setActiveTab, setPolicyTab } from '../../../store/slices/authSlice';
import { useDispatch } from 'react-redux';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLinkClick = (type: string) => {
    switch (type) {
      case 'main':
        navigate('/');
        break;
      case 'X.com':
        window.open(`https://x.com/BasepokerHQ`, '_blank');
        break;
      case 'Discord':
        window.open(`https://discord.gg/CfdEHGd26q`, '_blank');
        break;
      case 'Telegram':
        window.open(`https://t.me/basepokerHQ`, '_blank');
        break;
      case 'Reddit':
        window.open(`https://www.reddit.com/r/Basepoker`, '_blank');
        break;
      case 'Facebook':
        window.open(`https://www.facebook.com/basepoker`, '_blank');
        break;
      case 'Support Center':
        window.open(`https://support.basepoker.com`, '_blank');
        break;
      case 'Blog':
        window.open(`https://medium.com/@basepoker`, '_blank');
        break;
      case 'BeGambleAware':
        window.open(`https://www.begambleaware.org/`, '_blank');
        break;
      case 'GA':
        window.open(`https://www.gamblersanonymous.org.uk/`, '_blank');
        break;
      case 'Terms of Service':
      case 'Privacy Policy':
      case 'Cookie Policy':
      case 'House Rules':
      case 'Responsible Gaming':
        navigate('/policy');
        dispatch(setPolicyTab(type));
        break;
      default:
        break;
    }
  };

  const policyList = ['Terms of Service', 'Privacy Policy', 'Cookie Policy', 'House Rules', 'Responsible Gaming'];
  const supportList = ['Support Center', 'Blog'];
  // const communityList = ['Discord', 'X.com', 'Facebook', 'Telegram', 'Reddit'];
  const communityList = ['Discord', 'X.com', 'Facebook', 'Telegram'];
  return (
    <FooterWrap>
      <FooterContainer>
        <StyledLogoIcon onClick={() => onLinkClick('main')}>
          <HeaderLogoIcon />
        </StyledLogoIcon>
        <FooterInner>
          <StyledList>
            <StyledTitle>Policy</StyledTitle>
            {policyList.map((item, index) => (
              <StyledItem key={index} onClick={() => onLinkClick(item)}>
                {item}
              </StyledItem>
            ))}
          </StyledList>
          <StyledList>
            <StyledTitle>Support</StyledTitle>
            {supportList.map((item, index) => (
              <StyledItem key={index} onClick={() => onLinkClick(item)}>
                {item}
              </StyledItem>
            ))}
          </StyledList>
          <StyledList>
            <StyledTitle>Community</StyledTitle>
            {communityList.map((item, index) => (
              <StyledItem key={index} onClick={() => onLinkClick(item)}>
                {item}
              </StyledItem>
            ))}
          </StyledList>
        </FooterInner>
        <StyledLogoWrap>
          <StyledLogo>
            <StyledBeGambleAwareLogo onClick={() => onLinkClick('BeGambleAware')}>
              <BeGambleAwareLogo />
            </StyledBeGambleAwareLogo>
          </StyledLogo>
          <StyledLogo>
            <StyledAgeRestrictedIcon>
              <AgeRestrictedIcon />
            </StyledAgeRestrictedIcon>
            <StyledGaLogo onClick={() => onLinkClick('GA')}>
              <GaLogo />
            </StyledGaLogo>
          </StyledLogo>
        </StyledLogoWrap>
        <StyledCopyRight>Basepoker | © 2024</StyledCopyRight>
      </FooterContainer>
    </FooterWrap>
  );
};
const StyledBeGambleAwareLogo = styled.div`
  cursor: pointer;
  @media (max-width: 768px) {
    svg {
      width: 153.333px;
      height: 20px;
    }
  }
`;
const StyledAgeRestrictedIcon = styled.div`
  @media (max-width: 768px) {
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;
const StyledGaLogo = styled.div`
  cursor: pointer;
  @media (max-width: 768px) {
    svg {
      width: 46.875px;
      height: 30px;
    }
  }
`;

const StyledLogoIcon = styled.div`
  cursor: pointer;
  @media (max-width: 992px) {
    position: absolute;
    left: 0;
    top: 0;
  }
  @media (max-width: 768px) {
    svg {
      width: 135.3px;
      height: 16px;
    }
  }
`;

const StyledCopyRight = styled.div`
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.textStyles.TextXs_Medium}
  color: ${({ theme }) => theme.colors.gray400};
  @media (max-width: 768px) {
    left: 0;
    transform: none;
  }
`;
const FooterWrap = styled.div`
  text-align: center;
  ${TextStyles.TextMd}
  position: absolute;
  align-self: center;
  display: flex;
  justify-content: center;
  z-index: 11;
  background-color: ${({ theme }) => theme.colors.gray800};
  width: 100%;
  bottom: 0;
  height: 334px;
  box-sizing: border-box;
  padding: 60px 120px 0 120px;
  @media (max-width: 992px) {
    height: 458px;
    padding: 40px 60px 0 60px;
  }
  @media (max-width: 768px) {
    height: 426px;
    padding: 32px 20px 0 20px;
  }
`;
const StyledLogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 992px) {
    margin-bottom: 90px;
  }
`;

const StyledLogo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitle = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Semibold}
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 4px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Semibold}
  }
`;
const StyledItem = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Medium}
  color: ${({ theme }) => theme.colors.gray400};
  line-height: 20px;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Medium}
  }
`;
const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const FooterContainer = styled.footer`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  max-width: 1360px;
  width: 100%;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
  }
  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
  }
`;

const FooterInner = styled.div`
  display: flex;
  gap: 60px;
  @media (max-width: 768px) {
    margin-top: 48px;
    gap: 40px;
  }
`;

export default Footer;
