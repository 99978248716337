import styled from '@emotion/styled';

import { Colors } from 'src/styles/Colors';
import { Size } from './types';
import { css } from '@emotion/react';

export const StyledCheckbox = styled.label<{ checked?: boolean; size?: Size; bgColor: keyof typeof Colors; borderColor?: keyof typeof Colors; type: 'checkbox' | 'bar' }>`
  ${p => {
    if (p.type === 'bar') {
      return css`
        width: 3px;
        height: 22px;
        box-sizing: border-box;
        ${p.checked && `box-shadow: 0px 0px 6px 1px ${p.theme.colors.error500};`}
      `;
    } else {
      return css`
        height: ${p.size === 'sm' ? '20px' : p.size === 'xs' ? '16px' : '24px'};
        width: ${p.size === 'sm' ? '20px' : p.size === 'xs' ? '16px' : '24px'};
      `;
    }
  }}
  cursor: pointer;
  border-radius: ${({ type }) => (type === 'bar' ? 20 : 6)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  box-sizing: border-box;
  ${({ theme, borderColor, type }) => type === 'checkbox' && borderColor && `border: 1px solid ${theme.colors[borderColor]};`}
  background-color: ${({ theme, bgColor }) => `${theme.colors[bgColor]}`};
`;
