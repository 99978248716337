import { Country } from '../store/api/responseTypes';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { selectCountryList } from '../store/slices/utilSlice';
import { useCountryListQuery } from '../store/api/util';

export const useCountryList = (): Country[] => {
  const countryListFromRedux = useSelector(selectCountryList);

  const { data: countryListFromApi, isFetching } = useCountryListQuery(undefined, {
    skip: countryListFromRedux.length > 0
  });

  return useMemo(() => {
    if (countryListFromRedux.length > 0) {
      return countryListFromRedux;
    } else {
      return countryListFromApi?.data || [];
    }
  }, [countryListFromRedux, countryListFromApi]);
};
