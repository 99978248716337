import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { ArrowLeft } from '../../common/icon';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { dismissModal, ModalType, showModal } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useDevice from '../../../hooks/useDevice';
import { QRCodeSVG } from 'qrcode.react';
import LinearLayout from '../../layout/LinearLayout';
import NetworkSelect, { NetworkOptionType } from '../../common/select/NetworkSelect';
import CopyableBox from '../../common/text/CopyableBox';
import Text from '../../common/text/Text';
import { useCreateDepositAddressMutation } from '../../../store/api/assets';
import { AssetBalance, AssetType, ChainNetworkType } from '../../../store/api/responseTypes';
import BaseButton from '../../common/button/BaseButton';
import { setActiveTab } from '../../../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import CurrencyView from './CurrencySelect';
import { useMyBalance } from '../../../hooks/useMyBalance';
import { showSuccessToast } from '../../common/toast/BpToast';
import useAsset from '../../../hooks/useAsset';
import { toFixedFloor } from '../../../utils/StringUtil';
import { logDebug, logWarn } from '../../../utils/ConsoleUtil';

const DepositModal = ({ hasBack = true }: { hasBack?: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const { myBalance } = useMyBalance();
  const closeModal = () => dispatch(dismissModal());
  const backToCashierModal = () => dispatch(showModal(ModalType.CashierModal));

  const [selectedAssetBalance, setSelectedAssetBalance] = useState<AssetBalance | undefined>(myBalance?.balances[0]);

  const { tokenInfos: networksInfo } = useAsset(selectedAssetBalance?.assetName ?? AssetType.USDT);
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkOptionType | null>(null);
  const networkInfo = useMemo(() => networksInfo?.find(info => info.chainNetworkName === selectedNetwork?.type), [selectedNetwork, networksInfo]);

  const [depositAddress, setDepositAddress] = useState<string>('');
  const [mutate] = useCreateDepositAddressMutation();

  const createOrGetAddress = async (network: ChainNetworkType) => {
    const address = await mutate(network);
    setDepositAddress((address as any)?.data?.data);
  };

  const goToWalletPage = () => {
    navigate(`/settings`);
    dispatch(setActiveTab('Wallet'));
    closeModal();
  };

  useEffect(() => {
    if (selectedNetwork) {
      createOrGetAddress(selectedNetwork.type).then();
    }
  }, [selectedNetwork]);

  useEffect(() => {
    logWarn(networkInfo);
  }, [networkInfo]);

  return (
    <ModalWrap>
      <HorizontalLayout margin={isMobile ? '0' : '0 0 8px 0'}>
        {hasBack && (
          <LeftIconDiv>
            <ArrowLeft onClick={backToCashierModal} />
          </LeftIconDiv>
        )}
        <Title hasBack={hasBack}>{'Deposit'}</Title>
        <RightIconDiv>
          <XCloseIcon onClick={closeModal} />
        </RightIconDiv>
      </HorizontalLayout>
      <LinearLayout gap={16}>
        <CurrencyView onChange={setSelectedAssetBalance} balances={myBalance?.balances ?? []} />
        <LinearLayout gap={isMobile ? 2 : 4}>
          <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={'Network'} />
          <NetworkSelect assetType={selectedAssetBalance?.assetName ?? AssetType.USDT} onChange={setSelectedNetwork} />
        </LinearLayout>
        <LinearLayout gap={isMobile ? 2 : 4}>
          <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={`Your ${selectedAssetBalance?.assetName} deposit address`} />
          <CopyableBox
            copyableValue={depositAddress}
            mode={'copySuffix'}
            backgroundColor={'gray900'}
            onCopy={isCopied => {
              showSuccessToast(isCopied ? 'Deposit address is copied' : 'Failed to copy');
            }}
          />
        </LinearLayout>
        <LinearLayout gap={isMobile ? 8 : 12}>
          <StyledTextWrapper>
            <Text fontStyle={'TextSmall_Medium'} textColor={'gray400'} text={'The minimum deposit amount is '} />
            <Text fontStyle={'TextSmall_Bold'} textColor={'warning400'} text={`${toFixedFloor(networkInfo?.minDepositAmount ?? 0, networkInfo?.displayDecimals ?? 0)} ${selectedAssetBalance?.assetName}`} />
          </StyledTextWrapper>
          {!isMobile && (
            <QrcodeView>
              <QRCodeSVG value={`${selectedNetwork?.type === 'BITCOINCASH' ? '' : 'ethereum:'}${depositAddress}${selectedNetwork?.chainId ? '@' + selectedNetwork?.chainId : ''}`} size={180} bgColor={'#ffffff'} fgColor={'#000000'} level={'L'} includeMargin={false} />
            </QrcodeView>
          )}
          <Text fontStyle={'TextSmall_Medium'} textColor={'error500'} text={`Only send ${selectedAssetBalance?.assetName} to this address. ${networkInfo?.depositConfirmCount} confirmations required`} />
        </LinearLayout>
        <BaseButton size={'btn40'} text={'Check the transaction status'} textStyle={'TextSmall_Semibold'} color={'outline700'} onClick={goToWalletPage} />
      </LinearLayout>
    </ModalWrap>
  );
};

export default DepositModal;

const QrcodeView = styled.div`
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 0 auto;
`;

const StyledTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 3px;
`;

const LeftIconDiv = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ModalWrap = styled.div`
  gap: 24px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px 40px;
  border-radius: 12px;
  width: 600px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    gap: 16px;
    padding: 24px 20px;
  }
`;
const Title = styled.h1<{ hasBack: boolean }>`
  ${({ theme }) => theme.textStyles.TextXl_Semibold};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
  flex: 1;
  margin: ${({ hasBack }) => (hasBack ? '0 24px' : '0 24px 0 0')};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
    margin: 0 8px;
  }
`;
