import React, { useMemo } from 'react';
import { Avatar, PreviewUserStack, SeatBtnWrap, StyledFlagWrap, StyledPreviewDealerButton, StyledPreviewSeat, StyledSeatButton, UserCard, UserName, UserStack, UserWrap, Wrap } from './styles';
import { BlindButton, DealerButton } from '../../../common/icon';
import { Country, Profile } from '../../../../store/api/responseTypes';
import { TableSnapshot } from '../../../../store/slices/streamingTypes';
import { useCreateConfig } from '../../../../hooks/useCreateConfig';
import styled from '@emotion/styled';

interface Props {
  tableId: number;
  seatId: number;
  pos: { x: number; y: number };
  onClick?: () => void;
  isAlreadySeated?: boolean;
  tableSnapshot?: TableSnapshot;
  user?: Profile;
  countries?: Country[];
}

const PreviewSeat = ({ seatId, pos, tableId, onClick, isAlreadySeated, tableSnapshot, user, countries }: Props) => {
  const seatData = React.useMemo(() => tableSnapshot?.seats?.find(seat => seat.seatId === seatId), [tableSnapshot?.seats, seatId]);
  const userData = React.useMemo(() => tableSnapshot?.users?.find(user => user.userId === seatData?.userId), [tableSnapshot?.users, seatData?.userId]);
  const roles = React.useMemo(() => tableSnapshot?.hand?.roles, [tableSnapshot?.hand?.roles]);
  const flagUrl = useMemo(() => countries?.find(country => country.code === userData?.countryCode)?.flagUrl, [countries, userData?.countryCode]);
  const isMySeat = React.useMemo(() => user?.id === seatData?.userId, [user?.id, seatData?.userId]);
  const isDealer = roles?.dealer === seatId;
  const isBB = React.useMemo(() => roles?.bigBlind === seatId, [roles?.bigBlind]);
  const isSB = React.useMemo(() => roles?.smallBlind === seatId, [roles?.smallBlind]);
  const { getAssetInfo } = useCreateConfig();

  return (
    <StyledPreviewSeat left={pos?.x} top={pos?.y}>
      {userData ? (
        <Wrap>
          <StyledPreviewDealerButton position={seatId}>
            <DealerButton />
          </StyledPreviewDealerButton>
          {isDealer && (
            <StyledPreviewDealerButton position={seatId}>
              <DealerButton />
            </StyledPreviewDealerButton>
          )}
          {isBB && (
            <StyledPreviewDealerButton position={seatId}>
              <BlindButton type={'bb'} />
            </StyledPreviewDealerButton>
          )}
          {!isDealer && isSB && (
            <StyledPreviewDealerButton position={seatId}>
              <BlindButton type={'sb'} />
            </StyledPreviewDealerButton>
          )}

          <UserWrap>
            {flagUrl && (
              <StyledFlagWrap>
                <img src={flagUrl} alt={'flag'} />
              </StyledFlagWrap>
            )}
            <Avatar>
              <img src={userData?.profileImage} alt="avatar" />
            </Avatar>
            <UserCard>
              <UserName isMySeat={isMySeat}>{userData?.name || ''}</UserName>
              <PreviewUserStack isReserved={seatData?.status === 'RESERVED' || seatData?.status === 'SEAT_OUT'}>
                {getAssetInfo(tableSnapshot?.setting?.asset?.name)?.assetType === 'CRYPTO' && <StyledSymbol src={getAssetInfo(tableSnapshot?.setting?.asset?.name)?.symbolImage} alt={'symbol'} />}
                {seatData?.status === 'RESERVED' ? 'Reserved' : seatData?.status === 'SEAT_OUT' ? 'Sitting out' : `${userData.stack.toLocaleString()}`}
              </PreviewUserStack>
            </UserCard>
          </UserWrap>
        </Wrap>
      ) : (
        <SeatBtnWrap isPreset>
          <StyledSeatButton disabled={tableId < 0 || isAlreadySeated} onClick={onClick} />
        </SeatBtnWrap>
      )}
    </StyledPreviewSeat>
  );
};

const StyledSymbol = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 2px;
`;

export default PreviewSeat;
