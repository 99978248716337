import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { toFixedFloor } from '../../../utils/StringUtil';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: string) => void;
  asset?: string;
  displayDecimal: number;
  suffix?: 'Max' | 'Min';
  onSuffixClick?: () => void;
}

const TextNumberInput: React.FC<InputProps> = ({ onValueChange, asset, displayDecimal, min, max, suffix, onSuffixClick, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const [inputWidth, setInputWidth] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue('');
  }, [asset]);

  useEffect(() => {
    onValueChange?.(inputValue);
  }, [inputValue, onValueChange]);

  const handleChange = (v: string) => {
    let text = v;
    let result = text;

    // 숫자와 소수점 이외의 문자 제거
    if (/[^0-9.]+/.test(result)) {
      result = result.replace(/[^0-9.]+/g, '');
    }

    // displayDecimal이 0이면 소수점 자체를 입력하지 못하게 처리
    if (displayDecimal === 0 && result.includes('.')) {
      result = result.replace('.', ''); // 소수점을 제거
    }

    // 소수점이 여러 개 입력되지 않도록 처리 (소수점은 1개까지만 허용)
    if (result.split('.').length > 2) {
      result = result.split('.').slice(0, 2).join('.');
    }

    // 소수점 뒤에 허용된 자릿수만큼만 유지
    if (/^[0-9.]*$/.test(result)) {
      if (result.includes('.')) {
        const [integerPart, decimalPart] = result.split('.');
        const allowedDecimalPart = decimalPart.slice(0, displayDecimal);

        // displayDecimal이 0이면 소수점 이하를 제거
        if (displayDecimal === 0) {
          result = integerPart;
        } else {
          result = `${integerPart}.${allowedDecimalPart}`;
        }
      }

      // '00' 혹은 빈 문자열 처리
      if (result === '00' || result === '') {
        result = '0';
      }

      // 앞에 0이 있고 두 번째 글자가 소수점이 아닌 경우에만 0을 제거 (0.1 등 허용)
      if (result.charAt(0) === '0' && result.charAt(1) !== '.' && result.length > 1) {
        result = result.slice(1);
      }

      // 최대값 초과 방지
      if (max && Number(result) > Number(max)) {
        result = String(max);
      }
    }
    setInputValue(result);
  };

  const handleSuffixClick = () => {
    switch (suffix) {
      case 'Min':
        handleChange(String(min ?? ''));
        break;
      case 'Max':
        handleChange(String(max ?? ''));
        break;
    }
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement !== null) {
      const context = document.createElement('canvas').getContext('2d');
      const font = window.getComputedStyle(inputElement).font;
      if (!context) return;
      context.font = font;
      const textWidth = context.measureText(inputValue.length === 0 ? `${toFixedFloor(0, displayDecimal)}` : inputValue).width;
      setInputWidth(textWidth);
    }
  }, [inputValue, displayDecimal]);

  const handleWrapperClick = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (props.value) {
      handleChange(String(props.value ?? ''));
    }
  }, [props.value]);

  return (
    <Wrap>
      <InputWrapper onClick={handleWrapperClick}>
        <Input {...props} ref={inputRef} type="text" displayDecimal={displayDecimal} value={inputValue} onChange={e => handleChange(e.target.value)} textWidth={inputWidth} />
        <Suffix isEmpty={inputValue.length === 0}>{asset ?? 'USDT'}</Suffix>
      </InputWrapper>
      {suffix && <SuffixWrap onClick={handleSuffixClick}>{suffix}</SuffixWrap>}
    </Wrap>
  );
};
const Wrap = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;

  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray600};
    outline: none;
  }
`;
const SuffixWrap = styled.div`
  display: flex;
  height: 100%;
  padding: 16px 24px;
  margin-left: auto;
  ${({ theme }) => theme.ButtonColors.gray};
  color: ${({ theme }) => theme.colors.gray300};
  ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  user-select: none;
  @media (max-width: 768px) {
    padding: 10px 24px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray900};
  border: none;

  padding: 0 16px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

const Input = styled.input<InputProps & { textWidth: number }>`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  background: transparent;
  width: ${props => `${Math.max(0, props.textWidth)}px`}; // 너비를 입력 길이에 따라 동적으로 조정
  border: none;
  outline: none;
  padding: 0;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

const Suffix = styled.span<{ isEmpty: boolean }>`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  align-items: center;
  color: ${({ isEmpty, theme }) => (isEmpty ? theme.colors.gray400 : theme.colors.white)};
  pointer-events: none;
  user-select: none;
  margin-left: 4px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

export default TextNumberInput;
