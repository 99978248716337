import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { ChevronDown } from '../icon/icons/ChevronDown';
import { ReactComponent as SelectedIcon } from '../../../assets/img/ic_selected.svg';
import useDevice from '../../../hooks/useDevice';

interface OptionType {
  value: number;
  label: string;
}

interface NumberSelectProps {
  options: number[];
  onChange: (value: number) => void;
}

interface CustomDropdownProps {
  options: OptionType[];
  defaultValue?: number;
  onChange: (value: OptionType) => void;
}

const NumberSelect: React.FC<NumberSelectProps> = ({ options, onChange }) => {
  const numberOptions: OptionType[] = options.map(option => ({
    value: option,
    label: option.toString()
  }));

  const [selectedOption, setSelectedOption] = useState<OptionType>(numberOptions[0]);

  useEffect(() => {
    if (selectedOption !== null) {
      onChange(selectedOption.value);
    }
  }, [selectedOption]);

  const handleOptionChange = (option: OptionType) => {
    setSelectedOption(option);
  };

  return <CustomDropdown options={numberOptions} defaultValue={selectedOption ? selectedOption.value : undefined} onChange={handleOptionChange} />;
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, defaultValue, onChange }) => {
  const { isMobile } = useDevice();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionType | undefined>(options.find(option => option.value === defaultValue));
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const newSelectedOption = options.find(option => option.value === defaultValue);
    setSelectedOption(newSelectedOption);
  }, [defaultValue, options]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const handleSelect = (option: OptionType) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownSelected isMobile={isMobile} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? (
          <>
            <DropdownText isMobile={isMobile}>{selectedOption.label}</DropdownText>
            <ChevronDown w={16} h={16} color={'white'} />
          </>
        ) : (
          <>
            <DropdownText isMobile={isMobile}>Select a number</DropdownText>
            <ChevronDown w={16} h={16} color={'white'} />
          </>
        )}
      </DropdownSelected>
      {isOpen && (
        <DropdownList>
          {options.map(option => (
            <DropdownItem key={option.value} onClick={() => handleSelect(option)}>
              <DropdownText isMobile={isMobile}>{option.label}</DropdownText>
              {selectedOption?.value === option.value && <SelectedIcon />}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const DropdownSelected = styled.div<{ isMobile: boolean }>`
  display: flex;
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  min-height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray900};
  color: ${({ theme }) => theme.colors.white};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray600};
  }

  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const DropdownList = styled.div`
  position: absolute;
  width: 92.4%;
  margin-top: 2px;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  max-height: 348px;
  overflow-y: auto;
  z-index: 15;
  padding: 10px 10px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);
  @media (max-width: 768px) {
    max-height: 150px;
    gap: 4px;
    padding: 16px 10px;
  }
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  user-select: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`;

const DropdownText = styled.div<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  color: ${({ theme }) => theme.colors.white};
`;

const StyledSelectedIcon = styled(SelectedIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.primary500};
`;

export default NumberSelect;
