import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ChevronDownIcon } from 'src/components/common/icon';
import { AssetType, ChainNetworkType } from '../../../store/api/responseTypes';
import useAsset from '../../../hooks/useAsset';

export interface NetworkOptionType {
  chainId: number;
  label: string;
  image: string;
  confirmCount: number;
  type: ChainNetworkType;
}

interface CountrySelectProps {
  onChange: (value: NetworkOptionType) => void;
  assetType: AssetType | string;
  defaultValue?: NetworkOptionType;
}

interface CustomDropdownProps {
  options: NetworkOptionType[];
  defaultValue?: string;
  onChange: (value: NetworkOptionType) => void;
}

const NetworkSelect: React.FC<CountrySelectProps> = ({ onChange, assetType, defaultValue }) => {
  const { tokenInfos: networkList } = useAsset(assetType);
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkOptionType | null>(defaultValue || null);

  useEffect(() => {
    if (!defaultValue && networkList) {
      setSelectedNetwork({
        chainId: networkList[0].chainId,
        label: networkList[0].chainNetworkDisplayName,
        image: networkList[0].symbolImage,
        confirmCount: networkList[0].withdrawConfirmCount,
        type: networkList[0].chainNetworkName
      });
    }
  }, [networkList, defaultValue]);

  useEffect(() => {
    if (selectedNetwork !== null) {
      onChange(selectedNetwork);
    }
  }, [selectedNetwork]);

  const handleCountryChange = (option: NetworkOptionType) => {
    setSelectedNetwork(option);
  };

  const networkOptions: NetworkOptionType[] =
    networkList?.map(network => ({
      chainId: network.chainId,
      label: network.chainNetworkDisplayName,
      image: network.symbolImage,
      confirmCount: network.withdrawConfirmCount,
      type: network.chainNetworkName
    })) ?? [];

  return <CustomDropdown options={networkOptions} defaultValue={selectedNetwork ? selectedNetwork.chainId + '' : ''} onChange={handleCountryChange} />;
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, defaultValue, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<NetworkOptionType | undefined>(options.find(option => option.chainId + '' === defaultValue));
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const newSelectedOption = options.find(option => option.chainId + '' === defaultValue);
    setSelectedOption(newSelectedOption);
  }, [defaultValue, options]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const handleSelect = (option: NetworkOptionType) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownSelected onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? (
          <>
            <DropdownItemParent>
              <DropdownImage src={selectedOption.image} alt={selectedOption.chainId + ''} />
              <DropdownText>{selectedOption.label}</DropdownText>
            </DropdownItemParent>
            <ChevronDownIcon w={16} h={16} color={'white'} />
          </>
        ) : (
          <>
            <DropdownText></DropdownText>
            <ChevronDownIcon w={16} h={16} color={'white'} />
          </>
        )}
      </DropdownSelected>
      {isOpen && (
        <DropdownList>
          {options.map(option => (
            <DropdownItem key={option.chainId} onClick={() => handleSelect(option)}>
              <DropdownImage src={option.image} alt={option.chainId + ''} />
              <DropdownText>{option.label}</DropdownText>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownSelected = styled.div`
  display: flex;
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  min-height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray900};
  color: ${({ theme }) => theme.colors.white};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray600};
  }

  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const DropdownList = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  max-height: 348px;
  margin-top: 4px;
  overflow-y: auto;
  z-index: 15;
`;

const DropdownItemParent = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  min-height: 48px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const DropdownItem = styled(DropdownItemParent)`
  margin: 8px 8px;
  padding: 0 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`;

const DropdownImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

const DropdownText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

export default NetworkSelect;
