import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetUserNoteQuery, useUpdateUserNoteMutation } from '../../../../store/api/user';
import { useSelector } from 'react-redux';
import { selectedUser, selectedUserNote } from '../../../../store/slices/streaming';
import { useTableId } from '../../../../hooks/useTableId';
import { RootState } from '../../../../store/store';
import { useCountryList } from '../../../../hooks/useCountryList';

const Note = () => {
  const tableId = useTableId();
  const selectedUserData = useSelector((state: RootState) => selectedUser(state, tableId));
  const loadedNote = useSelector((state: RootState) => selectedUserNote(state, selectedUserData?.userId));
  const { isLoading } = useGetUserNoteQuery({ noteUserId: selectedUserData?.userId! }, { skip: selectedUserData?.userId === undefined });
  const [typedNote, setTypedNote] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [updateNote] = useUpdateUserNoteMutation();
  const countries = useCountryList();
  const flagUrl = React.useMemo(() => countries?.find(country => country.code === selectedUserData?.user?.countryCode)?.flagUrl, [countries, selectedUserData?.user?.countryCode]);

  useEffect(() => {
    if (isTyping) {
      const timer = setTimeout(() => {
        if (selectedUserData?.userId) {
          updateNote({ noteUserId: selectedUserData?.userId, contents: typedNote.trimStart().trimEnd() }).then();
        }
        setIsTyping(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [typedNote, isTyping, updateNote, selectedUserData]);

  useEffect(() => {
    setTypedNote('');
  }, [selectedUserData?.userId]);

  useEffect(() => {
    setTypedNote(loadedNote ?? '');
  }, [loadedNote]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let inputValue = event.target.value;

    if (inputValue.length > 2000) {
      inputValue = inputValue.substring(0, 2000);
    }

    setIsTyping(true);
    setTypedNote(inputValue);
  }, []);

  return (
    <Background>
      {selectedUserData?.user?.profileImage && (
        <UserProfile>
          <AvatarContainer>
            <Avatar src={selectedUserData?.user?.profileImage} alt="" />
            <StyledFlagWrap>
              <img src={flagUrl} alt={'flag'} />
            </StyledFlagWrap>
          </AvatarContainer>
          <UserName>{selectedUserData?.user?.name}</UserName>
        </UserProfile>
      )}
      <StyledTextArea disabled={selectedUserData?.user === undefined} placeholder={isLoading ? 'Loading..' : selectedUserData?.user ? `Type here. Notes will auto save!` : 'Click a player profile to add notes!'} value={typedNote} onChange={handleChange} />
    </Background>
  );
};

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
  height: 100%;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const UserName = styled.div`
  ${({ theme }) => theme.textStyles.TextLg}
  color: ${({ theme }) => theme.colors.white};
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  height: 140px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray600_30};
  ${({ theme }) => theme.textStyles.TextMd};
  color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  border-radius: 8px;
  resize: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

// 상위 컨테이너 스타일
const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; // 중앙 정렬
`;

export const StyledFlagWrap = styled.div`
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);

  overflow: hidden;

  img {
    width: 16px;
    height: 12px;
    border-radius: 2px;
  }
`;

export default Note;
