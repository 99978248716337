import { useRef } from 'react';
import { useAnimation } from 'framer-motion';
import useCalculateElements from './useCalculateElements';
import useDevice from './useDevice';
import { DURATION_BET_MOVE, DURATION_CARD_DEAL, DURATION_CARD_FLIP } from '../utils/AnimationUtil';

interface DealCardProps {
  hasAnimation: boolean;
  cardIndex?: number;
  isFlipped?: boolean;
}

interface AnimationState {
  rotate?: string;
  left?: string;
  top?: string;
  rotateY?: string;
  opacity?: number;
  visibility?: 'hidden' | 'visible';
}

interface useAnimationControlProps {
  fontSize?: number;
  type: 'card' | 'win' | 'bet' | 'pot';
}

const useAnimationControl = ({ fontSize, type }: useAnimationControlProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const controls = useAnimation();
  const isAnimating = useRef(false);
  const initialState = useRef<AnimationState>({
    visibility: 'hidden',
    opacity: 0
  });
  const animatedState = useRef<any>();
  const { isMobile } = useDevice();

  const dealCommunityCard = async ({ cardIndex, isFlipped, hasAnimation }: DealCardProps) => {
    if (!ref.current || isAnimating?.current || animatedState.current || cardIndex === undefined) return;
    const position = useCalculateElements({ ref, isMobile }).calculate();
    const destinationLeft = (isMobile ? 5 : 5.1) * cardIndex + 'em';
    controls.set({ visibility: 'visible', opacity: 0 });
    if (hasAnimation) {
      isAnimating.current = true;
      const isFlop = [0, 1, 2].includes(cardIndex);
      controls.set({ rotateY: 0 });
      await controls.start({
        left: [position.left, isFlop ? '0em' : destinationLeft],
        top: [position?.top, '0em'],
        opacity: [0, 1],
        transition: { duration: 0.2 },
        rotateY: 0
      });

      if (isFlipped) {
        await controls.start({
          rotateY: [0, 180],
          transition: { duration: 0.2, delay: 0.1 }
        });
      }
      if (isFlop) {
        await controls.start({
          rotateY: 180,
          left: ['0em', destinationLeft],
          top: ['0em', '0em'],
          transition: { duration: 0.2 }
        });
      }
    }
    const animatedStateData = {
      left: destinationLeft,
      rotateY: 180,
      top: '0em',
      opacity: 1
    };
    controls.set(animatedStateData);
    animatedState.current = animatedStateData;
    isAnimating.current = false;
  };

  const dealHoleCard = async ({ cardIndex, isFlipped, hasAnimation }: DealCardProps) => {
    if (!ref.current || isAnimating?.current) return;
    if (cardIndex === undefined) return;
    if (animatedState.current) return;
    const position = useCalculateElements({ ref, isMobile }).calculate();
    controls.set({ visibility: 'visible', opacity: 0 });
    if (hasAnimation) {
      isAnimating.current = true;
      controls.set({ rotateY: 0, rotate: isFlipped ? (cardIndex === 0 ? '-10deg' : '10deg') : 0 });
      await controls.start({
        left: [position.left, cardIndex === 0 ? '0.5em' : '3.4em'],
        top: [position.top, '0em'],
        opacity: [0, 1],
        rotateY: 0,
        rotate: isFlipped ? (cardIndex === 0 ? '-10deg' : '10deg') : 0,
        transition: { duration: DURATION_CARD_DEAL }
      });
      if (isFlipped) {
        await controls.start({
          rotate: isFlipped ? ['0deg', cardIndex === 0 ? '-10deg' : '10deg'] : undefined,
          rotateY: [0, isFlipped ? 180 : 0],
          transition: { duration: DURATION_CARD_FLIP }
        });
      }
    }
    const animatedStateData = {
      rotate: isFlipped ? (cardIndex === 0 ? '-10deg' : '10deg') : undefined,
      left: cardIndex === 0 ? '0.5em' : '3.4em',
      top: '0em',
      rotateY: isFlipped ? '180deg' : '0deg',
      opacity: 1
    };
    controls.set(animatedStateData);
    animatedState.current = animatedStateData;
    isAnimating.current = false;
  };

  const cardOpen = async ({ cardIndex }: { cardIndex?: number }) => {
    if (!ref.current || isAnimating?.current) return;
    if (animatedState.current?.rotateY === '180deg') return;
    isAnimating.current = true;
    await controls.start({
      rotateY: [0, 180],
      rotate: ['0deg', cardIndex === 0 ? '-10deg' : '10deg'],
      transition: { duration: 0.2 }
    });
    animatedState.current = {
      ...animatedState.current,
      rotateY: '180deg',
      rotate: cardIndex === 0 ? '-10deg' : '10deg'
    };
    isAnimating.current = false;
  };

  const highlightCard = async () => {
    if (!ref.current || isAnimating?.current) return;
    if (!animatedState.current) return;
    isAnimating.current = true;
    await controls.start({
      top: ['0em', '-0.8em'],
      transition: { duration: 0.2 }
    });
    const animatedStateData = {
      ...animatedState.current,
      top: '-0.8em'
    };
    controls.set(animatedStateData);
    animatedState.current = animatedStateData;
    isAnimating.current = false;
  };

  // Chips Animation
  const betChips = async ({ hasAnimation, chipPosition }: { hasAnimation: boolean; chipPosition?: { left?: string; top?: string } }) => {
    if (!ref.current || isAnimating?.current || !chipPosition) return;
    controls.set({ visibility: 'visible', opacity: 0 });
    if (!animatedState.current && hasAnimation) {
      isAnimating.current = true;
      await controls.start({
        opacity: [0, 1],
        left: ['0em', chipPosition.left ?? '0em'],
        top: ['0em', chipPosition.top ?? '0em'],
        transition: { duration: DURATION_BET_MOVE }
      });
    }
    const animatedStateData = {
      left: chipPosition.left ?? '0em',
      top: chipPosition.top ?? '0em',
      opacity: 1
    };
    if (animatedState.current || !hasAnimation) {
      controls.set(animatedStateData);
    }

    controls.set(animatedStateData);
    animatedState.current = animatedStateData;
    isAnimating.current = false;
  };

  const gatherChips = async () => {
    if (!ref.current || isAnimating?.current) return;
    if (animatedState.current) {
      isAnimating.current = true;
      const position = useCalculateElements({ ref, dest: 'chip', baseFontSize: fontSize, isMobile }).calculate();
      await controls.start({
        opacity: 1,
        left: [animatedState.current.left, position.left],
        top: [animatedState.current.top, position.top],
        transition: { duration: DURATION_BET_MOVE }
      });
      animatedState.current = {
        opacity: 1
      };
      isAnimating.current = false;
    }
  };

  const getWinningChips = async ({ chipPosition }: { chipPosition?: { left?: string; top?: string } }) => {
    if (!ref.current || isAnimating?.current || !chipPosition) return;
    if (animatedState.current) return;
    isAnimating.current = true;
    controls.set({ visibility: 'visible', opacity: 0 });
    const position = useCalculateElements({ ref, dest: 'chip', baseFontSize: fontSize, isMobile }).calculate();
    await controls.start({
      opacity: 1,
      left: [position.left, chipPosition.left ?? '0em'],
      top: [position.top, chipPosition.top ?? '0em'],
      transition: { duration: DURATION_BET_MOVE }
    });
    const animatedStateData = {
      opacity: 1,
      left: chipPosition.left,
      top: chipPosition.top
    };
    controls.set(animatedStateData);
    animatedState.current = animatedStateData;
    isAnimating.current = false;
  };

  const reset = () => {
    if (!ref.current || isAnimating?.current) return;
    controls.stop();
    controls.set({ opacity: 0, visibility: 'hidden' });
    animatedState.current = undefined;
    controls.set(initialState.current);
    isAnimating.current = false;
  };

  return {
    dealHoleCard,
    dealCommunityCard,
    cardOpen,
    betChips,
    reset,
    getWinningChips,
    highlightCard,
    gatherChips,
    controls,
    initial: animatedState.current || initialState?.current,
    ref
  };
};

export default useAnimationControl;
