import React from 'react';
import { ClickableIconProps, IconProps } from '../types';

export const XCloseIcon = React.memo<ClickableIconProps>(({ w = 20, h = 20, color = 'white', onClick }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} cursor={'pointer'}>
    <path
      d="M15.5886 5.58958C15.914 5.26414 15.914 4.73651 15.5886 4.41107C15.2632 4.08563 14.7355 4.08563 14.4101 4.41107L9.99935 8.82181L5.5886 4.41107C5.26317 4.08563 4.73553 4.08563 4.41009 4.41107C4.08466 4.73651 4.08466 5.26414 4.41009 5.58958L8.82084 10.0003L4.41009 14.4111C4.08466 14.7365 4.08466 15.2641 4.41009 15.5896C4.73553 15.915 5.26317 15.915 5.5886 15.5896L9.99935 11.1788L14.4101 15.5896C14.7355 15.915 15.2632 15.915 15.5886 15.5896C15.914 15.2641 15.914 14.7365 15.5886 14.4111L11.1779 10.0003L15.5886 5.58958Z"
      fill="white"
    />
  </svg>
));

XCloseIcon.displayName = 'XClose';
