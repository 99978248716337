import React from 'react';
import { useLocation } from 'react-router-dom';
import TableHeader from './TableHeader';
import MainHeader from './MainHeader';

const Index = () => {
  const location = useLocation();
  const path = location.pathname.split('/');
  return path.some(p => p === 'table') ? <TableHeader /> : <MainHeader path={location.pathname.split('/')[1]} />;
};

export default Index;
