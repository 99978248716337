import React from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import { TextStyles } from '../../../styles/textStyles';

interface HorizontalTitleDescriptionProps {
  imageUrl: string | undefined;
  imageSize: number;
  gap: number;
  value: string | undefined;
  fontStyle?: keyof typeof TextStyles;
  fontColor?: keyof typeof Colors;
}

const ImageWithText: React.FC<HorizontalTitleDescriptionProps> = ({ imageUrl, imageSize = 48, gap = 16, value, fontStyle = 'TextMd_Medium', fontColor = 'white' }) => {
  return (
    <Section gap={gap}>
      <Image size={imageSize} src={imageUrl} alt={`${value}`} />
      <Value valueFontStyle={fontStyle} valueColor={fontColor}>
        {value ?? ''}
      </Value>
    </Section>
  );
};

const Section = styled.div<{ gap: number }>`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: ${({ gap }) => gap + 'px'};
`;

const Image = styled.img<{ size: number }>`
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  border-radius: 50%;
  align-self: center;
`;

const Value = styled.span<{ valueFontStyle: keyof typeof TextStyles; valueColor: keyof typeof Colors }>`
  ${({ theme, valueFontStyle }) => theme.textStyles[valueFontStyle]};
  color: ${({ theme, valueColor }) => theme.colors[valueColor]};
  user-select: none;
`;

export default ImageWithText;
