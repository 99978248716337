import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { ChevronDown } from '../icon/icons/ChevronDown';
import { useCountryList } from '../../../hooks/useCountryList';

interface OptionType {
  value: string;
  label: string;
  flagUrl: string;
  code: string;
}

interface CountrySelectProps {
  onChange: (code: string) => void;
  value: string;
}

interface CustomDropdownProps {
  options: OptionType[];
  defaultValue?: string;
  onChange: (value: OptionType) => void;
}

const CountryCodeSelect: React.FC<CountrySelectProps> = ({ onChange, value }) => {
  const countryList = useCountryList();
  const [selectedCountry, setSelectedCountry] = useState<OptionType | null>(null);

  useEffect(() => {
    if (value && countryList) {
      const userCountry = countryList.find(country => country.callingCode === value || country.code === value);
      if (userCountry) {
        setSelectedCountry({
          value: userCountry.code,
          label: userCountry.name,
          flagUrl: userCountry.flagUrl,
          code: userCountry.callingCode
        });
      }
    }
  }, [value, countryList]);

  const handleCountryChange = (option: OptionType) => {
    onChange(option?.code);
  };

  const countryOptions: OptionType[] =
    countryList?.map(country => ({
      value: country.code,
      label: country.name,
      flagUrl: country.flagUrl,
      code: country.callingCode
    })) ?? [];

  return <CustomDropdown options={countryOptions} defaultValue={selectedCountry ? selectedCountry.code : ''} onChange={handleCountryChange} />;
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, defaultValue, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<OptionType | undefined>(options.find(option => option.code === defaultValue));
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const newSelectedOption = options.find(option => option.code === defaultValue);
    setSelectedOption(newSelectedOption);
  }, [defaultValue, options]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const handleSelect = (option: OptionType) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownSelected onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? (
          <>
            <DropdownItemParent>
              <DropdownText>+{selectedOption?.code}</DropdownText>
            </DropdownItemParent>
            <ChevronDown w={16} h={16} color={'white'} />
          </>
        ) : (
          <>
            <DropdownText>Select a profile flag</DropdownText>
            <ChevronDown w={16} h={16} color={'white'} />
          </>
        )}
      </DropdownSelected>
      {isOpen && (
        <DropdownList>
          {options.map(option => (
            <DropdownItem key={option.value} onClick={() => handleSelect(option)}>
              <DropdownImage src={option.flagUrl} alt={option.value} />
              <DropdownText>
                {option.label} (+{option.code})
              </DropdownText>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 118px;
  @media (max-width: 768px) {
    min-width: 108px;
  }
`;

const DropdownSelected = styled.div`
  display: flex;
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  min-height: 52px;
  padding-left: 16px;
  padding-right: 16px;
  border: none;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.gray900};
  color: ${({ theme }) => theme.colors.white};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray600};
  }

  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const DropdownList = styled.div`
  position: absolute;
  min-height: 348px;
  width: 243px;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 8px;
  max-height: 348px;
  margin-top: 4px;
  overflow-y: auto;
  z-index: 15;
  padding: 12px 8px;
  box-sizing: border-box;
  ${({ theme }) => theme.shadow.lg};
  @media (max-width: 768px) {
    padding: 10px 8px;
  }
`;

const DropdownItemParent = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  min-height: 52px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-radius: 8px;
  @media (max-width: 768px) {
    min-height: 40px;
  }
`;

const DropdownItem = styled(DropdownItemParent)`
  padding: 0 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray800};
  }
`;

const DropdownImage = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 16px;
`;

const DropdownText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

export default CountryCodeSelect;
