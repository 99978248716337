import React from 'react';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { PrizeAmount } from '../../../store/slices/tournamentTypes';
import { toFixedFloor } from '../../../utils/StringUtil';

const TournamentDetailTabPrizePool: React.FC = () => {
  const { tournamentPrizePool, tournamentAsset } = useTournamentLobby();

  const renderPrizePool = (payoutInfo: PrizeAmount) => (
    <StyledPrizePoolRow key={payoutInfo.id} selected={false}>
      <StyledPrizePoolCell color={'white'}>{payoutInfo.maxRank === payoutInfo.minRank ? `${payoutInfo.minRank}` : `${payoutInfo.minRank}~${payoutInfo.maxRank}`}</StyledPrizePoolCell>
      <StyledPrizePoolCell color={'white'}>{toFixedFloor(Number(payoutInfo.prizeAmount), tournamentAsset?.displayDecimals ?? 0, false, true) + ' ' + tournamentAsset?.name}</StyledPrizePoolCell>
    </StyledPrizePoolRow>
  );

  return (
    <PrizePoolContainer>
      <HorizontalLayout margin={'0 32px'} alignItem={'start'}>
        <Text fontStyle={'TextMd_Semibold'} textColor={'white'} text={'Prize Pool'} textAlign={'start'} />
      </HorizontalLayout>
      <TableSection flex={1}>
        <StyledTableHeader>
          <StyledPrizePoolHeader>
            <StyledPrizePoolCell color={'gray400'}>Rank</StyledPrizePoolCell>
            <StyledPrizePoolCell color={'gray400'}>Award</StyledPrizePoolCell>
          </StyledPrizePoolHeader>
        </StyledTableHeader>
        <StyledTableBody>{tournamentPrizePool?.pool?.map(renderPrizePool)}</StyledTableBody>
      </TableSection>
    </PrizePoolContainer>
  );
};

const PrizePoolContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledPrizePoolHeader = styled(StyledRowHeader)`
  grid-template-columns: 1fr 1fr;
`;

const StyledPrizePoolRow = styled(StyledRow)`
  grid-template-columns: 1fr 1fr;
`;

const StyledPrizePoolCell = styled(StyledCell)`
  padding: 8px;
`;

export default TournamentDetailTabPrizePool;
