import { ApiResponse, basePokerApi, handleTransformErrorResponse, handleTransformResponse } from './api';
import { Country, Emoji, HealthResponse } from './responseTypes';

export const utilApi = basePokerApi.injectEndpoints({
  endpoints: build => ({
    getServerStatus: build.query<ApiResponse<HealthResponse>, void>({
      query: () => `health`,
      transformResponse: handleTransformResponse('health'),
      transformErrorResponse: handleTransformErrorResponse('health'),
      providesTags: ['Util']
    }),
    countryList: build.query<ApiResponse<Country[]>, void>({
      query: () => `setting/country`,
      transformResponse: handleTransformResponse('countryList'),
      transformErrorResponse: handleTransformErrorResponse('countryList'),
      providesTags: ['Country']
    }),
    emojiList: build.query<ApiResponse<Emoji[]>, void>({
      query: () => `setting/emoji`,
      transformResponse: handleTransformResponse('emojiList'),
      transformErrorResponse: handleTransformErrorResponse('emojiList'),
      providesTags: ['Emoji']
    })
  })
});

export const { useGetServerStatusQuery, useCountryListQuery, useEmojiListQuery } = utilApi;
