import React from 'react';
import { Winner } from './styles';
import { ReactComponent as WinIcon } from 'src/assets/img/win-no-shadow.svg';

interface WinnerUiProps {
  handCombination?: string;
  isShow?: boolean;
}

const WinnerUi = ({ handCombination, isShow = false }: WinnerUiProps) => {
  return (
    <Winner>
      <WinIcon />
    </Winner>
  );
};

export default WinnerUi;