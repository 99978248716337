import styled from '@emotion/styled';
import Text from './Text';

export const LeftText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray300};
  text-align: start;
  align-self: start;
  user-select: none;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Medium};
  }
`;
