import React from 'react';
import styled from '@emotion/styled';
import { StyledCell, StyledRow, StyledRowHeader, StyledTableBody, StyledTableHeader, TableSection } from './styles';
import { useTournamentLobby } from '../../../hooks/useTournamentLobby';
import { TournamentTables } from '../../../store/slices/tournamentTypes';

const TournamentDetailTabTables: React.FC = () => {
  const { tournamentId, tournamentTables } = useTournamentLobby();

  const openTable = (shareCode: string) => {
    const width = 1600;
    const height = 900;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const url = `/tournament/${tournamentId}/table/${shareCode}`;
    const windowName = `${shareCode}`;
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left}`;
    window.open(url, windowName, windowFeatures);
  };

  const renderRow = (tournamentTable: TournamentTables) => (
    <TournamentTablesRow key={tournamentTable.shareCode} selected={false} onDoubleClick={() => openTable(tournamentTable.shareCode)}>
      <TournamentTablesCell color={'white'}>{tournamentTable.tableId}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{tournamentTable.playersCnt}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{tournamentTable.stack.largest}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{tournamentTable.stack.average}</TournamentTablesCell>
      <TournamentTablesCell color={'white'}>{tournamentTable.stack.smallest}</TournamentTablesCell>
    </TournamentTablesRow>
  );

  return (
    <TournamentTablesContainer>
      <TableSection>
        <StyledTableHeader>
          <TournamentTablesHeader>
            <TournamentTablesCell color={'gray400'}>Table</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Player</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Largest Stack</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Average Stack</TournamentTablesCell>
            <TournamentTablesCell color={'gray400'}>Smallest Stack</TournamentTablesCell>
          </TournamentTablesHeader>
        </StyledTableHeader>
        <StyledTableBody>{tournamentTables?.map(renderRow)}</StyledTableBody>
      </TableSection>
    </TournamentTablesContainer>
  );
};

const TournamentTablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TournamentTablesHeader = styled(StyledRowHeader)`
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 1fr;
`;

const TournamentTablesRow = styled(StyledRow)`
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 1fr;
`;

const TournamentTablesCell = styled(StyledCell)`
  padding: 8px;
`;

export default TournamentDetailTabTables;
