import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Usdt = React.memo<IconProps>(({ w = 32, h = 32, color = 'white' }) => (
  <svg width={w} height={h} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16' cy='16' r='16' fill='white' />
    <path
      d='M29.4957 19.3666C27.6373 26.8201 20.0873 31.3561 12.6321 29.4974C5.18001 27.6393 0.643446 20.0896 2.50266 12.6367C4.36024 5.18242 11.9103 0.646029 19.3632 2.50422C26.8179 4.3624 31.3541 11.9129 29.4955 19.3667L29.4957 19.3666H29.4957Z'
      fill='#26A17B' />
    <path fillRule='evenodd' clipRule='evenodd'
          d='M17.7591 17.5793C17.6607 17.5867 17.1524 17.617 16.0185 17.617C15.1166 17.617 14.4762 17.59 14.2516 17.5793C10.7661 17.426 8.16457 16.8193 8.16457 16.093C8.16457 15.3666 10.7661 14.7608 14.2516 14.605V16.9751C14.4795 16.9915 15.1321 17.03 16.034 17.03C17.1163 17.03 17.6583 16.9849 17.7559 16.9759V14.6067C21.2339 14.7616 23.8297 15.3683 23.8297 16.093C23.8297 16.8177 21.2347 17.4244 17.7559 17.5785L17.7591 17.5793ZM17.7592 14.3609V12.24H22.6131V9.00586H9.39781V12.24H14.2508V14.3601C10.3063 14.5413 7.33984 15.3225 7.33984 16.2588C7.33984 17.195 10.3063 17.9755 14.2508 18.1575V24.9537H17.7584V18.155C21.694 17.9738 24.6555 17.1934 24.6555 16.2579C24.6555 15.3225 21.6964 14.5421 17.7584 14.3601L17.7592 14.3609Z'
          fill='white' />
  </svg>

));

Usdt.displayName = 'Usdt';
