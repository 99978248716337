import { useState, useRef, useEffect, useCallback } from 'react';

const useBottomReached = () => {
  const [isReached, setIsReached] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const loadMore = useCallback((entries: IntersectionObserverEntry[]) => {
    const target = entries[0];
    setIsReached(target.isIntersecting);
  }, []);

  useEffect(() => {
    observer.current = new IntersectionObserver(loadMore, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    });

    const currentObserver = observer.current;

    if (loadMoreRef.current) {
      currentObserver.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        currentObserver.unobserve(loadMoreRef.current);
      }
    };
  }, [loadMore]);

  return { isReached, loadMoreRef };
};

export default useBottomReached;
