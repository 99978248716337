import { InputProps } from './types';
import { InputWrap, StyledInput, StyledText, Wrap } from './style';

const Input = ({ w, h = 40, block, radius, suffix, bgColor, color, placeholderColor, className, textStyles = 'TextMd', label, labelColor = 'gray300', labelStyles = 'TextMd_Medium', textAlign = 'left', ...props }: InputProps) => {
  return (
    <Wrap block={block}>
      {label && <StyledText fontStyle={labelStyles} text={label} color={labelColor} />}
      <InputWrap className={className} w={w} h={h} block={block} bgColor={bgColor} radius={radius}>
        <StyledInput textAlign={textAlign} color={color} {...props} />
        {suffix}
      </InputWrap>
    </Wrap>
  );
};

export default Input;
