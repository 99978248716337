import Card from 'src/components/common/card';

import Chip from 'src/components/common/chip';
import { ActiveOverlay, Avatar, StyledDealerButton, EmojiWrap, Hands, SeatBtnWrap, StyledSeatButton, StyledExtraCountDown, StyledFlagWrap, StyledSeat, StyledUserText, UserCard, UserName, UserStack, UserWrap, Wrap, StyledEmojiWrap } from './styles';
import { SeatProps } from './types';
import useSeat from '../../../../hooks/useSeat';
import useDevice from 'src/hooks/useDevice';
import Timer from './Timer';
import React from 'react';
import WinningRate from './WinningRate';
import WinnerUi from './WinnerUi';
import { DealerButton } from 'src/components/common/icon';
import EmojiBtn from '../chatBox/EmojiBtn';
import { useDispatch } from 'react-redux';
import authSlice from '../../../../store/slices/authSlice';
import { useUserTableSetting } from '../../../../hooks/useUserTableSetting';
import styled from '@emotion/styled';

export type positionType = 'top' | 'left' | 'right' | 'bottom';

const Seat = ({ seatId, seatPosition, fontSize, userTableSetting, selectUser, seatOrBuyIn, showVerifyPasswordModal, canReserve, emojis, pos, isTournament }: SeatProps) => {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const setting = useUserTableSetting();

  const {
    isBlur,
    winRate,
    winnerData,
    isTopWinRate,
    emojiUrl,
    isMySeat,
    isTurn,
    isDealer,
    isWinner,
    isFolded,
    seatedUserData,
    seatedPlayer,
    combinationMessage,
    actionBg,
    extraCountDown,
    isProgressSet,
    isProgressing,
    isExtraProgressing,
    actionText,
    timerBg,
    extraProgress,
    progress,
    textColor,
    transformedStack,
    showEmoji,
    hasSittingUser,
    cards,
    flagUrl,
    tableSettings,
    tableId,
    myUserData,
    symbolImage,
    dealerPos
  } = useSeat({ seatId, emojis, pos });

  const doAvatarTask = () => {
    if (isMySeat) {
      dispatch(
        authSlice.actions.setTableSetting({
          tableId: tableId,
          setting: { ...setting, isBlindView: !setting.isBlindView }
        })
      );
    } else {
      selectUser(seatedUserData?.userId);
    }
  };

  const doSeatTask = () => {
    if (myUserData?.isPasswordVerified) {
      seatOrBuyIn(seatId);
    } else {
      showVerifyPasswordModal(seatId);
    }
  };

  return (
    <StyledSeat left={pos.x} top={pos.y} style={{ fontSize: isMobile ? (isMySeat ? '10px' : '8.5px') : undefined, zIndex: isMySeat ? '10' : '1' }}>
      {hasSittingUser ? (
        <Wrap>
          {isTurn && <ActiveOverlay />}
          {isDealer && (
            <StyledDealerButton position={dealerPos}>
              <DealerButton />
            </StyledDealerButton>
          )}
          {isMySeat && (userTableSetting.isEmojiEnabled ?? true) && !isMobile && (
            <StyledEmojiWrap>
              <div style={{ position: 'relative' }}>
                <EmojiBtn />
              </div>
            </StyledEmojiWrap>
          )}
          {winRate !== undefined && <WinningRate seatPosition={seatPosition} isTopWinRate={isTopWinRate} winRate={winRate} />}
          <Chip fontSize={fontSize} type={'bet'} stack={seatedPlayer?.callAmount ?? 0} position={pos} chipValueMultiplier={tableSettings?.chipValueMultiplier} blindAmount={tableSettings?.blindAmount} />
          <Chip fontSize={fontSize} type={'win'} stack={winnerData?.winAmount ?? 0} position={pos} chipValueMultiplier={tableSettings?.chipValueMultiplier} blindAmount={tableSettings?.blindAmount} />
          <UserWrap isBlur={isBlur || (isTurn && extraCountDown !== undefined)} onClick={doAvatarTask}>
            {flagUrl && (
              <StyledFlagWrap>
                <img src={flagUrl} alt={'flag'} />
              </StyledFlagWrap>
            )}
            <Avatar>
              <img src={seatedUserData?.profileImage} alt="avatar" />
            </Avatar>
            <UserCard actionBg={actionBg}>
              <UserName isMySeat={isMySeat}>{seatedUserData?.name || ''}</UserName>
              {actionText ? (
                <StyledUserText type={['Sitting out', 'Reserved', 'Waiting'].includes(actionText) ? 'status' : 'action'} color={textColor}>
                  {actionText}
                </StyledUserText>
              ) : (
                <StyledStackWrap>
                  {!setting.isBlindView && symbolImage && <StyledSymbol src={symbolImage} alt={'token symbol'} />}
                  <UserStack>{transformedStack}</UserStack>
                </StyledStackWrap>
              )}
              {isProgressing && <Timer animate={isProgressSet} progress={progress} color={timerBg} />}
              {isExtraProgressing && <Timer animate={isProgressSet} progress={extraProgress} color={'error400'} />}
            </UserCard>
            <Hands isFold={isFolded} cards={cards} isMySeat={isMySeat}>
              <Card cardType={'hole'} cardIndex={0} card={cards?.[0]} />
              <Card cardType={'hole'} cardIndex={1} card={cards?.[1]} />
            </Hands>
          </UserWrap>
          {isWinner && <WinnerUi handCombination={combinationMessage} />}
          {isTurn && extraCountDown !== undefined && <StyledExtraCountDown>{extraCountDown || ''}</StyledExtraCountDown>}
          {userTableSetting.isEmojiEnabled && showEmoji && emojiUrl && (
            <EmojiWrap>
              <img src={emojiUrl} alt="emoji" />
            </EmojiWrap>
          )}
        </Wrap>
      ) : (
        <SeatBtnWrap>{!isTournament && canReserve && <StyledSeatButton onClick={() => doSeatTask()} />}</SeatBtnWrap>
      )}
    </StyledSeat>
  );
};

const StyledSymbol = styled.img`
  width: 1em;
  height: 1em;
`;

const StyledStackWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25em;
`;

export default Seat;
