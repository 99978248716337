import React from 'react';
import styled from '@emotion/styled';
import HorizontalLayout from '../../layout/HorizontalLayout';
import LinearLayout from '../../layout/LinearLayout';
import Text from '../text/Text';
import { numberToDisplayString } from '../../../utils/StringUtil';
import BaseButton from '../button/BaseButton';

interface NotificationProps {
  user: {
    name: string;
    image: string;
  };
  chips: number;
  onApprove: () => void;
  onDeny: () => void;
}

const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow:
    inset 0 -2px 4px rgba(152, 162, 179, 1),
    0 16px 32px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
  width: auto;
  min-width: 292px;
  padding: 0 16px;
`;

const Avatar = styled.img`
  width: 78px;
  height: 78px;
  border-radius: 50%;
  padding: 0 16px;
  align-self: center;
`;

const UserName = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  align-self: center;
  padding: 0 16px;
`;

const BuyInNotification: React.FC<NotificationProps> = ({ user, chips, onApprove, onDeny }) => {
  return (
    <NotificationWrapper>
      <HorizontalLayout gap={8}>
        <LinearLayout gap={8} width={'fit-content'}>
          <Avatar src={user.image} alt={`${user.name}'s avatar`} />
          <UserName>{user.name}</UserName>
        </LinearLayout>
        <LinearLayout gap={8} flex={1}>
          <LinearLayout backgroundColor={'gray100'} borderRadius={8} padding={8}>
            <Text fontStyle={'TextSmall_Medium'} textColor={'gray700'} text={'Buy-in'} />
            <Text fontStyle={'TextXl_Bold'} textColor={'primary600'} text={`P${numberToDisplayString(chips)}`} />
          </LinearLayout>
          <HorizontalLayout margin={'0'} gap={4}>
            <BaseButton size={'btn40'} onClick={onDeny} color={'gray'} textColor={'white'} textStyle={'TextSmall_Semibold'}>
              Deny
            </BaseButton>
            <BaseButton size={'btn40'} onClick={onApprove} color={'primary'} textColor={'white'} textStyle={'TextSmall_Semibold'}>
              Approve
            </BaseButton>
          </HorizontalLayout>
        </LinearLayout>
      </HorizontalLayout>
    </NotificationWrapper>
  );
};

export default BuyInNotification;
