import React from 'react';
import { ShowCardProps } from './types';
import { ShowCardFront, ShowCardNum, ShowCardWrap, ShowSuit } from './style';
import useCard from './useCard';
import { logError } from '../../../utils/ConsoleUtil';

const ShowCard: React.FC<ShowCardProps> = ({ card, h, ...props }) => {
  const { bgColor, smallSuit, textColor, borderColor } = useCard({ card });
  if (!card) {
    logError('Error: card is undefined');
    return <></>;
  }
  return (
    <ShowCardWrap {...props}>
      <ShowCardFront bgColor={bgColor} borderColor={borderColor}>
        <ShowCardNum color={textColor}>{card[0] === 'T' ? '10' : card[0]}</ShowCardNum>
        <ShowSuit>{smallSuit}</ShowSuit>
      </ShowCardFront>
    </ShowCardWrap>
  );
};

export default ShowCard;
