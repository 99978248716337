import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Clear = React.memo<IconProps>(({ w = 24, h = 24, color = 'gray400' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_19188_225181)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99967 2.50065C5.85754 2.50065 2.49967 5.85851 2.49967 10.0007C2.49967 14.1428 5.85754 17.5006 9.99967 17.5006C14.1418 17.5006 17.4997 14.1428 17.4997 10.0007C17.4997 5.85851 14.1418 2.50065 9.99967 2.50065ZM0.833008 10.0007C0.833008 4.93804 4.93706 0.833984 9.99967 0.833984C15.0623 0.833984 19.1663 4.93804 19.1663 10.0007C19.1663 15.0633 15.0623 19.1673 9.99967 19.1673C4.93706 19.1673 0.833008 15.0633 0.833008 10.0007ZM6.91042 6.9114C7.23586 6.58596 7.76349 6.58596 8.08893 6.9114L9.99967 8.82214L11.9104 6.9114C12.2359 6.58596 12.7635 6.58596 13.0889 6.9114C13.4144 7.23683 13.4144 7.76447 13.0889 8.08991L11.1782 10.0007L13.0889 11.9114C13.4144 12.2368 13.4144 12.7645 13.0889 13.0899C12.7635 13.4153 12.2359 13.4153 11.9104 13.0899L9.99967 11.1792L8.08893 13.0899C7.76349 13.4153 7.23586 13.4153 6.91042 13.0899C6.58498 12.7645 6.58498 12.2368 6.91042 11.9114L8.82116 10.0007L6.91042 8.08991C6.58498 7.76447 6.58498 7.23683 6.91042 6.9114Z"
        fill={Colors[color]}
      />
    </g>
    <defs>
      <clipPath id="clip0_19188_225181">
        <rect width={w} height={h} fill="white" />
      </clipPath>
    </defs>
  </svg>
));

Clear.displayName = 'Clear';
