import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { TableStyleOptions } from '../../../store/api/responseTypes';
import { useDispatch } from 'react-redux';
import { useUserTableSetting } from '../../../hooks/useUserTableSetting';
import authSlice from '../../../store/slices/authSlice';
import { tableBaseUrl } from '../../../utils/Util';
import useDevice from '../../../hooks/useDevice';

const styleOptions = {
  GRAY: tableBaseUrl + 'compressed-table-gray.png',
  BLUE: tableBaseUrl + 'compressed-table-blue.png',
  GREEN: tableBaseUrl + 'compressed-table-green.png'
};

const mobileStyleOptions = {
  GRAY: tableBaseUrl + 'compressed-mobile-table-gray.png',
  BLUE: tableBaseUrl + 'compressed-mobile-table-blue.png',
  GREEN: tableBaseUrl + 'compressed-mobile-table-green.png'
};

type Props = {
  tableId?: number | string | undefined;
  onChange?: (value: TableStyleOptions) => void;
};

const TableStyleSelector: React.FC<Props> = ({ tableId, onChange }) => {
  const { isMobile } = useDevice();
  const setting = useUserTableSetting();
  const [selectedOption, setSelectedOption] = useState<TableStyleOptions>(setting!.tableStyle);
  const dispatch = useDispatch();
  useEffect(() => {
    if (onChange) {
      onChange(selectedOption);
    }
    if (tableId && setting) {
      dispatch(
        authSlice.actions.setTableSetting({
          tableId: tableId,
          setting: { ...setting, tableStyle: selectedOption }
        })
      );
    }
  }, [selectedOption]);

  return (
    <Container>
      <Title>Table Style</Title>
      <OptionWrap tableId={tableId}>
        {Object.entries(styleOptions).map(([key, src], i) => (
          <TableOptionButton key={key + i} isSelected={selectedOption === key} tableId={tableId} onClick={() => setSelectedOption(key as TableStyleOptions)}>
            <img src={src} alt={`Option ${key}`} />
          </TableOptionButton>
        ))}
      </OptionWrap>
    </Container>
  );
};

export default TableStyleSelector;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: white;
`;

const OptionWrap = styled.div<{ tableId: number | string | undefined }>`
  display: grid;
  justify-content: space-around;
  gap: 12px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: ${({ tableId }) => (tableId ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)')};
  }
`;

const TableOptionButton = styled.div<{ isSelected: boolean; tableId: number | string | undefined }>`
  img {
    border: ${({ isSelected, theme }) => (isSelected ? `1px solid ${theme.colors.white}` : `1px solid ${theme.colors.gray500}`)};
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: ${({ tableId }) => (tableId ? '84px' : '120px')};
    height: auto;
    @media (max-width: 768px) {
      width: ${({ tableId }) => (tableId ? '84px' : '120px')};
    }
  }
`;
