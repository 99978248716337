import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import Text from '../../common/text/Text';
import useDevice from '../../../hooks/useDevice';
import TitleValueVertical from '../../common/text/TitleValueVertical';
import { TextStyles } from '../../../styles/textStyles';
import { Colors } from '../../../styles/Colors';
import { HorizontalLine } from '../../common/line/HorizontalLine';
import TournamentInfoTab from './TournamentInfoTab';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { dismissModal } from '../../../store/slices/modalSlice';

type InfoType = { label: string; fontStyle: keyof typeof TextStyles; value: string; valueColor: keyof typeof Colors };

interface TournamentInfoBoxProps {
  onClose?: () => void;
}

const TournamentInfoBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const ref = useRef<HTMLDivElement>(null);

  const closeModal = () => dispatch(dismissModal());

  const interval = useRef<NodeJS.Timeout>();
  const [lateRegEndTime, setLateRegEndTime] = useState<Date | null>(new Date(new Date().getTime() + 30 * 60 * 1000 + 21 * 1000));
  const [stateEndTime, setStateEndTime] = useState<Date | null>(new Date(new Date().getTime() + 3 * 60 * 1000 + 7 * 1000)); // [0] is the start time, [1] is the end time
  const [lateRegDiffTime, setLateRegDiffTime] = useState<number>(0);
  const [stateDiffTime, setStateDiffTime] = useState<number>(0);

  const timeFormat = (time: number) => {
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const infoData = {
    totalPrize: '2,500',
    symbolImage: 'https://assets.testbase.xyz/asset/USDT.svg',
    level: 5,
    nextLevel: 6,
    playerCount: 100,
    leftPlayerCount: 70,
    firstPlace: 72.48,
    lateRegEndTime,
    nextPrize: 14.36,
    placesPaid: '30',
    myRank: '50th',

    blinds: [
      { small: 300, big: 600 },
      { small: 350, big: 700 },
      { small: 400, big: 800 },
      { small: 450, big: 900 },
      { small: 500, big: 1000 },
      { small: 550, big: 1100 },
      { small: 600, big: 1200 },
      { small: 650, big: 1300 },
      { small: 700, big: 1400 }
    ]
  };

  React.useEffect(() => {
    if (lateRegEndTime) {
      const end = lateRegEndTime;
      interval.current = setInterval(() => {
        lateRegEndTime.getTime();
        const now = new Date().getTime();
        const diff = end.getTime() - now;

        setLateRegDiffTime(diff);
        if (diff < 0) {
          clearInterval(interval.current!);
          setLateRegDiffTime(0);
        }
      }, 500);
    } else {
      clearInterval(interval.current!);
      setLateRegDiffTime(0);
    }
    return () => {
      clearInterval(interval.current!);
    };
  }, [lateRegEndTime]);

  React.useEffect(() => {
    if (stateEndTime) {
      const end = stateEndTime;
      interval.current = setInterval(() => {
        stateEndTime.getTime();
        const now = new Date().getTime();
        const diff = end.getTime() - now;

        setStateDiffTime(diff);
        if (diff < 0) {
          clearInterval(interval.current!);
          setStateDiffTime(0);
        }
      }, 500);
    } else {
      clearInterval(interval.current!);
      setStateDiffTime(0);
    }
    return () => {
      clearInterval(interval.current!);
    };
  }, [stateEndTime]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    if (isMobile) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const prizeInfos: InfoType[] = [
    {
      label: 'Total Prize',
      fontStyle: 'DisplayXs_Semibold',
      value: '2,500',
      valueColor: 'warning300'
    },
    {
      label: `Level ${infoData.level}`,
      fontStyle: 'DisplayXs_Semibold',
      value: timeFormat(lateRegDiffTime),
      valueColor: 'white'
    },
    {
      label: 'Players Left',
      fontStyle: 'DisplayXs_Semibold',
      value: '70/100',
      valueColor: 'blue500'
    },
    {
      label: '1st Place',
      fontStyle: 'TextMd_Semibold',
      value: '72.48',
      valueColor: 'warning300'
    },
    {
      label: `blinds`,
      fontStyle: 'TextMd_Semibold',
      value: `${infoData.blinds[infoData.level - 1]?.small} | ${infoData.blinds[infoData.level - 1]?.big}`,
      valueColor: 'white'
    },
    {
      label: 'Places Paid',
      fontStyle: 'TextMd_Semibold',
      value: '30',
      valueColor: 'blue500'
    },
    {
      label: 'Next Prize',
      fontStyle: 'TextMd_Semibold',
      value: '10.29',
      valueColor: 'warning300'
    },
    {
      label: `Next Blind`,
      fontStyle: 'TextMd_Semibold',
      value: `${infoData.blinds[infoData.level]?.small} | ${infoData.blinds[infoData.level]?.big}`,
      valueColor: 'white'
    },
    {
      label: 'My Rank',
      fontStyle: 'TextMd_Semibold',
      value: '50th',
      valueColor: 'blue500'
    }
  ];

  const stackInfos: InfoType[] = [
    {
      label: 'Largest Stack',
      fontStyle: 'TextMd_Semibold',
      value: '96.3 BB',
      valueColor: 'success400'
    },
    {
      label: 'Average Stack',
      fontStyle: 'TextMd_Semibold',
      value: '22.1 BB',
      valueColor: 'success400'
    },
    {
      label: 'Smallest Stack',
      fontStyle: 'TextMd_Semibold',
      value: '1.5 BB',
      valueColor: 'success400'
    }
  ];

  return (
    <StyledContainer ref={ref}>
      {!isMobile && (
        <StyledWrap layoutId={'tournamentInfo'} onClick={handleOpen} transition={{ duration: 0 }}>
          <TitleValueHorizontal labelFontStyle={'TextSmall_Medium'} valueFontStyle={'TextSmall_Bold'} label={`Level ${infoData.level}`} value={timeFormat(stateDiffTime)} />
          <TitleValueHorizontal labelFontStyle={'TextSmall_Medium'} valueFontStyle={'TextSmall_Bold'} label={`Late Reg.`} value={timeFormat(lateRegDiffTime)} />
          <TitleValueHorizontal labelFontStyle={'TextSmall_Medium'} valueFontStyle={'TextSmall_Bold'} label={`Prize`} imageUrl={infoData.symbolImage} value={infoData.totalPrize} valueColor={'warning300'} />
          <TitleValueHorizontal labelFontStyle={'TextSmall_Medium'} valueFontStyle={'TextSmall_Bold'} label={`My Rank`}>
            <StyledTextWrap>
              <Text fontStyle={'TextSmall_Bold'} text={'05'} textColor={'blue500'} />
              <Text fontStyle={'TextSmall_Bold'} text={'/70'} textColor={'white'} />
            </StyledTextWrap>
          </TitleValueHorizontal>
        </StyledWrap>
      )}
      {(isMobile || isOpen) && (
        <StyledPanel
          layoutId={'tournamentInfo'}
          transition={{
            duration: 0.2,
            type: 'spring',
            stiffness: 240,
            damping: 20
          }}
        >
          {isMobile && (
            <HorizontalLayout margin={'0'} padding={36}>
              <Title>{'Tournament Info'}</Title>
              <RightIconDiv>
                <XCloseIcon onClick={closeModal} />
              </RightIconDiv>
            </HorizontalLayout>
          )}
          <StyledPrizeInfo>
            {prizeInfos.map((prizeInfo, index) => (
              <TitleValueVertical
                key={index}
                labelFontStyle={'TextSmall_Medium'}
                iconSize={index === 0 ? 20 : 16}
                imageUrl={prizeInfo.valueColor === 'warning300' ? infoData.symbolImage : undefined}
                valueFontStyle={prizeInfo.fontStyle}
                label={prizeInfo.label}
                value={prizeInfo.value}
                valueColor={prizeInfo.valueColor}
              />
            ))}
          </StyledPrizeInfo>
          <StyledHorizontalLine />
          <StyledPrizeInfo>
            {stackInfos.map((stackInfo, index) => (
              <TitleValueVertical key={index} labelFontStyle={'TextSmall_Medium'} valueFontStyle={stackInfo.fontStyle} label={stackInfo.label} value={stackInfo.value} valueColor={stackInfo.valueColor} />
            ))}
          </StyledPrizeInfo>
          <TournamentInfoTab initialTab={'Prize'} />
        </StyledPanel>
      )}
    </StyledContainer>
  );
};

const Title = styled.h1`
  ${({ theme }) => theme.textStyles.TextXl_Semibold};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
  flex: 1;
  margin: 0 24px 0 0;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
    margin: 0 8px;
  }
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHorizontalLine = styled(HorizontalLine)`
  margin: 0 16px;
  display: flex;
  width: auto;
  box-sizing: border-box;
`;

const StyledPrizeInfo = styled.div`
  display: grid;
  grid-gap: 24px;
  padding: 0 16px;
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
`;

const StyledPanel = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 10;
  height: 572px;
  width: 368px;
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray700};
  ${({ theme }) => theme.shadow['2xl']};
  padding-top: 16px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: auto;
    width: auto;
    border-radius: 0;
    padding-top: 0;
  }
`;

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`;

const StyledTextWrap = styled.div`
  display: flex;
`;

const StyledWrap = styled(motion.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  right: 0;
  left: 0;
  box-sizing: border-box;

  border-radius: 8px;
  gap: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray750};
  background: ${({ theme }) => theme.colors.gray700_50};
  cursor: pointer;
`;

export default TournamentInfoBox;
