import React, { useEffect, useState } from 'react';
import Text from '../components/common/text/Text';
import BaseButton from '../components/common/button/BaseButton';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { ModalType, showModal } from '../store/slices/modalSlice';
import { TextStyles } from '../styles/textStyles';
import useDevice from '../hooks/useDevice';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/template/footer/Footer';
import sampleVideo from '../assets/video/sampleVideo.mp4';
import mobileSampleVideo from '../assets/video/sampleVideo_mobile.mp4';
import { useAuthContext } from '../hooks/AuthContext';
import LobbyBanners from '../components/template/settings/LobbyBanners';
import { Tab, TabContainer } from '../components/template/settings/MenuTab';
import TableListTemplate from '../components/template/settings/TableListTemplate';
import { LobbyTableList } from '../store/api/responseTypes';
import LandingFAQ from '../components/template/landing/LandingFAQ';
import YellowCardImg from 'src/assets/img/Card-Yellow.png';
import PurpleCardImg from 'src/assets/img/Card-Purple.png';
import OrangeCardImg from 'src/assets/img/Card-Orange.png';
import NavyCardImg from 'src/assets/img/Card-Navy.png';
import GreenCardImg from 'src/assets/img/Card-Green.png';
import { useLobbyTableListQuery } from '../store/api/holdem';
import _ from 'lodash';
import usePageVisibility from '../hooks/usePageVisibility';
import TournamentCommingSoon from '../components/template/tournament/TournamentCommingSoon';

const LandingScreen = () => {
  const { isAuthorized } = useAuthContext();
  const { isMobile, isDesktop, isDesktopSmall } = useDevice();
  const [activeTab, setActiveTab] = React.useState<'CASHGAME' | 'TOURNAMENT'>('CASHGAME');
  const [tableList, setTableList] = useState<LobbyTableList | undefined>(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isVisible = usePageVisibility();
  const [skip, setSkip] = useState(false);
  const { data: listData, refetch } = useLobbyTableListQuery(undefined, { pollingInterval: 10000, skip });

  useEffect(() => {
    if (!listData?.data) return;
    setTableList(listData?.data);
  }, [listData]);

  useEffect(() => {
    if (isVisible) {
      setSkip(false);
      _.delay(() => {
        refetch?.();
      }, 100);
    } else {
      setSkip(true);
    }
  }, [isVisible, refetch]);

  const showAuthModal = () => dispatch(showModal(ModalType.AuthModal));

  const showCreateTableModal = () => dispatch(showModal(ModalType.CreateTableModal));

  const goLobby = () => {
    navigate('/cashgame');
  };

  const onCreateTableClick = () => {
    if (!isAuthorized) {
      showAuthModal();
      return;
    }
    navigate('/cashgame');
    showCreateTableModal();
  };

  return (
    <Background>
      <StyledInner>
        <StyledTitle>
          <StartText fontStyle={isDesktop ? 'DisplayXl_Semibold' : isMobile ? 'DisplaySm_Semibold' : 'DisplayLg_Semibold'} textAlign={'start'} text={'No.1 Online Crypto\\nPoker Platform'} />
          <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextLg_Medium'} text={`Play online poker on any browser, ${isMobile ? '' : '\\n'}using your favorite cryptocurrencies.`} textAlign={'start'} />
          <StyledButtons>
            {isAuthorized && <StartButton w={isMobile ? 120 : 180} size={isMobile ? 'btn44' : 'btn60'} color="gray" block={false} onClick={onCreateTableClick} text={'Create Table'} textStyle={isMobile ? 'TextMd_Semibold' : 'TextLg_Semibold'} />}
            <StartButton
              w={isMobile ? 120 : 180}
              size={isMobile ? 'btn44' : 'btn60'}
              color="primary"
              block={false}
              onClick={() => {
                isAuthorized ? goLobby() : showAuthModal();
              }}
              text={isAuthorized ? 'Play now' : 'Get started'}
              textStyle={isMobile ? 'TextMd_Semibold' : 'TextLg_Semibold'}
            />
          </StyledButtons>
        </StyledTitle>
        <ContentContainer>
          <Video autoPlay loop muted playsInline key={isMobile ? mobileSampleVideo : sampleVideo}>
            <source src={isMobile ? mobileSampleVideo : sampleVideo} type={'video/mp4'} />
            Your browser does not support the video tag.
          </Video>
        </ContentContainer>
      </StyledInner>
      <StyledBanner>
        <StyledBannerTitle type={'promotion'} fontStyle={'DisplaySm_Semibold'} text={'Promotions and Events'} textAlign={'start'} />
        <LobbyBanners type={'landing'} />
      </StyledBanner>
      <StyledLobby>
        <StyledLobbyInner>
          <StyledLobbyLinks>
            <StyledLobbySection>
              <StyledLobbyBanner>
                <img src={require('src/assets/img/landing-cashgame.png')} />
              </StyledLobbyBanner>
              <StyledLinkTitle textAlign={'start'} fontStyle={'DisplayXs_Semibold'} text={'Create private game'} marginBottom={8} marginTop={24} />
              <Text textAlign={'start'} marginBottom={24} lineHeight={isMobile ? 20 : 24} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} text={'Play poker with your friends in a secure, private setting. Easily set up a poker room and invite your friends to join.'} />
              <BaseButton size={isMobile ? 'btn44' : 'btn48'} color={'primary'} textStyle={'TextMd_Semibold'} text={'Create Private Game'} onClick={onCreateTableClick} />
            </StyledLobbySection>
            <StyledLobbySection>
              <StyledLobbyBanner>
                <img src={require('src/assets/img/landing-tournament.png')} />
              </StyledLobbyBanner>
              <StyledLinkTitle textAlign={'start'} fontStyle={'DisplayXs_Semibold'} text={'Join cash game'} marginBottom={8} marginTop={24} />
              <Text textAlign={'start'} marginBottom={24} lineHeight={isMobile ? 20 : 24} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} text={'Compete against players from around the world in Texas Hold’em, using cryptocurrencies as chips.'} />
              <BaseButton size={isMobile ? 'btn44' : 'btn48'} color={'primary'} textStyle={'TextMd_Semibold'} text={'Join Cash Game'} onClick={goLobby} style={{ marginTop: 'auto' }} />
            </StyledLobbySection>
          </StyledLobbyLinks>
          <StyledLobbyList>
            <StyledTabs>
              <TabContainer bgColor={'gray800'}>
                <Tab onClick={() => setActiveTab('CASHGAME')} aria-selected={activeTab === 'CASHGAME'}>
                  Cash Games
                </Tab>
                <Tab onClick={() => setActiveTab('TOURNAMENT')} aria-selected={activeTab === 'TOURNAMENT'}>
                  Tournaments
                </Tab>
              </TabContainer>
            </StyledTabs>
            <Content>{activeTab === 'CASHGAME' ? <TableListTemplate type={'landing'} assetFilter={'ALL'} tableList={tableList} /> : <TournamentCommingSoon />}</Content>
          </StyledLobbyList>
        </StyledLobbyInner>
      </StyledLobby>
      <StyledFeatures>
        <StyledFeatureTitle>
          <StyledBannerTitle type={'feature'} fontStyle={'DisplaySm_Semibold'} textAlign={'start'} text={'Why Basepoker is the best\\n place to play Poker'} />
          <BaseButton
            size={isMobile ? 'btn44' : 'btn60'}
            textStyle={isMobile ? 'TextMd_Semibold' : 'TextLg_Semibold'}
            color={'primary'}
            w={isMobile ? 120 : 220}
            onClick={() => {
              isAuthorized ? goLobby() : showAuthModal();
            }}
            text={isAuthorized ? 'Play now' : 'Get started'}
          />
        </StyledFeatureTitle>
        <StyledFeatureList>
          <StyledFeatureSection>
            <StyledTopSection>
              <StyledPlayBox>
                <StyledFeatureTitleText textAlign={'start'} fontStyle={isMobile ? 'TextMd_Semibold' : isDesktopSmall ? 'TextLg_Semibold' : 'TextXl_Semibold'} text={'Play anywhere on any device'} marginBottom={8} />
                <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={'Basepoker offers a web-based poker experience on any device. No download required. Just play on your favorite browser'} />
                <StyledPlayImage>
                  <img src={require('src/assets/img/landing-play.png')} />
                </StyledPlayImage>
              </StyledPlayBox>
              {!isDesktop && (
                <StyledSupportSection>
                  <StyledCreateBox>
                    <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={'Create your accout in 2 clicks'} textColor={'gray900'} marginBottom={8} />
                    <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={'You can use your email, socials or crypto wallet to create account'} textColor={'gray900'} />
                    <StyledAccountImage>
                      <img src={require('src/assets/img/landing-accounts.png')} />
                    </StyledAccountImage>
                  </StyledCreateBox>
                  <StyledSupportBox>
                    <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={'24/7 live customer support'} marginBottom={8} />
                    <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={'Our global team is here to help you'} />
                    <StyledSupportImage>
                      <img src={require('src/assets/img/landing-support.png')} />
                    </StyledSupportImage>
                  </StyledSupportBox>
                </StyledSupportSection>
              )}
            </StyledTopSection>
            <StyledBottomSection>
              <StyledCryptoBox>
                <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={'Online poker for crypto monies'} marginBottom={8} />
                <Text
                  fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'}
                  textAlign={'start'}
                  text={'Use unlimited play money or crypto as chips. Enjoy secure deposits and instant withdrawals with multiple cryptocurrencies and networks. We’ve made transactions easier, safer, and faster than ever!'}
                />
                <StyledCryptoImage>
                  <img src={require('src/assets/img/landing-crypto.png')} />
                </StyledCryptoImage>
              </StyledCryptoBox>
              {!isDesktop && (
                <StyledFairBox>
                  <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={'100% Random and Fair'} marginBottom={8} />
                  <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={'Our RNG (Random Number Generator) is now fully decentralized, giving you, the player, the unique ability to observe your influence on the card shuffling process in real-time. '} />
                  <StyledFairImage>
                    <img src={require('src/assets/img/landing-fair.png')} />
                  </StyledFairImage>
                </StyledFairBox>
              )}
            </StyledBottomSection>
          </StyledFeatureSection>
          {isDesktop && (
            <StyledFeatureSection>
              <StyledSupportSection>
                <StyledCreateBox>
                  <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={'Create your accout in 2 clicks'} textColor={'gray900'} marginBottom={8} />
                  <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={'You can use your email, socials or crypto wallet to create account'} textColor={'gray900'} />
                  <StyledAccountImage>
                    <img src={require('src/assets/img/landing-accounts.png')} />
                  </StyledAccountImage>
                </StyledCreateBox>
                <StyledSupportBox>
                  <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={'24/7 live customer support'} marginBottom={8} />
                  <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={'Our global team is here to help you'} />
                  <StyledSupportImage>
                    <img src={require('src/assets/img/landing-support.png')} />
                  </StyledSupportImage>
                </StyledSupportBox>
              </StyledSupportSection>
              <StyledFairBox>
                <StyledFeatureTitleText textAlign={'start'} fontStyle={'TextXl_Semibold'} text={'100% Random and Fair'} marginBottom={8} />
                <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} text={'Our RNG (Random Number Generator) is now fully decentralized, giving you, the player, the unique ability to observe your influence on the card shuffling process in real-time. '} />
                <StyledFairImage>
                  <img src={require('src/assets/img/landing-fair.png')} />
                </StyledFairImage>
              </StyledFairBox>
            </StyledFeatureSection>
          )}
        </StyledFeatureList>
      </StyledFeatures>
      <LandingFAQ />
      <Footer />
    </Background>
  );
};

const StyledFeatureTitleText = styled(Text)`
  @media (max-width: 1024px) {
    ${({ theme }) => theme.textStyles.TextLg_Semibold};
  }
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
  }
`;
const StyledLinkTitle = styled(Text)`
  @media (max-width: 1600px) {
    ${({ theme }) => theme.textStyles.DisplayXs_Semibold};
  }
  @media (max-width: 1280px) {
    ${({ theme }) => theme.textStyles.TextXl_Semibold};
  }
  @media (max-width: 1024px) {
    ${({ theme }) => theme.textStyles.TextLg_Semibold};
  }
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
  }
`;
const StyledBottomSection = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
const StyledTopSection = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 16px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledPlayImage = styled.div`
  max-width: 446px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  flex: 1;
  margin-top: 32px;
  @media (max-width: 1280px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    margin-top: 24px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
const StyledFairImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  img {
    width: 290px;
    height: auto;
  }
`;
const StyledSupportImage = styled.div`
  width: 218px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-top: 24px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
const StyledAccountImage = styled.div`
  width: 176px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
const StyledCryptoImage = styled.div`
  width: 280px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 24px;
    max-width: 280px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
const StyledBox = styled.div`
  width: 100%;
  display: flex;
  padding: 32px;
  flex-direction: column;
  border-radius: 12px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 24px;
  }
`;
const StyledFairBox = styled(StyledBox)`
  height: 472px;
  position: relative;
  background: linear-gradient(180deg, #ff7f3a 0%, #ec4a0a 100%), url(${OrangeCardImg});
  background-position: bottom;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    height: 100%;
  }
`;
const StyledSupportBox = styled(StyledBox)`
  height: auto;
  position: relative;
  background: linear-gradient(180deg, #6172f3 0%, #3538cd 100%), url(${PurpleCardImg});
  background-position: bottom right;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    width: 100%;
    height: 100%;
  }
`;
const StyledCreateBox = styled(StyledBox)`
  height: auto;
  position: relative;
  background: linear-gradient(180deg, #fef0c7 0%, #fdb022 100%), url(${YellowCardImg});
  background-position: top right;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    width: 100%;
    height: 100%;
  }
  //@media (max-width: 1024px) {
  //  height: 246px;
  //}
  //@media (max-width: 768px) {
  //  height: 200px;
  //}
`;
const StyledSupportSection = styled.div`
  display: flex;
  gap: 24px;
  flex: 1;
  @media (max-width: 1280px) {
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
  }
  @media (max-width: 1024px) {
    flex-direction: row;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const StyledCryptoBox = styled(StyledBox)`
  height: 292px;
  position: relative;
  background: linear-gradient(180deg, #018f69 0%, #1b6954 100%), url(${GreenCardImg});
  background-position: bottom right;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    height: 446px;
  }
  @media (max-width: 1024px) {
    height: 270px;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
const StyledPlayBox = styled(StyledBox)`
  height: auto;
  align-items: center;
  padding: 32px;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(180deg, #2d3748 0%, #1d2839 100%), url(${NavyCardImg});
  background-position: bottom;
  background-size: cover;
  background-blend-mode: overlay;
  @media (max-width: 1280px) {
    height: 600px;
    flex: 2;
  }
`;
const StyledFeatureSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  @media (max-width: 1280px) {
    gap: 16px;
  }
`;
const StyledFeatureList = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: 1280px) {
    gap: 16px;
  }
`;
const StyledFeatureTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }
`;
const StyledFeatures = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  max-width: 1200px;
  @media (max-width: 1280px) {
    gap: 16px;
    padding: 0 40px;
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
const Content = styled.div`
  width: 100%;
  height: 560px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: max-content;
    height: 400px;
  }
`;
const StyledTabs = styled.div`
  width: 300px;
`;
const StyledLobbyLinks = styled.div`
    display: flex;
    box-sizing: border-box;
    gap: 40px;
    @media (max-width: 1024px) {
        gap: 24px;
        padding: 0 40px;
    }
    @media (max-width: 768px) {
        gap: 32px;
        padding: 0 20px;
        flex-direction: column;
`;
const StyledLobbyList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray900};
  box-sizing: border-box;
  padding: 24px;
  padding-bottom: 0;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;

  @media (max-width: 768px) {
    padding: 24px 0;
  }
`;
const StyledLobbyBanner = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`;

const StyledLobbySection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: start;
`;

const StyledLobbyInner = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 90px;
`;
const StyledLobby = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray800};
  width: 100%;
  box-sizing: border-box;
  padding: 90px 40px 0 40px;
  @media (max-width: 1280px) {
    padding: 80px 40px 0 40px;
  }
  @media (max-width: 1024px) {
    padding: 80px 0 0 0;
  }
`;

const StyledBanner = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1280px) {
    max-width: 100%;
  }
`;
const StyledBannerTitle = styled(Text)<{ type: 'promotion' | 'feature' }>`
  @media (max-width: 1600px) {
    ${({ theme }) => theme.textStyles.DisplayXs_Semibold};
  }
  @media (max-width: 1280px) {
    margin: ${({ type }) => (type === 'promotion' ? '0 40px' : '0')};
    ${({ theme }) => theme.textStyles.TextXl_Semibold};
  }
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
    margin: ${({ type }) => (type === 'promotion' ? '0 20px' : '0')};
  }
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  max-width: 1506px;
  padding: 0 96px;
  box-sizing: border-box;
  @media (max-width: 1600px) {
    padding: 0 40px;
  }

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: start;
    gap: 40px;
  }

  @media (max-width: 768px) {
    gap: 12px;
    padding: 0 20px;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 12px;
`;

const Background = styled.div`
  background: ${({ theme }) => theme.colors.gray900};
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  color: white;
  padding-top: 178px;
  padding-bottom: 454px;
  //padding: 178px 96px 454px 96px;
  gap: 90px;
  box-sizing: border-box;
  min-height: 100vh;
  @media (max-width: 1600px) {
    //padding: 178px 40px 454px 40px;
  }
  @media (max-width: 992px) {
    padding-bottom: 490px;
    //padding: 178px 40px 490px 40px;
  }
  @media (max-width: 768px) {
    padding: 112px 0;
    padding-bottom: 474px;
    box-sizing: border-box;
    gap: 40px;
  }
`;

const StartText = styled(Text)<{
  fontStyle: keyof typeof TextStyles;
}>`
  ${({ fontStyle }) => TextStyles[fontStyle]};

  background: linear-gradient(90deg, #fff 0%, #fff 52.75%, #999 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 1600px) {
    ${({ fontStyle }) => TextStyles['DisplayLg_Semibold']};
  }
  @media (max-width: 768px) {
    text-align: start;
    ${({ fontStyle }) => TextStyles['DisplaySm_Semibold']};
  }
`;

const StartButton = styled(BaseButton)`
  margin-top: 16px;
  @media (max-width: 768px) {
    margin-top: 7px;
    align-self: start;
  }
`;

const ContentContainer = styled.div`
  border-radius: 12px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  color: white;

  width: 750px;
  height: auto;

  @media (max-width: 1600px) {
    width: 560px;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin-top: 12px;
    flex: inherit;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

export default LandingScreen;
