import React from 'react';
import styled from '@emotion/styled';
import { HeaderLogoIcon } from 'src/components/common/icon';
import useDevice from '../../../hooks/useDevice';
import LottieAnimation from './LottieAnimation';

const LoadingPage = () => {
  const { isMobile } = useDevice();

  return (
    <Wrap>
      <HeaderLogoIcon w={isMobile ? 272 : 400} h={isMobile ? 32 : 47} />
      <LottieAnimation />
    </Wrap>
  );
};

const Wrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.black};
  gap: 40px;
  @media (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.gray900};
  }
`;

export default LoadingPage;
