import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Wallet = React.memo<IconProps>(({ w = 20, h = 20, color = 'white' }) => (
  <svg width={w} height={h} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0328 2.5L4.30187 2.5C3.86259 2.49999 3.4836 2.49997 3.17146 2.52548C2.84201 2.55239 2.51433 2.61182 2.19901 2.77249C1.72861 3.01217 1.34615 3.39462 1.10647 3.86503C0.945809 4.18034 0.886379 4.50802 0.859461 4.83748C0.833959 5.14961 0.833971 5.52857 0.833985 5.96784V14.0321C0.833971 14.4714 0.833959 14.8504 0.859461 15.1625C0.886379 15.492 0.945808 15.8197 1.10647 16.135C1.34615 16.6054 1.7286 16.9878 2.19901 17.2275C2.51433 17.3882 2.84201 17.4476 3.17146 17.4745C3.4836 17.5 3.86257 17.5 4.30185 17.5L14.0328 17.5C14.4721 17.5 14.851 17.5 15.1632 17.4745C15.4926 17.4476 15.8203 17.3882 16.1356 17.2275C16.606 16.9878 16.9885 16.6054 17.2282 16.135C17.3888 15.8197 17.4483 15.492 17.4752 15.1625C17.5007 14.8504 17.5007 14.4714 17.5007 14.0321V12.7739C18.3238 12.4831 18.9456 11.7777 19.1193 10.9045C19.1678 10.6604 19.1676 10.3872 19.1674 10.07L19.1673 10.0001L19.1674 9.93017C19.1676 9.61292 19.1678 9.33973 19.1193 9.09568C18.9456 8.22245 18.3238 7.51705 17.5007 7.22629V5.96786C17.5007 5.52858 17.5007 5.14962 17.4752 4.83748C17.4483 4.50803 17.3888 4.18034 17.2282 3.86503C16.9885 3.39462 16.606 3.01217 16.1356 2.77249C15.8203 2.61183 15.4926 2.5524 15.1632 2.52548C14.851 2.49997 14.4721 2.49999 14.0328 2.5ZM14.0048 8.76609C14.065 8.75411 14.1569 8.75007 14.584 8.75007L16.2507 8.75007C16.6778 8.75007 16.7697 8.75411 16.8299 8.76609C17.1605 8.83184 17.4189 9.09026 17.4847 9.42083C17.4966 9.48104 17.5007 9.57292 17.5007 10.0001C17.5007 10.4272 17.4966 10.5191 17.4847 10.5793C17.4189 10.9099 17.1605 11.1683 16.8299 11.2341C16.7697 11.246 16.6778 11.2501 16.2507 11.2501H14.584C14.1569 11.2501 14.065 11.246 14.0048 11.2341C13.6742 11.1683 13.4158 10.9099 13.35 10.5793C13.338 10.5191 13.334 10.4272 13.334 10.0001C13.334 9.57292 13.338 9.48104 13.35 9.42083C13.4158 9.09026 13.6742 8.83184 14.0048 8.76609Z"
      fill={Colors[color]}
    />
  </svg>
));

Wallet.displayName = 'Wallet';
