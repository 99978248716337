import React, { useState, useRef, useCallback } from 'react';
import { logError } from '../utils/ConsoleUtil';

type UseFileUploadProps = {
  onFileChange?: (file: File | undefined) => void;
  onFileSelectError?: (error: string) => void;
};

const useAvatarUpload = ({ onFileChange, onFileSelectError }: UseFileUploadProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const triggerFileSelect = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileSelected = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0];

      if (!selectedFile) {
        onFileSelectError?.('No file selected.');
        return;
      }

      const limitSize = 5 * 1024 * 1024;
      const isInvalidFormat = !['image/png', 'image/jpg', 'image/jpeg'].includes(selectedFile.type);
      const isInvalidSize = selectedFile?.size > limitSize;
      if (isInvalidFormat) {
        onFileSelectError?.('Only JPG/JPEG/PNG is valid.');
        return;
      } else if (isInvalidSize) {
        onFileSelectError?.('Max size for avatar is 5 MB.');
        return;
      } else {
        setFile(selectedFile);
        setFileUrl(URL.createObjectURL(selectedFile));
        onFileChange?.(selectedFile);
      }

      try {
        const resizedFile = await resizeImage(selectedFile);
        setFile(resizedFile);
        setFileUrl(URL.createObjectURL(resizedFile));
        onFileChange?.(resizedFile);
      } catch (error) {
        logError('Image resizing error:', error);
        setFile(selectedFile);
        setFileUrl(URL.createObjectURL(selectedFile));
        onFileChange?.(selectedFile);
      }
    },
    [onFileChange, onFileSelectError]
  );

  const clearFile = useCallback(() => {
    setFile(null);
    setFileUrl(undefined);
    if (fileInputRef.current) fileInputRef.current.value = '';
    onFileChange?.(undefined);
  }, [onFileChange]);

  const resizeImage = useCallback(async (selectedFile: File): Promise<File> => {
    const maxWidth = 800;
    const maxHeight = 800;
    const reader = new FileReader();
    const image = new Image();

    return new Promise((resolve, reject) => {
      reader.onload = readerEvent => {
        image.onload = () => {
          const canvas = document.createElement('canvas');
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(image, 0, 0, width, height);

          canvas.toBlob(blob => {
            if (blob) {
              const resizedFile = new File([blob], selectedFile.name, {
                type: selectedFile.type,
                lastModified: Date.now()
              });
              resolve(resizedFile);
            } else {
              reject(new Error('Canvas toBlob failed'));
            }
          }, selectedFile.type);
        };
        image.src = <string>readerEvent?.target?.result?.toString();
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(selectedFile);
    });
  }, []);

  return {
    file,
    fileUrl,
    fileInputRef,
    triggerFileSelect,
    handleFileSelected,
    clearFile
  };
};

export default useAvatarUpload;
