import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { ArrowLeft, CloseIcon, SuccessIcon } from '../../common/icon';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { dismissModal, ModalType, showModal } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useDevice from '../../../hooks/useDevice';
import LinearLayout from '../../layout/LinearLayout';
import Text from '../../common/text/Text';
import AmountInput from './WithdrawInput';
import BaseButton from '../../common/button/BaseButton';
import { useMyBalance } from '../../../hooks/useMyBalance';
import { AssetBalance, AssetType, FoundUser } from '../../../store/api/responseTypes';
import useAsset from '../../../hooks/useAsset';
import CurrencyView from './CurrencySelect';
import { toFixedFloor } from '../../../utils/StringUtil';
import ImageWithText from '../../common/text/ImageWithText';
import { useRequestSendMutation } from '../../../store/api/assets';
import SearchUserInput from './SearchUserInput';
import { useNavigate } from 'react-router-dom';
import { setActiveTab } from '../../../store/slices/authSlice';
import { useAuthContext } from '../../../hooks/AuthContext';

const SendModal = ({ hasBack = true }: { hasBack?: boolean }) => {
  const { channel } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const closeModal = () => dispatch(dismissModal());
  const backToCashierModal = () => dispatch(showModal(ModalType.CashierModal));

  const { myBalance: data } = useMyBalance();
  const [selectedAssetBalance, setSelectedAssetBalance] = React.useState<AssetBalance | undefined>();
  const { network: assetInfo } = useAsset(selectedAssetBalance?.assetName ?? AssetType.USDT);
  const sendDecimal = assetInfo?.displayDecimals || 2;
  const minSendAmount = assetInfo?.minSendAmount || 0.1;

  const [inputRecipientQuery, setRecipientQuery] = React.useState('');
  const [sendAmount, setSendSendAmount] = React.useState<number>(0);
  const [amountErrorMessage, setAmountErrorMessage] = React.useState<string>('');
  const [selectedUser, setSelectedUser] = React.useState<FoundUser | null>(null);
  const [currentView, setCurrentView] = React.useState<'SendEnter' | 'SendConfirm' | 'SendDone'>('SendEnter');

  const [postRequestSend, { isLoading }] = useRequestSendMutation();

  const handleRecipientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecipientQuery(e.target.value);
  };

  useEffect(() => {
    if (Number(sendAmount) <= 0) {
      setAmountErrorMessage('');
    } else if (Number(selectedAssetBalance?.amount) < Number(minSendAmount) || Number(selectedAssetBalance?.amount) < Number(sendAmount)) {
      return setAmountErrorMessage('Not enough balance');
    } else if (Number(sendAmount) > 0 && Number(sendAmount) < Number(minSendAmount)) {
      return setAmountErrorMessage(`Minimum withdrawal amount is ${toFixedFloor(Number(minSendAmount ?? 0), sendDecimal) ?? '0.00'} USDT`);
    }
    setAmountErrorMessage('');
  }, [selectedAssetBalance, minSendAmount, sendAmount]);

  const handleAmountChange = (amount: string) => {
    setSendSendAmount(Number(amount));
  };

  const requestConfirm = async () => {
    setCurrentView('SendConfirm');
  };

  const requestSend = async () => {
    if (assetInfo?.id === undefined || selectedUser === null) return;
    postRequestSend({ amount: sendAmount, toUserId: selectedUser.userId, assetId: assetInfo?.id })
      .unwrap()
      .then(() => {
        setCurrentView('SendDone');
        channel.postMessage({ type: 'UPDATE_ASSET' });
      });
  };

  const handleBack = () => {
    switch (currentView) {
      case 'SendEnter':
        backToCashierModal();
        break;
      case 'SendConfirm':
        setCurrentView('SendEnter');
        break;
      case 'SendDone':
        setCurrentView('SendConfirm');
        break;
    }
  };

  const goToHistory = () => {
    navigate(`/settings`);
    dispatch(setActiveTab('History'));
    closeModal();
  };

  const getView = () => {
    switch (currentView) {
      case 'SendEnter':
        return (
          <SendEnter
            hasBack={hasBack}
            backClick={handleBack}
            closeClick={closeModal}
            goConfirm={requestConfirm}
            isMobile={isMobile}
            data={data}
            inputRecipientQuery={inputRecipientQuery}
            handleRecipientChange={handleRecipientChange}
            sendAmount={sendAmount}
            selectedTokenBalance={selectedAssetBalance?.amount}
            handleAmountChange={handleAmountChange}
            amountErrorMessage={amountErrorMessage}
            minSendAmount={minSendAmount}
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            setSelectedCurrency={setSelectedAssetBalance}
            assetInfo={assetInfo}
          />
        );
      case 'SendConfirm':
        return <SendConfirm hasBack={hasBack} isSendLoading={isLoading} backClick={handleBack} closeClick={closeModal} confirmClick={requestSend} isMobile={isMobile} user={selectedUser} network={assetInfo} sendAmount={sendAmount} />;
      case 'SendDone':
        return <SendDone closeClick={closeModal} confirmClick={requestConfirm} isMobile={isMobile} viewTransactionClick={goToHistory} doneClick={closeModal} user={selectedUser} network={assetInfo} sendAmount={sendAmount} />;
      default:
        return null;
    }
  };

  return getView();
};

export default SendModal;

const LeftIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ModalWrap = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px 40px;
  border-radius: 12px;
  min-width: 600px;
  max-width: 600px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    min-width: 0;
    gap: 16px;
    padding: 24px 20px;
  }
`;
const Title = styled.h1<{ hasBack: boolean }>`
  ${({ theme }) => theme.textStyles.TextXl_Semibold};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
  flex: 1;
  margin: ${({ hasBack }) => (hasBack ? '0 24px' : '0 24px 0 0')};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
    margin: 0 8px;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const StyledHorizontalLayout = styled(HorizontalLayout)`
  padding: 16px;
  @media (max-width: 768px) {
    padding: 12px 16px;
  }
`;

const StyledAmountText = styled(Text)`
  margin-top: auto;
`;

const StyledResultInfo = styled.div`
  padding: 14px 16px !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.gray750};
  border-radius: 8px;
  @media (max-width: 768px) {
    padding: 14px 16px !important;
  }
`;

const SendEnter = ({ hasBack, backClick, closeClick, goConfirm, isMobile, data, inputRecipientQuery, handleRecipientChange, selectedUser, sendAmount, selectedTokenBalance, handleAmountChange, amountErrorMessage, minSendAmount, setSelectedUser, setSelectedCurrency, assetInfo }: any) => (
  <ModalWrap>
    <HorizontalLayout margin={'0'}>
      {hasBack && (
        <LeftIconDiv>
          <ArrowLeft onClick={backClick} />
        </LeftIconDiv>
      )}
      <Title hasBack={hasBack}>{'Send'}</Title>
      <RightIconDiv>
        <CloseIcon onClick={closeClick} />
      </RightIconDiv>
    </HorizontalLayout>
    <LinearLayout gap={16}>
      <CurrencyView onChange={setSelectedCurrency} balances={data?.balances ?? []} />
      <LinearLayout gap={4}>
        <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} lineHeight={18} textAlign={'start'} textColor={'gray300'} text={'Recipient'} />
        <SearchUserInput onSelect={user => setSelectedUser(user)} selectedUser={selectedUser} />
      </LinearLayout>
      <LinearLayout gap={isMobile ? 2 : 4}>
        <HorizontalLayout justifyContent={'space-between'} margin={'0'}>
          <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={'Amount'} />
          <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'white'} text={`${toFixedFloor(selectedTokenBalance, assetInfo?.displayDecimals)} ${assetInfo?.name}`} />
        </HorizontalLayout>
        <AmountInput
          value={sendAmount}
          placeholder={`${toFixedFloor(0, assetInfo?.displayDecimals)}`}
          asset={assetInfo?.name}
          displayDecimal={assetInfo?.displayDecimals}
          min={Number(toFixedFloor(minSendAmount, assetInfo?.displayDecimals))}
          max={Number(toFixedFloor(selectedTokenBalance, assetInfo?.displayDecimals))}
          suffix={'Min'}
          onValueChange={handleAmountChange}
        />
        {amountErrorMessage && <Text textAlign={'start'} text={amountErrorMessage} fontStyle={'TextSmall_Medium'} textColor={'error500'} />}
      </LinearLayout>
    </LinearLayout>
    <BaseButton size={isMobile ? 'btn40' : 'btn60'} text={'Send'} color={'primary'} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} disabled={!selectedUser || Number(sendAmount) < Number(minSendAmount) || Number(sendAmount) > Number(selectedTokenBalance)} onClick={goConfirm} />
  </ModalWrap>
);

const SendConfirm = ({ hasBack, backClick, closeClick, confirmClick, isMobile, user, network, sendAmount, isSendLoading }: any) => (
  <ModalWrap>
    <HorizontalLayout margin={'0'}>
      {hasBack && (
        <LeftIconDiv>
          <ArrowLeft onClick={backClick} />
        </LeftIconDiv>
      )}
      <Title hasBack={hasBack}>{'Confirm Send'}</Title>
      <RightIconDiv>
        <CloseIcon onClick={closeClick} />
      </RightIconDiv>
    </HorizontalLayout>
    <LinearLayout gap={16}>
      <LinearLayout gap={isMobile ? 2 : 4}>
        <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={'Send to'} />
        <StyledHorizontalLayout backgroundColor={'gray750'} borderRadius={8} margin={'0'}>
          <ImageWithText gap={8} imageUrl={user?.profileImageUrl} imageSize={isMobile ? 40 : 50} value={user?.userName} fontStyle={isMobile ? 'TextLg_Semibold' : 'TextXl_Semibold'} fontColor={'white'} />
        </StyledHorizontalLayout>
      </LinearLayout>
      <LinearLayout gap={isMobile ? 2 : 4}>
        <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={'Amount'} />
        <StyledHorizontalLayout backgroundColor={'gray750'} borderRadius={8} margin={'0'}>
          <ImageWithText gap={8} imageUrl={network?.symbolImage} imageSize={20} value={network?.name} fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} fontColor={'white'} />
          <Text fontStyle={isMobile ? 'TextLg_Semibold' : 'TextXl_Semibold'} textColor={'white'} text={toFixedFloor(sendAmount, network?.displayDecimals) + ` ${network?.name}`} />
        </StyledHorizontalLayout>
        <Text marginTop={16} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textColor={'gray400'} textAlign={'start'} text={'Please ensure the recipient and amount is correct. No refunds supported.'} />
      </LinearLayout>
    </LinearLayout>
    <StyledButtons>
      <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} block={true} size={isMobile ? 'btn40' : 'btn60'} text={'Cancel'} color={'gray'} onClick={closeClick} />
      <BaseButton isLoading={isSendLoading} textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} block={true} size={isMobile ? 'btn40' : 'btn60'} text={'Confirm'} color={'primary'} onClick={confirmClick} />
    </StyledButtons>
  </ModalWrap>
);

const SendDone = ({ isMobile, closeClick, viewTransactionClick, doneClick, user, network, sendAmount }: any) => (
  <ModalWrap>
    <HorizontalLayout margin={'0'}>
      <Title hasBack={false}>{''}</Title>
      <RightIconDiv>
        <CloseIcon onClick={closeClick} />
      </RightIconDiv>
    </HorizontalLayout>
    <LinearLayout alignItem={'center'} gap={8} marginBottom={0}>
      <SuccessIcon w={isMobile ? 80 : 100} h={isMobile ? 80 : 100} />
      <Text fontStyle={isMobile ? 'TextXl_Semibold' : 'DisplayXs_Semibold'} textColor={'white'} text={'Send Successful'} />
    </LinearLayout>
    <HorizontalLayout margin={'0'} justifyContent={'center'} gap={8}>
      <StyledAmountText lineHeight={isMobile ? 30 : 36} fontStyle={isMobile ? 'DisplaySm_Semibold' : 'DisplayMd_Semibold'} textAlign={'end'} textColor={'white'} text={toFixedFloor(sendAmount, network?.displayDecimals)} />
      <StyledAmountText fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} textColor={'white'} text={network?.name} />
    </HorizontalLayout>
    <StyledResultInfo>
      <HorizontalLayout margin={'0'}>
        <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={'To'} />
        <ImageWithText gap={8} imageUrl={user?.profileImageUrl} imageSize={24} value={user?.userName} fontStyle={'TextMd_Medium'} fontColor={'white'} />
      </HorizontalLayout>
      <HorizontalLayout margin={'0'}>
        <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={'Amount'} />
        <Text fontStyle={'TextMd_Medium'} textColor={'white'} text={toFixedFloor(sendAmount, network?.displayDecimals) + ` ${network?.name}`} />
      </HorizontalLayout>
    </StyledResultInfo>
    <StyledButtons>
      <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} block={true} size={isMobile ? 'btn40' : 'btn60'} text={'View transaction'} color={'gray'} onClick={viewTransactionClick} />
      <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} block={true} size={isMobile ? 'btn40' : 'btn60'} text={'Done'} color={'primary'} onClick={doneClick} />
    </StyledButtons>
  </ModalWrap>
);
