import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Heart = React.memo<IconProps>(({ w = 40, h = 40, color = 'error600' }) => (
  <svg width={w} height={h} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.32229 21.532C-1.10743 16.6212 -1.10743 8.6044 3.32229 3.69359C5.4425 1.30115 8.31993 0 11.3488 0C14.3777 0 17.2551 1.30115 19.3753 3.69359L19.9811 4.36515L20.5868 3.69359C22.7449 1.30115 25.5845 0 28.6512 0C31.6801 0 34.5196 1.30115 36.6777 3.69359C41.1074 8.6044 41.1074 16.6212 36.6777 21.532L19.9811 40L3.32229 21.532Z"
      fill={Colors[color]}
    />
  </svg>
));

Heart.displayName = 'Heart';
