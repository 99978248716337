import React from 'react';
import CheckButton from './CheckButton';

import { PreActionsProps } from './types';

const PreActions = ({ isMobile, getActions, getActionText, handleSelect, selectedAction }: PreActionsProps) => {
  return (
    <>
      {getActions().map((action, i) => (
        <CheckButton key={i} isMobile={isMobile} size={isMobile ? 'btn48' : 'btn60'} type={isMobile ? 'bar' : 'checkbox'} isChecked={selectedAction === action} color="gray" textColor="white"
                     textStyle="TextMd_Bold" text={getActionText(action)} onClick={() => handleSelect(action)} />
      ))}
    </>
  );
};

export default PreActions;
