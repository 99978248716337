import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Spade = React.memo<IconProps>(({ w = 40, h = 40, color = 'gray900' }) => (
  <svg width={w} height={h} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.866 33.5031C31.8275 33.5031 34.6392 32.4144 36.7385 30.4478C38.8379 28.446 40 25.8472 40 23.0729C40 20.2634 38.8379 17.6646 36.7385 15.698L19.9812 0L3.26148 15.698C1.16214 17.6646 0 20.2634 0 23.0729C0 25.8472 1.16214 28.446 3.26148 30.4478C5.36083 32.4144 8.13496 33.5031 11.134 33.5031C13.7207 33.5031 16.12 32.6954 18.1443 31.1853L19.119 30.4126L18.9691 32.2388C18.9691 32.2622 18.9691 32.2856 18.9691 32.3091C18.5567 35.9614 15.2952 38.7006 11.3589 38.7006H10.8716V40H29.0909V38.7006H28.6035C24.7048 38.7006 21.4433 35.9614 20.9934 32.3091C20.9934 32.2856 20.9934 32.2622 20.9934 32.2388L20.8435 30.5531V30.4126L21.8557 31.1501C23.8425 32.6953 26.2793 33.5031 28.866 33.5031Z"
      fill={Colors[color]}
    />
  </svg>
));

Spade.displayName = 'Spade';
