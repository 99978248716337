import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const CashierDeposit = React.memo<IconProps>(({ w = 18, h = 18, color = 'white' }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12ZM12.5 4C8.08172 4 4.5 7.58172 4.5 12C4.5 16.4183 8.08172 20 12.5 20C16.9183 20 20.5 16.4183 20.5 12C20.5 7.58172 16.9183 4 12.5 4ZM12.5 7C13.0523 7 13.5 7.44772 13.5 8V13.5858L14.7929 12.2929C15.1834 11.9024 15.8166 11.9024 16.2071 12.2929C16.5976 12.6834 16.5976 13.3166 16.2071 13.7071L13.2071 16.7071C12.8166 17.0976 12.1834 17.0976 11.7929 16.7071L8.79289 13.7071C8.40237 13.3166 8.40237 12.6834 8.79289 12.2929C9.18342 11.9024 9.81658 11.9024 10.2071 12.2929L11.5 13.5858V8C11.5 7.44772 11.9477 7 12.5 7Z"
      fill="#D1FADF"
    />
  </svg>
));

CashierDeposit.displayName = 'CashierDeposit';
