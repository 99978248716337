import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useUpdateProfileMutation } from '../../../../store/api/user';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { ShieldIcon } from '../../../common/icon';
import { showSuccessToast } from '../../../common/toast/BpToast';
import Text from '../../../common/text/Text';
import useDevice from '../../../../hooks/useDevice';
import { ReactComponent as HelpCircle } from '../../../../assets/img/help-circle.svg';
import HorizontalLayout from '../../../layout/HorizontalLayout';
import { logWarn } from '../../../../utils/ConsoleUtil';

const SeedPhrase = () => {
  const { seed: savedSeed, isGameSeedAutoGenerated } = useUserProfile();
  const [updateSeed] = useUpdateProfileMutation();
  const [seed, setSeed] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const { isMobile } = useDevice();

  useEffect(() => {
    setSeed(savedSeed ?? '');
  }, [savedSeed, isGameSeedAutoGenerated]);

  // 입력이 멈춘 후 1초가 지난 후 updateSeed
  useEffect(() => {
    if (isTyping) {
      const timer = setTimeout(() => {
        if (savedSeed !== seed) {
          updateSeed({ gameSeed: seed.trimStart().trimEnd() }).then(() => {
            if (seed.trimStart().trimEnd().length > 0) {
              showSuccessToast('Player Seed Updated');
            } else {
              showSuccessToast('Random Seed Used');
            }
          });
        }
        setIsTyping(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [seed, isTyping, updateSeed]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let inputValue = event.target.value;

    if (inputValue.length > 100) {
      logWarn('Input exceeded 100 characters');
      inputValue = inputValue.slice(0, 100);
    }

    // 유효한 문자만 남기기
    const validInput = inputValue.replace(/[^a-zA-Z0-9 ]/g, '');

    setIsTyping(true);

    // 유효한 입력 값인지 확인하고 seed에 설정
    setSeed(validInput);
  }, []);

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Background>
      {!isMobile && (
        <HorizontalLayout margin={'0'} justifyContent={'start'} gap={4}>
          <Text fontStyle={'TextLg_Bold'} textAlign={'start'} text={'Player Seed'} />
          <StyledToolTip>
            <HelpCircle onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />
            {showTooltip && <Tooltip>The Player Seed is a unique identifier assigned to each player upon registration, but can be edited by the player at any time. The Player Seed serves as an input in the card distribution algorithm, ensuring randomness and integrity in every hand dealt.</Tooltip>}
          </StyledToolTip>
        </HorizontalLayout>
      )}
      <InputBackground>
        <StyledTextArea isTyping={isTyping} hasText={seed.length > 0} placeholder="Type your seed phrase here!" value={seed} onChange={handleChange} />
        <IconWrap>
          <ShieldIcon w={24} h={24} color={seed.length > 0 ? 'success500' : 'gray500'} subColor="gray600_30" />
        </IconWrap>
      </InputBackground>
    </Background>
  );
};

const StyledToolTip = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Tooltip = styled.div`
  position: absolute;
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.gray750};
  background-color: ${({ theme }) => theme.colors.white};
  text-align: start;
  border-radius: 8px;
  margin-left: 8px;
  padding: 12px;
  z-index: 10;
  bottom: 180%;
  left: 250%;
  transform: translateX(-50%);
  width: 280px;
  @media (max-width: 768px) {
    top: 160px;
  }
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 8px;
  height: 160px;
  width: 100%;
  position: relative;
  //vertical-align: center;
`;

const InputBackground = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray600_30};
  border-radius: 8px;
  height: 160px;

  @media (max-width: 768px) {
    width: 100%;
    background-color: rgba(71, 84, 103, 0.3);
  }
`;

const StyledTextArea = styled.textarea<{ hasText: boolean; isTyping: boolean }>`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  box-sizing: border-box;
  background: transparent;
  height: 100%;
  color: ${({ isTyping, hasText, theme }) => (isTyping ? theme.colors.white : hasText ? theme.colors.success500 : theme.colors.white)};
  margin: 16px 16px 0 16px;
  resize: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

const IconWrap = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  height: 48px;
  margin: 0 16px 16px 16px;
`;

export default SeedPhrase;
