import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTableList, selectTablePacket } from '../store/slices/streaming';
import { RootState } from '../store/store';
import { TableInfo } from '../store/api/responseTypes';
import { EventHeader, EventName, EventPayload, TableSnapshot, TableUpdate } from '../store/slices/streamingTypes';
import { useTableId } from './useTableId';
import { selectTournamentTablePacket } from '../store/slices/tournamentSlice';

interface UseTablePacketReturn {
  tableId: number | undefined;
  name: EventName;
  header: EventHeader;
  payload: EventPayload;
  tableSnapshot: TableSnapshot;
  update: TableUpdate;
}

export const useTablePacket = (id?: number): UseTablePacketReturn => {
  const tournamentMatch = location.pathname.match(/\/tournament\/([^/]+)/);
  const tournamentId = tournamentMatch ? tournamentMatch[1] : null;
  const shareCodeMatch = location.pathname.match(/\/table\/([^/]+)/);
  const shareCode = shareCodeMatch ? shareCodeMatch[1] : null;

  const tableId = useTableId(id);
  const tablePacket = tournamentId !== null && shareCode !== null ? useSelector((state: RootState) => selectTournamentTablePacket(state, shareCode)) : useSelector((state: RootState) => selectTablePacket(state, Number(tableId)));

  const { name, header, payload } = tablePacket;
  const tableSnapshot = payload?.snapshot || [];
  const update = payload?.update || {};

  return useMemo(
    () => ({
      tableId,
      name,
      header,
      payload,
      tableSnapshot,
      update
    }),
    [tableId, name, header, payload, tableSnapshot, update]
  );
};

export const useTableList = (): TableInfo[] => {
  const tableList = useSelector(selectTableList);
  return useMemo(() => {
    return tableList;
  }, [tableList]);
};
