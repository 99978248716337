export const logDebug = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
};

export const logWarn = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(...args);
  }
};

export const logError = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(...args);
  }
};
export const groupLog = (title: string, ...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.group(title);
    console.log(...args);
    console.groupEnd();
  }
};
