import { useSelector } from 'react-redux';
import { selectTableChatByTableId } from '../store/slices/streaming';
import { RootState } from '../store/store';
import { ChatEventPacket, HeaderAction } from '../store/slices/streamingTypes';
import { useTableId } from './useTableId';
import { useMemo } from 'react';

export const useChats = () => {
  const tableId = useTableId();
  const chatEvents = useSelector((state: RootState) => selectTableChatByTableId(state, Number(tableId)));

  const { chats, emojis } = useMemo(() => {
    const chats: ChatEventPacket[] = [];
    const emojis: ChatEventPacket[] = [];

    chatEvents.forEach((event: ChatEventPacket) => {
      switch (event.header.action) {
        case HeaderAction.CHAT_MESSAGE:
          chats.push(event);
          break;
        case HeaderAction.CHAT_EMOJI:
          emojis.push(event);
          break;
        default:
          break;
      }
    });

    return { chats, emojis };
  }, [chatEvents]);

  return { chatEvents, chats, emojis };
};
