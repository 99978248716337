import React from 'react';
import { Colors } from '../../../../styles/Colors';
import { TimerContainer, TimerProgress } from './styles';


interface Props {
  progress?: number;
  animate: boolean;
  color: keyof typeof Colors;
}

const Timer = ({ progress = 100, color, animate }: Props) => {
  return (
    <TimerContainer>
      <TimerProgress timerBg={color} width={progress} animate={animate} />
    </TimerContainer>
  );
};


export default Timer;
