import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const CashierWithdraw = React.memo<IconProps>(({ w = 18, h = 18, color = 'white' }) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12ZM12.5 4C8.08172 4 4.5 7.58172 4.5 12C4.5 16.4183 8.08172 20 12.5 20C16.9183 20 20.5 16.4183 20.5 12C20.5 7.58172 16.9183 4 12.5 4ZM8.79289 10.2929L11.7929 7.29289C12.1834 6.90237 12.8166 6.90237 13.2071 7.29289L16.2071 10.2929C16.5976 10.6834 16.5976 11.3166 16.2071 11.7071C15.8166 12.0976 15.1834 12.0976 14.7929 11.7071L13.5 10.4142V16C13.5 16.5523 13.0523 17 12.5 17C11.9477 17 11.5 16.5523 11.5 16V10.4142L10.2071 11.7071C9.81658 12.0976 9.18342 12.0976 8.79289 11.7071C8.40237 11.3166 8.40237 10.6834 8.79289 10.2929Z"
      fill="#D0D5DD"
    />
  </svg>
));

CashierWithdraw.displayName = 'CashierWithdraw';
