import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import Card from 'src/components/common/card';

export const StyledChipWrap = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const TableImg = styled.img<{ isMobile?: boolean }>`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: -1;
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      max-width: 100%;
      aspect-ratio: 1/1.61;
      margin-left: 0%;
      max-height: 100%;
      height: auto;
      margin-top: 20px;
    `}
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

export const StyledPotArea = styled.div`
  width: 160%;
  position: absolute;
  right: 50%;
  transform: translateX(50%);

  margin-top: 10px;

  max-width: 160%;
  @media (max-width: 768px) {
    margin-top: 8px;
    gap: 12px;
    max-width: 218px;
  }
`;

export const CombinationText = styled.div`
  position: absolute;
  width: 100%;
  bottom: 100%;
  margin-bottom: 8px;
  height: 1.25em;
  ${({ theme }) => theme.textStyles.TextSmall_Medium}
  color: ${({ theme }) => theme.colors.gray300};
  font-size: 0.88em;
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 4px;
    font-size: 11px;
    height: 18px;
  }
`;

export const CenterArea = styled.div`
  position: relative;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 70px;
  }
`;

export const CommunityCardsWrap = styled.div`
  position: relative;
  min-height: 6.5em;
  width: 25.125em;
  display: flex;
  gap: 8px;
  @media (max-width: 768px) {
    font-size: 10px;
    gap: 4px;
    height: 62px;
    width: 246px;
  }
`;

export const TotalPot = styled.div`
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  margin-bottom: 0.75em;
  flex-direction: column;
  align-items: center;
  min-height: 2.5em;
  gap: 0.125em;
  ${({ theme }) => theme.textStyles.TextSmall_Semibold}
  font-size: 1em;
  line-height: 1em;
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    margin-bottom: 22px;
    font-size: 12px;
    line-height: 12px;
    min-height: 30px;
  }
`;

export const Pot = styled.div`
  padding: 0.1875em 0.375em;
  border-radius: 100px;
  background: rgba(29, 41, 57, 0.4);
  color: ${({ theme }) => theme.colors.warning300};
`;

export const PlayingArea = styled.div`
  position: absolute;
  z-index: 0;
  width: 60%;
  height: 50%;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  /* padding-bottom: 10%; */
  @media (max-width: 768px) {
    padding-bottom: 35%;
    width: 100%;
    height: 50%;
  }
`;

export const StyledTableWrap = styled.div<{ tableFontSize: number }>`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  font-size: ${({ tableFontSize }) => tableFontSize}px;
  @media (max-width: 768px) {
    z-index: 0;
    font-size: 8.5px;
    max-height: 100%;
    touch-action: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* Microsoft Edge */
  user-select: none;
`;

export const StyledTableLogo = styled.div`
  position: absolute;
  gap: 0.69em;
  display: flex;
  align-items: center;
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);

  opacity: 0.1;

  svg {
    height: 2.66em;
    width: 100%;
    @media (max-width: 768px) {
      height: 28px;
    }
  }

  @media (max-width: 768px) {
    top: 40%;
    transform: translate(-50%, -50%) perspective(800px) rotateX(55deg) scaleY(1.5); /* 카드를 앞으로 기울임 */
    transform-origin: bottom; /* 회전의 기준점을 아래쪽으로 설정 */
    flex-direction: column;
  }
`;
export const StyledTableText = styled.div`
  font-family: Yellix-Bold, Lunasima-Bold;
  font-style: normal;
  font-size: 3.31em;
  font-weight: bolder;
  color: white;
  white-space: nowrap;
  @media (max-width: 768px) {
    font-size: 4em;
  }
`;
export const TableArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  padding: 1.56em 1.56em 2.5em 1.56em;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 40px 40px 80px 40px;
  }
`;

export const StyledArea = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const StyledTable = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  img {
    filter: drop-shadow(0px 29px 44px rgba(0, 0, 0, 0.81));
  }

  @media (max-width: 768px) {
    max-height: 100%;
    height: auto;
    display: flex;
  }
`;
