import { useSelector } from 'react-redux';
import { selectTableIdByShareCode } from '../store/slices/streaming';
import { RootState } from '../store/store';
import { selectTournamentTableIdByShareCode } from '../store/slices/tournamentSlice';

export const useTableId = (id?: number): number | undefined => {
  if (id !== undefined) {
    return id;
  }
  const isInTable = window.location.pathname.split('/').some(p => p === 'table');
  const isInTournament = location.pathname.split('/').some(p => p === 'tournament');
  const pathList = window.location.pathname.split('/');
  const idOrShareCode = pathList[pathList.length - 1];
  const isNumber = !isNaN(Number(idOrShareCode));
  if (isInTable) {
    if (isInTournament) {
      if (isNumber) {
        return Number(idOrShareCode);
      }
      return useSelector((state: RootState) => selectTournamentTableIdByShareCode(state, idOrShareCode));
    } else {
      if (isNumber) {
        return Number(idOrShareCode);
      }
      return useSelector((state: RootState) => selectTableIdByShareCode(state, idOrShareCode));
    }
  } else {
    return undefined;
  }
};
