import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import Header from '../template/header';
import BasePokerModal from '../template/modal/BasePokerModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import useAnalytics from '../../hooks/useAnalytics';
import useSocket from '../../hooks/useSocket';

const DefaultLayout = () => {
  const location = useLocation();
  const isInTable = location.pathname.split('/').some(p => p === 'table');
  useAnalytics();
  useSocket();
  return (
    <Wrap isInTable={isInTable}>
      <Header />
      <Outlet />
      <BasePokerModal />
      <ToastContainer className={'toastContainerClass'} toastClassName={'toastClass'} position="top-right" hideProgressBar={true} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
    </Wrap>
  );
};

const Wrap = styled.div<{ isInTable?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: ${({ isInTable }) => (isInTable ? '100%' : 'max-content')};
  height: ${({ isInTable }) => (isInTable ? '100%' : 'max-content')};
  overflow: scroll;

  .toastContainerClass {
    margin: 55px 0;
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

    @media (max-width: 768px) {
      margin: 16px -20px;
    }
  }

  .toastClass {
    ${({ theme }) => theme.textStyles.TextMd_Medium};
    color: ${({ theme }) => theme.colors.black};
    border-radius: 8px;
    margin: 12px 0 0 0;
    width: fit-content;
    min-height: 48px;
    @media (max-width: 768px) {
      ${({ theme }) => theme.textStyles.TextSmall_Medium};
      max-width: 80%;
      margin: 4px 0 0 0;
      min-height: 40px;
    }
  }
`;

export default DefaultLayout;
