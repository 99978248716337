import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Check = React.memo<IconProps>(({ w = 14, h = 14, color = 'white' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6654 3.5L5.2487 9.91667L2.33203 7" stroke={Colors[color]} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
));

Check.displayName = 'Check';
