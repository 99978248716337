import styled from '@emotion/styled';
import React from 'react';
import { BookmarkIcon, MessageTextIcon, ShieldIcon } from 'src/components/common/icon';
import { ChevronDown } from 'src/components/common/icon/icons/ChevronDown';
import useDevice from 'src/hooks/useDevice';
import EmojiBtn from './EmojiBtn';
import { css } from '@emotion/react';
import { SupportChatTab } from './index';
import { ModalType, showModal } from '../../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { useTableContext } from '../../../../hooks/TableProvider';

interface Props {
  useChat: boolean;
  selectedTab: SupportChatTab;
  setSelectedTab: React.Dispatch<React.SetStateAction<SupportChatTab>>;
}

const ChatNav = ({ useChat, selectedTab, setSelectedTab }: Props) => {
  const dispatch = useDispatch();
  const { user } = useUserProfile();
  const { isMobile } = useDevice();
  const { isChatEnable } = useTableContext();
  const isHide = selectedTab === 'Hide';
  const firstTab = useChat ? 'Chat' : 'Note';
  const navList = [
    ...(useChat
      ? [
          {
            id: 'Chat',
            icon: MessageTextIcon
          }
        ]
      : []),
    ...(user
      ? [
          {
            id: 'Note',
            icon: BookmarkIcon
          },
          {
            id: 'Seed',
            icon: ShieldIcon
          }
        ]
      : [])
  ];

  return (
    <Wrap>
      <Nav position="left">
        {isMobile && isChatEnable && (
          <IconWrap
            onClick={() => {
              setSelectedTab('Seed');
              dispatch(showModal(ModalType.SeedModal));
            }}
          >
            <ShieldIcon w={24} h={24} color="gray500" subColor={'gray600_30'} />
          </IconWrap>
        )}
        {!isMobile &&
          navList.map((nav, i) => {
            return (
              <IconWrap
                key={'navIcon-' + nav.id}
                onClick={() => {
                  setSelectedTab(nav.id as SupportChatTab);
                }}
              >
                <nav.icon w={24} h={24} color={nav.id === selectedTab ? 'white' : 'gray500'} subColor={'gray600_30'} />
              </IconWrap>
            );
          })}
        {!isMobile && (
          <ChevronWrapper isSelected={isHide} onClick={() => setSelectedTab(isHide ? firstTab : 'Hide')}>
            <ChevronDown color={isHide ? 'white' : 'gray500'} />
          </ChevronWrapper>
        )}
      </Nav>
      {isMobile && isChatEnable && (
        <Nav position="right">
          <IconWrap
            onClick={() => {
              setSelectedTab('Chat');
              dispatch(showModal(ModalType.ChatModal));
            }}
          >
            <MessageTextIcon />
          </IconWrap>
          <EmojiBtn />
        </Nav>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  align-self: end;
  @media (max-width: 768px) {
    justify-content: space-between;
    margin-bottom: 12px;
    display: flex;
    height: 100%;
    align-self: normal;
  }
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  cursor: pointer;
`;

const Nav = styled.div<{ position: 'right' | 'left' }>`
  background-color: ${({ theme }) => theme.colors.gray600_30};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 8px;
  @media (max-width: 768px) {
    flex-direction: row;
    ${({ position }) =>
      position === 'left'
        ? css`
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          `
        : css`
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          `}

    gap: 16px;
  }
`;

const ChevronWrapper = styled(IconWrap)<{ isSelected: boolean }>`
  display: inline-flex;
  transition: transform 0.1s ease-in-out;
  transform: ${({ isSelected }) => (isSelected ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export default ChatNav;
