import { ApiResponse, basePokerApi, createQueryParams, handleTransformErrorResponse, handleTransformResponse } from './api';
import { CheckUsernameRequestDto, FoundUser, KycInfo, ProfilePresetSelectRequestDto, SearchUserRequestDto, UpdateUserProfileDto, User, UserNote, UserNoteRequestDto } from './responseTypes';
import { TransactionListRequestDto, WalletTransaction, WalletTransactionResponse } from './dto';

export const userApi = basePokerApi.injectEndpoints({
  endpoints: build => ({
    getUserProfile: build.query<ApiResponse<User>, void>({
      query: () => `user/me`,
      providesTags: (result, error, arg, meta) => ['User'],
      transformResponse: handleTransformResponse('getProfile'),
      transformErrorResponse: handleTransformErrorResponse('getProfile')
    }),
    updateProfile: build.mutation<ApiResponse<User>, UpdateUserProfileDto>({
      query: (updateUserProfileDto: UpdateUserProfileDto) => ({
        url: 'user/me',
        method: 'POST',
        body: updateUserProfileDto
      }),
      transformResponse: handleTransformResponse('updateProfile'),
      transformErrorResponse: handleTransformErrorResponse('updateProfile')
    }),
    updateKyc: build.mutation<ApiResponse<KycInfo>, KycInfo>({
      query: (kyc: KycInfo) => ({
        url: 'user/update/kyc',
        method: 'POST',
        body: kyc
      }),
      invalidatesTags: ['User'],
      transformResponse: handleTransformResponse('updateKyc'),
      transformErrorResponse: handleTransformErrorResponse('updateKyc')
    }),
    updateProfileImage: build.mutation<ApiResponse<boolean>, ProfilePresetSelectRequestDto>({
      query: (profilePresetSelectRequestDto: ProfilePresetSelectRequestDto) => ({
        url: 'user/profile/preset',
        method: 'POST',
        body: profilePresetSelectRequestDto
      }),
      invalidatesTags: ['User'],
      transformResponse: handleTransformResponse('presetProfileImage'),
      transformErrorResponse: handleTransformErrorResponse('presetProfileImage')
    }),
    uploadProfileImage: build.mutation<ApiResponse<boolean>, File>({
      query: (file: File) => {
        const formData = new FormData();
        formData.append('image', file);
        return {
          url: 'user/profile/upload',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['User'],
      transformResponse: handleTransformResponse('uploadProfileImage'),
      transformErrorResponse: handleTransformErrorResponse('uploadProfileImage')
    }),
    getUserNote: build.query<ApiResponse<UserNote>, UserNoteRequestDto>({
      query: (userNoteRequestDto: UserNoteRequestDto) => ({
        url: `user/note?noteUserId=${userNoteRequestDto.noteUserId}`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse('getUserNote'),
      transformErrorResponse: handleTransformErrorResponse('getUserNote'),
      providesTags: (result, error, arg) => [{ type: 'UserNote', id: arg.noteUserId }]
    }),
    updateUserNote: build.mutation<ApiResponse<UserNote>, UserNote>({
      query: (userNote: UserNote) => ({
        url: 'user/note',
        method: 'POST',
        body: userNote
      }),
      invalidatesTags: (result, error: any, arg: UserNote) => [{ type: 'UserNote', id: arg?.noteUserId }],
      transformResponse: handleTransformResponse('updateUserNote'),
      transformErrorResponse: handleTransformErrorResponse('updateUserNote')
    }),
    checkValidUsername: build.query<ApiResponse<boolean>, CheckUsernameRequestDto>({
      query: (checkUsernameRequestDto: CheckUsernameRequestDto) => ({
        url: `user/isValidUserName?name=${checkUsernameRequestDto.name}`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse('checkValidUsername'),
      transformErrorResponse: handleTransformErrorResponse('checkValidUsername')
    }),
    transactionList: build.query<ApiResponse<WalletTransactionResponse>, TransactionListRequestDto>({
      query: (transactionListRequestDto: TransactionListRequestDto) => ({
        url: `asset/transactions?${createQueryParams(transactionListRequestDto)}`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse(`asset/transactions`),
      transformErrorResponse: handleTransformErrorResponse('asset/transactions'),
      providesTags: (result, error, page) =>
        result
          ? [...result.data.transactions.map(({ id }) => ({ type: 'User' as const, id })), { type: 'User', id: 'PARTIAL-LIST' }]
          : [
              {
                type: 'User',
                id: 'PARTIAL-LIST'
              }
            ]
    }),
    searchRecipientUser: build.query<ApiResponse<FoundUser[]>, SearchUserRequestDto>({
      query: (searchUserRequestDto: SearchUserRequestDto) => ({
        url: `user/search_user?${createQueryParams(searchUserRequestDto)}`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse('searchRecipientUser'),
      transformErrorResponse: handleTransformErrorResponse('searchRecipientUser'),
      providesTags: (result, error, page) =>
        result
          ? [...result.data.map(({ userId }) => ({ type: 'FoundUser' as const, userId })), { type: 'FoundUser', id: 'PARTIAL-LIST' }]
          : [
              {
                type: 'FoundUser',
                id: 'PARTIAL-LIST'
              }
            ]
    })
  })
});

export const {
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useLazyCheckValidUsernameQuery,
  useUpdateProfileMutation,
  useUpdateProfileImageMutation,
  useUploadProfileImageMutation,
  useGetUserNoteQuery,
  useUpdateUserNoteMutation,
  useLazyTransactionListQuery,
  useUpdateKycMutation,
  useLazySearchRecipientUserQuery
} = userApi;
