import React, { useState } from 'react';
import styled from '@emotion/styled';
import useDevice from '../../../hooks/useDevice';
import { ChevronDown } from '../../common/icon/icons/ChevronDown';
import { css } from '@emotion/react';
import { PolicyPages, SettingPages, supportPolicyPages, supportSettingPages } from '../../../store/slices/authSlice';

type SideTabProps = {
  onTabClick: (tabName: PolicyPages) => void;
  activeTab: string;
};

const PolicyTab: React.FC<SideTabProps> = ({ onTabClick, activeTab }) => {
  const { isMobile } = useDevice();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <SideBarWrap>
      {isMobile ? (
        <HoverContainer onClick={handleDropdownToggle}>
          <TitleBar>
            <Title>{activeTab}</Title>
            <ChevronDown color={'gray400'} w={20} h={20} />
          </TitleBar>
          <DropdownMenu isVisible={isDropdownOpen}>
            {supportPolicyPages.map((tabName, i) => (
              <SideButton
                key={tabName + i}
                onClick={() => {
                  onTabClick(tabName);
                  setIsDropdownOpen(false);
                }}
                className={activeTab === tabName ? 'active' : ''}
              >
                {tabName}
              </SideButton>
            ))}
          </DropdownMenu>
        </HoverContainer>
      ) : (
        supportPolicyPages.map((tabName, i) => (
          <SideButton key={tabName + i} onClick={() => onTabClick(tabName)} className={activeTab === tabName ? 'active' : ''}>
            {tabName}
          </SideButton>
        ))
      )}
    </SideBarWrap>
  );
};

const HoverContainer = styled.div`
  position: relative;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  padding: 12px 20px;
  background: ${({ theme }) => theme.colors.gray800};
  border: none; // Remove borders
  border-radius: 12px;
  outline: none; // Remove outline
  @media (max-width: 768px) {
    padding: 8px 16px;
    border-radius: 8px;
    gap: 8px;
  }
`;

const Title = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  }
`;

const DropdownMenu = styled.div<{ isVisible: boolean }>`
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray700};
  border-radius: 12px;
  margin-top: 8px;
  visibility: hidden;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  top: 100%;
  left: 0;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
  z-index: 15;

  &:hover {
    cursor: pointer;
  }

  align-items: start;
  ${props =>
    props.isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `};
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

export const SideBarWrap = styled.aside`
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  min-width: 200px;
  background-color: ${({ theme }) => theme.colors.gray800};
  border-radius: 12px;
  padding: 12px;
  position: sticky;
  margin-top: 158px;
  top: 80px;

  @media (max-width: 768px) {
    padding: 0;
    top: 60px;
    min-width: 160px;
    margin-top: 60px;
  }
`;

export const SideButton = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  width: 100%; // Make button full width
  max-height: 40px;
  padding: 20px; // Add padding for spacing
  text-align: left; // Align the text to the left horizontally
  display: flex; // Set display to flex to use flexbox properties
  align-items: center; // Center content vertically
  justify-content: flex-start; // Align content to the left
  background: transparent; // No background to buttons
  border: none; // Remove borders
  border-radius: 6px;
  outline: none; // Remove outline
  cursor: pointer; // Change cursor to indicate clickability

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray700};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.gray600};
    color: ${({ theme }) => theme.colors.white};
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

export default PolicyTab;
