import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animation/loading.json';
import spinnerData from '../../../assets/animation/spinner.json';

type LottieProps = {
  height?: number;
  width?: number;
  type?: 'loading' | 'spinner';
};

const LottieAnimation: React.FC<LottieProps> = ({ height = 60, width = 60, type = 'loading' }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: type === 'loading' ? animationData : spinnerData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie options={defaultOptions} height={height} width={width} />;
};

export default LottieAnimation;
