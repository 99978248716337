import React from 'react';
import { ClickableIconProps, IconProps } from '../types';

export const CalendarRight = React.memo<ClickableIconProps>(({ w = 24, h = 24, color = 'white', onClick }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.4114 3.57745C9.73683 3.25201 10.2645 3.25201 10.5899 3.57745L16.4232 9.41079C16.7487 9.73622 16.7487 10.2639 16.4232 10.5893L10.5899 16.4226C10.2645 16.7481 9.73683 16.7481 9.4114 16.4226C9.08596 16.0972 9.08596 15.5696 9.4114 15.2441L13.8221 10.8334H4.16732C3.70708 10.8334 3.33398 10.4603 3.33398 10C3.33398 9.5398 3.70708 9.16671 4.16732 9.16671H13.8221L9.4114 4.75596C9.08596 4.43053 9.08596 3.90289 9.4114 3.57745Z"
      fill="#101828"
    />
  </svg>
));

CalendarRight.displayName = 'CalendarRight';
