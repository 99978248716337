import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import { useDispatch } from 'react-redux';
import { dismissModal } from '../../../store/slices/modalSlice';
import { useUserProfile } from '../../../hooks/useUserProfile';
import LinearLayout from '../../layout/LinearLayout';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import BaseButton from '../../common/button/BaseButton';
import useDevice from '../../../hooks/useDevice';
import TrophyImg from 'src/assets/img/Trophy.png';
import { css } from '@emotion/react';

const TournamentResultModal = () => {
  const dispatch = useDispatch();
  const { user } = useUserProfile();
  const [clientWidth, setClientWidth] = useState(0);
  const ref = useRef(null);
  const { isMobile } = useDevice();
  const closeModal = () => {
    dispatch(dismissModal());
  };

  const userCount = 13;

  const onSlide = useCallback(
    (splide: any, prev: HTMLButtonElement, next: HTMLButtonElement, prevIndex: number, nextIndex: number) => {
      const { clientWidth: width } = splide.root;

      if (width < clientWidth) {
        prev.className += ' none';
        next.className += ' none';
      } else {
        let listWith = clientWidth - (isMobile ? 32 : 48);
        const canShowCount = Math.floor(listWith / 82);

        if (prevIndex < 0) {
          if (!prev.classList.contains('none')) {
            prev.className += ' none';
          }
        } else {
          prev.classList.remove('none');
        }
        if (userCount - canShowCount <= nextIndex - 1 || nextIndex === -1) {
          if (!next.classList.contains('none')) {
            next.className += ' none';
          }
          splide.Components.Controller.go(userCount - canShowCount);
        } else {
          next.classList.remove('none');
        }
      }
    },
    [clientWidth]
  );

  useEffect(() => {
    if (ref.current) {
      setClientWidth((ref.current as any)?.clientWidth);
    }
  }, [ref.current]);

  return (
    <ModalWrap isWin={true}>
      <HorizontalLayout justifyContent={'end'} margin={'0'}>
        <RightIconDiv>
          <XCloseIcon onClick={closeModal} />
        </RightIconDiv>
      </HorizontalLayout>
      <LinearLayout>
        <Text fontStyle={'TextMd_Semibold'} text={'Bounty Hunter $500'} lineHeight={24} />
        <Text fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} text={'May 8, 17:30'} textColor={'gray400'} lineHeight={isMobile ? 20 : 24} />
      </LinearLayout>
      <LinearLayout alignItem={'center'}>
        <Avatar src={user?.profile} alt="Profile" />
        <HorizontalLayout justifyContent={'center'} margin={'0'} gap={5}>
          <Text fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} text={'Marcus'} lineHeight={isMobile ? 20 : 24} />
          <Text fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} textColor={'gray400'} text={'knocked you out'} lineHeight={isMobile ? 20 : 24} />
        </HorizontalLayout>
        <HorizontalLayout justifyContent={'center'} alignItem={'end'} padding={4} margin={'0'}>
          <StyledText fontStyle={isMobile ? 'TextXl_Semibold' : 'DisplayXs_Semibold'} textColor={'primary400'} text={'208'} lineHeight={22} />
          <StyledText fontStyle={'TextSmall_Semibold'} textColor={'gray400'} text={'/459'} lineHeight={18} />
        </HorizontalLayout>
      </LinearLayout>
      <HorizontalLayout justifyContent={'center'} gap={8} alignItem={'end'} margin={'0'}>
        <StyledPrize fontSize={isMobile ? 40 : 55}>10.49</StyledPrize>
        <StyledPrize fontSize={isMobile ? 16 : 25}>USDT</StyledPrize>
      </HorizontalLayout>
      <StyledKnockoutList ref={ref}>
        <Text fontStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} textColor={'gray400'} text={'You knocked out 7 players'} lineHeight={24} />
        {clientWidth > 0 && (
          <StyledSplide
            // onMove={handleMove}
            onArrowsUpdated={onSlide}
            hasTrack={false}
            options={{
              padding: { left: isMobile ? 16 : 24, right: isMobile ? 16 : 24 },
              isNavigation: true,
              autoWidth: true,
              width: '100%',
              gap: `${8}px`,
              pagination: false,
              type: 'slide',
              arrows: true,
              drag: true,
              slideFocus: false
            }}
          >
            <SplideTrack>
              {new Array(userCount).fill(null).map((knockoutUser, i) => (
                <SplideSlide key={i} style={{ border: 'none', width: '78px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <StyledKnockOutUserAvatar src={user?.profile} alt="Profile" />
                  <StyledUserName style={{ width: '78px' }}>cebolaalt1111124124</StyledUserName>
                </SplideSlide>
              ))}
            </SplideTrack>
          </StyledSplide>
        )}
      </StyledKnockoutList>
      <StyledButtons>
        <BaseButton textStyle={isMobile ? 'TextMd_Semibold' : 'TextLg_Semibold'} size={isMobile ? 'btn48' : 'btn60'} text={'Close'} color={'gray'} />
        <BaseButton textStyle={isMobile ? 'TextMd_Semibold' : 'TextLg_Semibold'} size={isMobile ? 'btn48' : 'btn60'} text={'Re-enter'} color={'primary'} />
      </StyledButtons>
    </ModalWrap>
  );
};

const StyledPrize = styled.div<{ fontSize?: number }>`
  text-align: center;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  font-family: Yellix-Bold;
  display: flex;
  align-items: flex-end;
  font-size: ${({ fontSize }) => fontSize || 40}px;
  font-style: normal;
  font-weight: 700;
  line-height: ${({ fontSize }) => fontSize || 40}px;
  ${({ fontSize }) => fontSize === 16 && 'margin-bottom: 5px;'}
  ${({ fontSize }) => fontSize === 25 && 'margin-bottom: 7px;'}

    background: linear-gradient(180deg, #fbbd66 0%, #f8b364 50%, #df863f 75%, #f09c5f 87.5%, #ef9e5f 93.75%, #ffc368 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledButtons = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const StyledSplide = styled(Splide)`
  position: initial;

  .splide__arrows {
    .splide__arrow--next {
      right: 0;
      height: 100%;
      width: 28px;
      border-radius: 0;
      background-color: #0a0e16cc;

      &.none {
        display: none !important;
      }

      svg {
        fill: ${({ theme }) => theme.colors.gray400};
      }
    }

    .splide__arrow--prev {
      left: 0;
      height: 100%;
      width: 28px;
      border-radius: 0;
      background-color: #0a0e16cc;

      &.none {
        display: none !important;
      }

      svg {
        fill: ${({ theme }) => theme.colors.gray400};
      }
    }
  }
`;

const StyledUserName = styled.div`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledKnockoutList = styled.div`
  position: relative;
  padding: 24px 0;
  width: 100%;
  overflow: hidden;
  gap: 16px;
  display: flex;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray900};
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin-top: auto;
`;

const Avatar = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;
const StyledKnockOutUserAvatar = styled(Avatar)`
  width: 52px;
  height: 52px;
  @media (max-width: 768px) {
    width: 52px;
    height: 52px;
  }
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-right: 16px;
  }
`;

export default TournamentResultModal;

const ModalWrap = styled.div<{ isWin: boolean }>`
  display: flex;
  user-select: none;
  gap: 16px;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  ${({ isWin }) =>
    isWin &&
    css`
      background-image: url(${TrophyImg});
      background-repeat: no-repeat;
      background-position: 50% -70px;
      background-size: max(80%, 80%);
      @media (max-width: 768px) {
        background-position: 50% -60px;
        background-size: max(400px);
      }
    `}
  padding: 32px 40px;
  border-radius: 12px;
  width: 600px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 20px;
  }
`;
