import styled from '@emotion/styled';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import Table from 'src/components/template/room/table';
import ActionButtons from 'src/components/template/room/bettingControls';
import ChatBox from 'src/components/template/room/chatBox';

import { TableStyleOptions } from '../store/api/responseTypes';
import LoadingPage from 'src/components/common/loading/LoadingPage';
import useTableInit from 'src/hooks/useTableInit';
import ReserveSitOut from 'src/components/template/room/reserveSitout';
import { useUserTableSetting } from '../hooks/useUserTableSetting';
import useDevice from '../hooks/useDevice';
import TableInfo from '../components/template/room/tableInfo';
import ErrorTemplate from '../components/template/utils/ErrorTemplate';
import StackedNotification from '../components/common/toast/StackedNotification';
import useTableSound from '../hooks/useTableSound';

const TableScreen = () => {
  const setting = useUserTableSetting();
  const { isMobile } = useDevice();
  const { tableLoadingState } = useTableInit();
  useTableSound();

  const getModule = () => {
    if (isMobile) {
      return (
        <BottomWrap isMobile={isMobile}>
          <ChatBox />
          <ActionButtons />
        </BottomWrap>
      );
    } else {
      return (
        <>
          <ChatWrap>
            <ChatBox />
          </ChatWrap>
          <ActionWrap>
            <ActionButtons />
          </ActionWrap>
        </>
      );
    }
  };

  return (
    <AnimatePresence>
      <TableContainer tableStyle={setting?.tableStyle}>
        {tableLoadingState === 'LOADING' && <LoadingPage />}
        {!(tableLoadingState === 'SUCCESS' || tableLoadingState === 'JOINING') && <ErrorTemplate errorType={tableLoadingState} />}
        <TableInfo />
        {!isMobile && <ReserveSitOut />}
        <StackedNotification />
        <TableWrap>
          <Table />
        </TableWrap>
        {getModule()}
      </TableContainer>
    </AnimatePresence>
  );
};

const TableWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 160px;
  max-width: 1600px;
  max-height: 63%;
  @media (max-width: 1500px) {
    padding: 0 80px;
    max-width: 1180px;
  }
  @media (max-width: 768px) {
    height: 100%;
    padding: 0;
    max-height: 100%;
    max-width: 140% !important;
  }
`;

const TableContainer = styled.div<{ tableStyle?: TableStyleOptions }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: ${({ theme, tableStyle }) => tableStyle && theme.tableBg[tableStyle]};
  overflow: hidden;
  touch-action: none;
`;

const ChatWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 1;
  width: 444px;
`;

const ActionWrap = styled.div`
  position: fixed;
  z-index: 3;
  display: flex;
  bottom: 0;
  right: 0;
`;

const BottomWrap = styled.div<{ isMobile?: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 1;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

export default TableScreen;
