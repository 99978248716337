import styled from '@emotion/styled';

export const Wrap = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0 8px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    background-color: ${({ theme }) => theme.colors.gray800};
    flex-shrink: 0;
    height: 240px !important;
    padding: 8px;
    border-radius: 8px;
    width: 48px;
    margin: 0;
  }
`;

export const RangeSlider = styled.input<{ progress: number; useThumb: boolean }>`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  @media (max-width: 768px) {
    transform: rotateZ(-90deg) translateY(-98px);
    width: 224px;
    flex-shrink: 0;
    margin-right: 8px;
  }
  border-radius: 25px;
  background: transparent;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    touch-action: none;
    cursor: pointer;
    ${({ theme }) => theme.shadow.sliderTrack}
    background: ${({ progress, theme }) => `linear-gradient(to right, ${theme.colors.error600} ${progress}%, ${theme.colors.gray500} ${progress}%)`};
    border-radius: 25px;
  }

  &::-moz-range-track {
    width: 100%;
    touch-action: none;
    height: 12px;
    cursor: pointer;

    ${({ theme }) => theme.shadow.sliderTrack}
    background: ${({ progress, theme }) => `linear-gradient(to right, ${theme.colors.error600} ${progress}%, ${theme.colors.gray500} ${progress}%)`};
    border-radius: 25px;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    ${({ theme }) => theme.shadow.button}
    transform: rotateZ(90deg);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.gray600};
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    margin-top: -10px;
    opacity: ${({ useThumb }) => (useThumb ? 1 : 0)};
  }

  &::-moz-range-thumb {
    box-shadow: none;
    ${({ theme }) => theme.shadow.button}
    transform: rotateZ(90deg);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.gray600};
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    margin-top: -10px;
    opacity: ${({ useThumb }) => (useThumb ? 1 : 0)};
  }
`;
