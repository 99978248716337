import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styled from '@emotion/styled';
import useDevice from '../../../hooks/useDevice';
import BaseButton from '../../common/button/BaseButton';
import { css } from '@emotion/react';

interface LobbyBannersProps {
  type?: 'lobby' | 'landing';
}

const LobbyBanners = ({ type = 'lobby' }: LobbyBannersProps) => {
  const carouselRef = React.useRef(null);
  const { isDesktop, isDesktopSmall, isMobile } = useDevice();
  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: type === 'lobby' ? 992 : 1280 },
      items: 3,
      partialVisibilityGutter: 0
    },
    ...(type === 'landing' && {
      desktopSmall: {
        breakpoint: { max: 1280, min: 992 },
        items: 2,
        partialVisibilityGutter: 100
      }
    }),
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 2,
      partialVisibilityGutter: type === 'landing' ? 10 : 30
    },
    bigMobile: {
      breakpoint: { max: 768, min: 540 },
      items: 1,
      partialVisibilityGutter: 180
    },
    mobile: {
      breakpoint: { max: 540, min: 0 },
      items: 1,
      partialVisibilityGutter: 40
    }
  };

  useEffect(() => {
    if (carouselRef) {
      (carouselRef.current as any)?.goToSlide(0);
    }
  }, [isDesktop, isDesktopSmall, isMobile, carouselRef]);

  const learnToBasepoker = () => {
    window.open(`https://medium.com/@basepoker/how-to-use-basepoker-dc4893f82eeb`, '_blank');
  };

  const joinDiscord = () => {
    window.open(`https://discord.gg/zjZKg7pBsK`, '_blank');
  };

  const getBonus = () => {
    window.open(`https://medium.com/@basepoker/get-1-000-bonus-at-basepoker-com-6f500875e2df`, '_blank');
  };

  return (
    <StyledWrap type={type}>
      <Carousel ref={carouselRef} itemClass={'carouselItem'} sliderClass={'slider'} partialVisible={true} responsive={responsive} rewind={false} arrows={false}>
        <StyledPromoCard>
          <StyledContentWrap>
            <StyledContent>
              <StyledCategory>Tips</StyledCategory>
              <StyledTitle>How To Use Basepoker</StyledTitle>
              <StyledText>Learn how to set up your game</StyledText>
            </StyledContent>
            <StyledButton size={'btn32'} text={'Learn'} textColor={'gray200'} onClick={learnToBasepoker} />
          </StyledContentWrap>
          <StyledImgWrap>
            <img src={require('src/assets/img/promo-card-1.png')} alt="promo-card-1" />
          </StyledImgWrap>
        </StyledPromoCard>
        <StyledPromoCard>
          <StyledContentWrap>
            <StyledContent>
              <StyledCategory>Tips</StyledCategory>
              <StyledTitle>Join our Discord</StyledTitle>
              <StyledText>Join our Discord community!</StyledText>
            </StyledContent>
            <StyledButton size={'btn32'} text={'Join'} textColor={'gray200'} onClick={joinDiscord} />
          </StyledContentWrap>
          <StyledImgWrap>
            <img src={require('src/assets/img/promo-card-2.png')} alt="promo-card-2" />
          </StyledImgWrap>
        </StyledPromoCard>
        <StyledPromoCard>
          <StyledContentWrap>
            <StyledContent>
              <StyledCategory>Promotions</StyledCategory>
              <StyledTitle>$1,000 Deposit Bonus</StyledTitle>
              <StyledText>Get your first deposit bonus</StyledText>
            </StyledContent>
            <StyledButton size={'btn32'} text={'Learn'} textColor={'gray200'} onClick={getBonus} />
          </StyledContentWrap>
          <StyledImgWrap>
            <img src={require('src/assets/img/promo-card-3.png')} alt="promo-card-3" />
          </StyledImgWrap>
        </StyledPromoCard>
      </Carousel>
    </StyledWrap>
  );
};
const StyledButton = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.colors.gray500};
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  height: 34px;
  width: fit-content;
  padding: 8px 14px;

  &:hover {
    filter: none;
    border: 1px solid ${({ theme }) => theme.colors.gray400};
    background: rgba(255, 255, 255, 0.05);
    transition: all 0.2s ease-in-out;
  }
`;
const StyledCategory = styled.div`
  ${({ theme }) => theme.textStyles.TextXs_Semibold};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.gray600};
  border-radius: 4px;
  padding: 2px 8px;
  margin-bottom: 8px;
  width: fit-content;
`;
const StyledContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledTitle = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  }
  color: ${({ theme }) => theme.colors.white};
  line-height: 24px;
`;
const StyledText = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Medium};
  }
  color: ${({ theme }) => theme.colors.gray300};
  line-height: 20px;
`;
const StyledContent = styled.div``;
const StyledImgWrap = styled.div`
  width: auto;
  height: 100%;
  aspect-ratio: 10 / 13;

  img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StyledWrap = styled.div<LobbyBannersProps>`
  width: 100%;
  margin-bottom: 24px;

  @media (max-width: 992px) {
    width: 100vw;
  }
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }

  .carouselItem {
    box-sizing: border-box;
    width: 100%;
    padding-left: 8px;

    &:first-of-type {
      padding-left: 0;
    }

    @media (max-width: 1280px) {
      ${({ type }) =>
        type === 'landing' &&
        css`
          padding-left: 8px;

          &:first-of-type {
            padding-left: 40px;
          }

          &:last-of-type {
            padding-right: 40px;
          }
        `}
    }
    @media (max-width: 992px) {
      ${({ type }) =>
        type === 'lobby' &&
        css`
          padding-left: 8px;

          &:first-of-type {
            padding-left: 16px;
          }

          &:last-of-type {
            padding-right: 16px;
          }
        `}
    }
    @media (max-width: 768px) {
      width: 100vw;
      ${({ type }) =>
        type === 'landing' &&
        css`
          padding-left: 8px;

          &:first-of-type {
            padding-left: 20px;
          }

          &:last-of-type {
            padding-right: 20px;
          }
        `}
    }
  }

  .slider {
    @media (max-width: 768px) {
      width: 300vw !important;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

const StyledPromoCard = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 170px;
  padding: 20px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.gray800};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  user-select: none;
  margin-bottom: 4px;
  @media (max-width: 992px) {
    width: 100%;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray750};
  }

  transition: background 0.2s ease-in-out;
`;

export default LobbyBanners;
