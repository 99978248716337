import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useUserTableSetting } from '../../../hooks/useUserTableSetting';
import { useDispatch } from 'react-redux';
import { DeckStyleOptions, TableStyleOptions } from '../../../store/api/responseTypes';
import streamingSlice from '../../../store/slices/streaming';
import authSlice from '../../../store/slices/authSlice';

const styleOptions = {
  CLASSIC: require('src/assets/img/deck_style_classic.png'),
  FOUR_COLOR_WHITE: require('src/assets/img/deck_style_four_white.png'),
  FOUR_COLOR_COLORED: require('src/assets/img/deck_style_four_colored.png')
};

type Props = {
  tableId?: number | string | undefined;
  onChange?: (value: DeckStyleOptions) => void;
};

const DeckStyleSelector: React.FC<Props> = ({ tableId, onChange }) => {
  const setting = useUserTableSetting();
  const [selectedOption, setSelectedOption] = useState<DeckStyleOptions>(setting!.deckStyle!);
  const dispatch = useDispatch();
  useEffect(() => {
    if (onChange) {
      onChange(selectedOption);
    }
    if (tableId && setting) {
      dispatch(
        authSlice.actions.setTableSetting({
          tableId: tableId,
          setting: { ...setting, deckStyle: selectedOption }
        })
      );
    }
  }, [selectedOption]);
  return (
    <Container>
      <Title>Deck Style</Title>
      <OptionWrap>
        {Object.entries(styleOptions).map(([key, src], i) => (
          <DeckStyleOption key={key + i} tableId={tableId} isSelected={selectedOption === key} onClick={() => setSelectedOption(key as DeckStyleOptions)}>
            <img src={src} alt={`DeckStyle_${key}`} />
          </DeckStyleOption>
        ))}
      </OptionWrap>
    </Container>
  );
};

export default DeckStyleSelector;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: white;
`;

const OptionWrap = styled.div`
  display: grid;
  justify-content: space-around;
  gap: 12px;
  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const DeckStyleOption = styled.div<{ isSelected: boolean; tableId: number | string | undefined }>`
  border: ${({ isSelected, theme }) => (isSelected ? `1px solid ${theme.colors.white}` : `1px solid transparent`)};
  border-radius: 4px;
  cursor: pointer;

  img {
    display: block;
    width: ${({ tableId }) => (tableId ? '61px' : '76px')};
    height: auto;

    @media (max-width: 768px) {
      width: ${({ tableId }) => (tableId ? '61px' : '76px')};
    }
  }
`;
