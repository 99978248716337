import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Diamonds = React.memo<IconProps>(({ w = 40, h = 40, color = 'error600' }) => (
  <svg width={w} height={h} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.1485 38.5939C16.878 34.7456 13.964 31.1564 10.5582 27.9001C7.79564 25.2729 4.65468 22.8677 1.28666 20.7955L0 19.9815L1.28666 19.2045C4.69252 17.0953 7.79564 14.7271 10.5582 12.0999C13.964 8.88065 16.878 5.25437 19.1485 1.40608L19.9811 0L20.8136 1.40608C23.122 5.25437 26.0359 8.88065 29.4418 12.0999C32.1665 14.7271 35.3075 17.0953 38.7133 19.2045L40 19.9815L38.7133 20.7955C35.3075 22.9047 32.2043 25.2729 29.4418 27.9001C26.0359 31.1564 23.122 34.7456 20.8136 38.5939L19.9811 40L19.1485 38.5939Z"
      fill={Colors[color]}
    />
  </svg>
));

Diamonds.displayName = 'Diamonds';
