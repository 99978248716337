import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Rotate = React.memo<IconProps>(({ w = 24, h = 24, color = 'gray400' }) => (
  <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.9999 4.00001V10M22.9999 10H16.9999M22.9999 10L18.3699 5.64001C16.9905 4.25975 15.1999 3.3652 13.2678 3.09116C11.3358 2.81711 9.36705 3.17843 7.65823 4.12065C5.94942 5.06288 4.59313 6.53496 3.79374 8.31508C2.99436 10.0952 2.79519 12.0869 3.22625 13.9901C3.6573 15.8932 4.69524 17.6047 6.18363 18.8667C7.67203 20.1286 9.53026 20.8726 11.4783 20.9866C13.4263 21.1006 15.3586 20.5783 16.984 19.4985C18.6094 18.4187 19.8399 16.8399 20.4899 15"
      stroke={Colors[color]}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

Rotate.displayName = 'Rotate';
