import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import theme from '../../../styles/theme';

interface ToggleSwitchProps {
  isActive: boolean;
  onClick: () => void;
  disabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isActive, onClick, disabled }) => {
  return (
    <Switch>
      <Checkbox disabled={disabled} type="Checkbox" checked={isActive} onChange={onClick} id="toggleSwitch" />
      <Slider isActive={isActive} disabled={disabled} />
    </Switch>
  );
};

const Switch = styled.label`
  position: relative;
  display: flex;
  width: 36px;
  height: 20px;
  flex-shrink: 0;
`;

const Checkbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const sliderCss = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.gray600};
  transition: 0.4s;
  border-radius: 34px;
`;

const Slider = styled.span<{ isActive: boolean; disabled?: boolean }>`
  ${sliderCss};

  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    margin: 2px;
    background-color: ${({ isActive, theme, disabled }) => (!disabled && isActive ? theme.colors.white : theme.colors.gray800)};
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ isActive }) => (isActive ? 'translateX(16px)' : 'translateX(0)')};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.colors.primary600};

      &:before {
        box-shadow: 0 0 1px ${theme.colors.primary600};
      }
    `}
`;

export default ToggleSwitch;
