import React, { SyntheticEvent, useEffect } from 'react';
import styled from '@emotion/styled';
import { ChevronDown } from './icon/icons/ChevronDown';
import { TextStyles } from '../../styles/textStyles';
import { Colors } from '../../styles/Colors';

export interface CollapseProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'prefix'> {
  expanded?: boolean;
  title?: string;
  arrow?: boolean;
  textStyle?: keyof typeof TextStyles;
  textColor?: keyof typeof Colors;
  onToggle?: () => void;
}

interface CollapseStateProps {
  isExpanded?: boolean;
  height?: number;
}

const Collapse: React.FC<CollapseProps> = ({ children, textStyle = 'TextMd_Semibold', textColor = 'gray300', onToggle, ...props }) => {
  const panelRef = React.useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState<CollapseStateProps>({ isExpanded: props.expanded, height: panelRef.current?.clientHeight || 308 });
  const currentHeight = state.isExpanded ? state.height : 0;

  const toggleExpanded = (e: SyntheticEvent) => {
    if (props.expanded !== undefined) {
      onToggle?.();
      return;
    }
    e.preventDefault();
    setState({ isExpanded: !state.isExpanded, height: panelRef.current?.clientHeight });
  };

  useEffect(() => {
    setState({ isExpanded: props.expanded, height: panelRef.current?.clientHeight });
  }, [props.expanded, panelRef.current?.clientHeight]);

  return (
    <StyledCard {...props} expanded={state.isExpanded} onClick={toggleExpanded}>
      <StyledHeader textStyle={textStyle} textColor={textColor} block expanded={state.isExpanded}>
        {props.title}
        <ChevronDown w={24} h={24} color={'gray300'} />
      </StyledHeader>
      <StyledPanel height={currentHeight}>
        <div className="panel-inner" ref={panelRef}>
          {children}
        </div>
      </StyledPanel>
    </StyledCard>
  );
};

export default Collapse;

const StyledCard = styled.div<CollapseProps>`
  z-index: 40;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.2s ease-in;
  background-color: ${({ theme }) => theme.colors.gray800};
`;

const StyledHeader = styled.div<{
  block?: boolean;
  expanded?: boolean;
  textStyle: keyof typeof TextStyles;
  textColor: keyof typeof Colors;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${p => p.block && 'width: 100%'};
  cursor: pointer !important;
  overflow: hidden;
  color: ${({ theme, textColor }) => theme.colors[textColor]};
  ${({ theme, textStyle }) => theme.textStyles[textStyle]};

  .icon-arrow {
    ${p => p.expanded && `transform: rotate(-180deg)`};
  }
`;

const StyledPanel = styled.div<{ height?: number; whitespace?: boolean }>`
  height: ${p => p.height}px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;

  .panel-inner {
    padding: 16px 0 0;
  }
`;
