import React from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../styles/Colors';

type Props = {
  children: React.ReactNode;
  width?: string;
  margin?: string;
  gap?: number;
  backgroundColor?: keyof typeof Colors;
  borderRadius?: number;
  padding?: number;
  alignItem?: 'center' | 'start' | 'end';
  justifyContent?: 'space-between' | 'start' | 'end' | 'center';
};

const HorizontalLayout: React.FC<Props> = ({ children, width, margin = '20px 0', gap = 0, backgroundColor, ...props }) => {
  return (
    <Form {...props} margin={margin} gap={gap} width={width} backgroundColor={backgroundColor ? Colors[backgroundColor] : undefined}>
      {children}
    </Form>
  );
};

const Form = styled.div<{
  gap: number;
  margin: string;
  backgroundColor?: string;
  borderRadius?: number;
  padding?: number;
  width?: string;
  justifyContent?: string;
  alignItem?: string;
}>`
  width: ${({ width }) => width || '100%'};
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  align-items: ${({ alignItem }) => alignItem || 'center'};
  gap: ${({ gap }) => gap}px;
  padding: ${({ padding }) => padding}px;
  margin: ${({ margin }) => margin};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: ${({ borderRadius }) => (borderRadius !== undefined ? `${borderRadius}px` : '0')};
  @media (max-width: 768px) {
    min-width: 0;
    padding: ${({ padding }) => (padding ? padding / 2 : 0)}px;
  }
`;

export default HorizontalLayout;
