import React from 'react';
import styled from '@emotion/styled';
import { TextStyles } from 'src/styles/textStyles';
import { Colors } from '../../../styles/Colors';

interface TextStyleProp {
  fontStyle: keyof typeof TextStyles;
  className?: string;
  text?: string;
  onClick?: () => void;
  textColor?: keyof typeof Colors;
  marginTop?: number;
  marginBottom?: number;
  textAlign?: 'center' | 'start' | 'end';
  hasPointer?: boolean;
  lineHeight?: number;
}

const Text: React.FC<TextStyleProp> = ({ fontStyle, text, onClick, textColor, hasPointer, ...props }) => {
  const formatTextWithLineBreaks = (text: string) => {
    const lines = text.split('\\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <TextWrap {...props} fontStyle={fontStyle} textColor={textColor} onClick={onClick} hasPointer={hasPointer}>
      {formatTextWithLineBreaks(text ?? '')}
    </TextWrap>
  );
};

export default Text;

const TextWrap = styled.div<Omit<TextStyleProp, 'text'>>`
  ${({ theme, fontStyle }) => theme.textStyles[fontStyle]};
  color: ${({ theme, textColor }) => (textColor ? theme.colors[textColor] : 'white')};
  text-align: ${({ textAlign = 'center' }) => textAlign};
  align-self: ${({ textAlign = 'center' }) => textAlign};
  align-items: center;
  justify-content: center;
  display: flex;
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : '1.3em')};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
  user-select: ${({ hasPointer }) => (hasPointer ? 'none' : 'auto')};
  cursor: ${({ hasPointer }) => (hasPointer ? 'pointer' : 'auto')};
`;
