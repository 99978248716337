import React from 'react';
import { ClickableIconProps, IconProps } from '../types';
import { Colors } from '../../../../styles/Colors';

export const Logo = React.memo<ClickableIconProps>(({ w = 21, h = 14, color = 'white' }) => (
  <svg width={w} height={h} viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1933 6.99997C14.1933 10.866 11.016 13.9999 7.09664 13.9999C3.17728 13.9999 0 10.866 0 6.99997C0 3.13399 3.17728 0 7.09664 0C11.016 0 14.1933 3.13399 14.1933 6.99997Z" fill={Colors[color]} />
    <path d="M14.1933 6.99997C14.1933 10.866 11.016 13.9999 7.09664 13.9999C3.17728 13.9999 0 10.866 0 6.99997C0 3.13399 3.17728 0 7.09664 0C11.016 0 14.1933 3.13399 14.1933 6.99997Z" fill={Colors[color]} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2801 13.7101C13.5615 12.3239 15.0824 9.83726 15.0824 7.00024C15.0824 4.16297 13.5613 1.67616 11.2795 0.290026C11.9221 0.101331 12.6028 0 13.3076 0C17.2269 0 20.4042 3.134 20.4042 6.99997C20.4042 10.866 17.2269 13.9999 13.3076 13.9999C12.603 13.9999 11.9225 13.8987 11.2801 13.7101ZM10.2034 13.2965C12.5669 12.1606 14.1954 9.76799 14.1954 7.00024C14.1954 4.23227 12.5666 1.83954 10.2029 0.703663C7.83945 1.83965 6.21094 4.23222 6.21094 6.99997C6.21094 9.76794 7.8397 12.1607 10.2034 13.2965Z"
      fill={Colors[color]}
    />
  </svg>
));

Logo.displayName = 'Logo';
