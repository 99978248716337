import React from 'react';
import Text from '../../common/text/Text';
import LinearLayout from '../../layout/LinearLayout';
import useDevice from '../../../hooks/useDevice';
import { StyledPolicyLink, StyledPolicyText } from './styles';

const PrivacyPolicy = () => {
  const { isMobile } = useDevice();
  return (
    <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40} gap={24}>
      <Text lineHeight={20} fontStyle={isMobile ? 'TextMd_Bold' : 'TextLg_Bold'} textColor={'white'} text={'Privacy Policy'} textAlign={'start'} />
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'BASEPOKER.COM'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Last updated: June 17th, 2024'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Version P20240617.1'} />
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Introduction'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'1.1 This Privacy Policy outlines the manner in which Basepoker ("we", "our", "us") collects, processes, retains, and safeguards Personal Data, and the measures we implement to protect such Personal Data.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'1.2 By utilizing our Services, you acknowledge that you have read, understood, and agreed to the terms of this Privacy Policy, and you consent to our collection and use of your Personal Data for the purposes outlined in Section 3. If you do not wish to provide your Personal Data as described in this Privacy Policy, you should refrain from entering such information on our Website or providing it to us through other means. However, please note that failure to provide certain Personal Data may limit your ability to access and utilize all features of our Services.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'1.3 Capitalized terms not defined in this Privacy Policy shall have the meanings ascribed to them in our Terms & Conditions. This Privacy Policy is incorporated into, and forms an integral part of, our Terms & Conditions.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'1.4 Definitions:'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'• The terms "Anonymization", "Controller", "Processor", "Data Subject", "Data Portability", "Personal Data", "Processing", "Pseudonymization", "Cross-Border Processing of Personal Data", and "Supervisory Authority" shall have the same meanings as defined in the General Data Protection Regulation (GDPR).\\n• "You" refers to the player, the Data Subject utilizing the services of Basepoker.\\n• "Visitor" means an individual, other than a registered user, who accesses the public areas of our Website or Service but does not have access to restricted areas.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'1.5 Principles: This Policy is based on the following principles outlined in the GDPR:'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'• The Processing of Personal Data shall be conducted in a lawful, fair, and transparent manner.\\n• The collection, processing, and retention of Personal Data shall be limited to specified, explicit, and legitimate purposes, and no further processing shall occur in a manner incompatible with those purposes.\\n• The collection and retention of Personal Data shall be adequate, relevant, and limited to what is necessary in relation to the purposes for which the Personal Data is Processed.\\n• Personal Data shall be accurate and, where necessary, kept up-to-date.\\n• Reasonable steps shall be taken to ensure that inaccurate Personal Data, having regard to the purposes for which it is Processed, is erased or rectified without delay.\\n• Personal Data shall be kept in a form that permits identification of the Data Subject for no longer than is necessary for the purposes for which the Personal Data is Processed.\\n• All Personal Data shall be kept confidential and stored in a manner that ensures appropriate security measures.\\n• Personal Data shall not be shared with third parties except when necessary for them to provide services upon agreement.\\n• Data Subjects shall have the right to request access to, rectification or erasure of, or restriction of Processing of their Personal Data, as well as the right to object to Processing and the right to Data Portability.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'The Information We Collect'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'2.1 To provide you with our Services, we collect your Personal Data when you register an account with us. "Personal Data" refers to any information that can be used to directly or indirectly identify you, including but not limited to your first and last name, email address, residential address, telephone number, mobile number, date of birth, government-issued identification documents, electronic location data, and electronic device information such as passport/license details, MAC address, and IP address.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'2.2 Additionally, as part of delivering our Services, we collect information related to the transactions you conduct through our platform, specifics of the games you have played, and underlying gaming transaction data.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Use of Your Personal Information'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.1 When providing our Services, we ensure that we collect, retain, and process your Personal Data in accordance with the General Data Protection Regulation (GDPR). We will Process your Personal Data to enable us to:'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.2 Set up, administer, and manage your account and records, including processing deposits and withdrawals, while adhering to the requirements of applicable data protection laws.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.3 Provide and personalize the Services, such as allowing you to wager and play our games, by utilizing your electronic device information, including your MAC address and IP address.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.4 Receive and respond to your communications, inquiries, and requests.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.5 Notify you about updates to the Software, Services, or any changes to our Terms & Conditions or Privacy Policy.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.6 Ensure that we can fulfill our regulatory obligations regarding your account, including verifying the accuracy of any information you provide us, such as by utilizing your government-issued identification documents.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.7 Comply with our obligations under Applicable Laws and to Regulators in jurisdictions where we are licensed to operate, including the Isle of Man\'s data protection authority.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.8 Investigate and assist with the investigation of any suspected unlawful, fraudulent, or other improper activities connected with the Services you utilize, such as gaming transactions.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.9 Carry out market research campaigns and prepare statistics relating to the utilization of our Services.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.10 Provide you with support for the Services, including technical assistance and addressing any issues or concerns you may have.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.11 We will process your Personal Data, including your payment details and information, to enable us to set up, administer, and manage your account as well as to facilitate any purchases, subscriptions, or transactions you initiate through our platform. Additionally, we may utilize your Personal Data to enable you to wager and play our games as well as to provide you with support and resolve any issues related to our Services.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.12 We may also share some of your personal information with third parties, such as financial institutions, credit reference agencies, or government authorities, but only as required for identity verification purposes, fraud prevention measures, or to comply with legal obligations. Any information shared will be limited to the minimum necessary, and we will ensure appropriate safeguards are in place to protect your personal data.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.13 If at any time you wish for us to cease Processing your Personal Data, you can contact us, and we will take the appropriate steps to do so. However, please note that this may affect your ability to use certain aspects of our Services or continue participating in any ongoing gaming activities or transactions.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'3.14 We will retain and process your Personal Data only for as long as necessary to fulfill the specified purposes, unless a longer retention period is required or permitted by applicable laws or regulations. We implement robust security measures and organizational safeguards to ensure the confidentiality and integrity of your Personal Data throughout its entire lifecycle, including its eventual secure disposal or eras'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Disclosure of Your Personal Information'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'4.1 Except as outlined in this Policy, we will not intentionally disclose the Personal Data that we collect or retain through our Services to third parties without first obtaining your explicit consent. However, we may disclose Personal Data to third parties in the following circumstances:'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={' • To any company within our corporate group, including employees and subcontractors, who assist in providing the Services or have a legitimate need to access such information.\\n • To third parties that facilitate the provision of our Services, including but not limited to payment processors and marketing service providers.\\n • To third parties that aid in verifying the accuracy of your Personal Data, such as financial institutions and credit reference agencies. These third parties may retain a record of the verification process. This category includes entities that assist in identity and document verification.\\n • To third parties that assist in monitoring the use of our Services, including the detection and prevention of fraudulent activities, cheating, and collusion.\\n • To contractors, auditors, or other professional advisors who require access to such information to audit our business processes or provide consulting services.\\n • To law enforcement bodies or authorities that have a legitimate and reasonable requirement to access your Personal Data.\\n • To other online gaming sites, banks, credit card companies, and appropriate agencies, in cases where you have been found to have cheated or attempted to defraud us or any other user of our services, based on our legitimate interest or legal obligation to prevent fraud and cheating.\\n • To regulatory bodies or authorized entities that have a legitimate and reasonable requirement to access your Personal Data.\\n • To potential purchasers of Basepoker or investors in our company or any entity within our corporate group, including in the event of insolvency.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'4.2 We will keep Data Subjects informed and ensure that these trusted partners and third parties abide by mandatory data protection measures. During such data transmissions, we shall implement appropriate organizational, technical, and legal protection measures.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'4.3 Our platform provides customers with the ability to utilize "chat rooms" where players can communicate with each other. We will not be responsible for any data breaches that may arise from the use of our chat rooms. Therefore, you accept responsibility, and under no circumstances shall we be held liable for any damages that may result from a data breach in our chat rooms.'} />
          <StyledPolicyText>4.4 If at any time you wish for us to cease Processing your Personal Data for the purposes outlined above, please contact us, and we will take the appropriate steps to do
            so. However, please note that this may result in the closure of your account. You may reach us at <StyledPolicyLink
              onClick={() => window.open('mailto:support@basepoker.com', '_blank')}>support@basepoker.com</StyledPolicyLink>.</StyledPolicyText>

        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Data Subject Rights'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'5.1 We respect your privacy rights and provide you with reasonable access to the Personal Data that you have provided through your utilization of our Services. Under the GDPR, your principal rights as a Data Subject are:'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'• The right to be informed about the collection and use of your Personal Data'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'• The right to access your Personal Data that we hold'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'• The right to rectify any inaccurate or incomplete Personal Data'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'• The right to request the erasure of your Personal Data in certain circumstances'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'• The right to restrict the Processing of your Personal Data in certain circumstances'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'• The right to object to the Processing of your Personal Data on grounds relating to your particular situation'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'• The right to data portability, allowing you to receive your Personal Data in a structured, commonly used, and machine-readable format'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'• The right to lodge a complaint with a supervisory authority if you believe your rights have been violated'} />

          <StyledPolicyText>5.2 If you wish to access, amend, or request the deletion of any Personal Data we hold about you, please contact us by email at <StyledPolicyLink
            onClick={() => window.open('mailto:support@basepoker.com', '_blank')}>support@basepoker.com</StyledPolicyLink>. We will acknowledge your request within seventy-two (72) hours and handle
            it
            promptly. Basepoker will respond to such requests within one month, with the possibility of extending this period for particularly complex requests in accordance with Applicable Law. We
            will retain your information for as long as your account is active, as necessary to provide our services, or to comply with our legal obligations, resolve disputes, and enforce our
            agreements.</StyledPolicyText>


          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'5.3 You may update, correct, or delete your account information and preferences at any time by accessing the "My Page" section. Please note that while any changes you make will be reflected in our active user databases instantly or within a reasonable timeframe, we may retain certain information you have submitted for backup purposes, archiving, fraud prevention, analytics, legal compliance, or where we have a legitimate reason to do so. Where appropriate and feasible, we shall apply Anonymization or Pseudonymization techniques to your Personal Data to mitigate risks to you as a Data Subject.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'5.4 You have the choice to decline sharing certain Personal Data with us. However, in such cases, we may not be able to provide you with some or all of the features and functionality of our Services.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'5.5 At any time, you may object to the Processing of your Personal Data on legitimate grounds, except where such Processing is permitted by applicable law.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'5.6 In accordance with Applicable Law, we reserve the right to withhold the disclosure of Personal Data if such disclosure would adversely affect the rights and freedoms of others.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'5.7 You have the right to Data Portability, whereby you as a Data Subject may request to receive all Personal Data retained by us, free of charge, in a structured, commonly used, and machine-readable format. However, we reserve the right to charge a reasonable fee for complying with such requests if they are deemed manifestly unfounded or excessive. The Company may charge an administrative fee if further copies are requested. We shall maintain records, including a log of these requests.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'5.8 When we receive requests from Data Subjects to dispose of Personal Data records, we shall ensure that these requests are handled within a reasonable timeframe. We shall maintain records, including a log of these requests.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'5.9 We shall strive to implement adequate disposal mechanisms to ensure that no Personal Data is leaked outside of the organization.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'5.10 Should we fail to abide by the required data protection obligations, you shall have the right to lodge a complaint with your Supervisory Authority.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Contacting Us'} />
        <LinearLayout gap={8}>
          <StyledPolicyText>6.1 For detailed information about the members of the Basepoker Group and the jurisdictions in which we operate, please contact us at <StyledPolicyLink
            onClick={() => window.open('mailto:support@basepoker.com', '_blank')}>support@basepoker.com</StyledPolicyLink>.</StyledPolicyText>
          <StyledPolicyText>6.2 If at any time you believe that we have not adhered to this Privacy Policy, please contact us at <StyledPolicyLink
            onClick={() => window.open('mailto:support@basepoker.com', '_blank')}>support@basepoker.com</StyledPolicyLink>, and we will promptly investigate and address the issue.</StyledPolicyText>

        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Minors and Children\'s Privacy'} />
        <LinearLayout gap={8}>
          <StyledPolicyText>7. Protecting the privacy of minors is of utmost importance to us. Our Service is not intended for children under the age of 18, and we do not knowingly collect Personal
            Data from individuals under the age of 18. If you are under 18 years of age, please refrain from using or accessing our Service at any time or in any manner. If we learn that Personal Data
            has been collected on our Service from individuals under 18 years of age, we will take appropriate steps to delete this information. If you are a parent or guardian and discover that your
            child under 18 years of age has obtained an account on our Service, you may contact us at <StyledPolicyLink
              onClick={() => window.open('mailto:support@basepoker.com', '_blank')}>support@basepoker.com</StyledPolicyLink> and request that we delete your child's Personal Data from our
            systems.</StyledPolicyText>
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Security'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'8.1 We implement appropriate security measures to protect against loss, misuse, unauthorized access, alteration, disclosure, or destruction of your information. Basepoker has taken steps to ensure the ongoing confidentiality, integrity, availability, and resilience of systems and services Processing Personal Data and will restore the availability and access to information in a timely manner in the event of a physical or technical incident.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'8.2 Your winnings and cash-outs are kept strictly confidential, and winnings information is stored in secure operating environments. We do not provide winnings information to any third party unless such disclosure is required by law, regulation, or a similar governmental authority.'} />

          <StyledPolicyText>8.3 No method of transmission over the Internet or electronic storage is 100% secure. While we cannot ensure or warrant the security of any information you transmit to us
            or store on our Service, we shall implement adequate security mechanisms designed to protect Personal Data from being stolen, misused, or abused, and to prevent Personal Data breaches. If
            you believe your Personal Data has been compromised, please contact us at <StyledPolicyLink
              onClick={() => window.open('mailto:support@basepoker.com', '_blank')}>support@basepoker.com</StyledPolicyLink>.</StyledPolicyText>
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Data Breach'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'9.1 When we become aware of a suspected or actual Personal Data breach, we shall conduct an internal investigation and take appropriate remedial measures in a timely manner. Where there is any risk to the rights and freedoms of Data Subjects, we will notify the relevant Supervisory Authorities without undue delay and, when possible, within 72 hours.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'9.2 In the event that a Personal Data breach is likely to result in a high risk to the rights and freedoms of the Data Subject, our Data Protection Officer (DPO) will ensure that all affected Data Subjects are informed of the breach without undue delay.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Privacy Settings'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'10. Although we may allow you to adjust your privacy settings to limit access to certain Personal Data, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users with whom you may choose to share your information. We cannot and do not guarantee that information you post on or transmit to our Service will not be viewed by unauthorized persons. We have taken the necessary steps to protect your Personal Data in transit by utilizing HTTPS on our Website and implementing TLS 1.2 (a strong protocol), ECDHE_RSA with P-256 (a strong key exchange), and AES_256_GCM (a strong cipher).'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Data Retention'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'11.1 We will not retain Personal Data that we process for any purpose or purposes for longer than is necessary to fulfill that purpose or those purposes.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'11.2 We only retain the Personal Data collected from you for as long as your account remains active, or for a limited period as required to fulfill the purposes for which we initially collected the data, unless a longer retention period is mandated by law.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'11.3 Retention periods may vary from a few months for handling inquiries to over ten years to comply with our legal obligations, in line with our Data Retention Policy, resolve disputes, enforce agreements, or protect your vital interests or those of another natural person.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'11.4 With regard to payment processing information, your payment details will be stored for a maximum of eight years. If you wish to remove your saved payment details at any point, you can do so through the Basepoker Cashier.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'11.5 We shall ensure that all employees, agents, contractors, sub-contractors, suppliers, and any other third parties abide by the retention periods we have established and will be appropriately trained to do so.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Data Protection Officer/Representative'} />
        <LinearLayout gap={8}>
          <StyledPolicyText>12.1 Our data protection representative, responsible for matters relating to privacy and data protection at Basepoker, can be reached at: <StyledPolicyLink
            onClick={() => window.open('mailto:support@basepoker.com', '_blank')}>support@basepoker.com</StyledPolicyLink>.</StyledPolicyText>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'12.2 In accordance with applicable legal regulations governing the protection of Personal Data, each request or inquiry will be resolved without undue delay and at the latest within 30 days of receipt.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'12.3 When contacting us and submitting such requests, we will make reasonable efforts to confirm your identity and prevent unauthorized processing of Personal Data.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Accountability'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'13.1 We shall be responsible for and able to demonstrate compliance with the principles outlined above.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'13.2 Basepoker shall perform Data Protection Impact Assessments (DPIAs) periodically and/or for any changes to data Processing activities, in accordance with the Data Protection Impact Assessment Guidelines.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Changes to this Privacy Policy'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'14. As our Company evolves, there may be a need to update this Policy to keep pace with changes to our website, software, services, business, and Applicable Laws. We will, however, always maintain our commitment to respect the privacy of Data Subjects. We will ensure that we notify Data Subjects of any material changes to this Policy by email (using the most recent email address provided) or by posting revisions to this Policy, along with their effective date, in an easy-to-find area of our website.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Validity'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'15. This document was updated on June 18, 2024, and is effective from that date.'} />
          <StyledPolicyText>Contact: <StyledPolicyLink
            onClick={() => window.open('mailto:support@basepoker.com', '_blank')}>support@basepoker.com</StyledPolicyLink></StyledPolicyText>
        </LinearLayout>
      </LinearLayout>
    </LinearLayout>);
};

export default PrivacyPolicy;