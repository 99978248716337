import styled from '@emotion/styled';
import React from 'react';
import { Emoji } from '../../../../store/api/responseTypes';
import { bpSocketService } from '../../../../services/BpWebSocketService';
import { useEmojiList } from '../../../../hooks/useEmojiList';
import _, { throttle } from 'lodash';
import useDevice from '../../../../hooks/useDevice';
import { useTableContext } from '../../../../hooks/TableProvider';

const EmojiBtn = () => {
  const { isChatEnable } = useTableContext();
  const { isMobile } = useDevice();
  const [isOpenEmoji, setIsOpenEmoji] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const emojiList = useEmojiList();
  const emojiBtnIcon = emojiList?.[0]?.emojiUrl;

  const handleEmojiClick = React.useCallback(
    throttle((emoji: Emoji) => {
      bpSocketService.chatEmojiSend(emoji.alias);
      setIsOpenEmoji(false);
    }, 3000),
    []
  );

  const handleClickOutSide = (e: React.BaseSyntheticEvent | MouseEvent) => {
    if (isOpenEmoji && !ref.current?.contains(e.target)) {
      setIsOpenEmoji(false);
    }
  };

  React.useEffect(() => {
    if (isOpenEmoji) document.addEventListener('mousedown', handleClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, [isOpenEmoji]);

  return (
    <EmojiWrap>
      <img alt={'emoji-logo'} onClick={() => setIsOpenEmoji(prev => !prev)} src={emojiBtnIcon} style={{ width: isMobile ? '24px' : '2.5em', height: isMobile ? '24px' : '2.5em' }} />
      {isOpenEmoji && isChatEnable && (
        <EmojiPanel ref={ref} isOpen={isOpenEmoji}>
          {emojiList?.map((emoji, idx) => <img alt={emoji.name} key={idx} onClick={() => handleEmojiClick(emoji)} src={emoji.emojiUrl} />)}
        </EmojiPanel>
      )}
    </EmojiWrap>
  );
};

const EmojiPanel = styled.div<{ isOpen: boolean }>`
  position: absolute;
  display: ${({ isOpen }) => (isOpen ? 'grid' : 'none')};
  background-color: ${({ theme }) => theme.colors.gray50};
  padding: 16px;
  border-radius: 12px;
  z-index: 101;
  right: 6px;
  bottom: 6px;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
  box-shadow: 0px 6px 10px 4px rgba(16, 24, 40, 0.8);

  img {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 768px) {
    bottom: 130%;
    right: 0;
  }
`;

const EmojiWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default React.memo(EmojiBtn);
