import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Bookmark = React.memo<IconProps>(({ w = 24, h = 24, color = 'white' }) => (
  <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2413 2H9.7587C8.95374 1.99999 8.28937 1.99998 7.74818 2.04419C7.18608 2.09012 6.66937 2.18868 6.18404 2.43598C5.43139 2.81947 4.81947 3.43139 4.43598 4.18404C4.18868 4.66937 4.09012 5.18608 4.04419 5.74818C3.99998 6.28937 3.99999 6.95372 4 7.75869V21C4 21.3565 4.18976 21.686 4.49807 21.8649C4.80639 22.0438 5.18664 22.0451 5.49614 21.8682L12 18.1518L18.5039 21.8682C18.8134 22.0451 19.1936 22.0438 19.5019 21.8649C19.8102 21.686 20 21.3565 20 21V7.75873C20 6.95376 20 6.28937 19.9558 5.74818C19.9099 5.18608 19.8113 4.66937 19.564 4.18404C19.1805 3.43139 18.5686 2.81947 17.816 2.43598C17.3306 2.18868 16.8139 2.09012 16.2518 2.04419C15.7106 1.99998 15.0463 1.99999 14.2413 2ZM12 6C12.5523 6 13 6.44772 13 7V9H15C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H13V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V11H9C8.44772 11 8 10.5523 8 10C8 9.44772 8.44772 9 9 9H11V7C11 6.44772 11.4477 6 12 6Z"
      fill={Colors[color]}
    />
  </svg>
));

Bookmark.displayName = 'Bookmark';
