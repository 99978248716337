import React, { useState } from 'react';
import styled from '@emotion/styled';
import { LobbyTableList } from '../../../store/api/responseTypes';
import { ReactComponent as EmptyIcon } from 'src/assets/img/lobby-empty.svg';
import Text from '../../common/text/Text';
import { useDispatch } from 'react-redux';
import { ModalType, showModal } from '../../../store/slices/modalSlice';
import BaseButton from '../../common/button/BaseButton';
import { useAuthContext } from '../../../hooks/AuthContext';
import TableItem from './TableItem';

interface TableListTemplateProps {
  tableList?: LobbyTableList;
  selectedTable?: number;
  setSelectedTable?: (tableId: number) => void;
  assetFilter?: string;
  type?: 'lobby' | 'landing';
}

const TableListTemplate = ({ tableList, selectedTable, setSelectedTable, assetFilter, type = 'lobby' }: TableListTemplateProps) => {
  const { isAuthorized } = useAuthContext();
  const [isScrolling, setIsScrolling] = useState(false);
  const onScrollHandler = (event: any) => {
    const scrollTop = event.target.scrollTop;
    setIsScrolling(scrollTop !== 0);
  };

  const dispatch = useDispatch();

  const showAuthModal = () => {
    dispatch(showModal(ModalType.AuthModal));
  };

  const showCreateTableModal = () => {
    dispatch(showModal(ModalType.CreateTableModal));
  };

  const onCreateTableClick = () => {
    isAuthorized ? showCreateTableModal() : showAuthModal();
  };

  const isEmpty =
    tableList?.my.filter(info => (assetFilter === 'ALL' ? true : info.asset === assetFilter)).length === 0 &&
    tableList?.joined.filter(info => (assetFilter === 'ALL' ? true : info.asset === assetFilter)).length === 0 &&
    tableList?.general.filter(info => (assetFilter === 'ALL' ? true : info.asset === assetFilter)).length === 0;

  return (
    <StyledWrap type={type}>
      <StyledHead>
        <StyledHeadWrap>
          <div />
          <div>Table Name</div>
          <div>Stakes</div>
          <div>Buy-in</div>
          <div>Players</div>
        </StyledHeadWrap>
      </StyledHead>
      <StyledListWrap type={type} onScroll={onScrollHandler}>
        {tableList && !isEmpty ? (
          <StyledTableList>
            {Object.keys(tableList!).map(
              key =>
                tableList?.[key as keyof typeof tableList]
                  .filter(info => (assetFilter === 'ALL' ? true : info?.asset === assetFilter))
                  .map(tableInfo => <TableItem key={tableInfo.tableId} isMyTable={key !== 'general'} tableInfo={tableInfo} selectedTable={selectedTable} setSelectedTable={setSelectedTable} />)
            )}
          </StyledTableList>
        ) : (
          <StyledEmpty>
            <EmptyIcon />
            <Text fontStyle={'TextSmall_Medium'} textColor={'white'} text={'Be a Hold’em game host'} marginBottom={10} />
            <BaseButton textStyle="TextSmall_Semibold" size="btn40" color="primary" textColor="white" onClick={onCreateTableClick}>
              Create Table
            </BaseButton>
          </StyledEmpty>
        )}
      </StyledListWrap>
    </StyledWrap>
  );
};

const StyledEmpty = styled.div`
  width: 164px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  @media (max-width: 768px) {
    min-height: 100%;
  }
`;

const StyledListWrap = styled.div<{ type: 'lobby' | 'landing' }>`
  position: relative;
  overflow: scroll;
  height: 100%;
  padding-top: 48px;
  padding-bottom: 16px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-top: 34px;
    padding-bottom: 8px;
    overflow: ${({ type }) => (type === 'lobby' ? 'inherit' : 'scroll')};
    height: ${({ type }) => (type === 'lobby' ? 'max-content' : '100%')};
    ${({ type }) => type === 'lobby' && 'min-height: 482px;'}
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledBaseItem = styled.div<{ isSelected?: boolean; isMyTable?: boolean }>`
  display: flex;
  gap: 16px;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
  }

  div:nth-of-type(1) {
    width: 20px;
  }

  div:nth-of-type(2) {
    flex: 4;
  }

  div:nth-of-type(3) {
    flex: 3;
  }

  div:nth-of-type(4) {
    flex: 1.5;
  }

  div:last-of-type {
    flex: 2.5;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    gap: 12px;
    div:nth-of-type(1) {
      width: 16px;
    }

    div:nth-of-type(2) {
      flex: 3;
    }

    div:nth-of-type(3) {
      flex: 3;
    }

    div:nth-of-type(4) {
      flex: 1.4;
    }

    div:last-of-type {
      flex: 1 !important;
    }
  }
`;

const StyledHeadWrap = styled(StyledBaseItem)`
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0 8px !important;
  }
`;

const StyledHead = styled.div`
  display: flex;
  z-index: 5;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 16px;
  ${({ theme }) => theme.textStyles.TextMd_Medium}
  height: 48px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray750};
  justify-content: flex-start;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

  div {
    color: ${({ theme }) => theme.colors.gray400};
  }

  div:last-of-type {
    flex: 1;
    width: 100%;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    padding: 0 8px;
    height: 34px;
    ${({ theme }) => theme.textStyles.TextXm_Medium}
  }
`;

const StyledWrap = styled.div<{ type: 'lobby' | 'landing' }>`
  position: relative;
  width: 100%;
  border-radius: ${({ type }) => (type === 'lobby' ? '12px' : '12px 12px 0 0')};
  overflow: hidden;
  user-select: none;
  min-height: ${({ type }) => (type === 'lobby' ? '618px' : '100%')};
  max-height: ${({ type }) => (type === 'lobby' ? '55vh' : '100%')};
  height: ${({ type }) => (type === 'lobby' ? '55vh' : '100%')};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray800};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    border-radius: 0;
    max-height: max-content;
    height: max-content;
    min-height: ${({ type }) => (type === 'lobby' ? '482px' : '100%')};
    margin-bottom: 64px;
  }
`;

const StyledTableList = styled.div`
  overflow: scroll;
  display: flex;
  box-sizing: border-box;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
  gap: 8px;
  @media (max-width: 768px) {
    padding-top: 14px;
    gap: 8px;
    height: max-content;
  }
`;

export default TableListTemplate;
