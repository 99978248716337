import { store } from 'src/store/store';

export const baseWss = process.env.REACT_APP_BASE_WSS;
export const apiUrl = process.env.REACT_APP_BASE_API;
export const avatarBaseUrl = process.env.REACT_APP_BASE_ASS + '/profile/preset';
export const tableBaseUrl = process.env.REACT_APP_BASE_ASS + '/image/table/';

export function waitForReduxStateChange<T>(selector: (state: any) => T, condition: (selectedValue: T) => boolean, timeout: number = 5000): Promise<void> {
  return new Promise((resolve, reject) => {
    let timeoutId: NodeJS.Timeout | null = setTimeout(() => {
      unsubscribe(); // 타임아웃 발생 시 구독 취소
      reject(new Error('Timeout waiting for Redux state change'));
    }, timeout);
    // 상태 변경을 감지하는 리스너 함수
    const listener = () => {
      const selectedValue = selector(store.getState());
      if (condition(selectedValue)) {
        if (timeoutId) clearTimeout(timeoutId); // 조건 충족 시 타임아웃 클리어
        unsubscribe(); // 조건 충족 시 구독 취소
        resolve();
      }
    };
    // 스토어에 리스너 등록 및 구독 취소 함수 저장
    const unsubscribe = store.subscribe(listener);
    // 초기 조건 검사
    listener(); // 컴포넌트 마운트 시 조건이 이미 만족되었는지 검사
  });
}
