import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import BaseButton from '../../common/button/BaseButton';
import Collapse from '../../common/Collapse';
import useDevice from '../../../hooks/useDevice';

const faqs = [
  {
    title: 'What is Basepoker?',
    desc: 'Basepoker is built from the ground up to be crypto-native. This means you can use any cryptocurrency as chips. Whether you prefer Bitcoin, Ethereum, or any other digital currency, you can easily integrate your crypto assets into the game. Just visit the site, connect your crypto wallet, and you’re ready to go. We wanted to remove as many barriers as possible to make it easy for anyone to start playing.'
  },
  {
    title: 'Is Basepoker fair?',
    desc: 'Absolutely! Basepoker’s decentralized Random Number Generator (RNG) ensures fairness by using input from all players at the table to shuffle the deck. It employs the SHA-256 cryptographic hash function, which is highly secure and impossible to reverse engineer. This allows players to verify the randomness of the shuffle and guarantees the integrity of each game.'
  },
  {
    title: 'What payment methods does Basepoker accept?',
    desc: 'Basepoker supports multiple cryptocurrency options for deposits. You can use, Ethereum (USDT, MNT), Polygon (USDT), and Base (BRETT). You can deposit directly from your crypto wallet or crypto exchanges.'
  },
  {
    title: 'Are my funds safe on Basepoker?',
    desc: 'Yes, your funds are secure on Basepoker. We use Proof of Reserves to guarantee that all user deposits are backed 1:1, ensuring complete transparency. Additionally, we use multi-auth vaults and advanced Multi-Party Computation (MPC) technology to protect your funds from any unauthorized access or threats.'
  },
  {
    title: 'Can I play on Basepoker using my mobile device?',
    desc: 'Yes, you can play on Basepoker.com using your mobile device. We are a browser-based online poker site, which means you can access our platform from any device with a web browser, including smartphones and tablets.\\nWe recommend using browsers such as Chrome for Android devices and Safari for iOS devices to ensure the best performance and compatibility. For optimal experience, ensure your mobile device has a stable internet connection to enjoy seamless gameplay on Basepoker.com.'
  },
  {
    title: 'Can I play multiple tables simultaneously on Basepoker?',
    desc: 'Yes, you can play multiple tables simultaneously on Basepoker.com. There is no limit to the number of tables you can join or create. However, the number of tables you can effectively manage simultaneously may depend on the processing power of your device. We recommend ensuring your computer can handle multiple instances of the game for the best experience.'
  }
];

const LandingFaq = () => {
  const [expandedIndex, setExpandedIndex] = React.useState<number | null>(0);
  const { isMobile } = useDevice();

  const goSupportCenter = () => {
    window.open(`https://support.basepoker.com`, '_blank');
  };

  return (
    <StyledWrap>
      <StyledTitleWrap>
        <StyledTitle textAlign={'start'} fontStyle={'DisplaySm_Semibold'} text={'What would you like to\\n know about Basepoker?'} lineHeight={30} />
        <BaseButton size={isMobile ? 'btn44' : 'btn48'} color={'primary'} textStyle={'TextMd_Semibold'} text={'Support Center'} w={150} onClick={goSupportCenter} />
      </StyledTitleWrap>
      <StyledFaqs>
        {faqs.map((faq, index) => (
          <Collapse key={index} expanded={index === expandedIndex} onToggle={() => setExpandedIndex(index)} title={faq.title}>
            <Text lineHeight={24} textColor={'gray300'} textAlign={'start'} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} text={faq.desc} />
          </Collapse>
        ))}
      </StyledFaqs>
    </StyledWrap>
  );
};

const StyledTitle = styled(Text)`
    line-height: 38px;
    @media (max-width: 1280px) {
        ${({ theme }) => theme.textStyles.TextXl_Semibold}
        line-height: 30px;
    }
    @media (max-width: 768px) {
        ${({ theme }) => theme.textStyles.TextMd_Semibold}
        line-height: 24px;
`;
const StyledFaqs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  gap: 8px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StyledWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1280px) {
    gap: 16px;
    padding: 0 40px;
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
    gap: 32px;
  }
`;

const StyledTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 240px;
  @media (max-width: 768px) {
    gap: 24px;
  }
`;

export default LandingFaq;
