import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const Usdc = React.memo<IconProps>(({ w = 32, h = 32, color = 'white' }) => (
  <svg width={w} height={h} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='16' cy='16' r='16' fill='white' />
    <g clipPath='url(#clip0_8130_197289)'>
      <path
        d='M15.9986 29.218C23.2984 29.218 29.216 23.3004 29.216 16.0006C29.216 8.70083 23.2984 2.7832 15.9986 2.7832C8.69888 2.7832 2.78125 8.70083 2.78125 16.0006C2.78125 23.3004 8.69888 29.218 15.9986 29.218Z'
        fill='#2775CA' />
      <path
        d='M13.5758 25.1424C13.5758 25.4728 13.3555 25.583 13.0251 25.583C8.94971 24.2612 6.08594 20.5163 6.08594 16.1105C6.08594 11.7047 8.94971 7.95977 13.0251 6.63803C13.3555 6.52788 13.5758 6.74817 13.5758 7.07861V7.84962C13.5758 8.06991 13.4656 8.2902 13.2454 8.40035C10.0512 9.61194 7.84826 12.5859 7.84826 16.1105C7.84826 19.6351 10.1613 22.7192 13.2454 23.8206C13.4656 23.9308 13.5758 24.1511 13.5758 24.3714V25.1424Z'
        fill='white' />
      <path
        d='M16.8808 22.3889C16.8808 22.6092 16.6605 22.8295 16.4402 22.8295H15.559C15.3387 22.8295 15.1184 22.6092 15.1184 22.3889V21.0672C13.3561 20.8469 12.475 19.8556 12.1445 18.4237C12.1445 18.2034 12.2547 17.9831 12.475 17.9831H13.4663C13.6866 17.9831 13.7967 18.0933 13.9069 18.3135C14.1271 19.0846 14.5677 19.7454 15.9996 19.7454C17.1011 19.7454 17.8721 19.1947 17.8721 18.3135C17.8721 17.4324 17.4315 17.102 15.8895 16.8817C13.5764 16.5512 12.475 15.8904 12.475 14.0179C12.475 12.586 13.5764 11.4846 15.1184 11.2643V9.94253C15.1184 9.72224 15.3387 9.50195 15.559 9.50195H16.4402C16.6605 9.50195 16.8808 9.72224 16.8808 9.94253V11.2643C18.2025 11.4846 19.0837 12.2556 19.304 13.4672C19.304 13.6875 19.1938 13.9078 18.9735 13.9078H18.0924C17.8721 13.9078 17.7619 13.7976 17.6518 13.5773C17.4315 12.8063 16.8808 12.4759 15.8895 12.4759C14.788 12.4759 14.2373 13.0266 14.2373 13.7976C14.2373 14.5686 14.5677 15.0092 16.2199 15.2295C18.5329 15.5599 19.6344 16.2208 19.6344 18.0933C19.6344 19.5251 18.5329 20.7367 16.8808 21.0672V22.3889Z'
        fill='white' />
      <path
        d='M18.9726 25.5836C18.6422 25.6937 18.4219 25.4735 18.4219 25.143V24.372C18.4219 24.1517 18.532 23.9314 18.7523 23.8213C21.9465 22.6097 24.1494 19.6358 24.1494 16.1111C24.1494 12.5865 21.8364 9.50244 18.7523 8.40099C18.532 8.29085 18.4219 8.07056 18.4219 7.85027V7.07925C18.4219 6.74882 18.6422 6.63867 18.9726 6.63867C22.9378 7.96041 25.9117 11.7053 25.9117 16.1111C25.9117 20.5169 23.048 24.2619 18.9726 25.5836Z'
        fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_8130_197289'>
        <rect width='26.4348' height='26.4348' fill='white' transform='translate(2.78125 2.7832)' />
      </clipPath>
    </defs>
  </svg>
));

Usdc.displayName = 'Usdc';
