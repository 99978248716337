import React from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import LinearLayout from '../../layout/LinearLayout';
import useDevice from '../../../hooks/useDevice';
import { StyledPolicyLink, StyledPolicyText } from './styles';

const CookiePolicy = () => {
  const { isMobile } = useDevice();
  return (
    <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40} gap={24}>
      <Text lineHeight={20} fontStyle={isMobile ? 'TextMd_Bold' : 'TextLg_Bold'} textColor={'white'} text={'Cookie Policy'} textAlign={'start'} />
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'BASEPOKER.COM'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Last updated: June 17th, 2024'} />
        <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'} text={'Version C20240617.1'} />
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Introduction'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'This Cookie Policy explains how Basepoker ("we", "us", or "our") uses cookies and similar technologies on our website.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'What Are Cookies'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Cookies are small text files that are placed on your device when you visit a website. They are commonly used to make websites work more efficiently and to provide information to the website owners.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'How We Use Cookies'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Essential Cookies: These cookies are necessary for the operation of our website. They enable basic functions like page navigation and access to secure areas of the website. Without these cookies, the website may not function properly.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Analytical/Performance Cookies: We use these cookies to analyze how visitors use our website and to monitor website performance. This helps us improve the website\'s functionality and user experience.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Functionality Cookies: These cookies allow the website to remember choices you make and provide enhanced features and personalization. For example, they may remember your username or language preferences.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Advertising Cookies: We may use these cookies to deliver personalized advertisements and promotional content based on your interests and browsing behavior. These cookies may track your browsing activity across different websites.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Type of Cookies We Use'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Below is a list of the types of cookies we use on our website:'} />
          <LinearLayout>
            <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                  text={' • Session Cookies: These cookies are temporary and are deleted when you close your browser.'} />
            <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                  text={' • Persistent Cookies: These cookies remain on your device for a specified period or until you delete them.'} />
            <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                  text={' • First-Party Cookies: These cookies are set by our website and can only be read by our website.'} />
            <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                  text={' • Third-Party Cookies: These cookies are set by third-party services that we use, such as analytics and advertising providers.'} />
          </LinearLayout>
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Third-Party Cookies'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'We may also use third-party cookies provided by trusted partners for advertising and analytics purposes. These cookies may collect information about your browsing activity and interactions with our website and other websites. Our third-party partners may use cookies to serve ads based on your past visits to our website and other websites.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Your Cookie Choices'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'You have the option to manage your cookie preferences by adjusting your browser settings to block or delete cookies. Please note that blocking or deleting cookies may affect your experience on our website and limit certain features.'} />
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'Additionally, you can opt-out of personalized advertising by visiting the Network Advertising Initiative (NAI) opt-out page or the Digital Advertising Alliance (DAA) opt-out page. You can also adjust your advertising preferences on platforms such as Google Ads Settings and Facebook Ad Preferences.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Consent'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'By continuing to use our website, you consent to the use of cookies as described in this Cookie Policy. If you do not consent to the use of cookies, you should discontinue using our website or adjust your browser settings accordingly.'} />
        </LinearLayout>
      </LinearLayout>
      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Changes to this Privacy Policy'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'As our Company evolves, there may be a need to update this Policy to keep pace with changes to our website, software, services, business, and Applicable Laws. We will, however, always maintain our commitment to respect the privacy of Data Subjects. We will ensure that we notify Data Subjects of any material changes to this Policy by email (using the most recent email address provided) or by posting revisions to this Policy, along with their effective date, in an easy-to-find area of our website.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Validity'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'This document was updated on June 18, 2024, and is effective from that date.'} />
          <StyledPolicyText>Contact: <StyledPolicyLink
            onClick={() => window.open('https://help@basepoker.com', '_blank')}>help@basepoker.com</StyledPolicyLink></StyledPolicyText>
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Changes to this Policy'} />
        <LinearLayout gap={8}>
          <Text lineHeight={20} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'white'}
                text={'We may update this Cookie Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website.'} />
        </LinearLayout>
      </LinearLayout>

      <LinearLayout gap={12}>
        <Text lineHeight={20} fontStyle={'TextSmall_Bold'} textAlign={'start'} textColor={'white'} text={'Contact Us'} />
        <LinearLayout gap={8}>
          <StyledPolicyText>If you have any questions, concerns, or requests regarding this Cookie Policy or our use of cookies, please contact us at <StyledPolicyLink
            onClick={() => window.open('https://help@basepoker.com', '_blank')}>help@basepoker.com</StyledPolicyLink></StyledPolicyText>

        </LinearLayout>
      </LinearLayout>

    </LinearLayout>
  );
};


export default CookiePolicy;