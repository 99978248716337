import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { basePokerApi } from './api/api';
import modalStateSlice from './slices/modalSlice';
import streamingSlice from './slices/streaming';
import authSlice from './slices/authSlice';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import utilSlice from './slices/utilSlice';
import * as Sentry from '@sentry/react';
import tableSlice from './slices/tableSlice';
import { logDebug } from '../utils/ConsoleUtil';
import userAssetSlice from './slices/assetSlice';
import tournamentSlice from './slices/tournamentSlice';

const logger = createLogger({});

const isDev = process.env.NODE_ENV === 'development';

const persistConfig = {
  key: 'root',
  version: 4,
  storage,
  whitelist: ['authSlice', 'tableSlice', 'utilSlice'],
  blacklist: ['streamingSlice', 'modals', 'userAssetSlice']
};

const rootReducer = combineReducers({
  authSlice: authSlice.reducer,
  userAssetSlice: userAssetSlice.reducer,
  tableSlice: tableSlice.reducer,
  streamingSlice: streamingSlice.reducer,
  tournamentSlice: tournamentSlice.reducer,
  utilSlice: utilSlice.reducer,
  modalStateSlice: modalStateSlice.reducer,
  [basePokerApi.reducerPath]: basePokerApi.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const persistChangedMiddleware: Middleware = store => next => action => {
  switch (action.type) {
    case REHYDRATE:
    case REGISTER:
    case FLUSH:
    case PAUSE:
    case PERSIST:
    case PURGE:
      logDebug('Persist state has been ' + action.type);
      break;
    default:
      break;
  }
  return next(action);
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(basePokerApi.middleware, persistChangedMiddleware),
  devTools: isDev,
  enhancers: getDefaultEnhancers => {
    return getDefaultEnhancers.concat(sentryReduxEnhancer);
  }
});

// Define Root State and Dispatch Types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Define Typed Hooks
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const persistor = persistStore(store);
