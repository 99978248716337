type ActionSoundType = 'bet' | 'all-in' | 'call' | 'check' | 'fold' | 'raise';
export type SoundType = ActionSoundType | `voice-${ActionSoundType}` | 'card-flip' | 'chip-gathering' | 'chip-multi' | 'chip-single' | 'countdown' | 'my-card-flip' | 'my-turn' | 'villain-win' | 'win';

interface IPlayingSource {
  source: AudioBufferSourceNode | null;
  gainNode: GainNode | null;
  timeoutId?: NodeJS.Timeout;
}

type VolumeLevel = 0 | 1;

class SoundManager {
  public static _instance: SoundManager | null = null;
  public audioContext!: AudioContext; // 초기화는 create에서
  private audios: Map<SoundType, AudioBuffer> = new Map();
  private playingSources: Map<SoundType, IPlayingSource> = new Map();
  private volume: VolumeLevel = 1;
  private voiceVolume: VolumeLevel = 1;

  private constructor() {}

  private ensureAudioContext(): void {
    const resumeAudio = () => {
      if (this.audioContext.state === 'suspended') {
        this.audioContext.resume().then(() => {});
      }
    };

    document.addEventListener('click', resumeAudio);
    document.addEventListener('touchend', resumeAudio);
  }

  public static async create(): Promise<SoundManager> {
    if (SoundManager._instance) {
      await SoundManager._instance.audioContext.close().catch(() => {}); // 기존 AudioContext 종료
    }

    const manager = new SoundManager();
    manager.audioContext = new AudioContext();
    manager.ensureAudioContext();

    await manager.loadSounds({
      bet: require('src/assets/sound/bet.wav'),
      'all-in': require('src/assets/sound/all-in.wav'),
      call: require('src/assets/sound/call.wav'),
      check: require('src/assets/sound/check.wav'),
      fold: require('src/assets/sound/fold.wav'),
      raise: require('src/assets/sound/raise.wav'),
      'voice-bet': require('src/assets/sound/voice-bet.wav'),
      'voice-all-in': require('src/assets/sound/voice-all-in.wav'),
      'voice-call': require('src/assets/sound/voice-call.wav'),
      'voice-check': require('src/assets/sound/voice-check.wav'),
      'voice-fold': require('src/assets/sound/voice-fold.wav'),
      'voice-raise': require('src/assets/sound/voice-raise.wav'),
      'card-flip': require('src/assets/sound/card-flip.wav'),
      'chip-gathering': require('src/assets/sound/chip-gathering.wav'),
      'chip-multi': require('src/assets/sound/chip-multi.wav'),
      'chip-single': require('src/assets/sound/chip-single.wav'),
      countdown: require('src/assets/sound/countdown.wav'),
      'my-card-flip': require('src/assets/sound/my-card-flip.wav'),
      'my-turn': require('src/assets/sound/my-turn.wav'),
      'villain-win': require('src/assets/sound/villain-win.wav'),
      win: require('src/assets/sound/win.wav')
    });

    SoundManager._instance = manager; // 새로 생성된 인스턴스를 저장
    return SoundManager._instance;
  }

  private async loadSounds(soundData: Record<SoundType, string>): Promise<void> {
    const promises = Object.entries(soundData).map(async ([key, url]) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
      this.audios.set(key as SoundType, audioBuffer);
    });

    await Promise.all(promises);
  }

  public setSoundsVolume(volume: VolumeLevel): void {
    this.volume = volume;
    this.playingSources.forEach(source => {
      if (source.gainNode) {
        source.gainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
      }
    });
  }

  public setVoiceVolume(volume: VolumeLevel): void {
    this.voiceVolume = volume;
    this.playingSources.forEach((source, key) => {
      if (source.gainNode && key.startsWith('voice-')) {
        source.gainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
      }
    });
  }

  public playSound(soundType: SoundType, delay: number = 0): void {
    if (this.audioContext.state === 'suspended') return;
    const audioBuffer = this.audios.get(soundType);
    if (audioBuffer) {
      if (delay > 0) {
        const timeoutId = setTimeout(() => this.startSound(soundType, audioBuffer), delay * 1000);
        this.playingSources.set(soundType, { source: null, gainNode: null, timeoutId });
      } else {
        this.startSound(soundType, audioBuffer);
      }
    }
  }

  private startSound(soundType: SoundType, audioBuffer: AudioBuffer): void {
    const gainNode = this.audioContext.createGain();
    if (soundType.startsWith('voice-')) {
      gainNode.gain.setValueAtTime(this.volume && this.voiceVolume ? 1 : 0, this.audioContext.currentTime);
    } else {
      gainNode.gain.setValueAtTime(this.volume, this.audioContext.currentTime);
    }
    gainNode.connect(this.audioContext.destination);
    const source = this.audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(gainNode);
    if (this.volume === 0) {
      return;
    } else {
      source.start();
      this.playingSources.set(soundType, { source, gainNode });
      source.onended = () => {
        this.playingSources.delete(soundType);
      };
    }
  }

  public stopSound(soundType: SoundType, fadeDuration: number = 1.0): void {
    const playingInfo = this.playingSources.get(soundType);
    if (playingInfo) {
      const { source, gainNode, timeoutId } = playingInfo;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (source && gainNode) {
        gainNode.gain.setValueAtTime(gainNode.gain.value, this.audioContext.currentTime);
        gainNode.gain.exponentialRampToValueAtTime(0.001, this.audioContext.currentTime + fadeDuration);
        source.stop(this.audioContext.currentTime + fadeDuration);
        source.onended = null;
      }
    }
  }
}

export default SoundManager;
