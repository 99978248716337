import React from 'react';
import { IconProps } from '../types';
import { Colors } from 'src/styles/Colors';

export const ArrowDown = React.memo<IconProps>(({ w = 18, h = 18, color = 'white' }) => (
  <svg width={w} height={h} color={Colors[color]} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3.75V14.25M9 14.25L14.25 9M9 14.25L3.75 9" stroke={Colors[color]} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
));

ArrowDown.displayName = 'ArrowDown';
