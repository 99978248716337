import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import TextInput, { InputProps } from './TextInput';
import { selectCurrentEmail, selectIsEmailValid, setEmail } from '../../../store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux'; // TextInput 경로에 맞게 조정

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error500};
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  margin-top: 4px;
`;

const EmailInput: React.FC<InputProps> = ({ ...props }) => {
  const email = useSelector(selectCurrentEmail);
  const isEmailValid = useSelector(selectIsEmailValid);
  const dispatch = useDispatch();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmail(e.target.value));
  };

  return (
    <Container>
      <TextInput type="email" value={email || ''} onChange={handleEmailChange} {...props} placeholder={props.placeholder} />
      {!isEmailValid && <ErrorMessage>Please enter a valid email</ErrorMessage>}
    </Container>
  );
};

export default EmailInput;
