import { ApiResponse, basePokerApi, createQueryParams, handleTransformErrorResponse, handleTransformResponse } from './api';
import { TournamentConfig, TournamentData, TournamentListResponse } from './tournamentTypes';
import { TournamentCreateRequestBody, TournamentListRequestDto, TournamentStatusChangeRequest } from './dto';

export const tournamentApi = basePokerApi.injectEndpoints({
  endpoints: build => ({
    tournamentConfig: build.query<ApiResponse<TournamentConfig>, void>({
      query: () => `tournaments/config`,
      transformResponse: handleTransformResponse('tournamentConfig'),
      transformErrorResponse: handleTransformErrorResponse('tournamentConfig')
    }),
    tournamentList: build.query<ApiResponse<TournamentListResponse>, TournamentListRequestDto>({
      query: (tournamentListRequestDto: TournamentListRequestDto) => ({
        url: `tournaments?${createQueryParams(tournamentListRequestDto)}`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse('tournamentList'),
      transformErrorResponse: handleTransformErrorResponse('tournamentList'),
      providesTags: result =>
        result
          ? [
              ...result.data.tournaments.map(({ id }) => ({
                type: 'Tournament' as const,
                id
              })),
              { type: 'Tournament', id: 'LIST' }
            ]
          : [{ type: 'Tournament', id: 'LIST' }]
    }),
    createTournament: build.mutation<ApiResponse<TournamentData>, TournamentCreateRequestBody>({
      query: (tournamentCreateRequestBody: TournamentCreateRequestBody) => ({
        url: 'tournaments',
        method: 'POST',
        body: tournamentCreateRequestBody
      }),
      transformResponse: handleTransformResponse('createTournament'),
      transformErrorResponse: handleTransformErrorResponse('createTournament'),
      invalidatesTags: ['Tournament']
    }),
    testTournamentStatusChange: build.mutation<ApiResponse<void>, TournamentStatusChangeRequest>({
      query: (tournamentStatusChangeRequest: TournamentStatusChangeRequest) => ({
        url: `tournaments/${tournamentStatusChangeRequest.tournamentId}/status`,
        method: 'PATCH'
      }),
      transformResponse: handleTransformResponse('testTournamentStatusChange'),
      transformErrorResponse: handleTransformErrorResponse('testTournamentStatusChange'),
      invalidatesTags: (result, error, arg) => [{ type: 'Tournament', id: arg.tournamentId }]
    }),
    testTournamentBlindStatusChange: build.mutation<ApiResponse<void>, TournamentStatusChangeRequest>({
      query: (tournamentStatusChangeRequest: TournamentStatusChangeRequest) => ({
        url: `tournaments/${tournamentStatusChangeRequest.tournamentId}/up-blind`,
        method: 'PATCH'
      }),
      transformResponse: handleTransformResponse('testTournamentBlindStatusChange'),
      transformErrorResponse: handleTransformErrorResponse('testTournamentBlindStatusChange'),
      invalidatesTags: (result, error, arg) => [{ type: 'Tournament', id: arg.tournamentId }]
    })
  })
});

export const { useTournamentConfigQuery, useLazyTournamentListQuery, useCreateTournamentMutation, useTestTournamentStatusChangeMutation, useTestTournamentBlindStatusChangeMutation } = tournamentApi;
