import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const Amount = styled(motion.div)<{ type?: 'pot' | 'bet' | 'win' }>`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1em;
  padding: 2px 6px;
  border-radius: 100px;
  line-height: 1.25em;
  white-space: nowrap;
  background: rgba(29, 41, 57, 0.4);
  @media (max-width: 768px) {
    font-size: ${({ type }) => (type === 'pot' ? '12px' : '9px')};
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25em;
  width: 5em;
  height: auto;
`;

export const StackWrap = styled.div`
  display: flex;
  gap: 0.25em;
`;

export const ChipStack = styled.div<{ maxStack: number; chipGap: number }>`
  position: relative;
  width: 1.5em;
  height: 1.5em;

  ${({ maxStack }) =>
    Array(maxStack)
      .fill(null)
      .map(
        (_, i) => css`
          svg:nth-of-type(${i + 1}) {
            --i: ${i};
          }
        `
      )}
  svg:first-of-type {
    ${({ theme }) => theme.shadow.chip};
  }

  svg {
    position: absolute;
    bottom: ${({ chipGap }) => `calc(var(--i) * ${chipGap / 16}em)`};
    left: 0;
    width: 1.5em;
    height: 1.5em;
  }
`;
