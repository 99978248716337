import React, { useMemo, useEffect, useCallback } from 'react';
import { animate, useMotionValue, useTransform } from 'framer-motion';
import { ChipProps, ChipStack, TChipColor } from './types';
import { useUserTableSetting } from '../../../hooks/useUserTableSetting';
import { toFixedFloor } from '../../../utils/StringUtil';
import { divideBigNumbers, modBigNumbers, multiplyBigNumbers, subtractBigNumbers } from '../../../utils/BigNumberUtil';
import { useCreateConfig } from '../../../hooks/useCreateConfig';
import { useTableContext } from '../../../hooks/TableProvider';
import useDevice from '../../../hooks/useDevice';

const chipValues = [
  { decimal: 5000, color: 'brown' },
  { decimal: 2500, color: 'orange' },
  { decimal: 1000, color: 'yellow' },
  { decimal: 500, color: 'purple' },
  { decimal: 100, color: 'black' },
  { decimal: 25, color: 'green' },
  { decimal: 10, color: 'blue' },
  { decimal: 5, color: 'red' },
  { decimal: 1, color: 'white' }
];

type positionType = 'top' | 'left' | 'right' | 'bottom';
export type combinedPositionType = positionType | `${positionType}-${positionType}`;

const useChip = ({ stack, maxStack, chipValueMultiplier, blindAmount, position }: ChipProps) => {
  const [pos, setPos] = React.useState<combinedPositionType>();
  const { tableAssetInfo, betUnit } = useTableContext();
  const userTableSetting = useUserTableSetting();
  const { isMobile } = useDevice();
  const { getAssetInfo } = useCreateConfig();
  const tableAssetConfig = getAssetInfo(tableAssetInfo?.name);
  const chipDecimal = betUnit?.toString()?.split('.')[1]?.length ?? 0;

  const displayStack = useMemo(() => {
    if (userTableSetting.isBlindView && blindAmount?.big) {
      return divideBigNumbers(stack, blindAmount.big).toNumber();
    }
    return stack;
  }, [userTableSetting.isBlindView, stack, blindAmount]);

  const handleConvert = useCallback(() => {
    const chipValueMultiplierDecimal = chipValueMultiplier ?? 1;
    let effectiveAmount = stack > 0 ? Math.max(divideBigNumbers(stack, chipValueMultiplierDecimal).toNumber(), 1) : 0;
    return chipValues.reduce((result: ChipStack[], { decimal, color }) => {
      const numChips = Math.floor(divideBigNumbers(effectiveAmount, decimal).toNumber());
      if (numChips > 0) {
        result.push({ chipColor: color as TChipColor, numChips });
        effectiveAmount = subtractBigNumbers(effectiveAmount, multiplyBigNumbers(decimal, numChips).toNumber()).toNumber();
      }
      return result;
    }, []);
  }, [stack, chipValueMultiplier]);

  const distributeChips = useCallback(() => {
    const chips = handleConvert();
    const totalChips = chips.length;
    const maxChipsPerStack = Math.ceil(totalChips / maxStack!);
    const minChipsPerStack = Math.floor(totalChips / maxStack!);

    return Array.from({ length: maxStack! }, (_, i) => {
      const chipCount = i + 1 >= maxStack! - (totalChips % maxStack!) + 1 ? maxChipsPerStack : minChipsPerStack;
      return chips.splice(0, chipCount);
    });
  }, [handleConvert, maxStack]);

  const animatedStack = useMotionValue(displayStack);

  const rounded = useTransform(animatedStack, latest => {
    const numericValue = latest || 0;

    if (userTableSetting.isBlindView) {
      const hasDecimal = numericValue % 1 !== 0;
      return toFixedFloor(numericValue, hasDecimal ? 1 : 0).toLocaleString() + ' BB';
    }

    if (tableAssetConfig?.assetType === 'BPP') {
      return numericValue.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    }

    return numericValue.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: chipDecimal
    });
  });

  useEffect(() => {
    if (displayStack !== animatedStack.get()) {
      animate(animatedStack, displayStack, { duration: 0.3 });
    }
  }, [displayStack, animatedStack]);

  const calculatePosition = (pos?: combinedPositionType): { left: string; top: string } | undefined => {
    if (!pos) return undefined;
    let x = 0; // 초기 x 위치 (50% 중앙)
    let y = 0; // 초기 y 위치 (50% 중앙)
    // Split the position if it's a combined type
    const [primary, secondary] = pos.split('-') as [positionType, positionType?];

    // Handle primary position (30% movement)
    switch (primary) {
      case 'top':
        // y -= 35;
        y -= 7; //em
        break;
      case 'bottom':
        // y += 105;
        y += 7.8; //em
        break;
      case 'left':
        x -= 7.5; // Move left 30%
        break;
      case 'right':
        x += 7.5; // Move right 30%
        break;
    }
    // Handle secondary position (10% movement)
    if (secondary) {
      switch (secondary) {
        case 'top':
          y -= isMobile ? 2 : 3; // Move up 10%
          break;
        case 'bottom':
          y += isMobile ? 2 : 3; // Move down 10%
          break;
        case 'left':
          x -= isMobile ? 1 : 2; // Move left 10%
          break;
        case 'right':
          x += isMobile ? 1 : 2; // Move right 10%
          break;
      }
    }

    // Ensure values remain between 0 and 100
    const value = { left: `${x}em`, top: `${y}em` };
    return value;
  };
  useEffect(() => {
    if (!position) return;
    if (isMobile) {
      if (position.y < 50) {
        setPos('bottom');
      } else {
        setPos('top');
      }
      return;
    }
    if (position?.x < 15) {
      if (position.y > 50) {
        setPos('right-top');
      } else if (position.y < 50) {
        setPos('right-bottom');
      } else {
        setPos('right');
      }
    } else if (position?.x > 85) {
      if (position.y > 50) {
        setPos('left-top');
      } else if (position.y < 50) {
        setPos('left-bottom');
      } else {
        setPos('left');
      }
    } else {
      if (position.y > 50) {
        if (position.x < 50) {
          setPos('top-right');
        } else if (position.x > 50) {
          setPos('top-left');
        } else {
          setPos('top');
        }
      } else {
        if (position.x < 50) {
          setPos('bottom-right');
        } else if (position.x > 50) {
          setPos('bottom-left');
        } else {
          setPos('bottom');
        }
      }
    }
  }, [position]);

  return {
    rounded,
    distributeChips,
    calculatePosition,
    pos
  };
};

export default useChip;
