import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Text from '../../common/text/Text';
import SeedPhrase from '../room/chatBox/SeedPhrase';
import { useDispatch } from 'react-redux';
import { dismissModal } from '../../../store/slices/modalSlice';
import { ReactComponent as HelpCircle } from '../../../assets/img/help-circle.svg';
import HorizontalLayout from '../../layout/HorizontalLayout';
import BaseButton from '../../common/button/BaseButton';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';

const SeedModal: React.FC = () => {
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showTooltip) {
      timer = setTimeout(() => {
        setShowTooltip(false);
      }, 3000); // Hide tooltip after 3 seconds
    }
    return () => clearTimeout(timer);
  }, [showTooltip]);

  return (
    <Box>
      <Wrap>
        <Top>
          <HorizontalLayout margin={'0'} justifyContent={'start'} gap={4}>
            <Text fontStyle={'TextMd_Bold'} textAlign={'start'} text={'Player Seed'} />
            <StyledTooltipWrap>
              {showTooltip && <Tooltip>The Player Seed is a unique identifier assigned to each player at registration, editable anytime. It ensures randomness and integrity in the card distribution algorithm.</Tooltip>}
              <HelpCircle onClick={() => setShowTooltip(!showTooltip)} />
            </StyledTooltipWrap>
          </HorizontalLayout>
          <XCloseIcon onClick={() => dispatch(dismissModal())} />
        </Top>
        <SeedPhrase />
      </Wrap>
    </Box>
  );
};

const StyledTooltipWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Tooltip = styled.div`
  position: absolute;
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.gray750};
  background-color: ${({ theme }) => theme.colors.gray50};
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);
  text-align: start;
  border-radius: 6px;
  padding: 12px;
  bottom: 160%;
  left: 370%;
  transform: translateX(-50%);
  width: 280px;
  z-index: 1001;
`;

const Box = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 16px;
  box-sizing: border-box;
`;

const Top = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
export default SeedModal;
