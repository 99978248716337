import React from 'react';
import { Colors } from '../../../../styles/Colors';

interface SeatButtonProps {
  className?: string;
  color?: keyof typeof Colors;
  onClick?: () => void;
}

export const SeatButton = React.memo<SeatButtonProps>(({ className, color = 'gray400', onClick }) => (
  <svg className={className} onClick={onClick} width={82} height={82} viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="41" cy="41" r="41" fill="#101828" fillOpacity="0.6" />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M39.4919 30.75C39.8768 31.4167 40.839 31.4167 41.2239 30.75L47.7191 19.5C48.104 18.8333 47.6229 18 46.8531 18H33.8627C33.0929 18 32.6118 18.8333 32.9967 19.5L39.4919 30.75ZM39.9919 62.75C40.3768 63.4167 41.339 63.4167 41.7239 62.75L55.5803 38.75C55.9652 38.0833 55.4841 37.25 54.7143 37.25L27.0015 37.25C26.2317 37.25 25.7506 38.0833 26.1355 38.75L39.9919 62.75Z"
          fill={Colors[color]} />
  </svg>
));

SeatButton.displayName = 'SeatButton';







