import { ApiResponse, basePokerApi, createQueryParams, handleTransformErrorResponse, handleTransformResponse } from './api';
import { AssetConfig, ChainNetworkType, BalanceResponse, RequestWithdrawRequestDto, CheckUsernameRequestDto, CheckAddressRequestDto, SendRequestDto, SendResponse } from './responseTypes';
import { HistoryListRequestDto, HistoryResponse, TransactionListRequestDto, WalletTransactionResponse } from './dto';

export const assetsApi = basePokerApi.injectEndpoints({
  endpoints: build => ({
    getAssetConfig: build.query<ApiResponse<AssetConfig>, void>({
      query: () => ({
        url: `/asset/config`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse('getAssetConfig'),
      transformErrorResponse: handleTransformErrorResponse('getAssetConfig'),
      providesTags: [{ type: 'Assets', id: 'config' }]
    }),
    getBalance: build.query<ApiResponse<BalanceResponse>, void>({
      query: () => ({
        url: `/asset/get_balance`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse('userBalance'),
      transformErrorResponse: handleTransformErrorResponse('userBalance'),
      providesTags: [{ type: 'Assets', id: 'balance' }]
    }),
    createDepositAddress: build.mutation<ApiResponse<string>, ChainNetworkType>({
      query: (chainNetwork: ChainNetworkType) => ({
        url: `/asset/create_deposit_address`,
        method: 'POST',
        body: { chainNetwork }
      }),
      transformResponse: handleTransformResponse('createDepositAddress'),
      transformErrorResponse: handleTransformErrorResponse('createDepositAddress')
    }),
    requestWithdrawal: build.mutation<ApiResponse<string>, RequestWithdrawRequestDto>({
      query: (dto: RequestWithdrawRequestDto) => ({
        url: `/asset/withdraw`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: ['Assets'],
      transformResponse: handleTransformResponse('requestWithdrawal'),
      transformErrorResponse: handleTransformErrorResponse('requestWithdrawal')
    }),
    requestSend: build.mutation<ApiResponse<SendResponse>, SendRequestDto>({
      query: (dto: SendRequestDto) => ({
        url: `/asset/send`,
        method: 'POST',
        body: dto
      }),
      invalidatesTags: ['Assets'],
      transformResponse: handleTransformResponse('requestSend'),
      transformErrorResponse: handleTransformErrorResponse('requestSend')
    }),
    transactionList: build.query<ApiResponse<WalletTransactionResponse>, TransactionListRequestDto>({
      query: (transactionListRequestDto: TransactionListRequestDto) => ({
        url: `asset/transactions?${createQueryParams(transactionListRequestDto)}`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse(`asset/transactions`),
      transformErrorResponse: handleTransformErrorResponse('asset/transactions'),
      providesTags: (result, error, page) =>
        result
          ? [...result.data.transactions.map(({ id }) => ({ type: 'User' as const, id })), { type: 'User', id: 'PARTIAL-LIST' }]
          : [
              {
                type: 'User',
                id: 'PARTIAL-LIST'
              }
            ]
    }),
    historyList: build.query<ApiResponse<HistoryResponse>, HistoryListRequestDto>({
      query: (historyListRequestDto: HistoryListRequestDto) => ({
        url: `asset/balance_history?${createQueryParams(historyListRequestDto)}`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse(`assetHistories`),
      transformErrorResponse: handleTransformErrorResponse('assetHistories'),
      providesTags: (result, error, page) =>
        result
          ? [...result.data.histories.map(({ id }) => ({ type: 'Assets' as const, id: 'HISTORY-' + id })), { type: 'Assets', id: 'HISTORY-LIST' }]
          : [
              {
                type: 'Assets',
                id: 'HISTORY-LIST'
              }
            ]
    }),
    checkVerifyAddress: build.query<ApiResponse<boolean>, CheckAddressRequestDto>({
      query: (checkUsernameRequestDto: CheckAddressRequestDto) => ({
        url: `asset/verify_address?${createQueryParams(checkUsernameRequestDto)}`,
        method: 'GET'
      }),
      transformResponse: handleTransformResponse('checkVerifyAddress'),
      transformErrorResponse: handleTransformErrorResponse('checkVerifyAddress')
    })
  })
});

export const { useGetAssetConfigQuery, useGetBalanceQuery, useLazyGetBalanceQuery, useCreateDepositAddressMutation, useRequestWithdrawalMutation, useRequestSendMutation, useLazyTransactionListQuery, useLazyHistoryListQuery, useLazyCheckVerifyAddressQuery } = assetsApi;
