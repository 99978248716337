import React from 'react';
import BaseButton from '../../common/button/BaseButton';
import useDevice from '../../../hooks/useDevice';
import Text from '../../common/text/Text';
import styled from '@emotion/styled';
import { AddChips, CashierIcon } from '../../common/icon';
import { ModalType, showModal } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';

interface CashierButtonProps {
  amount: string;
}

const CashierButton = ({ amount }: CashierButtonProps) => {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const showCashierModal = () => dispatch(showModal(ModalType.CashierModal));
  return (
    <CashierButtonWrap bgColor={'gray600_50'} size={isMobile ? 'btn32' : 'btn44'} onClick={showCashierModal}>
      <StyledAmount>
        {!isMobile && <AddChips w={20} h={20} />}
        <Text text={`$${amount}`} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Semibold'} textColor={'white'} />
      </StyledAmount>
      <StyledCashierButton>{isMobile ? <CashierIcon /> : 'Cashier'}</StyledCashierButton>
    </CashierButtonWrap>
  );
};

const CashierButtonWrap = styled(BaseButton)`
  width: fit-content;
`;

const StyledCashierButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  background-color: ${({ theme }) => theme.colors.primary600};
  color: ${({ theme }) => theme.colors.white};
  height: 100%;
  padding: 0 12px;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.primary700};
  }
`;

const StyledAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
  gap: 8px;
  @media (max-width: 768px) {
    padding: 0 12px;
  }
`;

export default CashierButton;
