import React from 'react';
import Text from 'src/components/common/text/Text';

import Checkbox from 'src/components/common/checkbox';
import { CheckButtonProps } from './types';
import { StyledCheckButton, StyledCheckButtonContent } from './styles';

const CheckButton = ({ text, isChecked, type = 'checkbox', isMobile, onClick, ...props }: CheckButtonProps) => {
  const handleCheck = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClick && onClick();
  };
  return (
    <StyledCheckButton {...props} type={type} onClick={handleCheck} isChecked={isChecked} shadow="button" w={200} block>
      <Checkbox allowClick={false} type={type} onChange={() => null} checked={isChecked} borderColor={isChecked ? 'error400' : 'gray600'} bgColor={isChecked ? 'error600' : 'gray500'} size="sm" />
      <StyledCheckButtonContent>
        <Text className="checkButton" text={text} fontStyle={isMobile ? 'TextSmall_Bold' : 'TextMd_Bold'} />
      </StyledCheckButtonContent>
    </StyledCheckButton>
  );
};

export default CheckButton;
