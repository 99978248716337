import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Country, Emoji } from '../api/responseTypes';
import { utilApi } from '../api/util';
import { PURGE } from 'redux-persist';

type UtilState = {
  countryList: Country[];
  emojiList: Emoji[];
};

const utilSlice = createSlice({
  name: 'util',
  initialState: {
    countryList: [],
    emojiList: []
  } as UtilState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(PURGE, (state, action) => {
      state.countryList = [];
      state.emojiList = [];
    });
    builder.addMatcher(utilApi.endpoints.countryList.matchFulfilled, (state, { payload }) => {
      state.countryList = payload.data;
    });
    builder.addMatcher(utilApi.endpoints.emojiList.matchFulfilled, (state, { payload }) => {
      state.emojiList = payload.data;
    });
  }
});

export default utilSlice;

export const selectCountryList = (state: RootState) => state.utilSlice.countryList;
export const selectEmojiList = (state: RootState) => state.utilSlice.emojiList;
