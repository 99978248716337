import { useEffect } from 'react';
import { useUserTableSetting } from './useUserTableSetting';
import SoundManager from '../utils/SoundUtil';
import _ from 'lodash';
import { useTableContext } from './TableProvider';

const useTableSound = () => {
  const setting = useUserTableSetting();
  const { myPlayerData } = useTableContext();
  useEffect(() => {
    /**
     * 사운드 설정 새로고침
     */
    if (!SoundManager._instance) return;
    if (setting.isSoundEnabled !== undefined) {
      SoundManager._instance?.setSoundsVolume(setting.isSoundEnabled ? 1 : 0);
    }
  }, [setting.isSoundEnabled, SoundManager._instance]);

  useEffect(() => {
    /**
     * 플레이어 상태에 따른 보이스 볼륨 조절
     */
    if (!SoundManager._instance) return;
    _.delay(() => {
      SoundManager._instance?.setVoiceVolume(myPlayerData && myPlayerData?.status !== 'FOLD' ? 1 : 0);
    }, 1000);
  }, [myPlayerData?.status, SoundManager._instance]);
};

export default useTableSound;
