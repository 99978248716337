import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';

export const TabContainer = styled.div<{ bgColor?: keyof typeof Colors }>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] : theme.colors.gray900)};
  border-radius: 1000px;
  width: 100%;
`;

export const Tab = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  background: transparent;
  color: ${({ theme }) => theme.colors.gray400};
  border: none;
  padding: 10px;
  margin: 6px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-radius: 1000px;

  &[aria-selected='true'] {
    background-color: ${({ theme }) => theme.colors.gray600};
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXm_Semibold};
  }
`;
